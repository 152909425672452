import React, { useState, useEffect } from "react";
import {
    Avatar,
    Button,
    TextField,
    Checkbox,
    FormControlLabel,
    FormGroup,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    InputAdornment,
    Paper,
    Typography,
    Box,
    Backdrop,
    CircularProgress,
    Link,
} from "@mui/material";
import { PhotoCamera } from '@mui/icons-material';
import API from "../../../API";
import useAlertDialog from "../../../Components/useAlertDialog";
import AlertDialog from "../../../Components/AlertDialog";
import { RateField } from "../../../Components/RateField";
import MuiPhoneInput from "../../../Components/MuiPhoneInput";
import UploadFileDialogPFP from "../../../CreatorUser/onboardupload";
import CheckCircle from "@mui/icons-material/CheckCircle";
import { Link as RouterLink } from 'react-router-dom';
import routes from "../../../Config/routes";
import { parseLocalFloat, parseLocalInt, region } from "../../../Utils/constants";
import CreatorPlatforms from "../../../Components/CreatorData/creatorPlatforms";
import CreatorInfo from "../../../Components/CreatorData/creatorInfo";
import { platformSelection, validatePlatformSelection } from "../../../Components/CreatorData/creatorValidation";
import { useCreator } from "../../../Hooks/agencySelectedCreatorContext";

export default function AgencyCreatorEdit({ }) {
    const { creatorData, refetch } = useCreator();
    const { dialogState, openDialog, closeDialog } = useAlertDialog();

    const [contactEmail, setContactEmail] = useState('');
    const [paymentEmail, setPaymentEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [creatorName, setCreatorName] = useState('');
    const [creatorRegion, setCreatorRegion] = useState('');
    const [platforms, setPlatforms] = useState(platformSelection);
    const [primaryMarket, setPrimaryMarket] = useState([]);
    const [notesContentStyle, setNotesContentStyle] = useState('');
    const [profilePhotoUrl, setProfilePhotoUrl] = useState('');
    const [race, setRace] = useState('');
    const [gender, setGender] = useState('');
    const [location, setLocation] = useState('');

    // Instagram
    const [instagramLink, setInstagramLink] = useState("");
    const [instagramBrandRate, setInstagramBrandRate] = useState("");
    const [instagramFollowerCount, setInstagramFollowerCount] = useState("");
    const [instagramSongRate, setInstagramSongRate] = useState("");
    const [instagramStoryRate, setInstagramStoryRate] = useState("");
    const [instagramFeedRate, setInstagramFeedRate] = useState("");

    // TikTok
    const [tikTokLink, setTikTokLink] = useState("");
    const [tikTokFollowerCount, setTikTokFollowerCount] = useState("");
    const [tikTokBrandRate, setTikTokBrandRate] = useState("");
    const [tikTokSongRate, setTikTokSongRate] = useState("");

    // YouTube
    const [youtubeLink, setYoutubeLink] = useState("");
    const [youtubeFollowerCount, setYoutubeFollowerCount] = useState("");
    const [youtubeIntegrationRate, setYoutubeIntegrationRate] = useState("");
    const [youtubeShortsRate, setYoutubeShortsRate] = useState("");
    const [youtubeDedicatedRate, setYoutubeDedicatedRate] = useState("");

    const [xLink, setXLink] = useState('');
    const [xFollowerCount, setXFollowerCount] = useState('');
    const [xFeedPost, setXFeedPost] = useState('');
    const [xRepost, setXRepost] = useState('');

    const [podcastLink, setPodcastLink] = useState('');
    const [podcastFollowerCount, setPodcastFollowerCount] = useState('');
    const [podcastPreroll, setPodcastPreroll] = useState('');
    const [podcastMidroll, setPodcastMidroll] = useState('');
    const [podcastPostroll, setPodcastPostroll] = useState('');
    const [podcastFull, setPodcastFull] = useState('');

    const [kickLink, setKickLink] = useState('');
    const [kickFollowerCount, setKickFollowerCount] = useState('');
    const [kickPlacement, setKickPlacement] = useState('');

    const [twitchLink, setTwitchLink] = useState('');
    const [twitchFollowerCount, setTwitchFollowerCount] = useState('');
    const [twitchPlacement, setTwitchPlacement] = useState('');

    const [userStatus, setUserStatus] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [validationError, setValidationError] = useState("");
    const [paymentMethod, setPaymentMethod] = useState("PayPal");
    const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);

    useEffect(() => {
        if (creatorData) {
            GetUserData();
        }
    }, [creatorData])

    function GetUserData() {

        const user = creatorData.creator_user;

        const creator = creatorData.creator;

        setUserStatus(creator?.status ? creator.status : (user?.refresh_token ? 'Partner' : 'Associate'));
        setPaymentMethod(user?.payout_preferred || 'PayPal');


        setContactEmail(creator.email);
        setPaymentEmail(user?.paypal || creator.payout_email || '');
        setPhoneNumber(creator.phone_number || '');
        setCreatorName(creator.creator);
        setCreatorRegion(creator.region || '');
        setPlatforms({
            TikTok: creator.tiktok_link !== null,
            Instagram: creator.instagram_link !== null,
            YouTube: creator.youtube_link !== null,
            X: creator.x_link !== null,
            Podcast: creator.podcast_link !== null,
            Kick: creator.kick_link !== null,
            Twitch: creator.twitch_link !== null,
          });
        setPrimaryMarket(creator.primary_markets || []);
        setNotesContentStyle(creator.notes_content_style || '');
        setRace(creator.ethnicity || '');
        setGender(creator.gender || '');
        setLocation(creator.geolocation || '');
        setProfilePhotoUrl(creator.pfphref);

        //Instagram
        setInstagramLink(creator.instagram_link ? creator.instagram_link.replace("https://www.instagram.com/", "") : "");
        setInstagramFollowerCount(creator.instagram ? formatNumber(creator.instagram) : "");

        setInstagramBrandRate(creator.rate_ig_reelbrand?.toFixed(2) || "");
        setInstagramSongRate(creator.rate_ig_reelsound?.toFixed(2) || "");
        setInstagramFeedRate(creator.rate_ig_feedpost?.toFixed(2) || "");
        setInstagramStoryRate(creator.rate_ig_story?.toFixed(2) || "");

        //TikTok
        setTikTokLink(creator.tiktok_link ? creator.tiktok_link.replace("https://www.tiktok.com/@", "") : "");
        setTikTokFollowerCount(creator.tiktok ? formatNumber(creator.tiktok) : "");

        setTikTokBrandRate(creator.rate_tt_brand?.toFixed(2) || "");
        setTikTokSongRate(creator.rate_tt_sound?.toFixed(2) || "");

        //Youtube
        setYoutubeLink(creator.youtube_link ? creator.youtube_link.replace("https://www.youtube.com/", "") : "");
        setYoutubeFollowerCount(creator.youtube ? formatNumber(creator.youtube) : "");

        setYoutubeIntegrationRate(creator.rate_yt_integ?.toFixed(2) || "");
        setYoutubeShortsRate(creator.rate_yt_short?.toFixed(2) || "");
        setYoutubeDedicatedRate(creator.rate_yt_dedicated?.toFixed(2) || "");

        //X
        setXLink(creator.x_link ? creator.x_link.replace("https://www.x.com/", "") : "");
        setXFollowerCount(creator.follow_x ? formatNumber(creator.follow_x.toString()) : "");

        setXFeedPost(creator.rate_x_feedpost?.toFixed(2) || "");
        setXRepost(creator.rate_x_repost?.toFixed(2) || "");

        //Kick
        setKickLink(creator.kick_link ? creator.kick_link.replace("https://www.kick.com/", "") : "");
        setKickFollowerCount(creator.follow_kick ? formatNumber(creator.follow_kick.toString()) : "");

        setKickPlacement(creator.rate_kick_product?.toFixed(2) || "");

        //Twitch
        setTwitchLink(creator.twitch_link ? creator.twitch_link.replace("https://www.twitch.tv/", "") : "");
        setTwitchFollowerCount(creator.twitch ? formatNumber(creator.twitch) : "");
        setTwitchPlacement(creator.rate_twitch_product?.toFixed(2) || "");

        //Podcast
        setPodcastLink(creator.podcast_link || "");
        setPodcastFollowerCount(creator.follow_podcast ? formatNumber(creator.follow_podcast) : "");
        setPodcastPreroll(creator.rate_podcast_preroll?.toFixed(2) || "");
        setPodcastMidroll(creator.rate_podcast_midroll?.toFixed(2) || "");
        setPodcastPostroll(creator.rate_podcast_postroll?.toFixed(2) || "");
        setPodcastFull(creator.rate_podcast_full?.toFixed(2) || "");
    }

    const handleOpenUploadDialog = () => {
        setIsUploadDialogOpen(true);
    };

    const handleCloseUploadDialog = (uploadedUrl) => {
        console.log(uploadedUrl)
        if (uploadedUrl) {
            setProfilePhotoUrl(uploadedUrl);
        }
        setIsUploadDialogOpen(false);
    };

    const formatNumber = (value) => {
        const number = parseInt(value, 10);
        return isNaN(number) ? "0" : number.toString();
    };

    const handleStartSaveCreator = () => {
        handleSaveCreator();
    };

    const handleSaveCreator = async () => {

        if (phoneNumber.length < 11) {
            alert(`This Phone number is not valid`);
            return;
        }

        if (creatorName.includes(" ")) {
            setValidationError("Creator name cannot contain spaces.");
            return;
        }

        if (!validatePlatformSelection(
            platforms,
            (message) => alert(message),
            tikTokBrandRate, tikTokSongRate,
            instagramBrandRate, instagramSongRate, instagramFeedRate, instagramStoryRate,
            youtubeIntegrationRate, youtubeShortsRate, youtubeDedicatedRate,
            xFeedPost, xRepost,
            kickPlacement,
            twitchPlacement,
            podcastPreroll,
            podcastMidroll,
            podcastPostroll,
            podcastFull
        )) {
            return;
        }

        setIsLoading(true);

        const formattedTikTokLink = tikTokLink ? `https://www.tiktok.com/@${tikTokLink}` : null;
        const formattedInstagramLink = instagramLink ? `https://www.instagram.com/${instagramLink}` : null;
        const formattedYouTubeLink = youtubeLink ? `https://www.youtube.com/${youtubeLink}` : null;
        const formattedXLink = xLink ? `https://www.x.com/${xLink}` : null;
        const formattedKickLink = kickLink ? `https://www.kick.com/${kickLink}` : null;
        const formattedTwitchLink = twitchLink ? `https://www.twitch.tv/${twitchLink}` : null;

        let payload = null

        try {
            payload = {
                tiktok: parseLocalInt(tikTokFollowerCount) || null,
                tiktok_link: formattedTikTokLink || null,
                instagram: parseLocalInt(instagramFollowerCount) || null,
                instagram_link: formattedInstagramLink || null,
                youtube: parseLocalInt(youtubeFollowerCount) || null,
                youtube_link: formattedYouTubeLink || null,

                twitch: parseLocalInt(twitchFollowerCount) || null,
                twitch_link: formattedTwitchLink || null,

                follow_x: parseLocalInt(xFollowerCount) || null,
                x_link: formattedXLink || null,

                follow_podcast: parseLocalInt(podcastFollowerCount) || null,
                podcast_link: podcastLink || null,

                follow_kick: parseLocalInt(kickFollowerCount) || null,
                kick_link: formattedKickLink || null,

                rate_tt_sound: parseLocalFloat(tikTokSongRate),
                rate_tt_brand: parseLocalFloat(tikTokBrandRate),

                rate_ig_feedpost: parseLocalFloat(instagramStoryRate),
                rate_ig_reelsound: parseLocalFloat(instagramSongRate),
                rate_ig_reelbrand: parseLocalFloat(instagramBrandRate),
                rate_ig_story: parseLocalFloat(instagramStoryRate),

                rate_yt_integ: parseLocalFloat(youtubeIntegrationRate),
                rate_yt_short: parseLocalFloat(youtubeShortsRate),
                rate_yt_dedicated: parseLocalFloat(youtubeDedicatedRate),

                rate_podcast_preroll: parseLocalFloat(podcastPreroll),
                rate_podcast_midroll: parseLocalFloat(podcastMidroll),
                rate_podcast_postroll: parseLocalFloat(podcastPostroll),
                rate_podcast_full: parseLocalFloat(podcastFull),

                rate_twitch_product: parseLocalFloat(twitchPlacement),
                rate_kick_product: parseLocalFloat(kickPlacement),
                rate_x_feedpost: parseLocalFloat(xFeedPost),
                rate_x_repost: parseLocalFloat(xRepost),

                geolocation: location,
                gender: gender,
                ethnicity: race,
                primary_markets: primaryMarket,
                region: creatorRegion,
                notescontent_style: notesContentStyle || null,

                contactEmail: contactEmail,
                paymentEmail: paymentEmail,
                phone_number: phoneNumber,
                pfphref: profilePhotoUrl,
            };
        } catch (error) {
            alert(`Error parsing this form: ${error}`);
            setIsLoading(false);
            return;
        }


        try {
            await API.agency.editCreator(creatorData.creator.creator, payload);
            openDialog("Success", "Creator data edited successfully", closeDialog, closeDialog, "Ok", null);
            refetch();
        } catch (error) {
            openDialog("Error", `Network error: ${error.response.data && error.response.data.error ? error.response.data.error : error}`, closeDialog, closeDialog, "Ok", null);
            console.error('Network error:', error);
        }
        setIsLoading(false);
    };

    return (
        <>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <AlertDialog alertState={dialogState}></AlertDialog>
            <Box sx={{ paddingInline: 4, paddingBlockStart: 2, paddingBlockEnd: 4, marginBlockStart: 2, maxWidth: '50em', marginInline: { xs: "auto", md: 0 } }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 3 }}>
                    <Avatar
                        alt={creatorName}
                        src={profilePhotoUrl}
                        sx={{ width: 100, height: 100, cursor: 'pointer' }}
                        onClick={handleOpenUploadDialog}
                    >
                        {!profilePhotoUrl && <PhotoCamera fontSize="large" />}
                    </Avatar>
                </Box>
                <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                    {!!creatorData?.creator_user ? 'This creator is verified' : 'This creator is not verified'}
                    <CheckCircle sx={{ marginLeft: '8px', color: !!creatorData?.creator_user ? 'success.main' : 'action.disabled' }} />
                </Typography>
                {!creatorData?.creator_user && <Link variant="subtitle2" component={RouterLink} to={routes.creatorConnect.replace(':creator', creatorData?.creator?.creator)}>
                    Send your creator this link to verify them through CreatorConnect!
                </Link>}

                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleStartSaveCreator();
                    }}
                >
                    <Typography variant="h5" sx={{ marginBlockEnd: 2, marginBlockStart: 1 }}>Payment Information</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1rem', alignItems: "end" }}>
                        <FormControl fullWidth sx={{ flex: 1 }} margin="dense" disabled>
                            <InputLabel>Payment Method</InputLabel>
                            <Select value={paymentMethod} label='Payment Method'>
                                <MenuItem value="PayPal">PayPal, Standard, Fees Apply</MenuItem>
                                <MenuItem value="Stripe">BlitzPay, Faster, Less Fees</MenuItem>
                            </Select>
                        </FormControl>
                        {<TextField
                            margin="dense"
                            label="Paypal Payment Email"
                            type="email"
                            disabled={paymentMethod != 'PayPal'}
                            fullWidth
                            sx={{ flex: 1 }}
                            value={paymentEmail}
                            onChange={(e) => setPaymentEmail(e.target.value)}
                            variant="outlined"
                            required
                        />}
                    </Box>
                    <CreatorInfo
                        creatorName={creatorName}
                        setCreatorName={null}
                        creatorRegion={creatorRegion}
                        setCreatorRegion={setCreatorRegion}
                        race={race}
                        setRace={setRace}
                        gender={gender}
                        setGender={setGender}
                        location={location}
                        setLocation={setLocation}
                        primaryMarket={primaryMarket}
                        setPrimaryMarket={setPrimaryMarket}
                        notesContentStyle={notesContentStyle}
                        setNotesContentStyle={setNotesContentStyle}
                        contactEmail={contactEmail}
                        setContactEmail={setContactEmail}
                        phoneNumber={phoneNumber}
                        setPhoneNumber={setPhoneNumber}
                        validationError={validationError}
                    ></CreatorInfo>
                    <Typography variant="h5" sx={{ marginBlockEnd: 1, marginBlockStart: 1 }}>Platform and Rates</Typography>
                    <CreatorPlatforms
                        platforms={platforms} setPlatforms={setPlatforms}
                        instagramLink={instagramLink} setInstagramLink={setInstagramLink}
                        instagramFollowerCount={instagramFollowerCount} setInstagramFollowerCount={setInstagramFollowerCount}
                        instagramBrandRate={instagramBrandRate} setInstagramBrandRate={setInstagramBrandRate}
                        instagramSongRate={instagramSongRate} setInstagramSongRate={setInstagramSongRate}
                        instagramFeedRate={instagramFeedRate} setInstagramFeedRate={setInstagramFeedRate}
                        instagramStoryRate={instagramStoryRate} setInstagramStoryRate={setInstagramStoryRate}
                        tikTokLink={tikTokLink} setTikTokLink={setTikTokLink}
                        tikTokFollowerCount={tikTokFollowerCount} setTikTokFollowerCount={setTikTokFollowerCount}
                        tikTokBrandRate={tikTokBrandRate} setTikTokBrandRate={setTikTokBrandRate}
                        tikTokSongRate={tikTokSongRate} setTikTokSongRate={setTikTokSongRate}
                        youtubeLink={youtubeLink} setYoutubeLink={setYoutubeLink}
                        youtubeFollowerCount={youtubeFollowerCount} setYoutubeFollowerCount={setYoutubeFollowerCount}
                        youtubeIntegrationRate={youtubeIntegrationRate} setYoutubeIntegrationRate={setYoutubeIntegrationRate}
                        youtubeShortsRate={youtubeShortsRate} setYoutubeShortsRate={setYoutubeShortsRate}
                        youtubeDedicatedRate={youtubeDedicatedRate} setYoutubeDedicatedRate={setYoutubeDedicatedRate}
                        xLink={xLink} setXLink={setXLink}
                        xFollowerCount={xFollowerCount} setXFollowerCount={setXFollowerCount}
                        xFeedPost={xFeedPost} setXFeedPost={setXFeedPost}
                        xRepost={xRepost} setXRepost={setXRepost}
                        podcastLink={podcastLink} setPodcastLink={setPodcastLink}
                        podcastFollowerCount={podcastFollowerCount} setPodcastFollowerCount={setPodcastFollowerCount}
                        podcastPreroll={podcastPreroll} setPodcastPreroll={setPodcastPreroll}
                        podcastMidroll={podcastMidroll} setPodcastMidroll={setPodcastMidroll}
                        podcastPostroll={podcastPostroll} setPodcastPostroll={setPodcastPostroll}
                        podcastFull={podcastFull} setPodcastFull={setPodcastFull}
                        kickLink={kickLink} setKickLink={setKickLink}
                        kickFollowerCount={kickFollowerCount} setKickFollowerCount={setKickFollowerCount}
                        kickPlacement={kickPlacement} setKickPlacement={setKickPlacement}
                        twitchLink={twitchLink} setTwitchLink={setTwitchLink}
                        twitchFollowerCount={twitchFollowerCount} setTwitchFollowerCount={setTwitchFollowerCount}
                        twitchPlacement={twitchPlacement} setTwitchPlacement={setTwitchPlacement}
                    ></CreatorPlatforms>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isLoading}
                        sx={{ marginTop: 1, marginBottom: 2 }}
                    >
                        Save Changes
                    </Button>
                </form>
            </Box>
            <UploadFileDialogPFP open={isUploadDialogOpen} onClose={handleCloseUploadDialog} />
        </>
    );
}

import React, { useState, useEffect } from "react";
import { Button, Checkbox, IconButton, MenuItem, Select, FormControl, InputLabel, Box, FormControlLabel, Switch, Pagination, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useMutation, useQuery } from "react-query";
import client from "../../../../API";
import { StyledTableRow, StyledTableCell } from "../../../../Utils/styledcell";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import PayoutDialog from "../payoutdialog";
import { drawerWidth, payoutStatuses } from "../../../../Utils/constants";
import { useIsMounted } from "../../../../Hooks/use-is-mounted";
import {
  StyleButton,
  StyleContent,
  StyleStickyButtonContainter, StyleContentWithNavBar
} from "../../../../Utils/ThemedStyles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const PayoutDetailsView = () => {
  const isMounted = useIsMounted();
  const [selectedIds, setSelectedIds] = useState([]);
  const [isPayoutDialogOpen, setPayoutDialogOpen] = useState(false);
  const [editingPayout, setEditingPayout] = useState(null);
  const [statusFilter, setStatusFilter] = useState("");

  const [page, setPage] = useState(0);
  const itemsPerPage = 10;
  const [usePagination, setUsePagination] = useState(true);

  const { data: payouts, refetch: fetchPayouts, error } = useQuery({
    initialData: [],
    queryKey: ['payouts'],
    queryFn: () => client.payouts.listAdmin(),
    refetchOnWindowFocus:false
  });

  useEffect(() => {
    setSelectedIds([]);
  }, [payouts]);

  useEffect(() => {
    if (error) {
      alert(error?.response?.data?.error || error?.response?.data?.message || error.message);
      console.error("Error fetching payouts:", error);
    }
  }, [error]);

  const onDeletePayouts = async () => {
    if (selectedIds.length > 0) {
      try {
        const data = await client.payouts.delete({ payoutIds: selectedIds });
        console.log("Deletion successful", data);
        fetchPayouts();
      } catch (error) {
        console.error("Error deleting payouts", error);
      }
    }
  };

  const handleDialogOpen = (payout) => {
    setEditingPayout(payout);
    setPayoutDialogOpen(true);
  };

  const handleDialogClose = (changed) => {
    setPayoutDialogOpen(false);
    setEditingPayout(null);
    if(changed)
      fetchPayouts();
  };

  const handleSelectPayout = (payoutId) => {
    const newSelectedIds = selectedIds.includes(payoutId)
      ? selectedIds.filter(id => id !== payoutId)
      : [...selectedIds, payoutId];
    setSelectedIds(newSelectedIds);
  };

  const handleFilterChange = (event) => {
    setStatusFilter(event.target.value);
    setPage(0);
  };

  const filteredPayouts = statusFilter
    ? payouts.filter(payout => payout.status === statusFilter)
    : payouts;

  const pagedPayouts = usePagination ? filteredPayouts.slice(page * itemsPerPage, Math.min((page * itemsPerPage) + itemsPerPage, filteredPayouts.length)) : filteredPayouts;

  return (
    <Box sx={StyleContentWithNavBar}>
      <Box sx={StyleStickyButtonContainter}>
        <Button
          variant="contained"
          onClick={onDeletePayouts}
          startIcon={<DeleteIcon />}
          disabled={!selectedIds.length}
          sx={StyleButton}
        >
          Delete Selected
        </Button>
        <TextField
          select
          value={statusFilter}
          onChange={handleFilterChange}
          label='Status'
          sx={{ minWidth: '10rem' }}
          SelectProps={{sx:{textTransform:'capitalize'}}}
        >
          <MenuItem value="all">All Statuses</MenuItem>
          {payoutStatuses.map((payoutStatus) => <MenuItem key={payoutStatus} value={payoutStatus} sx={{ textTransform: 'capitalize' }}>{payoutStatus}</MenuItem>)}
        </TextField>
        <FormControlLabel
          label='Use pagination'
          checked={usePagination}
          onChange={() => setUsePagination(prev => !prev)}
          color="primary"
          control={<Switch></Switch>}
        ></FormControlLabel>
      </Box>
      <TableContainer component={Paper} sx={(theme) => ({ marginTop: theme.spacing(2) })}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={selectedIds.length > 0 && selectedIds.length < filteredPayouts.length}
                  checked={selectedIds.length === filteredPayouts.length && filteredPayouts.length > 0}
                  onChange={(event) => setSelectedIds(
                    event.target.checked ? filteredPayouts.map((n) => n.id) : []
                  )}
                />
              </TableCell>
              <TableCell>Payout ID</TableCell>
              <TableCell>PO Number</TableCell>
              <TableCell>Creator Name</TableCell>
              <TableCell>Issued At</TableCell>
              <TableCell>Is Creator Connected</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>BlitzPay</TableCell>
              <TableCell>Subtotal</TableCell>
              <TableCell>Fee</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Who's Paying Fee?</TableCell>

              <TableCell>Client Name</TableCell>
              <TableCell>Company Name</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pagedPayouts?.map((payout) => (
              <StyledTableRow key={payout.id}>
                <StyledTableCell padding="checkbox">
                  <Checkbox
                    onChange={() => handleSelectPayout(payout.id)}
                    checked={selectedIds.includes(payout.id)}
                  />
                </StyledTableCell>
                <StyledTableCell>{payout.id}</StyledTableCell>
                <StyledTableCell sx={{textWrap:'nowrap'}}>{payout.po_number}</StyledTableCell>
                <StyledTableCell>{payout.creatorName} ({payout.creatorStatus})</StyledTableCell>
                <StyledTableCell>{payout.payout_date.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</StyledTableCell>
                <StyledTableCell>{payout.isCreatorConnected}</StyledTableCell>
                <StyledTableCell sx={{ textTransform: 'capitalize' }}>{payout.status}</StyledTableCell>
                <StyledTableCell>{payout.blitzpay ? "Yes" : "No"}</StyledTableCell>
                <StyledTableCell>${payout.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</StyledTableCell>
                <StyledTableCell>{payout.interest_rate}</StyledTableCell>
                <StyledTableCell>
                  ${(payout.amount * (1 - (payout.interest_rate / 100))).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </StyledTableCell>
                <StyledTableCell>{payout.role || ""}</StyledTableCell>

                <StyledTableCell>{payout.clientName}</StyledTableCell>
                <StyledTableCell>{payout.companyName}</StyledTableCell>
                <StyledTableCell>
                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <IconButton onClick={() => handleDialogOpen(payout)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDialogOpen(payout)}>
                      {<CheckCircleIcon></CheckCircleIcon>}
                    </IconButton>
                  </Box>
                </StyledTableCell>

              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {
        usePagination && <Box sx={{ paddingBlock: 2 }}>
          <Pagination
            sx={{ marginInline: 'auto', maxWidth: 'fit-content' }}
            count={Math.floor(filteredPayouts.length / itemsPerPage)} page={page + 1}
            onChange={(event, value) => setPage(value - 1)}>
          </Pagination>
        </Box>
      }
      {
        isPayoutDialogOpen && (
          <PayoutDialog
            open={isPayoutDialogOpen}
            onClose={handleDialogClose}
            payoutInfo={editingPayout}
          />
        )
      }
    </Box >
  );
};

export default PayoutDetailsView;

import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  Card,
  Divider,
  CircularProgress,
  Paper,
  TextField,
  Button,
  IconButton,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  Tooltip,
  useTheme,
  Stack
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import {
  InsertDriveFileOutlined,
  ImageOutlined,
  CampaignOutlined,
  GroupOutlined,
  DescriptionOutlined,
  DownloadOutlined,
  CloseOutlined,
  DoneOutlined,
  PersonOutlined
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import SelectFileDialog from '../../../Components/tools/selectfile';
import { useQuery } from 'react-query';
import client from '../../../API';
import SelectCampaignDialog from '../../../Components/tools/selectcampaigndialog';
import SelectCreatorDialog from '../../../Components/tools/selectcreatordialog';

const SafeAttachment = ({ attachment }) => {
  const theme = useTheme();
  
  const renderAttachment = (att) => {
    const attachmentType = att.type || att.attachment_type;
    const fileData = att.file || att.file_data;

    const getIcon = () => {
      switch (attachmentType) {
        case 'file':
          return fileData?.content_type?.startsWith('image/') ? (
            <ImageOutlined fontSize="small" />
          ) : (
            <InsertDriveFileOutlined fontSize="small" />
          );
        case 'campaign':
          return <CampaignOutlined fontSize="small" />;
        case 'roster':
          return <GroupOutlined fontSize="small" />;
        case 'mediakit':
          return <DescriptionOutlined fontSize="small" />;
        case 'creator':
          return <PersonOutlined fontSize="small" />;
        default:
          return <InsertDriveFileOutlined fontSize="small" />;
      }
    };

    const getContent = () => {
      if (!fileData) {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <CircularProgress size={20} />
            <Typography variant="body2">Loading attachment...</Typography>
          </Box>
        );
      }

      if (attachmentType === 'file') {
        const isImage = fileData.content_type?.startsWith('image/');
        
        return isImage ? (
          <Box
            component="img"
            src={fileData.url}
            alt="Attachment"
            sx={{
              maxWidth: 200,
              maxHeight: 200,
              borderRadius: 1,
              cursor: 'pointer',
              transition: '0.3s',
              '&:hover': {
                boxShadow: theme.shadows[3],
                transform: 'scale(1.02)'
              }
            }}
            onClick={() => window.open(fileData.url, '_blank')}
          />
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <IconButton 
              href={fileData.url} 
              download={fileData.filename}
              sx={{ color: theme.palette.primary.main }}
            >
              <DownloadOutlined />
            </IconButton>
            <Typography variant="body2">
              {fileData.filename}
            </Typography>
            <Chip
              label={(fileData.content_type?.split('/')[1] || 'file').toUpperCase()}
              size="small"
              sx={{ ml: 1 }}
            />
          </Box>
        );
      }

      if (attachmentType === 'campaign') {
        return (
          <Link 
            to={fileData.url} 
            style={{ 
              textDecoration: 'none', 
              color: 'inherit',
              display: 'inline-block'
            }}
          >
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: 1.5,
              cursor: 'pointer',
              transition: '0.2s',
              '&:hover': {
                opacity: 0.8,
                transform: 'translateX(2px)'
              }
            }}>
              <CampaignOutlined color="primary" />
              <Typography variant="body2">
                {fileData.meta?.name || 'Unnamed Campaign'}
              </Typography>
              <Chip
                label="Campaign"
                size="small"
                color="secondary"
                variant="outlined"
              />
            </Box>
          </Link>
        );
      }

      if (attachmentType === 'creator') {
        return (
          <Link 
            to={fileData.url}
            style={{ 
              textDecoration: 'none', 
              color: 'inherit',
              display: 'inline-block'
            }}
          >
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: 1.5,
              cursor: 'pointer',
              transition: '0.2s',
              '&:hover': {
                opacity: 0.8,
                transform: 'translateX(2px)'
              }
            }}>
              <Avatar 
                src={fileData.meta?.pfphref} 
                sx={{ width: 24, height: 24 }}
              />
              <Typography variant="body2">
                {fileData.meta?.creator || 'Unnamed Creator'}
              </Typography>
              <Chip
                label="Creator"
                size="small"
                color="secondary"
                variant="outlined"
              />
            </Box>
          </Link>
        );
      }

      return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {getIcon()}
          <Typography variant="body2">
            {att.campaign?.name && `Campaign: ${att.campaign.name}`}
            {att.roster?.name && `Roster: ${att.roster.name}`}
            {att.creator?.name && `Creator: ${att.creator.name}`}
            {attachmentType === 'mediakit' && 'Media Kit'}
          </Typography>
        </Box>
      );
    };

    return (
      <Paper
        key={att.id}
        elevation={0}
        sx={{
          mt: 1,
          p: 1.5,
          borderRadius: 2,
          border: `1px solid ${theme.palette.divider}`,
          backgroundColor: theme.palette.background.default,
          width: 'fit-content'
        }}
      >
        <Tooltip title={`${attachmentType.charAt(0).toUpperCase() + attachmentType.slice(1)} Attachment`}>
          {getContent()}
        </Tooltip>
      </Paper>
    );
  };

  try {
    return renderAttachment(attachment);
  } catch (error) {
    console.error('Error rendering attachment:', error);
    return (
      <Paper sx={{ p: 1, bgcolor: 'error.light' }}>
        <Typography variant="caption">Error displaying attachment</Typography>
      </Paper>
    );
  }
};

export default function RightPanel({
  theme = useTheme(),
  isMobile,
  isLeftPanelCollapsed,
  selectedConversation,
  messages,
  isLoadingMessages,
  messageText,
  setMessageText,
  handleSendMessage,
  sendMessageMutation,
  currentUser,
  toggleLeftPanel,
  handleClickAddParticipant
}) {
  const messagesContainerRef = useRef(null);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [campaignDialogOpen, setCampaignDialogOpen] = useState(false);
  const [creatorDialogOpen, setCreatorDialogOpen] = useState(false);
  const [attachmentDialogsOpen, setAttachmentDialogsOpen] = useState({
    mediakit: false,
    roster: false
  });
  const [selectedFile, setSelectedFile] = useState(null);

  const { data: files, refetch: refetchFiles } = useQuery('files', () => 
    client.files.fetch("")
  );

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const handleFileSelect = (file) => {
    setSelectedFile(file);
    setUploadDialogOpen(false);
  };

  const handleCampaignSelect = (campaign) => {
    const campaignAttachment = {
      type: 'campaign',
      file_data: {
        filename: `${campaign.name} Campaign`,
        content_type: 'application/campaign',
        url: `/campaigns/${campaign.id}`,
        meta: campaign
      }
    };
    setSelectedFile(campaignAttachment);
    setCampaignDialogOpen(false);
  };

  const handleCreatorSelect = (creator) => {
    const creatorAttachment = {
      type: 'creator',
      file_data: {
        filename: `${creator.creator} Profile`,
        content_type: 'application/creator',
        url: `/creator/${creator.creator}`,
        meta: creator
      }
    };
    setSelectedFile(creatorAttachment);
    setCreatorDialogOpen(false);
  };

  const handleSend = () => {
    const attachments = [];
    
    if (selectedFile) {
      if (selectedFile.type === 'campaign') {
        attachments.push({
          type: 'campaign',
          campaign_id: selectedFile.file_data.meta.id,
          meta: selectedFile.file_data.meta
        });
      } else if (selectedFile.type === 'creator') {
        attachments.push({
          type: 'creator',
          creator_id: selectedFile.file_data.meta.creator,
          meta: selectedFile.file_data.meta
        });
      } else {
        attachments.push({
          type: 'file',
          file_id: selectedFile.id
        });
      }
    }

    handleSendMessage({
      message: messageText,
      attachments
    });
    
    setMessageText('');
    setSelectedFile(null);
  };

  const formatMessage = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex).map((part, index) => {
      if (urlRegex.test(part)) {
        return (
          <a
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: theme.palette.primary.main }}
          >
            {part}
          </a>
        );
      }
      return part;
    });
  };

  const handleAttachmentDialog = (type, open) => {
    if (type === 'campaign') {
      setCampaignDialogOpen(open);
    } else if (type === 'creator') {
      setCreatorDialogOpen(open);
    } else {
      setAttachmentDialogsOpen(prev => ({ ...prev, [type.toLowerCase()]: open }));
    }
  };

  return (
    <Card sx={{ flex: 1, height: '100%', display: 'flex', flexDirection: 'column' }}>
      {/* Header */}
      <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {!isMobile && (
            <IconButton onClick={toggleLeftPanel}>
              {isLeftPanelCollapsed ? <MenuIcon /> : <ChevronRightIcon />}
            </IconButton>
          )}
          <Typography variant="h6">
            {selectedConversation?.display_name || 'Select a conversation'}
          </Typography>
        </Box>
        {selectedConversation && (
          <Button variant="outlined" onClick={handleClickAddParticipant}>
            Add Participant
          </Button>
        )}
      </Box>

      <Divider />

      {/* Attachment Buttons */}
      {selectedConversation && (
        <Box sx={{ p: 2, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
          <Button
            variant="contained"
            startIcon={<InsertDriveFileOutlined />}
            onClick={() => setUploadDialogOpen(true)}
          >
            Attach File
          </Button>
          <Button
            variant="outlined"
            startIcon={<CampaignOutlined />}
            onClick={() => handleAttachmentDialog('campaign', true)}
          >
            Campaign
          </Button>
          <Button
            variant="outlined"
            startIcon={<PersonOutlined />}
            onClick={() => handleAttachmentDialog('creator', true)}
          >
            Creator
          </Button>
         
        </Box>
      )}

      {/* Messages Area */}
      {selectedConversation ? (
        <>
          <Box ref={messagesContainerRef} sx={{ flex: 1, overflowY: 'auto', p: 2 }}>
            {isLoadingMessages ? (
              <CircularProgress sx={{ mx: 'auto', my: 4 }} />
            ) : (
              messages.map((msg) => {
                const isCurrentUser = msg.sender_id === currentUser?.id;
                const sender = msg.sender || msg.creator;
                const avatarSrc = sender?.pfphref || '';
                const profileLink = msg.sender ? `/user/${msg.sender.id}` : `/creator/${msg.creator?.creator}`;

                return (
                  <Box
                    key={msg.id}
                    sx={{
                      display: 'flex',
                      justifyContent: isCurrentUser ? 'flex-end' : 'flex-start',
                      mb: 2,
                      gap: 1
                    }}
                  >
                    {!isCurrentUser && (
                      <Avatar src={avatarSrc} sx={{ width: 40, height: 40 }} />
                    )}

                    <Paper
                      sx={{
                        maxWidth: '70%',
                        p: 2,
                        borderRadius: 3,
                        bgcolor: isCurrentUser ? 'primary.main' : 'background.paper',
                        color: isCurrentUser ? 'primary.contrastText' : 'text.primary'
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, gap: 1 }}>
                        <Link
                          to={profileLink}
                          style={{
                            color: 'inherit',
                            textDecoration: 'none',
                            fontWeight: 500
                          }}
                        >
                          {sender?.first_name ? `${sender.first_name} ${sender.last_name}` : sender?.creator}
                        </Link>
                        <Typography variant="caption" sx={{ opacity: 0.8 }}>
                          {new Date(msg.created_at).toLocaleTimeString()}
                        </Typography>
                      </Box>

                      <Typography variant="body1" paragraph>
                        {formatMessage(msg.message)}
                      </Typography>

                      {msg.attachments?.length > 0 && (
                        <Box sx={{ mt: 1.5 }}>
                          {msg.attachments.map((att) => (
                            <SafeAttachment key={att.id} attachment={att} />
                          ))}
                        </Box>
                      )}

                      {Object.keys(msg.read_receipts_detailed || {}).length > 0 && (
                        <Box sx={{ mt: 1, display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
                          {Object.entries(msg.read_receipts_detailed).map(([id, info]) => (
                            <Chip
                              key={id}
                              label={`${info.displayName} read`}
                              size="small"
                              variant="outlined"
                              sx={{
                                fontSize: '0.65rem',
                                color: 'text.secondary',
                                borderColor: 'divider'
                              }}
                            />
                          ))}
                        </Box>
                      )}
                    </Paper>

                    {isCurrentUser && (
                      <Avatar src={avatarSrc} sx={{ width: 40, height: 40 }} />
                    )}
                  </Box>
                );
              })
            )}
          </Box>

          {/* Message Input */}
          <Box sx={{ p: 2, borderTop: `1px solid ${theme.palette.divider}` }}>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Type your message..."
                value={messageText}
                onChange={(e) => setMessageText(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && !e.shiftKey && handleSend()}
                disabled={sendMessageMutation.isLoading}
                multiline
                maxRows={4}
                InputProps={{
                  startAdornment: selectedFile && (
                    <Chip
                      label={selectedFile.file_data?.filename}
                      onDelete={() => setSelectedFile(null)}
                      sx={{ mr: 1 }}
                      color="primary"
                      variant={selectedFile.file_data?.url ? 'filled' : 'outlined'}
                      deleteIcon={selectedFile.file_data?.url ? <DoneOutlined /> : <CloseOutlined />}
                    />
                  ),
                }}
              />
              <Button
                variant="contained"
                onClick={handleSend}
                disabled={(!messageText.trim() && !selectedFile) || sendMessageMutation.isLoading}
                sx={{ minWidth: 100 }}
              >
                {sendMessageMutation.isLoading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <>
                    Send <SendIcon sx={{ ml: 1 }} />
                  </>
                )}
              </Button>
            </Box>
          </Box>
        </>
      ) : (
        <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography variant="h6" color="text.secondary">
            Select a conversation
          </Typography>
        </Box>
      )}

      {/* File Selection Dialog */}
      <SelectFileDialog
        open={uploadDialogOpen}
        onClose={() => {
          setUploadDialogOpen(false);
          refetchFiles();
        }}
        files={files}
        selectedAsset={selectedFile}
        onAssetClick={handleFileSelect}
        refetchFiles={refetchFiles}
      />

      {/* Campaign Selection Dialog */}
      <SelectCampaignDialog
        open={campaignDialogOpen}
        onClose={() => setCampaignDialogOpen(false)}
        onCampaignSelect={handleCampaignSelect}
      />

      {/* Creator Selection Dialog */}
      <SelectCreatorDialog
        open={creatorDialogOpen}
        onClose={() => setCreatorDialogOpen(false)}
        onCreatorSelect={handleCreatorSelect}
      />

      {/* Other Attachment Dialogs */}
      {['mediakit', 'roster'].map((type) => (
        <Dialog
          key={type}
          open={attachmentDialogsOpen[type]}
          onClose={() => handleAttachmentDialog(type, false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>{`Attach ${type.charAt(0).toUpperCase() + type.slice(1)}`}</DialogTitle>
          <DialogContent>
            <Typography>
              {`Select a ${type} to attach to this message...`}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleAttachmentDialog(type, false)}>Cancel</Button>
            <Button variant="contained">Attach</Button>
          </DialogActions>
        </Dialog>
      ))}
    </Card>
  );
}
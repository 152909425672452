import React from 'react';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { RateField } from '../../../../Components/RateField';
import { platformRatesListFromCreator } from '../../../../Utils/constants';

export default function CreatorDetailsCard({
  creator,
  index,
  handlePriceChange,
  handleOptionChange,
  onRemove,
  triedNext,
}) {
  const promotions = platformRatesListFromCreator(creator);

  return (
    <Grid item xs={12} sm={6} md={6}>
      <Card
        sx={{
          borderRadius: 2,
          boxShadow: 3,
          overflow: 'hidden',
          position: 'relative',
          transition: 'transform 0.3s ease-in-out',
          '&:hover': {
            transform: 'scale(1.02)',
          },
        }}
      >
        <CardMedia
          component="img"
          image={creator.pfphref || '/default_profile.png'}
          alt={creator.creator}
          sx={{
            height: 250,
            objectFit: 'cover',
          }}
        />
        <CardContent sx={{ p: 3 }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
            {creator.creator}
          </Typography>
          <TextField
            error={triedNext && !creator.rateKey}
            helperText={
              triedNext && !creator.rateKey
                ? 'Select a promotion for this creator'
                : ''
            }
            value={creator.rateKey}
            onChange={(e) =>
              handleOptionChange(index, 'rateKey', e.target.value)
            }
            label="Promotion Type"
            select
            fullWidth
            variant="outlined"
            size="small"
            sx={{ mb: 2 }}
          >
            <MenuItem value="">None</MenuItem>
            {promotions.map((promo) => (
              <MenuItem
                key={`${creator.creator}-${promo.rateKey}`}
                value={promo.rateKey}
              >
                {promo.label}
              </MenuItem>
            ))}
          </TextField>
          <RateField
            error={
              triedNext && (!creator.basePrice || creator.basePrice === '0')
            }
            helperText={
              triedNext && (!creator.basePrice || creator.basePrice === '0')
                ? 'Enter a rate for this creator'
                : ''
            }
            label="Base Price"
            variant="outlined"
            fullWidth
            value={creator.basePrice}
            onChange={(value) => handlePriceChange(index, value)}
            sx={{ mb: 2 }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={creator.usageRights}
                onChange={(e) =>
                  handleOptionChange(index, 'usageRights', e.target.checked)
                }
                color="primary"
              />
            }
            label="Usage Rights"
            sx={{ mb: 2 }}
          />
          <Typography variant="body2" color="text.secondary">
            Adjusted Price: ${creator.adjustedPrice.toFixed(2)}
          </Typography>
        </CardContent>
        <IconButton
          size="small"
          onClick={() => onRemove(creator.creator)}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            backgroundColor: 'rgba(255,255,255,0.9)',
            '&:hover': { backgroundColor: 'rgba(255,255,255,1)' },
          }}
        >
          <DeleteIcon color="error" />
        </IconButton>
      </Card>
    </Grid>
  );
}

import React, { useState } from 'react';
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  TextField
} from '@mui/material';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import LeftPanel from './leftPanel';
import RightPanel from './rightPanel';
import API from '../../../API';
import useAuth from '../../../Hooks/use-auth';

export default function ConversationsHub() {
  const queryClient = useQueryClient();
  const { getCurrrentUser } = useAuth();
  const currentUser = getCurrrentUser();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [selectedConversationId, setSelectedConversationId] = useState(null);
  const [messageText, setMessageText] = useState('');
  const [isLeftPanelCollapsed, setIsLeftPanelCollapsed] = useState(false);

  // For the "Add Participant" dialog
  const [addParticipantOpen, setAddParticipantOpen] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState(null); // For user-based sharing
  const [inviteEmails, setInviteEmails] = useState(''); // For email-based invites
 // ========== NEW STATE FOR "CREATE NEW CHAT" ==========
 const [newChatOpen, setNewChatOpen] = useState(false);
 const [selectedNewChatUsers, setSelectedNewChatUsers] = useState([]);

 // ======================================
 // =========== QUERIES ==================
 // ======================================

 // 2) Create (or find) a new conversation with multiple participants
 const createNewChatMutation = useMutation(
   (recipientIds) => API.conversations.checkOrCreateConversation({ recipient_ids: recipientIds }),
   {
     onSuccess: (res) => {
       // res should look like: { conversation_id: 123 }
       queryClient.invalidateQueries(['userSentConversations']);
       setSelectedConversationId(res.conversation_id);
       setNewChatOpen(false);
       setSelectedNewChatUsers([]);
     },
     onError: (err) => {
       console.error('Error creating new conversation:', err);
     }
   }
 );
  // ======================================
  // =========== QUERIES ==================
  // ======================================
  const { data: conversations = [], isLoading: isLoadingConversations } = useQuery(
    ['userSentConversations'],
    () => API.conversations.fetchSent()
  );

  const { data: messages = [], isLoading: isLoadingMessages } = useQuery(
    ['conversationMessages', selectedConversationId],
    () => API.conversations.fetchMessages(selectedConversationId),
    { enabled: Boolean(selectedConversationId) }
  );

  const { data: adminUsers = [], isLoading: isLoadingAdminUsers } = useQuery(
    ['adminUsers'],
    () => API.users.userAdminFetch()
  );

  // ======================================
  // =========== MUTATIONS ================
  // ======================================
  // Replace the existing sendMessageMutation with:
const sendMessageMutation = useMutation(
  (messageData) => API.conversations.postMessage(selectedConversationId, messageData),
  {
    onSuccess: () => {
      setMessageText('');
      setSelectedFile(null); // Clear attachment after send
      queryClient.invalidateQueries(['conversationMessages', selectedConversationId]);
    }
  }
);

  // --- 1) Existing user ID share route ---
  const shareConversationMutation = useMutation(
    ({ conversationId, participantIds }) =>
      API.conversations.share(conversationId, participantIds),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['userSentConversations']);
        queryClient.invalidateQueries(['conversationMessages', selectedConversationId]);
        handleCloseAddParticipant();
      },
      onError: (err) => {
        console.error('Error sharing conversation:', err);
      }
    }
  );

  // --- 2) Invite by email route ---
  const inviteByEmailMutation = useMutation(
    (emailsArray) => API.conversations.inviteByEmail(selectedConversationId, emailsArray),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['userSentConversations']);
        queryClient.invalidateQueries(['conversationMessages', selectedConversationId]);
        handleCloseAddParticipant();
      },
      onError: (err) => {
        console.error('Error inviting by email:', err);
      }
    }
  );

  // ======================================
  // =========== HANDLERS =================
  // ======================================
  const handleSendMessage = (messageData) => {
    if (selectedConversationId && (messageData.message.trim() || messageData.attachments?.length)) {
      sendMessageMutation.mutate(messageData);
    }
  };

  const toggleLeftPanel = () => {
    setIsLeftPanelCollapsed(!isLeftPanelCollapsed);
  };
  const handleClickAddParticipant = () => {
    setAddParticipantOpen(true);
  };

  const handleCloseAddParticipant = () => {
    setAddParticipantOpen(false);
    setSelectedParticipant(null);
    setInviteEmails('');
  };
  const handleOpenNewChat = () => {
    setNewChatOpen(true);
  };
  const handleCloseNewChat = () => {
    setNewChatOpen(false);
    setSelectedNewChatUsers([]);
  };
  const handleCreateNewChat = () => {
    if (!selectedNewChatUsers || !selectedNewChatUsers.length) return;
    // Map from { label, value } to just user IDs
    const recipientIds = selectedNewChatUsers.map((u) => u.value);
    createNewChatMutation.mutate(recipientIds);
  };

  // Used for the Autocomplete
  const adminUserOptions = adminUsers.map((u) => ({
    label: `${u.first_name} ${u.last_name}`,
    value: u.id
  }));

  // --- Share with existing user by ID ---
  const handleConfirmAddParticipant = () => {
    if (!selectedParticipant) return;
    shareConversationMutation.mutate({
      conversationId: selectedConversationId,
      participantIds: [selectedParticipant.value]
    });
  };
  const selectedConversation = conversations.find(
    (c) => c.id === selectedConversationId
  );
  // --- Invite by email (new or existing) ---
  const handleInviteByEmail = () => {
    const trimmed = inviteEmails.trim();
    if (!trimmed) return;
    // For multiple, split by comma:
    const emailsArray = trimmed
      .split(',')
      .map((e) => e.trim())
      .filter(Boolean);

    inviteByEmailMutation.mutate(emailsArray);
  };

  // ======================================
  // =========== RENDER ===================
  // ======================================
  return (
    <Box sx={{ p: 3, height: '100vh', display: 'flex', flexDirection: 'column', bgcolor: 'background.default' }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 3, fontWeight: 'bold' }}>
        Conversations Hub
      </Typography>

      <Box sx={{ flex: 1, display: 'flex', gap: 2, overflow: 'hidden', position: 'relative' }}>
      <LeftPanel
        isLoadingConversations={isLoadingConversations}
        conversations={conversations}
        selectedConversationId={selectedConversationId}
        isMobile={isMobile}
        isLeftPanelCollapsed={isLeftPanelCollapsed}
        handleSelectConversation={setSelectedConversationId}
        toggleLeftPanel={toggleLeftPanel}
        onCreateNewChatClicked={handleOpenNewChat}

      />

      {/* Pass the entire selected conversation object, not just the ID */}
      <RightPanel
        theme={theme}
        isMobile={isMobile}
        isLeftPanelCollapsed={isLeftPanelCollapsed}
        selectedConversation={selectedConversation}
        messages={messages}
        isLoadingMessages={isLoadingMessages}
        messageText={messageText}
        setMessageText={setMessageText}
        handleSendMessage={handleSendMessage}
        sendMessageMutation={sendMessageMutation}
        currentUser={currentUser}
        toggleLeftPanel={toggleLeftPanel}
        handleClickAddParticipant={handleClickAddParticipant}
      />
      </Box>

      {/* Add Participant Dialog */}
      <Dialog open={addParticipantOpen} onClose={handleCloseAddParticipant}>
        <DialogTitle>Add Participant</DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 3, minWidth: '350px' }}>
          {isLoadingAdminUsers ? (
            <CircularProgress />
          ) : (
            <>
              {/* 1) Add an existing user by ID (autocomplete) */}
              <Box>
                <Typography variant="subtitle2" sx={{ mb: 1 }}>
                  Select existing user:
                </Typography>
                <Autocomplete
                  options={adminUserOptions}
                  value={selectedParticipant}
                  onChange={(event, newValue) => setSelectedParticipant(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} label="Search user" variant="outlined" />
                  )}
                  sx={{ width: '100%' }}
                />
                <Button
                  sx={{ mt: 1 }}
                  variant="contained"
                  onClick={handleConfirmAddParticipant}
                  disabled={!selectedParticipant || shareConversationMutation.isLoading}
                >
                  {shareConversationMutation.isLoading ? 'Adding...' : 'Add Selected User'}
                </Button>
              </Box>

              {/* 2) Invite new participant by email */}
              <Box>
                <Typography variant="subtitle2" sx={{ mb: 1 }}>
                  Or invite by email (comma-separated for multiple):
                </Typography>
                <TextField
                  label="Email(s)"
                  variant="outlined"
                  fullWidth
                  value={inviteEmails}
                  onChange={(e) => setInviteEmails(e.target.value)}
                  placeholder="john@example.com, jane@example.com"
                />
                <Button
                  sx={{ mt: 1 }}
                  variant="contained"
                  color="secondary"
                  onClick={handleInviteByEmail}
                  disabled={inviteByEmailMutation.isLoading}
                >
                  {inviteByEmailMutation.isLoading ? 'Inviting...' : 'Invite by Email'}
                </Button>
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddParticipant}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog 
        open={newChatOpen} 
        onClose={handleCloseNewChat}
        fullWidth 
        maxWidth="sm" // You can set to "md" or "lg" for larger sizes
        sx={{
          '& .MuiDialog-paper': {
            width: '600px', // Specify width for the dialog
            maxWidth: '90vw', // Ensure responsiveness
            height: '500px', // Increase height
            maxHeight: '80vh', // Ensure it doesn't overflow the screen
          },
        }}
      >        
      <DialogTitle>Create New Chat</DialogTitle>
      <DialogContent>
      {isLoadingAdminUsers ? (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <CircularProgress />
      </Box>
    ) : (
            <Autocomplete
              multiple
              options={adminUserOptions}
              value={selectedNewChatUsers}
              onChange={(event, newValue) => setSelectedNewChatUsers(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select user(s)"
                  variant="outlined"
                  placeholder="Search..."
                />
              )}
              sx={{ width: '100%', mt: 2 }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNewChat}>Cancel</Button>
          <Button
            variant="contained"
            onClick={handleCreateNewChat}
            disabled={createNewChatMutation.isLoading || !selectedNewChatUsers.length}
          >
            {createNewChatMutation.isLoading ? 'Creating...' : 'Create Chat'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  TextField,
  IconButton,
  Checkbox,
  Tooltip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Chip,
  Divider,
  useTheme,
  Tabs,
  Tab
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import MuiDateField from "../../../../Components/MuiDateField";
import { formatDateToYYYYMMDD } from "../../../../Utils/constants";



function PickerToLocal(dateObject) {
  const localDate = new Date(dateObject.getUTCFullYear(), dateObject.getUTCMonth(), dateObject.getUTCDate());
  return localDate;
}

const TimelineEntry = ({ event, onEditField, campaignStatus }) => {
  const theme = useTheme();
  const isDraft = campaignStatus === 'Draft';
  const isComplete = event.status === 'complete';

  return (
    <Card
      variant="outlined"
      sx={{
        mb: 2,
        borderLeft: `4px solid ${isComplete ? theme.palette.success.main : theme.palette.primary.main}`,
        opacity: event.will_delete ? 0.6 : 1,
        transition: 'all 0.2s ease',
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: theme.shadows[2]
        }
      }}
    >
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <TextField
            value={event.objective}
            variant="outlined"
            fullWidth
            onChange={(e) => onEditField(event.index, 'objective', e.target.value)}
            disabled={!isDraft}
            sx={{ 
              '& .MuiInputBase-root': { fontSize: '1.125rem' },
              textDecoration: isComplete ? 'line-through' : 'none'
            }}
          />
          
          <Box display="flex" alignItems="center" gap={1} ml={2}>
            <Chip
              label={event.status}
              color={isComplete ? 'success' : 'default'}
              variant="outlined"
              size="small"
              sx={{ textTransform: 'capitalize' }}
            />
            <IconButton
              onClick={() => onEditField(event.index, 'will_delete', !event.will_delete)}
              disabled={!isDraft}
            >
              {event.will_delete ? <DeleteIcon color="error" /> : <DeleteOutlineIcon />}
            </IconButton>
          </Box>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Notes"
              value={event.notes}
              fullWidth
              multiline
              minRows={2}
              disabled={!isDraft}
              onChange={(e) => onEditField(event.index, 'notes', e.target.value)}
            />
          </Grid>
          
          <Grid item xs={12} md={6}>
            <TextField
              label="Manager Notes"
              value={event.manager_notes}
              fullWidth
              multiline
              minRows={2}
              onChange={(e) => onEditField(event.index, 'manager_notes', e.target.value)}
              sx={{ 
                backgroundColor: theme.palette.grey[100],
                '& .MuiInputBase-root': {
                  backgroundColor: theme.palette.grey[100],
                }
              }}
            />
          </Grid>
        </Grid>

        <Divider sx={{ my: 2 }} />

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center" gap={2}>
            <MuiDateField
              label="Due Date"
              value={formatDateToYYYYMMDD(event.projected_date)}
              disabled={!isDraft}
              onChange={(e) => onEditField(event.index, 'projected_date', PickerToLocal(e.target.valueAsDate))}
              sx={{ width: 160 }}
            />
            
            <Tooltip title="Creator Delivered">
              <Checkbox
                checked={event.manager_completed}
                onChange={(e) => onEditField(event.index, 'manager_completed', e.target.checked)}
                color="primary"
              />
            </Tooltip>
          </Box>

          <IconButton
            onClick={() => onEditField(event.index, 'status', isComplete ? 'incomplete' : 'complete')}
            color={isComplete ? 'success' : 'default'}
          >
            {isComplete ? <CheckCircleIcon /> : <CheckCircleOutlineIcon />}
          </IconButton>
        </Box>
      </CardContent>
    </Card>
  );
};

const TimelineTab = ({ campaignDetails, timelineEvents, onEditField, onCreateRow }) => {
  const [selectedCreator, setSelectedCreator] = useState(campaignDetails.creators[0]?.id || '');
  const [openDialog, setOpenDialog] = useState(false);
  const theme = useTheme();

  if (!timelineEvents) return null;

  const currentTimeline = timelineEvents
    .map((event, index) => ({ ...event, index }))
    .filter((event) => event.username === selectedCreator)
    .sort((a, b) => {
      if (!a.projected_date) return 1;
      if (!b.projected_date) return -1;
      return new Date(a.projected_date) - new Date(b.projected_date);
    });

  const isTimelineEmpty = currentTimeline.length === 0;

  const handleApplyTemplate = () => {
    const today = new Date();
    const templateEvents = [
      {
        objective: 'Send script/video idea',
        status: 'incomplete',
        notes: '',
        manager_notes: '',
        manager_completed: false,
        projected_date: new Date(today.getTime() + 3 * 24 * 60 * 60 * 1000),
        will_delete: false
      },
      {
        objective: 'Send draft/approve draft',
        status: 'incomplete',
        notes: '',
        manager_notes: '',
        manager_completed: false,
        projected_date: new Date(today.getTime() + 4 * 24 * 60 * 60 * 1000),
        will_delete: false
      },
      {
        objective: 'Video posted',
        status: 'incomplete',
        notes: '',
        manager_notes: '',
        manager_completed: false,
        projected_date: new Date(today.getTime() + 5 * 24 * 60 * 60 * 1000),
        will_delete: false
      }
    ];

    templateEvents.forEach(event => onCreateRow(selectedCreator, event));
    setOpenDialog(false);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
        <Tabs
          value={selectedCreator}
          onChange={(e, newValue) => setSelectedCreator(newValue)}
          variant="scrollable"
          scrollButtons="auto"
        >
          {campaignDetails.creators.map((creator) => (
            <Tab 
              key={creator.id} 
              label={creator.id} 
              value={creator.id} 
              sx={{
                minWidth: 120,
                '&.Mui-selected': {
                  color: theme.palette.primary.main,
                }
              }}
            />
          ))}
        </Tabs>
      </Box>

      <Box display="flex" justifyContent="flex-end" mb={4}>
        {isTimelineEmpty && (
          <Button 
            variant="contained" 
            onClick={() => setOpenDialog(true)}
          >
            Apply Template
          </Button>
        )}
      </Box>

      <Grid container spacing={2}>
        {currentTimeline.map((event) => (
          <Grid item xs={12} key={event.index}>
            <TimelineEntry 
              event={event} 
              onEditField={onEditField} 
              campaignStatus={campaignDetails.campaign_status} 
            />
          </Grid>
        ))}
      </Grid>

      {campaignDetails.campaign_status === 'Draft' && (
        <Box textAlign="center" mt={4}>
          <Button
            variant="outlined"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => onCreateRow(selectedCreator)}
            sx={{
              borderStyle: 'dashed',
              '&:hover': { borderStyle: 'dashed' }
            }}
          >
            Add New Task
          </Button>
        </Box>
      )}

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Apply Template Timeline</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will create a standard timeline template for the selected creator.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleApplyTemplate} variant="contained" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TimelineTab;
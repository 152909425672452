import React from "react";
import { Box, Button, Typography, Fade, useTheme, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import routes from "../../Config/routes";
import paymentImage from "../../Components/globalAssets/blackwhite.png";

const PaymentSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const handleAccess = () => navigate(`${routes.demoRegister}/thealexroa`);

  return (
    <Box
      component="section"
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: { xs: 'center', md: 'space-around' },
        alignItems: "center",
        gap: {xs:4, md:8},
        maxWidth: '60rem',
        height: '100%',
        marginInline: 'auto'
      }}
    >
      {/* Left: Payment Image */}
      <Box
        sx={{
          width:{xs:'50%', md:'70%'},
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            position: "relative",
            borderRadius: "50%",
            overflow: "hidden",
            width: '100%',
            aspectRatio: '1/1',
            boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",
          }}
        >
          <img
            src={paymentImage}
            alt="Payment"
            style={{
              width: "100%",
              height: "auto",
              objectFit: "contain",
            }}
          />
        </Box>
      </Box>

      {/* Right: Text Content */}
      <Box
        sx={{
          maxWidth: "60rem",
          textAlign: { xs: "center", md: "left" },
          flexGrow:{xs:0,md:1},
          flexShrink:1
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontWeight: 800,
            mb: 2,
            fontSize: { xs: "2rem", md: "2.5rem" },
          }}
        >
          Blitz: The New Way to Pay and Get Paid
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ mb: 2 }}
        >
          Whether you&apos;re a creator, freelancer, or global team member,
          Blitz ensures instant, hassle-free transactions. Choose immediate
          payouts for a small fee, or opt for Net 30/60 terms at no cost. No
          more waiting, no more complications.
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ mb: 3, display: {xs:'none',md:'inline-block'} }}
        >
          Trusted by top brands worldwide, Blitz streamlines payment processes
          so you can focus on what matters: growing your business and
          delighting your clients.
        </Typography>
        <Typography
          variant="body1"
          sx={{
            mb:2,
            fontStyle: "italic",
            display: {xs:'none',md:'inline-block'} 
          }}
        >
          &quot;Blitz transformed our payment workflows. Our international freelancers
          love the speed and transparency—and we love the simplicity.&quot; – Meta
        </Typography>

        <Button
          variant="contained"
          onClick={handleAccess}
          sx={{
            px: 4,
            py: 1.5,
            borderRadius: 50,
            background: "linear-gradient(45deg, #7b2a2a 30%, #5a1e1e 90%)",
            fontWeight: 600,
            textTransform: "none",
            fontSize: "1.1rem",
            boxShadow: "0 4px 15px rgba(123,42,42,0.3)",
            "&:hover": {
              transform: "translateY(-2px)",
              boxShadow: "0 6px 20px rgba(123,42,42,0.4)",
              background: "linear-gradient(45deg, #7b2a2a 30%, #5a1e1e 90%)",
            },
            transition: "all 0.3s ease",
          }}
        >
          Request Access Now
        </Button>
      </Box>
    </Box>
  );
};

export default PaymentSection;

import React, { useMemo, useState } from "react";
import {
  Paper,
  Box,
  IconButton,
  Button,
  Badge,
  Typography,
  Stepper,
  Step,
  StepLabel,
  MobileStepper,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import {
  ShoppingCart as ShoppingCartIcon,
  KeyboardArrowLeft,
} from "@mui/icons-material";

import CreatorDetails from "./steps/creatordetails";
import CampaignDetails from "./steps/campaigndetails";
import Timelines from "./steps/campaigntimelines";
import CampaignConfirmation from "./steps/campaignconfirmation";
import LeftColumnSearch from "./leftColumnSearch";

// Steps for multi-creator flow
const steps = [
  "Sourcing",
  "Creator Details",
  "Campaign Details",
  "Timelines",
  "Confirmation",
];

function MarketplaceBooking({
  onInviteToPartnership,
  data,
  loading,
  onSubmitCampaign,
}) {
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const isLgScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));

  // Decide how many items per page in the left column
  let itemsPerPage = 4;
  if (isSmScreen) itemsPerPage = 6;
  if (isLgScreen) itemsPerPage = 12;
  if (isXlScreen) itemsPerPage = 16;

  // States for the multi-step flow
  const [activeStep, setActiveStep] = useState(0);
  const [selectedItems, setSelectedItems] = useState(new Set());
  const [creatorsData, setCreatorsData] = useState([]);
  const [timelinesData, setTimelinesData] = useState({});
  const [campaignData, setCampaignData] = useState(null);
  const [isBlitzFeeOnCreators, setIsBlitzFeeOnCreators] = useState(true);

  // Example "idealDueDate" logic
  const today = new Date();
  const sevenDays = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);
  const idealDueDate = useMemo(
    () =>
      Object.entries(timelinesData).reduce((accum, [creator, timeline]) => {
        const latestDueDate = timeline.reduce(
          (innerAcc, event) =>
            event.projected_date <= innerAcc ? innerAcc : event.projected_date,
          sevenDays
        );
        return latestDueDate <= accum ? accum : latestDueDate;
      }, sevenDays),
    [timelinesData]
  );

  /**
   * Called when selecting/deselecting a creator in the LeftColumnSearch.
   */
  const handleAddToCampaign = (creatorId) => {
    const newSelected = new Set(selectedItems);
    const isSelected = newSelected.has(creatorId);

    if (isSelected) {
      // If already selected, remove it
      newSelected.delete(creatorId);
      setCreatorsData((prev) => prev.filter((item) => item.id !== creatorId));
      setTimelinesData((prev) => {
        const updated = { ...prev };
        delete updated[creatorId];
        return updated;
      });
    } else {
      // If not selected, add it
      newSelected.add(creatorId);
      const creator = data.find((c) => c.creator === creatorId);
      // Provide default rate info, etc. if needed:
      setCreatorsData((prev) => [
        ...prev,
        {
          ...creator,
          id: creator.creator,
          adjustedPrice: creator.instagram_post ?? 50, // sample fallback
          rateKey: "instagram_post", // sample fallback
        },
      ]);
    }

    setSelectedItems(newSelected);
  };

  /**
   * If the user explicitly removes a creator from the "Creator Details" step,
   * we just toggle them off as well.
   */
  const handleRemoveCreator = (creatorId) => {
    if (selectedItems.has(creatorId)) {
      handleAddToCampaign(creatorId); // effectively toggles it off
    }
  };

  /**
   * Called when user finishes the final step (Confirmation) in a multi-creator scenario
   */
  const handleFinish = () => {
    onSubmitCampaign({
      campaignData: {
        ...campaignData,
        idealDueDate,
      },
      timelinesData,
      creatorsData,
      isBlitzFeeOnCreators,
    });
  };

  /**
   * If user clicks the cart button:
   * - If exactly one creator in cart → open single-partnership dialog
   * - If multiple creators → go to step=1 (Creator Details)
   * - If zero creators → do nothing or show an alert
   */
  const handleCartClick = () => {
    const cartSize = selectedItems.size;

    if (cartSize === 0) {
      // No creators yet; do nothing or show alert
      // alert("No creators selected!"); // optional
      return;
    }

    if (cartSize === 1) {
      // Exactly one selected → open single-partnership
      const [soleCreatorId] = [...selectedItems];
      const soleCreator = data.find((c) => c.creator === soleCreatorId);
      onInviteToPartnership(soleCreator);
    } else {
      // More than one → proceed with normal flow, step #1
      setActiveStep(1);
    }
  };

  return (
    <>
      <Box>
        {/* Top bar showing the current step and a cart icon */}
        <Paper
          elevation={8}
          square
          sx={{
            paddingBlock: 1,
            paddingInline: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            position: "relative",
            zIndex: 2,
          }}
        >
          <Typography variant="h6">{steps[activeStep]}</Typography>

          {/* Cart icon button */}
          <IconButton onClick={handleCartClick}>
            <Badge
              badgeContent={selectedItems.size}
              color="secondary"
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
              <ShoppingCartIcon />
            </Badge>
          </IconButton>
        </Paper>

        {/* Desktop Stepper */}
        <Paper
          elevation={4}
          square
          sx={{
            paddingBlock: 4,
            position: "relative",
            zIndex: 1,
            display: { xs: "none", md: "block" },
          }}
        >
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Paper>
      </Box>

      {/* Step content */}
      {activeStep === 0 && (
        <LeftColumnSearch
          onCreatorSelect={handleAddToCampaign}
          selectedItems={selectedItems}
          data={data}
          loading={loading}
          itemsPerPage={itemsPerPage}
        />
      )}
      {activeStep === 1 && (
        <CreatorDetails
          selectedCreators={selectedItems}
          creatorsData={creatorsData}
          setCreatorsData={setCreatorsData}
          isBlitzFeeOnCreators={isBlitzFeeOnCreators}
          setIsBlitzFeeOnCreators={setIsBlitzFeeOnCreators}
          onNext={() => setActiveStep(2)}
          onBack={() => setActiveStep(0)}
          onRemove={handleRemoveCreator}
          data={data}
        />
      )}
      {activeStep === 2 && (
        <CampaignDetails
          onNext={() => setActiveStep(3)}
          onBack={() => setActiveStep(1)}
          setCampaignData={setCampaignData}
        />
      )}
      {activeStep === 3 && (
        <Timelines
          campaignDetails={campaignData}
          selectedCreatorsData={creatorsData}
          creatorTimelines={timelinesData}
          setCreatorTimelines={setTimelinesData}
          onNext={() => setActiveStep(4)}
          onBack={() => setActiveStep(2)}
        />
      )}
      {activeStep === 4 && (
        <CampaignConfirmation
          selectedCreatorsData={creatorsData}
          timelinesData={timelinesData}
          isBlitzFeeOnCreators={isBlitzFeeOnCreators}
          data={data}
          campaignData={campaignData}
          onBack={() => setActiveStep(3)}
          onFinish={handleFinish}
          idealDueDate={idealDueDate}
        />
      )}

      {/* Mobile Stepper (bottom nav) */}
      <MobileStepper
        activeStep={activeStep}
        variant="progress"
        steps={steps.length}
        position="static"
        backButton={
          <Button
            size="small"
            variant="text"
            onClick={() => setActiveStep((prev) => Math.max(prev - 1, 0))}
            disabled={activeStep === 0}
          >
            <KeyboardArrowLeft />
          </Button>
        }
        nextButton={<Typography variant="subtitle2">{steps[activeStep]}</Typography>}
        sx={{ display: { xs: "flex", md: "none" } }}
      />

      <MobileStepper
        component={Paper}
        square
        elevation={8}
        activeStep={activeStep}
        variant="progress"
        steps={steps.length}
        position="bottom"
        backButton={
          <Button
            size="small"
            variant="text"
            onClick={() => setActiveStep((prev) => Math.max(prev - 1, 0))}
            disabled={activeStep === 0}
          >
            <KeyboardArrowLeft />
          </Button>
        }
        nextButton={
          <Typography variant="subtitle2">
            <b>{steps[activeStep]}</b>
          </Typography>
        }
        sx={{ display: { xs: "flex", md: "none" } }}
      />
    </>
  );
}

export default MarketplaceBooking;

import { createTheme } from "@mui/material";

export const getTheme = (options = {}) => {
    const {
      darkMode = false,
      customColors = {},
      fontFamily = 'Inter, Arial, sans-serif',
      density = 'normal'
    } = options;

  const isLight = !darkMode;
  const spacingMap = {
    compact: 4,
    normal: 8,
    comfortable: 10
  };

  return createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: customColors.primary || (isLight ? '#7b2a2a' : '#cb534f'),
        ...(isLight && { dark: '#662323' })
      },
      secondary: {
        main: customColors.secondary || '#646aff',
      },
      background: {
        default: isLight ? '#ececec' : '#121212',
      },
    },
    spacing: spacingMap[density] || 8,
    components: {
      MuiAppBar: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: "#fff",
            backgroundImage: 'unset',
            color: '#000'
          }
        }
      },
      MuiButton: {
        defaultProps: {
          variant: 'outlined',
        },
        styleOverrides: {
          root: {
            fontWeight: 'bold',
          },
        }
      },
      MuiListItemText: {
        styleOverrides: {
          primary: {
            fontWeight: 'bold',
          }
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontWeight: 'bold',
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            '& .MuiTab-root': {
              fontWeight: 'bold',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            fontWeight: 'bold',
          },
          input: {
            fontWeight: 'bold',
          },
          inputMultiline: {
            fontWeight: 'normal',
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            fontWeight: 'bold',
          },
          select: {
            fontWeight: 'bold',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            fontWeight: 'bold',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            colorScheme: isLight ? 'light' : 'dark',
          }
        }
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontWeight: 'bold'
          }
        }
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontWeight: 'bold'
          }
        }
      },
      MuiCssBaseline: {
        styleOverrides: (theme) => `
          * {
            scrollbar-color: ${theme.palette.text.disabled} transparent;
            color-scheme: ${isLight ? 'light' : 'dark'};
          }
        `
      }
    },
    shape: {
      borderRadius: 12,
    },
    typography: {
      fontFamily,
      h1: {
        fontWeight: 'bold',
      },
      h2: {
        fontWeight: 'bold',
      },
      h3: {
        fontWeight: 'bold',
      },
      h4: {
        fontWeight: 'bold',
      },
      h5: {
        fontWeight: 'bold',
      },
      h6: {
        fontWeight: 'bold',
      },
      codeblock: {
        fontFamily: '"Source Code Pro", monospace',
        fontSize: '0.875rem',
        fontWeight: 'normal',
      },
    },
  });
};

// Default themes for non-authenticated users
export const lightTheme = getTheme({ darkMode: false });
export const darkTheme = getTheme({ darkMode: true });
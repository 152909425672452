import React from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
  styled,
  alpha,
  LinearProgress,
  useTheme
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Globe from "./globe";
import ZapFeedForUser from "./zapfeedforusers";
import TopUsersFeed from "./topusers";
import useAuth from "../../../Hooks/use-auth";
import API from "../../../API";
import { useQuery } from "react-query";

const GradientCard = styled(Card)(({ theme }) => ({
  minHeight: 200,
  background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.1)}, ${alpha(theme.palette.secondary.main, 0.05)})`,
  backdropFilter: 'blur(10px)',
  borderRadius: '24px',
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'translateY(-8px)',
  },
}));

const ReferralCard = styled(Card)(({ theme }) => ({
  background: `linear-gradient(135deg, ${alpha(theme.palette.success.main, 0.15)}, ${alpha(theme.palette.success.light, 0.1)})`,
  border: `1px solid ${alpha(theme.palette.success.main, 0.2)}`,
  borderRadius: '24px',
  position: 'relative',
  overflow: 'hidden',
  '&:before': {
    content: '""',
    position: 'absolute',
    top: '-50%',
    right: '-50%',
    width: '200%',
    height: '200%',
    background: `radial-gradient(circle, ${alpha(theme.palette.success.main, 0.1)} 0%, transparent 70%)`,
  },
}));

const ActionGrid = styled(Grid)(({ theme }) => ({
  position: 'relative',
  zIndex: 1,
  [theme.breakpoints.up('md')]: {
    paddingRight: '450px',
  },
}));

const FloatingGlobe = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: '-40px',
  top: '-40px',
}));

const FloatingGlobeContainer = styled(Box)(({ theme }) => ({
  display: { xs: 'none', md: 'block' },
  position: 'absolute',
  overflowX: 'clip',
  overflowY: 'clip',
  top: 0,
  right: 0,
  width: '450px',
  height: '450px',
  zIndex: 0,
  opacity: 0.5
}));

const Dashboard = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { getCurrrentUser } = useAuth();
  const userInfo = getCurrrentUser();

  const { data: dashboardData } = useQuery({
    queryKey: ["user", "dashboard"],
    queryFn: API.users.dashboard,
    refetchOnWindowFocus: false,
  });

  const referralsCount = dashboardData?.referralsCount || 0;
  const referralProgress = (referralsCount / 5) * 100;

  return (
    <Box sx={{ p: 2 }}>
      <FloatingGlobeContainer>
        <FloatingGlobe>
          <Globe size={450} />
        </FloatingGlobe>
      </FloatingGlobeContainer>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography
            variant="h2"
            sx={{
              fontWeight: 800,
              background: 'linear-gradient(45deg, #4F46E5 30%, #EC4899 90%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              mb: 4,
            }}
          >
            Welcome back, {userInfo?.first_name}!
          </Typography>
        </Grid>

        <ActionGrid item xs={12}>
          <Grid container spacing={3}>
            {[
              {
                title: "Campaigns",
                value: dashboardData?.campaigns.length,
                icon: '📈',
                route: '/campaigns',
              },
              {
                title: "Payouts",
                value: `$${dashboardData?.payouts?.reduce((sum, p) => sum + (p.amount || 0), 0).toFixed(2)}`,
                icon: '💸',
                route: '/blitzpay',
              },
              {
                title: "Creators",
                value: dashboardData?.creators.length,
                icon: '👥',
                route: '/creatorsignup',
              },
              {
                title: "Partnerships",
                value: dashboardData?.partnerships.length,
                icon: '🤝',
                route: '/partnerships',
              },
            ].map((card, i) => (
              <Grid item xs={12} sm={6} md={3} key={i}>
                <GradientCard>
                  <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h3" sx={{ mb: 1 }}>
                      {card.icon}
                    </Typography>
                    <Typography variant="h5" sx={{ fontWeight: 700, mb: 0.5 }}>
                      {card.value}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary">
                      {card.title}
                    </Typography>
                    <Button
                      onClick={() => navigate(card.route)}
                      sx={{ mt: 'auto', alignSelf: 'flex-start' }}
                    >
                      Manage →
                    </Button>
                  </CardContent>
                </GradientCard>
              </Grid>
            ))}
          </Grid>
        </ActionGrid>

        {/* Main content row */}
        <Grid item xs={12}>
          <Grid container spacing={4}>
            {/* Left side: Zap Feed */}
            <Grid item xs={12} lg={8}>
              <ZapFeedForUser />
            </Grid>

            {/* Right side: Referral & Top Users */}
            <Grid item xs={12} lg={4}>
              <ReferralCard sx={{ p: 3, mb: 4 }}>
                <Box sx={{ position: 'relative', zIndex: 1 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                    <Typography variant="h4" sx={{ color: theme.palette.success.main }}>
                      🎁
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: 700, color: theme.palette.success.dark }}>
                      Earn Up To $1000
                    </Typography>
                  </Box>
                  <Typography sx={{ mb: 2 }} color="text.secondary">
                    Refer 5 friends and unlock your $1000 reward!
                    You've referred <strong>{referralsCount}/5</strong> users.
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={referralProgress}
                    sx={{
                      height: 8,
                      borderRadius: 4,
                      mb: 3,
                      backgroundColor: alpha(theme.palette.success.main, 0.1),
                      '& .MuiLinearProgress-bar': {
                        borderRadius: 4,
                        backgroundColor: theme.palette.success.main
                      }
                    }}
                  />
                  <Button
                    fullWidth
                    variant="contained"
                    color="success"
                    onClick={() => navigate(`/demoregister/${userInfo.id}`)}
                    sx={{
                      borderRadius: 3,
                      fontWeight: 700,
                      boxShadow: `0 4px 12px ${alpha(theme.palette.success.main, 0.3)}`,
                      '&:hover': {
                        transform: 'translateY(-2px)',
                        transition: 'transform 0.2s'
                      }
                    }}
                  >
                    Get Referral Link →
                  </Button>
                </Box>
              </ReferralCard>

              {/* Top Users Feed */}
              <TopUsersFeed />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
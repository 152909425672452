import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { Box, Typography, Button, TextField, CircularProgress, Alert } from '@mui/material';
import { Edit, Save, Cancel } from '@mui/icons-material';
import client from '../../../API';
const ContractDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editMode, setEditMode] = React.useState(false);
  const [formState, setFormState] = React.useState({});
  
  const { data: contract, isLoading, error, refetch } = useQuery(
    ['contract', id],
    () => client.files.getContract(id)
  );

  const updateMutation = useMutation(
    (updatedData) => client.files.updateContract(id, updatedData),
    {
      onSuccess: () => {
        refetch();
        setEditMode(false);
      }
    }
  );

  React.useEffect(() => {
    if (contract) {
      setFormState({
        name: contract.name,
        description: contract.description,
        contract_body: contract.contract_body
      });
    }
  }, [contract]);

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value
    });
  };

  const handleSave = () => {
    updateMutation.mutate(formState);
  };

  if (isLoading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error.message}</Alert>;

  return (
    <Box sx={{ p: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
        <Typography variant="h4">
          {editMode ? (
            <TextField
              name="name"
              value={formState.name}
              onChange={handleChange}
              fullWidth
              variant="standard"
            />
          ) : (
            contract.name
          )}
        </Typography>
        {editMode ? (
          <Box>
            <Button
              startIcon={<Save />}
              onClick={handleSave}
              variant="contained"
              sx={{ mr: 2 }}
            >
              Save
            </Button>
            <Button
              startIcon={<Cancel />}
              onClick={() => setEditMode(false)}
              variant="outlined"
            >
              Cancel
            </Button>
          </Box>
        ) : (
          <Button
            startIcon={<Edit />}
            onClick={() => setEditMode(true)}
            variant="contained"
          >
            Edit
          </Button>
        )}
      </Box>

      <TextField
        label="Description"
        name="description"
        value={formState.description || ''}
        onChange={handleChange}
        fullWidth
        multiline
        rows={2}
        disabled={!editMode}
        sx={{ mb: 4 }}
      />

      <TextField
        label="Contract Content"
        name="contract_body"
        value={formState.contract_body || ''}
        onChange={handleChange}
        fullWidth
        multiline
        rows={20}
        disabled={!editMode}
        InputProps={{
          style: {
            fontFamily: '"Courier New", Courier, monospace',
            fontSize: '0.875rem'
          }
        }}
      />

      {contract.file_url && (
        <Box sx={{ mt: 4 }}>
          <Button
            variant="contained"
            href={contract.file_url}
            target="_blank"
          >
            View PDF
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ContractDetailsPage;
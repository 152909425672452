import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  FormControlLabel,
  Switch,
} from "@mui/material";
import client from "../../../API"; // Adjust the path to your API client
import { useMutation } from "react-query";
import { formatDateToYYYYMMDD, parseLocalFloat, payoutStatuses } from "../../../Utils/constants";

const PayoutsDialog = ({ open, onClose, payoutInfo = undefined }) => {
  const [poNumber, setPONumber] = useState("");
  const [amount, setAmount] = useState(""); // Changed to 'amount' as per backend serialization
  const [status, setStatus] = useState("");
  const [creatorName, setCreatorName] = useState("");
  const [payoutDate, setPayoutDate] = useState("");
  const [creatorPayoutEmail, setCreatorPayoutEmail] = useState("");
  const [blitzPay, setBlitzPay] = useState(false);
  const [interestRate, setInterestRate] = useState('');

  useEffect(() => {
    if (payoutInfo) {
      setPONumber(payoutInfo.po_number);
      setAmount(payoutInfo.amount?.toFixed(2) || '0');
      setStatus(payoutInfo.status);
      setCreatorName(payoutInfo.creatorName);
      setPayoutDate(payoutInfo.payout_date);
      setCreatorPayoutEmail(payoutInfo.creatorPayoutEmail);
      setBlitzPay(payoutInfo.blitzpay);
      setInterestRate(payoutInfo.interest_rate?.toFixed(2) || '0');
    }
  }, [payoutInfo]);
  const handleSubmit = async () => {
    const submissionData = {
      id: payoutInfo?.id,
      amount: parseLocalFloat(amount),
      status: status,
      creatorName: creatorName,
      blitzPay: blitzPay,
      interest_rate: parseLocalFloat(interestRate)
    };

    console.log("Submitting data:", submissionData); // Check what's being sent

    try {
      const data = await client.payouts.editAdmin(submissionData);
      console.log("Edit successful", data);
      alert("Edit succesful");
      onClose(true);
    } catch (error) {
      alert(error?.response?.data?.error || error?.response?.data?.details || error.message);
      console.error("Error editing payout", error);
    }
  };

  return (
    <Dialog open={open} onClose={() => onClose(false)}>
      <DialogTitle>{"Edit Payout"}</DialogTitle>
      <DialogContent>
        {/* Existing fields */}
        <TextField
          label="PO Number"
          fullWidth
          margin="dense"
          value={poNumber}
          onChange={(e) => setPONumber(e.target.value)}
          disabled
          required
        />
        <TextField
          label="Creator Name"
          fullWidth
          margin="dense"
          value={creatorName}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          label="Creator Name"
          fullWidth
          margin="dense"
          value={creatorName}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          label="Amount Due"
          type="number"
          fullWidth
          margin="dense"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          required
        />
        <TextField
          label="Interest Rate"
          type="number"
          fullWidth
          margin="dense"
          value={interestRate}
          onChange={(e) => setInterestRate(e.target.value)}
          required
        />
        <TextField
          label="Final amount"
          type="number"
          fullWidth
          margin="dense"
          value={parseLocalFloat(amount) * (1 - (parseLocalFloat(interestRate) / 100))}
          InputProps={{
            readOnly: true,
          }}
          required
        />
        <TextField
          label="Payout Date"
          type="date"
          fullWidth
          margin="dense"
          inputProps={{ readOnly: true }}
          value={formatDateToYYYYMMDD(payoutDate)}
        />
        <TextField
          select
          fullWidth
          margin="dense"
          value={status}
          label="Status"
          onChange={(e) => setStatus(e.target.value)}
          id="status-select"
          SelectProps={{ sx: { textTransform: 'capitalize' } }}
        >
          {payoutStatuses.map((poStatus) => <MenuItem key={poStatus} value={poStatus} sx={{ textTransform: 'capitalize' }}>{poStatus}</MenuItem>)}
        </TextField>
        <FormControlLabel label='BlitzPay' control={<Switch></Switch>} checked={blitzPay} onChange={(event, checked) => setBlitzPay(true)}></FormControlLabel>{" "}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)}>Cancel</Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PayoutsDialog;

import { Box, Chip, Container, Divider, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'
import Codebox from '../../Components/Codebox'
import { Link as RouterLink } from 'react-router-dom'
import routes from '../../Config/routes'
import tutorial_1 from '../../Components/globalAssets/docs_apikeys_1.png'
import { StyledTableRow } from '../../Utils/styledcell'

export default function DocsCreators(props) {

    return (
        <Container sx={{ paddingBlock: 2 }}>
            <Typography variant='h3'>Creator</Typography>
            <Divider></Divider>
            <br />
            <Typography variant='h6'>Endpoints</Typography>
            <Divider></Divider>
            <Paper variant='outlined' sx={{ marginBlockStart: 2, padding: 3 }}>
                <Typography component='span' variant='subtitle2'>
                    <Chip label='GET' color='warning'></Chip> /thirdparty/creators/
                    <Typography variant='inherit' color='primary' component='span'>
                        &lt;creator_id&gt;
                    </Typography>
                </Typography><br />
                <br />
                Retrieves a creators information along with its subscription data
                <TableContainer component={Paper} elevation={1} sx={{ marginBlock: 2, maxWidth: '30rem' }}>
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={3}>
                                    Path parameters
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    Parameter
                                </TableCell>
                                <TableCell>
                                    Type
                                </TableCell>
                                <TableCell>
                                    Description
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <StyledTableRow>
                                <TableCell>creator_id</TableCell>
                                <TableCell>String</TableCell>
                                <TableCell>
                                    The creator_id you're trying to get the data from. The <Typography variant='inherit' color='primary' component='span'> 'sub' </Typography>
                                    key obtained from the Creators' JWT
                                </TableCell>
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <b>Response</b><br />
                <Typography component='span' variant='subtitle2'>Success (200)</Typography><br />
                <Codebox>
                    <Typography variant='codeblock'>
                        &#123;<br />
                        &emsp;"subscription": &#123;...&#125;,&emsp;<Typography component='span' variant='inherit' color='success.main'>// Subscription instance</Typography><br />
                        &emsp;"creator":
                        &#123;...&#125;&emsp;<Typography component='span' variant='inherit' color='success.main'>// Creator instance</Typography><br />
                        &#125;
                    </Typography>
                </Codebox>

            </Paper>
            <br />
            <Typography variant='h6'>Entities</Typography>
            <Divider></Divider>
            A Subscription entity holds all of the data Blitz holds of the creator's subscription to your service, like their last login date and when did they joined.<br />
            <TableContainer component={Paper} sx={{ marginBlockStart: 2, maxWidth: '37rem' }}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={3}>
                                Entity: Subscription
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Field
                            </TableCell>
                            <TableCell>
                                Type
                            </TableCell>
                            <TableCell>
                                Description
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <StyledTableRow>
                            <TableCell>id</TableCell>
                            <TableCell>String</TableCell>
                            <TableCell>The ID of the creator in our database.</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>service_id</TableCell>
                            <TableCell>String</TableCell>
                            <TableCell>The id of your service.</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>signup_date</TableCell>
                            <TableCell>String</TableCell>
                            <TableCell>The date and time this creator first agreed to share their data with your App. (RFC 1123 string)</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>last_login_date</TableCell>
                            <TableCell>Array of AudienceData</TableCell>
                            <TableCell>The date and time this creator first last used "Login with Blitz" with your App. (RFC 1123 string)</TableCell>
                        </StyledTableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <br/>
            A Creator entity holds all of the data related to a Creator in Blitz. This includes rates for different promotion types, engagement data for
            different platforms, and links to platforms<br />
            <TableContainer component={Paper} sx={{ maxWidth: '37rem' }}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={3}>
                                Entity: Creator
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Field
                            </TableCell>
                            <TableCell>
                                Type
                            </TableCell>
                            <TableCell>
                                Description
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <StyledTableRow>
                            <TableCell>creator</TableCell>
                            <TableCell>String</TableCell>
                            <TableCell>The unique identifier/username of the creator.</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>avg_views</TableCell>
                            <TableCell>String</TableCell>
                            <TableCell>The average number of views the creator’s content receives.</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>date_added</TableCell>
                            <TableCell>String</TableCell>
                            <TableCell>The date and time the creator was added (RFC 1123 string).</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>ethnicity</TableCell>
                            <TableCell>String</TableCell>
                            <TableCell>The creator's ethnicity (e.g., "Hispanic").</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>gender</TableCell>
                            <TableCell>String</TableCell>
                            <TableCell>The gender identity of the creator (e.g., "Non-Binary").</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>geolocation</TableCell>
                            <TableCell>String</TableCell>
                            <TableCell>The creator’s geographical location.</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>region</TableCell>
                            <TableCell>String</TableCell>
                            <TableCell>The creator’s geographic region.</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>last_updated</TableCell>
                            <TableCell>String or null</TableCell>
                            <TableCell>The date and time the creator’s data was last updated (RFC 1123 string).</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>notes_content_style</TableCell>
                            <TableCell>String</TableCell>
                            <TableCell>Creator's bio</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>pfphref</TableCell>
                            <TableCell>String</TableCell>
                            <TableCell>Link to the creator's profile picture</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>pfphref</TableCell>
                            <TableCell>String</TableCell>
                            <TableCell>Link to the creator's profile picture</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>primary_markets</TableCell>
                            <TableCell>Array of strings or null</TableCell>
                            <TableCell>The industries the creator is involved with (e.g., "Gaming").</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>instagram_link</TableCell>
                            <TableCell>String or null</TableCell>
                            <TableCell>Link to the creator's Instagram profile.</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>youtube</TableCell>
                            <TableCell>String or null</TableCell>
                            <TableCell>This creator following on the Youtube platform.</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>youtube_link</TableCell>
                            <TableCell>String or null</TableCell>
                            <TableCell>Link to the creator's youtube profile.</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>tiktok</TableCell>
                            <TableCell>String or null</TableCell>
                            <TableCell>This creator following on the Tiktok platform.</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>tiktok_link</TableCell>
                            <TableCell>String or null</TableCell>
                            <TableCell>Link to the creator's Tiktok profile.</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>twitch</TableCell>
                            <TableCell>String or null</TableCell>
                            <TableCell>This creator following on the Youtube platform.</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>twitch_link</TableCell>
                            <TableCell>String or null</TableCell>
                            <TableCell>Link to the creator's youtube profile.</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>kick_link</TableCell>
                            <TableCell>String or null</TableCell>
                            <TableCell>Link to the creator's Kick profile.</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>follow_kick</TableCell>
                            <TableCell>Integer or null</TableCell>
                            <TableCell>This creator following on the Kick platform.</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>follow_podcast</TableCell>
                            <TableCell>Integer or null</TableCell>
                            <TableCell>This creator following on a Podcasting platform.</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>podcast_link</TableCell>
                            <TableCell>String or null</TableCell>
                            <TableCell>Link to the creator's podcast</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>follow_x</TableCell>
                            <TableCell>Integer or null</TableCell>
                            <TableCell>This creator following on the X/Twitter platform.</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>x_link</TableCell>
                            <TableCell>String or null</TableCell>
                            <TableCell>Link to the creator's X/Twitter</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>rate_ig_feedpost</TableCell>
                            <TableCell>Decimal or null</TableCell>
                            <TableCell>Rate for Instagram Feed post</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>rate_ig_reelbrand</TableCell>
                            <TableCell>Decimal or null</TableCell>
                            <TableCell>Rate for Instagram Reel post</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>rate_ig_reelbrand</TableCell>
                            <TableCell>Decimal or null</TableCell>
                            <TableCell>Rate for Instagram Reel post</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>rate_ig_reelsound</TableCell>
                            <TableCell>Decimal or null</TableCell>
                            <TableCell>Rate for Instagram Reel Sound</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>rate_ig_story</TableCell>
                            <TableCell>Decimal or null</TableCell>
                            <TableCell>Rate for Instagram Story post</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>rate_kick_product</TableCell>
                            <TableCell>Decimal or null</TableCell>
                            <TableCell>Rate for Kick product practiceslacement</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>rate_podcast_full</TableCell>
                            <TableCell>Decimal or null</TableCell>
                            <TableCell>Rate for full sponsorship of podcast episode</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>rate_podcast_midroll</TableCell>
                            <TableCell>Decimal or null</TableCell>
                            <TableCell>Rate for a mid-roll ad in podcast</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>rate_podcast_postroll</TableCell>
                            <TableCell>Decimal or null</TableCell>
                            <TableCell>Rate for a post-roll ad in podcast</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>rate_podcast_preroll</TableCell>
                            <TableCell>Decimal or null</TableCell>
                            <TableCell>Rate for a pre-roll ad in podcast</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>rate_tt_brand</TableCell>
                            <TableCell>Decimal or null</TableCell>
                            <TableCell>Rate for a TikTok brand deal</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>rate_tt_sound</TableCell>
                            <TableCell>Decimal or null</TableCell>
                            <TableCell>Rate for TikTok sound</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>rate_twitch_product</TableCell>
                            <TableCell>Decimal or null</TableCell>
                            <TableCell>Rate for Twitch product placement</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>rate_x_feedpost</TableCell>
                            <TableCell>Decimal or null</TableCell>
                            <TableCell>Rate for X feed post</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>rate_x_repost</TableCell>
                            <TableCell>Decimal or null</TableCell>
                            <TableCell>Rate for X repost</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>rate_yt_dedicated</TableCell>
                            <TableCell>Decimal or null</TableCell>
                            <TableCell>Rate for Youtube dedicated video brand deal</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>rate_yt_short</TableCell>
                            <TableCell>Decimal or null</TableCell>
                            <TableCell>Rate for Youtube short brand deal</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>public</TableCell>
                            <TableCell>Array of CreatorPublic Entities</TableCell>
                            <TableCell>Demographic data for several </TableCell>
                        </StyledTableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <TableContainer component={Paper} sx={{ marginBlockStart: 2, maxWidth: '37rem' }}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={3}>
                                Entity: CreatorPublic
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Field
                            </TableCell>
                            <TableCell>
                                Type
                            </TableCell>
                            <TableCell>
                                Description
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <StyledTableRow>
                            <TableCell>average_comments</TableCell>
                            <TableCell>String</TableCell>
                            <TableCell>The average number of comments the creator's content receives on this platform.</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>average_likes</TableCell>
                            <TableCell>String</TableCell>
                            <TableCell>The average number of likes the creator's content receives on this platform.</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>average_views</TableCell>
                            <TableCell>String</TableCell>
                            <TableCell>The average number of views the creator's content receives.</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>audience_data</TableCell>
                            <TableCell>Array of AudienceData</TableCell>
                            <TableCell>The audience data demographic data</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>country_data</TableCell>
                            <TableCell>Array of CountryData</TableCell>
                            <TableCell>The audience' country demographic data</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>platform</TableCell>
                            <TableCell>Platform</TableCell>
                            <TableCell>The platform data.</TableCell>
                        </StyledTableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <TableContainer component={Paper} sx={{ marginBlockStart: 2, maxWidth: '37rem' }}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={3}>
                                Entity: Platform
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Field
                            </TableCell>
                            <TableCell>
                                Type
                            </TableCell>
                            <TableCell>
                                Description
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <StyledTableRow>
                            <TableCell>key</TableCell>
                            <TableCell>String</TableCell>
                            <TableCell>The platform key (e.g., "yt" for YouTube).</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>name</TableCell>
                            <TableCell>String</TableCell>
                            <TableCell>The name of the platform (e.g., "YouTube").</TableCell>
                        </StyledTableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <TableContainer component={Paper} sx={{ marginBlockStart: 2, maxWidth: '37rem' }}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={3}>
                                Entity: AudienceData
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Field
                            </TableCell>
                            <TableCell>
                                Type
                            </TableCell>
                            <TableCell>
                                Description
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <StyledTableRow>
                            <TableCell>age_range</TableCell>
                            <TableCell>String</TableCell>
                            <TableCell>The age range of the audience (e.g., "55+" or "13-17").</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>gender</TableCell>
                            <TableCell>String</TableCell>
                            <TableCell>The gender of the audience (e.g., "female", "male", "other").</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>value</TableCell>
                            <TableCell>String</TableCell>
                            <TableCell>The percentage value for the audience demographic.
                            </TableCell>
                        </StyledTableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <TableContainer component={Paper} sx={{ marginBlockStart: 2, maxWidth: '37rem' }}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={3}>
                                Entity: CountryData
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Field
                            </TableCell>
                            <TableCell>
                                Type
                            </TableCell>
                            <TableCell>
                                Description
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <StyledTableRow>
                            <TableCell>code</TableCell>
                            <TableCell>String</TableCell>
                            <TableCell>2 letter country code as per ISO 3166-1 alpha-2</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>value</TableCell>
                            <TableCell>String</TableCell>
                            <TableCell>The percentage value for the audience demographic.
                            </TableCell>
                        </StyledTableRow>
                    </TableBody>
                </Table>
            </TableContainer>

        </Container>
    )
}

import React, { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import client from '../../API';
import UploadFileDialog from '../../Components/tools/uploadfile';
import CreateAssetDialog from '../../Components/tools/createasset';
import CreateContractDialog from '../../Components/tools/createcontract';
import {
  Button,
  FormControlLabel,
  Switch,
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Paper,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  useTheme,
  CircularProgress,
  Alert
} from '@mui/material';
import {
  PictureAsPdf,
  Description,
  Image,
  UploadFile,
  Add,
  Folder,
  InsertDriveFile,
  Assignment
} from '@mui/icons-material';

const AssetsManager = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [createAssetDialogOpen, setCreateAssetDialogOpen] = useState(false);
  const [createContractDialogOpen, setCreateContractDialogOpen] = useState(false);
  const [prettyView, setPrettyView] = useState(true);
  const [activeTab, setActiveTab] = useState("all");

  // Separate queries for files and contracts
  const { 
    data: files, 
    isLoading: filesLoading,
    error: filesError,
    refetch: refetchFiles 
  } = useQuery('files', () => client.files.fetch(''), {
    initialData: [],
    initialDataUpdatedAt: 0 // Add missing initial data config
  });
  
  const { 
    data: contractsRes, 
    isLoading: contractsLoading, 
    error: contractsError 
  } = useQuery('contracts', () => client.files.fetchContracts(), {
    initialData: { data: [] }, // Match API response structure
    initialDataUpdatedAt: 0
  });
  
  
  // Process and merge data
  const isImageFile = (filename) => {
    const imageExtensions = ['.png', '.jpg', '.jpeg', '.gif', '.bmp', '.webp'];
    return imageExtensions.some(ext => filename?.toLowerCase().endsWith(ext));
  };

  // Determine file type for filtering
  const determineFileType = (filename) => {
    if (filename?.toLowerCase().endsWith('.html') || filename?.toLowerCase().endsWith('.htm')) {
      return 'html';
    }
    if (filename?.toLowerCase().endsWith('.pdf')) {
      return 'pdf';
    }
    if (isImageFile(filename)) {
      return 'image';
    }
    return 'misc';
  };

  // Loading and error states
  const isLoading = filesLoading || contractsLoading;
  const error = filesError || contractsError;

  const handleFileClick = (file) => {
    if (file.type === 'contract') {
      navigate(`/contract/${file.id}`);
    } else {
      window.open(file.file_url, '_blank');
    }
  };

  const handleSuccess = () => {
    queryClient.invalidateQueries('files');
    queryClient.invalidateQueries('contracts');
  };

  const openUploadDialog = () => setUploadDialogOpen(true);
  const closeUploadDialog = () => {
    setUploadDialogOpen(false);
    handleSuccess();
  };

  const openCreateAssetDialog = () => setCreateAssetDialogOpen(true);
  const closeCreateAssetDialog = () => {
    setCreateAssetDialogOpen(false);
    handleSuccess();
  };

  const openCreateContractDialog = () => setCreateContractDialogOpen(true);
  const closeCreateContractDialog = () => {
    setCreateContractDialogOpen(false);
    handleSuccess();
  };

  const togglePrettyView = () => setPrettyView((prev) => !prev);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const filterFiles = (files, filter) => {
    if (!files) return [];
    switch (filter) {
      case 'contract':
        return files.filter(file => file.type === 'contract');
      case 'html':
        return files.filter(file => determineFileType(file.filename) === 'html');
      case 'pdf':
        return files.filter(file => determineFileType(file.filename) === 'pdf');
      case 'image':
        return files.filter(file => determineFileType(file.filename) === 'image');
      case 'misc':
        return files.filter(file => determineFileType(file.filename) === 'misc');
      default:
        return files;
    }
  };

  const renderFileIcon = (file) => {
    if (file.type === 'contract') {
      return <Assignment fontSize="large" color="primary" />;
    }
    if (file.fileType === 'image') {
      return <Image fontSize="large" />;
    }
    if (file.fileType === 'pdf') {
      return <PictureAsPdf fontSize="large" />;
    }
    return <Description fontSize="large" />;
  };

  const renderFilePreview = (file) => {
    if (file.type === 'contract') {
      return <Assignment fontSize="large" color="primary" sx={{ fontSize: 80, margin: 'auto' }} />;
    }
    if (isImageFile(file.filename)) { // Use direct image check here
      return (
        <img
          src={file.file_url}
          alt={file.filename}
          style={{ 
            width: '100%', 
            height: 'auto', 
            maxHeight: '200px',
            objectFit: 'cover',
            borderRadius: '8px'
          }}
        />
      );
    }
    return renderFileIcon(file);
  };
// Updated contract processing
const contracts = (contractsRes?.data || []).map(contract => ({
  ...contract,
  type: 'contract',
  filename: `${contract.name}.pdf`,
  fileType: 'contract',
  creation_date: contract.created_at // Ensure consistent date field
}));
const processedFiles = (files || []).map(file => ({
  ...file,
  fileType: determineFileType(file.filename) // Add fileType to regular files
}));

const mergedData = [...processedFiles, ...contracts];
  const filteredFiles = filterFiles(mergedData, activeTab);

  if (isLoading) return <CircularProgress sx={{ margin: '2rem auto', display: 'block' }} />;
  if (error) return <Alert severity="error" sx={{ m: 2 }}>{error.message}</Alert>;

  return (
    <Box sx={{ p: 4, maxWidth: 1600, margin: '0 auto' }}>
      <Box sx={{
        mb: 4,
        p: 4,
        borderRadius: 4,
        background: `linear-gradient(45deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
        boxShadow: 3
      }}>
        <Typography variant="h3" component="h1" sx={{ 
          color: 'white', 
          mb: 2,
          fontWeight: 700,
          letterSpacing: 1
        }}>
          Digital Assets
        </Typography>
        
        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
          <Button
            variant="contained"
            onClick={openUploadDialog}
            startIcon={<UploadFile />}
            sx={{
              bgcolor: 'white',
              color: theme.palette.primary.main,
              '&:hover': { bgcolor: 'rgba(255,255,255,0.9)' }
            }}
          >
            Upload New
          </Button>
          <Button
            variant="contained"
            onClick={openCreateAssetDialog}
            startIcon={<Add />}
            sx={{
              bgcolor: 'white',
              color: theme.palette.primary.main,
              '&:hover': { bgcolor: 'rgba(255,255,255,0.9)' }
            }}
          >
            Create Asset
          </Button>
          <Button
            variant="contained"
            onClick={openCreateContractDialog}
            startIcon={<Assignment />}
            sx={{
              bgcolor: 'white',
              color: theme.palette.primary.main,
              '&:hover': { bgcolor: 'rgba(255,255,255,0.9)' }
            }}
          >
            New Contract
          </Button>
        </Box>
      </Box>

      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        mb: 4 
      }}>
        <Tabs 
          value={activeTab} 
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            '& .MuiTabs-indicator': {
              height: 3,
              borderRadius: 2
            }
          }}
        >
          <Tab label="All Assets" value="all" icon={<Folder />} />
          <Tab label="Contracts" value="contract" icon={<Assignment />} />
          <Tab label="HTML" value="html" icon={<Description />} />
          <Tab label="PDF" value="pdf" icon={<PictureAsPdf />} />
          <Tab label="Images" value="image" icon={<Image />} />
          <Tab label="Other" value="misc" icon={<InsertDriveFile />} />
        </Tabs>

        <FormControlLabel
          control={
            <Switch 
              checked={prettyView} 
              onChange={togglePrettyView}
              color="secondary"
            />
          }
          label="Gallery View"
          sx={{ ml: 2 }}
        />
      </Box>

      {prettyView ? (
        <Grid container spacing={3}>
          {filteredFiles?.map((file) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={file.id}>
              <Card
                component={file.type === 'contract' ? Link : 'div'}
                to={file.type === 'contract' ? `/contract/${file.id}` : undefined}
                onClick={() => file.type !== 'contract' && handleFileClick(file)}
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'transform 0.2s, box-shadow 0.2s',
                  textDecoration: 'none',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: 6,
                    cursor: 'pointer'
                  }
                }}
              >
                <Box sx={{ 
                  flexGrow: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minHeight: 200
                }}>
                  {renderFilePreview(file)}
                </Box>
                <CardContent sx={{ flexGrow: 0 }}>
                  <Typography variant="subtitle1" fontWeight={600}>
                    {file.name || file.filename}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {new Date(file.created_at || file.creation_date).toLocaleDateString()}
                    {file.type === 'contract' && ' • Contract'}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <TableContainer component={Paper} sx={{ borderRadius: 4 }}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead sx={{ bgcolor: theme.palette.action.selected }}>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>Name</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Type</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Created Date</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredFiles?.map((file) => (
                <TableRow 
                  key={file.id}
                  hover
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      {renderFileIcon(file)}
                      {file.name || file.filename}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Chip 
                      label={file.type === 'contract' ? 'Contract' : file.fileType?.toUpperCase()}
                      size="small"
                      color={file.type === 'contract' ? 'secondary' : 'primary'}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>
                    {new Date(file.created_at || file.creation_date).toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    <Button 
                      variant="outlined" 
                      size="small"
                      onClick={() => handleFileClick(file)}
                    >
                      {file.type === 'contract' ? 'View' : 'Preview'}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <UploadFileDialog open={uploadDialogOpen} onClose={closeUploadDialog} />
      <CreateAssetDialog open={createAssetDialogOpen} onClose={closeCreateAssetDialog} />
      <CreateContractDialog 
        open={createContractDialogOpen} 
        onClose={closeCreateContractDialog}
        onSuccess={handleSuccess}
      />
    </Box>
  );
};

export default AssetsManager;
import React from "react";
import { Box, Typography, Container, Toolbar } from "@mui/material";
import HomeHeader from "../../../Components/HomeHeader";
import HomeFooter from "../../../Components/HomeFooter";
import { globalStyles } from "../../../Utils/Styles";
import { StyledDivider as Divider } from "../../../Components/LightDivider";

const TermsOfServiceAgencies = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        width: '100%',
        color: '#000'
      }}
    >
      <HomeHeader />
      <Toolbar />

      {/* Main Content */}
      <Container
        sx={{
          ...globalStyles.wholeHeightMinusToolbar,
          paddingTop: 6,
          paddingBottom: 4,
        }}
      >
        {/* Heading */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h4" sx={{ fontWeight: "bold", mb: 3 }}>
            Terms of Service for Agencies
          </Typography>

          <Typography variant="body1" paragraph>
            Welcome to Blitz! These Terms of Service ("Terms") govern the relationship
            between Blitz Systems Corp ("we," "our," or "Blitz") and marketing agencies
            ("Agency," "you," or "your") that use our services, including Blitzpay and
            other tools provided on our platform. By accessing and using our services,
            you agree to be bound by these Terms.
          </Typography>
        </Box>

        <Divider />

        {/* 1. Overview of Services */}
        <Box sx={{ my: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            1. Overview of Services
          </Typography>
          <Typography variant="body1" paragraph>
            Blitz provides agencies with tools to manage campaigns, connect with creators,
            and facilitate payments through Blitzpay. Our services simplify campaign
            execution and financial management, helping agencies pay creators faster
            and maintain smoother operations.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>1.1. Blitz is completely FREE</strong> for agencies to use. There are
            no subscription fees or hidden charges for accessing the platform's core
            features. The only fee applied is the Blitzpay Fee, which is charged for processed
            payouts through our platform when you choose to pay creators or receive early
            payments.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>1.2. Blitzpay service:</strong> Blitzpay offers agencies the option to
            receive early payments or pay creators within 24 hours for an additional fee.
            This service is subject to approval of a credit line and the terms outlined
            in these Terms.
          </Typography>
        </Box>

        <Divider />

        {/* 2. Credit Line Application for BlitzPay */}
        <Box sx={{ my: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            2. Credit Line Application for Blitzpay
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>2.1. Application process:</strong> Agencies wishing to use the Blitzpay
            feature can apply for a credit line. The application will require submitting
            relevant financial documents for review.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>2.2. Credit line approval:</strong> Once we receive the necessary documents,
            Blitz will assess the agency’s creditworthiness and assign an appropriate credit
            line. Approval is at Blitz’s sole discretion.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>2.3. Blitzpay fee and payment terms:</strong> Depending on the agency’s
            payment terms and credit line, the factoring fee will range between 5% and 10%
            of the Blitzpay amount. The exact fee and due date will be specified in the
            Blitzpay agreement for each transaction.
          </Typography>
        </Box>

        <Divider />

        {/* 3. Financial Health Score */}
        <Box sx={{ my: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            3. Financial Health Score
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>3.1. What is the Financial Health Score?</strong> Blitz assigns each
            agency a Financial Health Score, which reflects their payment history and
            overall financial behavior. This score helps determine the factoring fee and
            credit line eligibility for future Blitzpay transactions.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>3.2. How it works:</strong>
          </Typography>
          <Typography variant="body1" component="ul" sx={{ pl: 3 }}>
            <li>
              <strong>Maintaining a good score:</strong> Paying on time will help agencies
              keep a high score, ensuring continued access to lower Blitzpay fees (5%–7%)
              and favorable credit terms.
            </li>
            <li>
              <strong>Delayed payments:</strong> If payments are delayed beyond the agreed
              due date, the score will be negatively affected. This can result in increased
              Blitzpay fees (up to 10%) for future transactions and a possible reduction
              of the available credit line.
            </li>
            <li>
              <strong>Severe delays:</strong> Multiple or significant delays may lead to
              the suspension of Blitzpay services or termination of the agreement.
            </li>
          </Typography>
        </Box>

        <Divider />

        {/* 4. Blitzpay Payment Terms */}
        <Box sx={{ my: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            4. Blitzpay Payment Terms
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>4.1. Payment obligations:</strong> Agencies must repay the full invoiced
            amount, including the agreed fee, by the specified due date.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>4.2. Late payment fee:</strong> In the event payment is not received by
            the due date, Blitz will charge an additional 0.5% per day on the outstanding
            balance for each day the payment is late beyond the due date.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>4.3. Payment methods:</strong> Payments can be made via direct bank transfer
            or through Stripe. Please note that payments made through Stripe may incur an
            additional platform fee.
          </Typography>
        </Box>

        <Divider />

        {/* 5. Agency Responsibilities */}
        <Box sx={{ my: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            5. Agency Responsibilities
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>5.1. Accurate information:</strong> Agencies must provide accurate and
            up-to-date campaign, financial, and payment details.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>5.2. Compliance with laws:</strong> Agencies are responsible for ensuring
            compliance with all applicable laws and regulations when using Blitz services.
          </Typography>
        </Box>

        <Divider />

        {/* 6. Termination and Amendments */}
        <Box sx={{ my: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            6. Termination and Amendments
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>6.1. Termination or suspension:</strong> Blitz reserves the right to
            terminate or suspend an agency’s access to services at any time for breach
            of these Terms, late payments, or misuse of the platform.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>6.2. Updates to Terms:</strong> Blitz may update these Terms periodically.
            Any changes will be posted on our website, and continued use of the services
            constitutes acceptance of the updated Terms.
          </Typography>
        </Box>

        <Divider />

        {/* 7. Governing Law and Dispute Resolution */}
        <Box sx={{ my: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            7. Governing Law and Dispute Resolution
          </Typography>
          <Typography variant="body1" paragraph>
            These Terms are governed by the laws of the State of Texas, United States.
            Any disputes will be resolved in the courts of Houston, Texas.
          </Typography>
        </Box>

        <Divider />

        {/* Acceptance of Terms */}
        <Box sx={{ my: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            Acceptance of Terms
          </Typography>
          <Typography variant="body1" paragraph>
            By using Blitz services, you acknowledge that you have read, understood, and agree
            to be bound by these Terms of Service.
          </Typography>
        </Box>
      </Container>

      <HomeFooter />
    </Box>
  );
};

export default TermsOfServiceAgencies;

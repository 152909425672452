import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Typography,
  Box,
  TextField,
  IconButton,
  Snackbar,
  Alert,
  Avatar,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  CircularProgress,
  Paper,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import OfferIcon from "@mui/icons-material/LocalOffer";
import PayoutIcon from "@mui/icons-material/MonetizationOn";
import MenuIcon from "@mui/icons-material/Menu";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useAuth from "../../../../Hooks/use-auth";
import client from "../../../../API";
import SendOfferDialog from "./smallercomps/sendoffer";
import PayoutDialog from "./smallercomps/payout";
import OfferDialog from "./smallercomps/offerdialog";
import DeliverableCard from "./smallercomps/deliverablecard";
import PartnershipsDetailView from "./detailview";
import {
  Delete,
  PersonAddAlt1Outlined as PersonAddAlt1OutlinedIcon,
} from "@mui/icons-material";
import AlertDialog from "../../../../Components/AlertDialog";
import useAlertDialog from "../../../../Components/useAlertDialog";

const PartnershipsPage = () => {
  const { partnershipId } = useParams();

  const [messages, setMessages] = useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [sendOfferOpen, setSendOfferOpen] = useState(false);
  const [sendPayoutOpen, setSendPayoutOpen] = useState(false);
  const [viewOfferOpen, setViewOfferOpen] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [viewDetailsOpen, setViewDetailsOpen] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [shareEmails, setShareEmails] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [offerSent, setOfferSent] = useState(false);
  const [userMessagesCount, setUserMessagesCount] = useState(0);
  const [showMessageCapAlert, setShowMessageCapAlert] = useState(false);

  // NEW STATE FOR DETECTING PENDING STATUS
  const [pendingDialogOpen, setPendingDialogOpen] = useState(false);

  const queryClient = useQueryClient();
  const { getCurrrentUser } = useAuth();

  const userInfo = getCurrrentUser();
  const userCompany = userInfo.company_name;
  const isTcc = userCompany === "TCC";

  const { dialogState, openDialog, closeDialog } = useAlertDialog();

  // Fetch partnership
  const {
    data: partnershipFetch,
    isLoading: loading,
    error: partnershipError,
    refetch: partnershipRefetch,
  } = useQuery({
    queryKey: ["partnerships", partnershipId],
    queryFn: () => client.partnerships.fetchPartnership(partnershipId),
    refetchOnWindowFocus: false,
  });

  // Handle fetch errors
  useEffect(() => {
    if (partnershipError) {
      console.error("Error fetching partnership details:", partnershipError);
      if (partnershipError.response && partnershipError.response.status === 403) {
        alert("You may not view this partnership!");
      } else {
        alert(
          `Error while fetching partnership: ${
            partnershipError.response?.data?.error || partnershipError.message
          }`
        );
      }
    }
  }, [partnershipError]);

  // Partnership data
  const partnershipData = !!partnershipFetch
    ? partnershipFetch.partnership
    : { shared_with: [] };
  const partnership = !!partnershipFetch ? partnershipData.name : "N/A";
  const creator = !!partnershipFetch
    ? {
        name: partnershipData.creator,
        pfphref: partnershipData.pfphref || "/placeholder.png",
      }
    : { name: "Unknown", pfphref: "/placeholder.png" };

  // CHECK FOR "pending" STATUS AFTER PARTNERSHIP LOADS
  useEffect(() => {
    if (!loading && partnershipData && partnershipData.status) {
      // If partnershipData.status is "pending" (case-insensitive), open the dialog
      if (partnershipData.status.toLowerCase() === "pending") {
        setPendingDialogOpen(true);
      }
    }
  }, [loading, partnershipData]);

  // If there's a conversation already, it will be on the partnership object
  const conversationId = !!partnershipFetch ? partnershipData.conversation_id : null;

  // Fetch messages
  const fetchMessages = useQuery(
    ["messages", conversationId],
    () => client.partnershipConversations.getMessages(conversationId),
    {
      enabled: !!conversationId,
      onSuccess: (data) => {
        const sortedMessages = data.sort((a, b) => a.id - b.id);
        setMessages(sortedMessages);

        const userMessages = sortedMessages.filter(
          (msg) => msg.sender_id === userInfo.id
        );
        const creatorMessages = sortedMessages.filter(
          (msg) => msg.sender_id !== userInfo.id
        );

        // Counting how many messages user has sent since last creator message
        if (creatorMessages.length > 0) {
          const lastCreatorMessageTime = new Date(
            creatorMessages[creatorMessages.length - 1].created_at
          ).getTime();
          const recentUserMessages = userMessages.filter(
            (msg) => new Date(msg.created_at).getTime() > lastCreatorMessageTime
          );
          setUserMessagesCount(recentUserMessages.length);
        } else {
          setUserMessagesCount(userMessages.length);
        }
      },
    }
  );

  // Create conversation if none
  const createConversation = useMutation(client.partnershipConversations.create, {
    onSuccess: () => {
      partnershipRefetch();
    },
    onError: (error) => {
      console.error("Error creating conversation:", error);
    },
  });

  // Send message
  const sendMessageMutation = useMutation(
    ({ id, input }) => client.conversations.sendMessage(id, input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["messages", conversationId]);
        fetchMessages.refetch();
        setMessage("");
      },
      onError: (error) => {
        console.error("Error sending message:", error);
        if (error?.response?.data?.error === "forbidden") {
          openDialog(
            "Error",
            "This message was flagged as containing prohibited content. Please refrain from sharing personal contact information.",
            closeDialog,
            closeDialog,
            "Ok",
            null
          );
          return;
        }
        openDialog(
          "Error",
          <>
            Error while sending message: <br />
            {error?.response?.data?.error || error.message}
          </>,
          closeDialog,
          closeDialog,
          "Ok",
          null
        );
      },
    }
  );

  // Delete message (only TCC can do this)
  const {
    mutate: deleteMessage,
    isLoading: deleteMessageLoading,
  } = useMutation({
    mutationFn: (id) => client.conversations.deleteMessage(id),
    onSuccess: () => fetchMessages.refetch(),
    onError: (error) => {
      openDialog(
        "Error",
        <>
          Error while deleting message: <br />
          {error?.response?.data?.error || error.message}
        </>,
        closeDialog,
        closeDialog,
        "Ok",
        null
      );
    },
  });

  // Basic check for phone numbers or "contact me" attempts
  const isMessageAllowed = (messageText) => {
    const prohibitedKeywords = [
      "text me",
      "contact me",
      "contact",
      "@gmail.com",
      "wa",
      "whatsapp",
      "convenient contact",
    ];
    const phoneRegex = /(?:\d[\s-]?){10,}/; // approximate phone matching
    for (let keyword of prohibitedKeywords) {
      if (messageText.toLowerCase().includes(keyword)) {
        return false;
      }
    }
    if (phoneRegex.test(messageText)) {
      return false;
    }
    return true;
  };

  const onConfirmDeleteMessage = (messageId) => {
    closeDialog();
    deleteMessage(messageId);
  };

  const handleDeleteMessage = (messageId) => {
    if (isTcc) {
      openDialog(
        "Warning",
        "Delete message?",
        () => onConfirmDeleteMessage(messageId),
        closeDialog,
        "Yes",
        "No"
      );
    }
  };

  // Handle sending user message
  const handleSendMessage = async () => {
    if (userMessagesCount >= 5) {
      setShowMessageCapAlert(true);
      return;
    }

    if (!isMessageAllowed(message)) {
      alert(
        "Your message contains prohibited content. Please refrain from sharing personal contact information."
      );
      return;
    }

    const messageData = { message };

    if (conversationId) {
      sendMessageMutation.mutate({
        input: messageData,
        id: conversationId,
      });
    } else {
      try {
        // If no conversation, create one first
        const response = await createConversation.mutateAsync({
          partnership_id: partnershipId,
          creator_id: creator.name,
        });
        sendMessageMutation.mutate({
          input: messageData,
          id: response.conversation_id,
        });
      } catch (error) {
        // handle error silently or show user message
        return;
      }
    }
  };

  // Update partnership to "Completed" or another status
  const { mutate: updatePartnershipStatus } = useMutation(
    (status) => client.partnerships.updateStatus(partnershipId, { status }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("partnerships");
        setConfirmDialogOpen(false);
      },
      onError: (error) => {
        console.error("Error updating partnership status:", error);
      },
    }
  );

  const handleCompletePartnershipClick = () => {
    setConfirmDialogOpen(true);
    handleMenuClose();
  };

  const handleConfirmCompletion = () => {
    updatePartnershipStatus("Completed");
  };

  // Menu handling
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Offer & Payout dialogs
  const handleSendOfferClick = () => {
    setSendOfferOpen(true);
  };
  const handleSendPayoutClick = () => {
    setSendPayoutOpen(true);
  };
  const handleViewOfferClick = (offer) => {
    setSelectedOffer(offer);
    setViewOfferOpen(true);
  };
  const handleViewDetailsClick = () => {
    setViewDetailsOpen(true);
    handleMenuClose();
  };

  // Called after an offer is sent
  const handleOfferSent = () => {
    sendMessageMutation.mutate({
      id: conversationId,
      input: { message: "An offer was made, see above." },
    });
    setOfferSent(true);
  };

  // Sharing the partnership with colleagues
  const sharePartnershipMutation = useMutation(
    (sharedWith) => client.partnerships.share(partnershipId, { shared_with: sharedWith }),
    {
      onSuccess: () => {
        setShareDialogOpen(false);
        setShareEmails("");
        alert("Partnership shared successfully");
        // Re-fetch partnership data to update shared_with
        queryClient.invalidateQueries(["partnership", partnershipId]);
        partnershipRefetch();
      },
      onError: (error) => {
        console.error("Error sharing partnership:", error);
        alert(
          `Error sharing partnership: ${
            error.response?.data?.error || error.message
          }`
        );
      },
    }
  );

  const handleShareClick = () => {
    setShareDialogOpen(true);
  };
  const handleShareSubmit = () => {
    const emails = shareEmails
      .split(",")
      .map((email) => email.trim())
      .filter((email) => email !== "");
    if (emails.length === 0) {
      alert("Please enter at least one email address");
      return;
    }
    sharePartnershipMutation.mutate(emails);
  };

  // Helper to highlight links in messages
  const formatMessage = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex).map((part, index) => {
      if (urlRegex.test(part)) {
        return (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      }
      return part;
    });
  };

  // Loading screen
  if (loading) {
    return (
      <Typography
        sx={{
          width: "100vw",
          height: "100vh",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Loading partnership details...
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100vh",
      }}
    >
      <AlertDialog alertState={dialogState} />

      {creator.name && (
        <>
          <Paper
            sx={{ position: "sticky", top: 0, zIndex: 2, padding: 2 }}
            elevation={4}
            square
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{ mb: 2 }}
            >
              <Box display="flex" alignItems="center">
                <Avatar src={creator.pfphref} alt={creator.name} />
                <Typography variant="h6" sx={{ marginLeft: 2 }}>
                  Partnership with @{creator.name} - {partnership}
                </Typography>
                <Typography variant="body1" sx={{ marginLeft: 2 }}>
                  {partnershipData.description}
                </Typography>
              </Box>
              <Box>
                <IconButton onClick={handleShareClick} color="inherit">
                  <PersonAddAlt1OutlinedIcon />
                </IconButton>
                <IconButton onClick={handleMenuOpen} color="inherit">
                  <MenuIcon />
                </IconButton>
              </Box>
              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                <MenuItem onClick={handleViewDetailsClick}>
                  View Partnership Details
                </MenuItem>
                {partnershipData.status === "Active" && (
                  <MenuItem onClick={handleCompletePartnershipClick}>
                    Complete Partnership
                  </MenuItem>
                )}
              </Menu>
            </Box>
          </Paper>

          {/* Warning banner */}
          <Paper
            elevation={2}
            sx={{ p: 2, mb: 4, backgroundColor: "#fff3cd", margin: 2 }}
          >
            <Typography
              variant="body1"
              gutterBottom
              sx={{ color: "#856404", textAlign: "center" }}
            >
              ⚠️ Deals and payments must happen within the Blitz platform. Going
              outside the Blitz platform could result in banishment from the
              platform and losing deals from other partnerships.
            </Typography>
          </Paper>

          {/* Messages container */}
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              padding: 2,
              minHeight: "400px",
              overflowY: "auto",
              flexGrow: 1,
            }}
          >
            {fetchMessages.isLoading && <Typography>Loading messages...</Typography>}
            {fetchMessages.error && <Typography>Error loading messages</Typography>}

            {messages.map((msg, index) => {
              const isUserMessage = msg.sender_id === userInfo.id;
              const justify = isUserMessage ? "flex-end" : "flex-start";

              // Figure out who is sending the message
              let senderName = "";
              if (msg.sender_first_name && msg.sender_last_name) {
                senderName = `${msg.sender_first_name} ${msg.sender_last_name}`;
              } else if (msg.creator_id) {
                senderName = msg.creator_id;
              } else {
                senderName = "Blitz System";
              }

              return (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: justify,
                    mb: 2,
                  }}
                >
                  {/* If there's a deliverable, show the DeliverableCard. Otherwise, show a text message. */}
                  {msg.deliverable ? (
                    <DeliverableCard
                      deliverable={msg.deliverable}
                      onViewOffer={handleViewOfferClick}
                      isUserMessage={isUserMessage}
                    />
                  ) : (
                    <Paper
                      elevation={2}
                      sx={{
                        maxWidth: "70%",
                        p: 2,
                        position: "relative",
                        borderRadius: 2,
                        backgroundColor: isUserMessage ? "#007bff" : "#e4e6eb",
                        color: isUserMessage ? "white" : "black",
                        textAlign: isUserMessage ? "right" : "left",
                        zIndex: 0,
                      }}
                    >
                      {/* Show sender name if not the user's own message */}
                      {!isUserMessage && (
                        <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                          {senderName}
                        </Typography>
                      )}
                      <Typography variant="body1">
                        {formatMessage(msg.message)}
                      </Typography>
                      <Typography variant="caption" sx={{ display: "block", mt: 1 }}>
                        {new Date(msg.created_at).toLocaleTimeString()} -{" "}
                        {msg.read_at
                          ? `Read on ${new Date(msg.read_at).toLocaleString()}`
                          : "Not read yet"}
                      </Typography>
                      {isTcc && (
                        <IconButton
                          disabled={deleteMessageLoading}
                          onClick={() => handleDeleteMessage(msg.id)}
                          sx={(theme) => ({
                            backgroundColor: theme.palette.background.paper,
                            boxShadow: 2,
                            position: "absolute",
                            right: !isUserMessage ? "calc(0% - 30px)" : "auto",
                            left: isUserMessage ? "calc(0% - 30px)" : "auto",
                            top: "calc(50% - 20px)",
                            zIndex: 2,
                          })}
                        >
                          <Delete />
                        </IconButton>
                      )}
                    </Paper>
                  )}
                </Box>
              );
            })}
          </Box>

          {/* Message input + action buttons */}
          <Box sx={{ padding: 2 }}>
            <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
              <Button
                variant="contained"
                startIcon={<OfferIcon />}
                onClick={handleSendOfferClick}
                sx={{ mr: 2 }}
              >
                Send Offer
              </Button>
              <Button
                variant="contained"
                startIcon={<PayoutIcon />}
                onClick={handleSendPayoutClick}
              >
                Send Payout
              </Button>
            </Box>
            <Box display="flex" alignItems="center">
              <TextField
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                label="Type a message"
                variant="outlined"
                sx={{ flex: 1 }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleSendMessage();
                  }
                }}
              />
              <IconButton onClick={handleSendMessage} color="primary" sx={{ ml: 2 }}>
                {sendMessageMutation.isLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  <SendIcon />
                )}
              </IconButton>
            </Box>
          </Box>

          {/* Offer dialogs */}
          <SendOfferDialog
            open={sendOfferOpen}
            onClose={() => setSendOfferOpen(false)}
            partnershipId={partnershipId}
            creatorId={creator.name}
            conversationId={conversationId}
            onOfferSent={handleOfferSent}
          />
          <PayoutDialog
            open={sendPayoutOpen}
            onClose={() => setSendPayoutOpen(false)}
            partnershipId={partnershipId}
            creatorId={creator.name}
            conversationId={conversationId}
          />
          {selectedOffer && (
            <OfferDialog
              open={viewOfferOpen}
              onClose={() => setViewOfferOpen(false)}
              offer={selectedOffer}
            />
          )}
          <PartnershipsDetailView
            open={viewDetailsOpen}
            onClose={() => setViewDetailsOpen(false)}
            partnershipId={partnershipId}
            partnershipData={partnershipData}
            creator={{ name: creator.name }}
          />

          {/* Show snack bar if message cap is exceeded */}
          <Snackbar
            open={showMessageCapAlert}
            autoHideDuration={6000}
            onClose={() => setShowMessageCapAlert(false)}
          >
            <Alert
              onClose={() => setShowMessageCapAlert(false)}
              severity="warning"
              sx={{ width: "100%" }}
            >
              Messages since last creator response: You are capped at 5
            </Alert>
          </Snackbar>

          {/* Share Partnership Dialog */}
          <Dialog open={shareDialogOpen} onClose={() => setShareDialogOpen(false)}>
            <DialogTitle>Share Partnership</DialogTitle>
            <DialogContent>
              {partnershipData.shared_with && partnershipData.shared_with.length > 0 ? (
                <>
                  <Typography variant="subtitle1">Already shared with:</Typography>
                  <ul>
                    {partnershipData.shared_with.map((email) => (
                      <li key={email}>{email}</li>
                    ))}
                  </ul>
                </>
              ) : (
                <Typography variant="subtitle1">
                  Nobody has been shared this yet! Invite your first colleague today.
                </Typography>
              )}
              <DialogContentText sx={{ mt: 2 }}>
                Enter the email addresses of the users you want to share this partnership
                with. Separate multiple emails with commas.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="emails"
                label="Email Addresses"
                type="email"
                fullWidth
                variant="standard"
                value={shareEmails}
                onChange={(e) => setShareEmails(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShareDialogOpen(false)}>Cancel</Button>
              <Button onClick={handleShareSubmit} disabled={sharePartnershipMutation.isLoading}>
                {sharePartnershipMutation.isLoading ? <CircularProgress size={24} /> : "Share"}
              </Button>
            </DialogActions>
          </Dialog>

          {/* Confirm completion Dialog */}
          <Dialog
            open={confirmDialogOpen}
            onClose={() => setConfirmDialogOpen(false)}
          >
            <DialogTitle>Confirm Completion</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to mark this partnership as completed?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setConfirmDialogOpen(false)}>Cancel</Button>
              <Button onClick={handleConfirmCompletion} color="primary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>

          {/* NEW DIALOG: SHOW IF PARTNERSHIP STATUS IS "pending" */}
          <Dialog
            open={pendingDialogOpen}
            onClose={() => setPendingDialogOpen(false)}
          >
            <DialogTitle>Your Partnership is still pending</DialogTitle>
            <DialogContent>
              <DialogContentText>
                It looks like this partnership is still in a pending status. Please send a
                formal offer or request to your partner so you can specify the deal details
                and get started.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setPendingDialogOpen(false)} color="primary">
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Box>
  );
};

export default PartnershipsPage;

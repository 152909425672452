import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Button,
  Snackbar,
  Alert,
  DialogContentText,
  Autocomplete,
  FormControl,
  Select,
  MenuItem
} from "@mui/material";
import { useNavigate } from "react-router-dom";

// ====== EXAMPLE IMPORTS ======
import client from "../../API"; // Your main API client
import API from "../../API";     // If 'creatorConnect' is on this same object, or adjust as needed

// Utility functions from your code or inline them
import { getPONumber, parseLocalFloat } from "../../Utils/constants";

// The same steps from your original InvoiceDialog
const steps = [
  "Select Creator",
  "Select Payment Method",
  "Enter Client Information",
  "Enter Invoice Details",
  "Confirm and Submit",
];

const ClientInvoicingPage = () => {
  const navigate = useNavigate();

  // -----------------------------
  // STEP / UI STATE
  // -----------------------------
  const [activeStep, setActiveStep] = useState(0);

  // -----------------------------
  // 0) FETCH CREATORS
  // -----------------------------
  const [creatorList, setCreatorList] = useState([]);
  const [selectedCreator, setSelectedCreator] = useState(null);

  // On mount, fetch the list of creators
  useEffect(() => {
    const fetchCreators = async () => {
      try {
        // Adjust to your actual API call for listing creators
        const data = await client.creators.list();
        // Map each item to have a `label` for the Autocomplete
        const formatted = (data || []).map((c) => ({
          ...c,
          label: c.creator, // or c.name, etc.
        }));
        setCreatorList(formatted);
      } catch (error) {
        console.error("Error fetching creators:", error);
      }
    };
    fetchCreators();
  }, []);

  // -----------------------------
  // 1) PAYMENT METHOD
  // -----------------------------
  const [paymentMethod, setPaymentMethod] = useState("");

  // -----------------------------
  // 2) CLIENT INFORMATION
  // -----------------------------
  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [clientNotes, setClientNotes] = useState("");
  // For the interest rate, if we detect their email is in the system
  const [interestRate, setInterestRate] = useState(0);
  const [blitzFee, setBlitzFee] = useState(0);
  const [netToReceive, setNetToReceive] = useState(0);

  // -----------------------------
  // 3) INVOICE DETAILS
  // -----------------------------
  const [poNumber, setPONumber] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  const [amountDue, setAmountDue] = useState("");
  const [shareEmail, setShareEmail] = useState(""); // e.g. to “share” the invoice link

  // -----------------------------
  // SNACKBAR
  // -----------------------------
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const showSnackbar = (message, severity = "info") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  const handleCloseSnackbar = (_event, reason) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };

  // -----------------------------
  // ON MOUNT: generate default PO
  // -----------------------------
  useEffect(() => {
    setPONumber(getPONumber("TCC")); 
    // or: setPONumber(`INV-${Math.floor(Math.random() * 1000000)}`)
  }, []);

  // Whenever amountDue or interestRate changes, recalc fees
  useEffect(() => {
    const amt = parseLocalFloat(amountDue);
    const fee = (amt * interestRate) / 100;
    setBlitzFee(fee);
    setNetToReceive(amt - fee);
  }, [amountDue, interestRate]);

  // -----------------------------
  // STEPPER NAV
  // -----------------------------
  const handleNext = () => {
    // Step-by-step validation
    if (activeStep === 0) {
      if (!selectedCreator) {
        showSnackbar("Please select a Creator to continue.", "error");
        return;
      }
    }
    else if (activeStep === 1 && !paymentMethod) {
      showSnackbar("Please select a Payment Method.", "error");
      return;
    }
    else if (activeStep === 2) {
      if (!clientName || !clientEmail) {
        showSnackbar("Please enter the Client's name and email.", "error");
        return;
      }
    }
    else if (activeStep === 3) {
      if (!poNumber || !amountDue) {
        showSnackbar("Please enter the PO Number and Amount Due.", "error");
        return;
      }
    }
    setActiveStep((prev) => prev + 1);
  };
  const handleBack = () => setActiveStep((prev) => prev - 1);

  // -----------------------------
  // CHECK if CLIENT EMAIL is in PLATFORM
  // (like your "creatorConnect.fetchUserInfo" flow)
  // -----------------------------
  const handleCheckClientEmail = async () => {
    if (!clientEmail) return;
    try {
      const response = await API.creatorConnect.fetchUserInfo(clientEmail);
      if (response?.found) {
        // user found => apply interestRate from their data
        const userData = response.user;
        setInterestRate(userData.company_interest_rate ?? 0);
        showSnackbar("Client found in the platform. Applied their interest rate.", "success");
      } else {
        // not found => default
        setInterestRate(10); // or something else
        showSnackbar("Client not found. We'll invite them. Using default 10% rate.", "info");
      }
    } catch (err) {
      console.error("Error checking email:", err);
      showSnackbar(`Error checking client email: ${err.message}`, "error");
    }
  };

  // -----------------------------
  // FINAL SUBMIT
  // (mimics invoiceDialog.handleSubmit)
  // -----------------------------
  const handleSubmitInvoice = async () => {
    // Build the same submissionData as your InvoiceDialog does
    const submissionData = {
      // Must match your DB PK for the Creator
      creator_id: selectedCreator?.creator, // Or if your API needs .id or something else
      payment_method: paymentMethod,
      po_number: poNumber,
      amount_due: parseLocalFloat(amountDue),
      // Additional fields:
      email: shareEmail,
      client_name: clientName,
      client_email: clientEmail,
      client_notes: clientNotes,
      items: [
        {
          description: itemDescription,
          amount: parseLocalFloat(amountDue),
        },
      ],
    };

    try {
      const response = await client.invoices.create(submissionData);
      if (response.status === "Success") {
        showSnackbar("Invoice created and shared successfully!", "success");
        // Return or navigate away after a small delay
        setTimeout(() => {
          navigate("/"); // go back to your main page
        }, 1200);
      } else {
        throw new Error(response.message || "Error creating invoice");
      }
    } catch (error) {
      console.error("Error submitting invoice:", error);
      showSnackbar(`Error submitting invoice: ${error.message}`, "error");
    }
  };

  // -----------------------------
  // PAYPAL SUBMIT
  // (mimics invoiceDialog.handlePayPalPayment)
  // -----------------------------
  const handlePayPalPayment = async () => {
    try {
      const data = {
        amount: parseLocalFloat(amountDue),
        po_number: poNumber,
        creator_id: selectedCreator?.creator,
      };
      const response = await client.invoices.createPaypalPayment(data);
      if (response.status === "Success" && response.approvalUrl) {
        // redirect to PayPal
        window.location.href = response.approvalUrl;
      } else {
        showSnackbar("PayPal payment initiation failed", "error");
      }
    } catch (error) {
      console.error("Error initiating PayPal payment:", error);
      showSnackbar("Error initiating PayPal payment", "error");
    }
  };

  // -----------------------------
  // RENDER STEP CONTENT
  // -----------------------------
  const renderStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        // SELECT CREATOR (Autocomplete)
        return (
          <Box>
            <Autocomplete
              options={creatorList}
              getOptionLabel={(option) => option.label || ""}
              value={selectedCreator}
              onChange={(_, newValue) => setSelectedCreator(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select a Creator"
                  variant="outlined"
                  fullWidth
                  required
                />
              )}
            />
          </Box>
        );

      case 1:
        // SELECT PAYMENT METHOD
        return (
          <FormControl fullWidth sx={{ mt: 2 }}>
            <Select
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
              displayEmpty
            >
              <MenuItem value="">-- Select Payment Method --</MenuItem>
              <MenuItem value="ach">Bank Wire / ACH Transfer</MenuItem>
              <MenuItem value="paypal">PayPal</MenuItem>
              <MenuItem value="stripe">Stripe</MenuItem>
              <MenuItem value="bank_wire">Bank Wire</MenuItem>
            </Select>
          </FormControl>
        );

      case 2:
        // ENTER CLIENT INFORMATION
        return (
          <Box display="flex" flexDirection="column" gap={2} mt={2}>
            <TextField
              label="Client Name"
              value={clientName}
              onChange={(e) => setClientName(e.target.value)}
              fullWidth
            />
            <TextField
              label="Client Email"
              value={clientEmail}
              onChange={(e) => setClientEmail(e.target.value)}
              onBlur={handleCheckClientEmail}
              fullWidth
            />
            <TextField
              label="Notes"
              value={clientNotes}
              onChange={(e) => setClientNotes(e.target.value)}
              fullWidth
              multiline
              rows={3}
            />
            {!!interestRate && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                Current Interest Rate: {interestRate}%
              </Typography>
            )}
          </Box>
        );

      case 3:
        // ENTER INVOICE DETAILS
        return (
          <Box display="flex" flexDirection="column" gap={2} mt={2}>
            <TextField
              label="PO Number"
              value={poNumber}
              onChange={(e) => setPONumber(e.target.value)}
              fullWidth
            />
            <Button variant="outlined" onClick={() => setPONumber(getPONumber("TCC"))}>
              Generate PO
            </Button>

            <TextField
              label="Item Description"
              multiline
              minRows={3}
              value={itemDescription}
              onChange={(e) => setItemDescription(e.target.value)}
              fullWidth
            />
            <TextField
              label="Amount Due"
              type="number"
              value={amountDue}
              onChange={(e) => setAmountDue(e.target.value)}
              fullWidth
            />

            {interestRate > 0 && (
              <Typography variant="body2">
                Blitz Fee: ${blitzFee.toFixed(2)} (at {interestRate}%)
              </Typography>
            )}
            {interestRate > 0 && (
              <Typography variant="body2" fontWeight="bold">
                Net to Receive: ${netToReceive.toFixed(2)}
              </Typography>
            )}

            <TextField
              label="Share Invoice (Email)"
              type="email"
              value={shareEmail}
              onChange={(e) => setShareEmail(e.target.value)}
              placeholder="Optional: send invoice link"
              fullWidth
            />
          </Box>
        );

      case 4:
        // CONFIRM & SUBMIT
        return (
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Review Invoice Details
            </Typography>
            <Typography variant="body2">
              <strong>Creator:</strong> {selectedCreator?.creator}
            </Typography>
            <Typography variant="body2">
              <strong>Payment Method:</strong> {paymentMethod || "N/A"}
            </Typography>
            <Typography variant="body2">
              <strong>Client Name:</strong> {clientName}
            </Typography>
            <Typography variant="body2">
              <strong>Client Email:</strong> {clientEmail}
            </Typography>
            <Typography variant="body2">
              <strong>Notes:</strong> {clientNotes || "N/A"}
            </Typography>
            <Typography variant="body2">
              <strong>PO Number:</strong> {poNumber}
            </Typography>
            <Typography variant="body2">
              <strong>Item Description:</strong> {itemDescription}
            </Typography>
            <Typography variant="body2">
              <strong>Amount Due:</strong> ${amountDue}
            </Typography>
            <Typography variant="body2">
              <strong>Interest Rate:</strong> {interestRate}%
            </Typography>
            <Typography variant="body2">
              <strong>Blitz Fee:</strong> ${blitzFee.toFixed(2)}
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              Net to Receive: ${netToReceive.toFixed(2)}
            </Typography>
            <Typography variant="body2">
              <strong>Share Invoice Email:</strong> {shareEmail || "N/A"}
            </Typography>
          </Box>
        );

      default:
        return "Unknown step";
    }
  };

  // -----------------------------
  // MAIN RENDER
  // -----------------------------
  return (
    <Box sx={{ p: 3, maxWidth: 800, margin: "0 auto" }}>
      <Typography variant="h4" gutterBottom>
        Create Invoice
      </Typography>

      <DialogContentText sx={{ mb: 2 }}>
        Fill out the steps below to create and share a new invoice.
      </DialogContentText>

      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {/* Step Content */}
      <Box sx={{ mt: 3 }}>{renderStepContent(activeStep)}</Box>

      {/* Stepper Actions */}
      <Box display="flex" justifyContent="space-between" mt={4}>
        {activeStep > 0 && (
          <Button variant="outlined" onClick={handleBack}>
            Back
          </Button>
        )}

        {activeStep < steps.length - 1 ? (
          <Button variant="contained" onClick={handleNext}>
            Next
          </Button>
        ) : (
          <>
            {paymentMethod === "paypal" ? (
              <Button
                variant="contained"
                color="secondary"
                onClick={handlePayPalPayment}
              >
                Pay with PayPal
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmitInvoice}
              >
                Submit Invoice
              </Button>
            )}
          </>
        )}
      </Box>

      {/* Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ClientInvoicingPage;

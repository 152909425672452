import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Toolbar,
} from "@mui/material";
import { parseLocalFloat, parseLocalInt } from "../../../Utils/constants";
import API from "../../../API";
import BlitzHeader from "../../../Components/BlitzHeader";
import CreatorPlatforms from "../../../Components/CreatorData/creatorPlatforms";
import CreatorInfo from "../../../Components/CreatorData/creatorInfo";
import { platformSelection, validatePlatformSelection } from "../../../Components/CreatorData/creatorValidation";

const CreatorSignup = ({ onSubmit }) => {
  const { manager } = useParams();
  const navigate = useNavigate();
  const [contactEmail, setContactEmail] = useState("");
  const [paymentEmail, setPaymentEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailSame, setEmailSame] = useState(false);
  const [creatorName, setCreatorName] = useState("");
  const [creatorRegion, setCreatorRegion] = useState("");
  const [platforms, setPlatforms] = useState(platformSelection);
  const [primaryMarket, setPrimaryMarket] = useState([]);
  const [notesContentStyle, setNotesContentStyle] = useState(
    "tell us about yourself"
  );

  const [race, setRace] = useState("");
  const [gender, setGender] = useState("");
  const [location, setLocation] = useState("");
  const [pfphref, setPfphref] = useState("");

  // Instagram
  const [instagramLink, setInstagramLink] = useState("");
  const [instagramBrandRate, setInstagramBrandRate] = useState("");
  const [instagramFollowerCount, setInstagramFollowerCount] = useState("");
  const [instagramSongRate, setInstagramSongRate] = useState("");
  const [instagramStoryRate, setInstagramStoryRate] = useState("");
  const [instagramFeedRate, setInstagramFeedRate] = useState("");

  // TikTok
  const [tikTokLink, setTikTokLink] = useState("");
  const [tikTokFollowerCount, setTikTokFollowerCount] = useState("");
  const [tikTokBrandRate, setTikTokBrandRate] = useState("");
  const [tikTokSongRate, setTikTokSongRate] = useState("");

  // YouTube
  const [youtubeLink, setYoutubeLink] = useState("");
  const [youtubeFollowerCount, setYoutubeFollowerCount] = useState("");
  const [youtubeIntegrationRate, setYoutubeIntegrationRate] = useState("");
  const [youtubeShortsRate, setYoutubeShortsRate] = useState("");
  const [youtubeDedicatedRate, setYoutubeDedicatedRate] = useState("");

  const [xLink, setXLink] = useState('');
  const [xFollowerCount, setXFollowerCount] = useState('');
  const [xFeedPost, setXFeedPost] = useState('');
  const [xRepost, setXRepost] = useState('');

  const [podcastLink, setPodcastLink] = useState('');
  const [podcastFollowerCount, setPodcastFollowerCount] = useState('');
  const [podcastPreroll, setPodcastPreroll] = useState('');
  const [podcastMidroll, setPodcastMidroll] = useState('');
  const [podcastPostroll, setPodcastPostroll] = useState('');
  const [podcastFull, setPodcastFull] = useState('');

  const [kickLink, setKickLink] = useState('');
  const [kickFollowerCount, setKickFollowerCount] = useState('');
  const [kickPlacement, setKickPlacement] = useState('');

  const [twitchLink, setTwitchLink] = useState('');
  const [twitchFollowerCount, setTwitchFollowerCount] = useState('');
  const [twitchPlacement, setTwitchPlacement] = useState('');


  // Dialog state
  const [openDialog, setOpenDialog] = useState(false);
  const [openRedirectDialog, setOpenRedirectDialog] = useState(false);
  const [validationError, setValidationError] = useState("");

  useEffect(() => {
    if (!manager) {
      setOpenDialog(true);
    }
  }, [manager]);

  const handleSaveCreator = async () => {
    if (!validatePlatformSelection(
      platforms,
      (message) => alert(message),
      tikTokBrandRate, tikTokSongRate,
      instagramBrandRate, instagramSongRate, instagramFeedRate, instagramStoryRate,
      youtubeIntegrationRate, youtubeShortsRate, youtubeDedicatedRate,
      xFeedPost, xRepost,
      kickPlacement,
      twitchPlacement,
      podcastPreroll,
      podcastMidroll,
      podcastPostroll,
      podcastFull
    )) {
      return;
    }



    if (phoneNumber.length < 11) {
      alert(`This Phone number is not valid`);
      return;
    }
    if (creatorName.includes(" ")) {
      setValidationError("Creator name cannot contain spaces.");
      return;
    }

    const formattedTikTokLink = tikTokLink ? `https://www.tiktok.com/@${tikTokLink}` : null;
    const formattedInstagramLink = instagramLink ? `https://www.instagram.com/${instagramLink}` : null;
    const formattedYouTubeLink = youtubeLink ? `https://www.youtube.com/${youtubeLink}` : null;
    const formattedXLink = xLink ? `https://www.x.com/${xLink}` : null;
    const formattedKickLink = kickLink ? `https://www.kick.com/${kickLink}` : null;
    const formattedTwitchLink = twitchLink ? `https://www.twitch.tv/${twitchLink}` : null;

    let payload = null;
    try {
      payload = {
        creator: creatorName,
        geolocationgenderethnicity: `${location} / ${gender} / ${race}`,
        geolocation: location,
        gender: gender,
        ethnicity: race,
        primary_markets: primaryMarket,
        region: creatorRegion,
        notescontent_style: notesContentStyle || null,

        tiktok: parseLocalInt(tikTokFollowerCount) || null,
        tiktok_link: formattedTikTokLink || null,
        instagram: parseLocalInt(instagramFollowerCount) || null,
        instagram_link: formattedInstagramLink || null,
        youtube: parseLocalInt(youtubeFollowerCount) || null,
        youtube_link: formattedYouTubeLink || null,

        twitch: parseLocalInt(twitchFollowerCount) || null,
        twitch_link: formattedTwitchLink || null,

        follow_x: parseLocalInt(xFollowerCount) || null,
        x_link: formattedXLink || null,

        follow_podcast: parseLocalInt(podcastFollowerCount) || null,
        podcast_link: podcastLink || null,

        follow_kick: parseLocalInt(kickFollowerCount) || null,
        kick_link: formattedKickLink || null,

        rate_tt_sound: parseLocalFloat(tikTokSongRate),
        rate_tt_brand: parseLocalFloat(tikTokBrandRate),

        rate_ig_feedpost: parseLocalFloat(instagramStoryRate),
        rate_ig_reelsound: parseLocalFloat(instagramSongRate),
        rate_ig_reelbrand: parseLocalFloat(instagramBrandRate),
        rate_ig_story: parseLocalFloat(instagramStoryRate),

        rate_yt_integ: parseLocalFloat(youtubeIntegrationRate),
        rate_yt_short: parseLocalFloat(youtubeShortsRate),
        rate_yt_dedicated: parseLocalFloat(youtubeDedicatedRate),

        rate_podcast_preroll: parseLocalFloat(podcastPreroll),
        rate_podcast_midroll: parseLocalFloat(podcastMidroll),
        rate_podcast_postroll: parseLocalFloat(podcastPostroll),
        rate_podcast_full: parseLocalFloat(podcastFull),

        rate_twitch_product: parseLocalFloat(twitchPlacement),
        rate_kick_product: parseLocalFloat(kickPlacement),
        rate_x_feedpost: parseLocalFloat(xFeedPost),
        rate_x_repost: parseLocalFloat(xRepost),

        contactEmail: contactEmail,
        paymentEmail: paymentEmail,
        phone_number: phoneNumber,
        pfphref: pfphref || null,
        manager: manager || null,
      };
    } catch (error) {
      alert(`Error parsing this form: ${error}`);
      return;
    }
    console.log(payload)

    try {
      const data = await API.creators.signup(payload);

      setOpenRedirectDialog(true);
      setTimeout(() => {
        navigate(`/creators/${creatorName}`);
      }, 3000);

    } catch (error) {
      alert(`Network error: ${(error?.response?.data?.error || error.message)}\nPlease contact us.`);
      console.error('Network error:', (error?.response?.data?.error || error.message));
    }

  };

  return (
    <>
      <BlitzHeader></BlitzHeader>
      <Toolbar></Toolbar>
      <Container maxWidth="md" sx={{ marginBlockStart: 2 }}>
        <Typography variant="h4"> {manager} Creator Intake Form</Typography>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSaveCreator();
          }}
        >
          <CreatorInfo
            creatorName={creatorName}
            setCreatorName={setCreatorName}
            creatorRegion={creatorRegion}
            setCreatorRegion={setCreatorRegion}
            race={race}
            setRace={setRace}
            gender={gender}
            setGender={setGender}
            location={location}
            setLocation={setLocation}
            primaryMarket={primaryMarket}
            setPrimaryMarket={setPrimaryMarket}
            notesContentStyle={notesContentStyle}
            setNotesContentStyle={setNotesContentStyle}
            contactEmail={contactEmail}
            setContactEmail={setContactEmail}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            validationError={validationError}
            paymentEmail={paymentEmail}
            setPaymentEmail={setPaymentEmail}
            emailSame={emailSame}
            setEmailSame={setEmailSame}
            pfphref={pfphref}
            setPfphref={setPfphref}
          ></CreatorInfo>
          <Typography variant="h5" sx={{ marginBlockEnd: 1, marginBlockStart: 1 }}>Platform and Rates</Typography>
          <CreatorPlatforms
            platforms={platforms} setPlatforms={setPlatforms}
            instagramLink={instagramLink} setInstagramLink={setInstagramLink}
            instagramFollowerCount={instagramFollowerCount} setInstagramFollowerCount={setInstagramFollowerCount}
            instagramBrandRate={instagramBrandRate} setInstagramBrandRate={setInstagramBrandRate}
            instagramSongRate={instagramSongRate} setInstagramSongRate={setInstagramSongRate}
            instagramFeedRate={instagramFeedRate} setInstagramFeedRate={setInstagramFeedRate}
            instagramStoryRate={instagramStoryRate} setInstagramStoryRate={setInstagramStoryRate}
            tikTokLink={tikTokLink} setTikTokLink={setTikTokLink}
            tikTokFollowerCount={tikTokFollowerCount} setTikTokFollowerCount={setTikTokFollowerCount}
            tikTokBrandRate={tikTokBrandRate} setTikTokBrandRate={setTikTokBrandRate}
            tikTokSongRate={tikTokSongRate} setTikTokSongRate={setTikTokSongRate}
            youtubeLink={youtubeLink} setYoutubeLink={setYoutubeLink}
            youtubeFollowerCount={youtubeFollowerCount} setYoutubeFollowerCount={setYoutubeFollowerCount}
            youtubeIntegrationRate={youtubeIntegrationRate} setYoutubeIntegrationRate={setYoutubeIntegrationRate}
            youtubeShortsRate={youtubeShortsRate} setYoutubeShortsRate={setYoutubeShortsRate}
            youtubeDedicatedRate={youtubeDedicatedRate} setYoutubeDedicatedRate={setYoutubeDedicatedRate}
            xLink={xLink} setXLink={setXLink}
            xFollowerCount={xFollowerCount} setXFollowerCount={setXFollowerCount}
            xFeedPost={xFeedPost} setXFeedPost={setXFeedPost}
            xRepost={xRepost} setXRepost={setXRepost}
            podcastLink={podcastLink} setPodcastLink={setPodcastLink}
            podcastFollowerCount={podcastFollowerCount} setPodcastFollowerCount={setPodcastFollowerCount}
            podcastPreroll={podcastPreroll} setPodcastPreroll={setPodcastPreroll}
            podcastMidroll={podcastMidroll} setPodcastMidroll={setPodcastMidroll}
            podcastPostroll={podcastPostroll} setPodcastPostroll={setPodcastPostroll}
            podcastFull={podcastFull} setPodcastFull={setPodcastFull}
            kickLink={kickLink} setKickLink={setKickLink}
            kickFollowerCount={kickFollowerCount} setKickFollowerCount={setKickFollowerCount}
            kickPlacement={kickPlacement} setKickPlacement={setKickPlacement}
            twitchLink={twitchLink} setTwitchLink={setTwitchLink}
            twitchFollowerCount={twitchFollowerCount} setTwitchFollowerCount={setTwitchFollowerCount}
            twitchPlacement={twitchPlacement} setTwitchPlacement={setTwitchPlacement}
          ></CreatorPlatforms>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginTop: "5px", marginBottom: "10px" }}
          >
            Submit Information
          </Button>
        </form>
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle>Signup Form Information</DialogTitle>
          <DialogContent>
            <p>
              This signup form is for creators to be seen in the Blitz
              marketplace and to send campaigns directly to their email and
              phone number. By opting in you are joining a community of thousands of creators world wide!
            </p>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openRedirectDialog}>
          <DialogTitle>Success</DialogTitle>
          <DialogContent>
            <p>Redirecting you to your mediakit...</p>
          </DialogContent>
        </Dialog>
      </Container>
    </>
  );
};

export default CreatorSignup;

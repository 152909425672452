import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  IconButton,
  Button,
  Tabs,
  Tab,
  Alert,
  Paper,
  Backdrop,
  CircularProgress,
  Pagination,
  Checkbox,
  TextField,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Divider,
  useTheme,
} from "@mui/material";
import { useMutation, useQuery } from "react-query";
import { Delete, FileCopy, Share, Add } from "@mui/icons-material";
import client from "../../../../API";
import CastingCallDialog from "./castingcallsdialog";
import CreateCallDialog from "./CreateCallDialog";
import DealIOCreateForm from "../../../DealsIO/dealscreate";
import { globalStyles } from "../../../../Utils/Styles";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useAuth from "../../../../Hooks/use-auth";
import { useNavigate } from "react-router-dom";
import { 
  CardMedia, 
  Chip, 
  CardActions 
} from "@mui/material";

import { Add as AddIcon } from "@mui/icons-material";

const dummyPhotoLinks = [
  "https://storage.googleapis.com/blitzccmc.appspot.com/1/287262.jpg",
  "https://storage.googleapis.com/blitzccmc.appspot.com/1/wp2468668.jpg",
  "https://storage.googleapis.com/blitzccmc.appspot.com/1/eev9l01j44ab1.jpg",
];

const castingRowsPerPage = 12;
const statusColorMap = {
  ACTIVE: 'success',
  COMPLETED: 'info',
  DELETED: 'error'
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return isNaN(date.getTime()) ? "Invalid Date" : 
    `${(date.getMonth()+1).toString().padStart(2,'0')}/${date.getDate().toString().padStart(2,'0')}/${date.getFullYear().toString().substr(-2)}`;
};

const summarizeText = (text, maxLength = 50) => {
  return text.length <= maxLength ? text : text.substring(0, maxLength) + "...";
};

const CastingCalls = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [currentView, setCurrentView] = useState('casting');
  const [bannerVisible, setBannerVisible] = useState(false);

  // Casting Calls State
  const [castingTabValue, setCastingTabValue] = useState("all");
  const [selectedCastingCallId, setSelectedCastingCallId] = useState(null);
  const [openCastingCreate, setOpenCastingCreate] = useState(false);
  const [castingPage, setCastingPage] = useState(0);
  const [castingRowCount, setCastingRowCount] = useState(0);

  // DealIOs State
  const [dealIOs, setDealIOs] = useState([]);
  const [filteredDealIOs, setFilteredDealIOs] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDealIOs, setSelectedDealIOs] = useState([]);
  const [selectedAction, setSelectedAction] = useState("");
  const [openDealCreate, setOpenDealCreate] = useState(false);

  const { getCurrrentUser } = useAuth();
  const userInfo = getCurrrentUser();

  // Casting Calls Data
  const { 
    data: castingCalls, 
    isFetching: loadingCasting, 
    refetch: fetchCastingCalls, 
    error: castingError 
  } = useQuery({
    queryKey: ['castingCalls', 'list', { castingPage, castingTabValue }],
    queryFn: () => client.castingCalls.list({ page: castingPage, rows: castingRowsPerPage, status: castingTabValue }),
    enabled: currentView === 'casting',
    refetchOnWindowFocus: false
  });

  // DealIOs Data
  const { 
    data: dealIOsData, 
    isFetching: loadingDeals, 
    refetch: fetchDealIOs 
  } = useQuery({
    queryKey: ['dealIOs', 'list'],
    queryFn: () => client.dealsIO.list(),
    enabled: currentView === 'deals',
    onSuccess: (data) => {
      setDealIOs(data);
      setFilteredDealIOs(data);
    }
  });

  useEffect(() => {
    if (castingCalls) {
      setCastingRowCount(castingCalls.total);
    }
  }, [castingCalls]);

  // Casting Calls Mutations
  const { mutate: updateCastingStatus } = useMutation(
    ({ id, status }) => client.castingCalls.updateStatus(id, status),
    { onSuccess: fetchCastingCalls }
  );

  // DealIOs Mutations
  const { mutateAsync: deleteDealIO } = useMutation(client.dealsIO.delete, {
    onSuccess: () => fetchDealIOs()
  });

  const { mutateAsync: copyDealIO } = useMutation(
    ({ dealIOId }) => client.dealsIO.copy({ dealIOId }),
    { onSuccess: () => fetchDealIOs() }
  );

  const handleViewChange = (event, newValue) => setCurrentView(newValue);

  // Casting Call Handlers
  const handleCreateCastingOpen = () => {
    const hasOpenCall = castingCalls?.casting_calls.some(
      call => call.status === "ACTIVE" && call.user_id === userInfo.id
    );
    if (hasOpenCall) {
      setBannerVisible(true);
      return;
    }
    setOpenCastingCreate(true);
  };

  const handleCastingDelete = (id) => updateCastingStatus({ id, status: "DELETED" });
  const handleCastingComplete = (id) => updateCastingStatus({ id, status: "COMPLETED" });

  // DealIO Handlers
  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    setFilteredDealIOs(dealIOs.filter(deal => 
      deal.user_data.some(user => 
        user.role === "Owner" && user.name.toLowerCase().includes(query)
      )
    ));
  };

  const handleCheckboxChange = (dealIOId) => {
    const selectedIndex = selectedDealIOs.indexOf(dealIOId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedDealIOs, dealIOId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedDealIOs.slice(1));
    } else if (selectedIndex === selectedDealIOs.length - 1) {
      newSelected = newSelected.concat(selectedDealIOs.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedDealIOs.slice(0, selectedIndex),
        selectedDealIOs.slice(selectedIndex + 1)
      );
    }
    setSelectedDealIOs(newSelected);
  };

  const handleDealAction = async () => {
    if (selectedAction === "delete" && selectedDealIOs.length > 0) {
      await Promise.all(selectedDealIOs.map(id => deleteDealIO(id)));
    }
    setSelectedDealIOs([]);
  };

  const handleCopyDealIO = async (dealId) => {
    try {
      await copyDealIO({ dealIOId: dealId });
    } catch (error) {
      console.error("Failed to copy Deal IO:", error);
    }
  };

  const handleShareDealIO = (dealId) => {
    navigator.clipboard.writeText(`${window.location.origin}/deals/public/${dealId}`);
  };

  return (
    <Box sx={{ 
      ...globalStyles.wholeHeightMinusToolbar, 
      width: '100%', 
      display: 'flex', 
      flexDirection: 'column',
      bgcolor: 'background.default'
    }}>
      <Backdrop open={loadingCasting || loadingDeals} sx={{ zIndex: theme.zIndex.drawer - 1 }}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Paper elevation={4} square sx={{ pt: 2, mb: 2, borderRadius: '0 0 8px 8px' }}>
        <Typography variant="h3" sx={{ textAlign: "center", mb: 2, fontWeight: 'bold', color: 'primary.main' }}>
          {currentView === 'casting' ? 'Casting Calls' : 'Deal Factoring'}
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: 4, pb: 2 }}>
          <Button
            variant="contained"
            onClick={currentView === 'casting' ? handleCreateCastingOpen : () => setOpenDealCreate(true)}
            sx={{ borderRadius: 2, px: 3, py: 1, fontWeight: 'bold', boxShadow: 3 }}
          >
            + New {currentView === 'casting' ? 'Casting Call' : 'Deal IO'}
          </Button>

          <Tabs value={currentView} onChange={handleViewChange}>
            <Tab label="Casting Calls" value="casting" />
            <Tab label="Deal Factoring" value="deals" />
          </Tabs>
        </Box>

        {currentView === 'casting' && (
          <Tabs value={castingTabValue} onChange={(e, v) => setCastingTabValue(v)} sx={{ px: 4 }}>
            <Tab label="All" value="all" />
            <Tab label="Active" value="active" />
            <Tab label="Completed" value="completed" />
          </Tabs>
        )}
      </Paper>

      {currentView === 'casting' ? (
        <>
          {bannerVisible && (
            <Alert severity="warning" sx={{ mx: 4, mb: 2, borderRadius: 2 }}>
              You may only have one active casting call at a time.
            </Alert>
          )}

          <Box sx={{ px: 4, flex: 1, overflow: 'auto' }}>
            <Grid container spacing={3}>
              {castingCalls?.casting_calls.map((call) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={call.id}>
                  <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', transition: 'transform 0.2s' }}>
                    <CardMedia
                      component="img"
                      height="200"
                      image={dummyPhotoLinks[Math.round((call.date_created.getTime() / 1000) % dummyPhotoLinks.length)]}
                      alt="Casting Call"
                      sx={{ objectFit: 'cover' }}
                    />

                    <CardContent sx={{ flexGrow: 1 }}>
                      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
                        <Chip label={call.status} color={statusColorMap[call.status]} size="small" />
                        <Typography variant="body2">#{call.id}</Typography>
                      </Stack>
                      <Typography variant="h6" gutterBottom>{call.platforms.join(", ")}</Typography>
                      <Typography variant="body2" paragraph sx={{ WebkitLineClamp: 3 }}>
                        {call.brief}
                      </Typography>
                      <Divider sx={{ my: 1 }} />
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2" fontWeight="bold">Budget:</Typography>
                        <Typography variant="body2">${call.budget_min || 0} - ${call.budget_max || 0}</Typography>
                      </Stack>
                    </CardContent>

                    <CardActions sx={{ justifyContent: 'space-between', p: 2 }}>
                      <IconButton onClick={() => handleCastingDelete(call.id)} color="error" size="small">
                        <Delete />
                      </IconButton>
                      {castingTabValue !== 'completed' && (
                        <IconButton
                          onClick={() => handleCastingComplete(call.id)}
                          color={call.status === 'COMPLETED' ? "success" : 'default'}
                          size="small"
                        >
                          {call.status === 'COMPLETED' ? <CheckCircleIcon /> : <CheckCircleOutlineIcon />}
                        </IconButton>
                      )}
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Pagination
            count={Math.ceil(castingRowCount / castingRowsPerPage)}
            page={castingPage + 1}
            onChange={(e, v) => setCastingPage(v - 1)}
            sx={{ p: 2, mt: 'auto', borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
          />
        </>
      ) : (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={() => setOpenDealCreate(true)}
                sx={{ mb: 2 }}
              >
                Create Deal IO
              </Button>
            </Box>

            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <TextField
                variant="outlined"
                size="small"
                placeholder="Search deals..."
                value={searchQuery}
                onChange={handleSearchChange}
                sx={{ flexGrow: 1 }}
              />
              <FormControl variant="outlined" size="small" sx={{ minWidth: 180 }}>
                <InputLabel>Action</InputLabel>
                <Select 
                  value={selectedAction} 
                  onChange={(e) => setSelectedAction(e.target.value)} 
                  label="Action"
                >
                  <MenuItem value="edit">Edit</MenuItem>
                  <MenuItem value="delete">Delete</MenuItem>
                </Select>
              </FormControl>
              <Button 
                variant="contained" 
                onClick={handleDealAction}
                disabled={!selectedAction || selectedDealIOs.length === 0}
              >
                Apply Action
              </Button>
            </Box>
          </Box>

          <Box sx={{ px: 4, flex: 1, overflow: 'auto' }}>
            <Grid container spacing={3}>
              {filteredDealIOs.map((deal) => (
                <Grid item xs={12} sm={6} md={4} key={deal.id}>
                  <Card sx={{ p: 2 }}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Checkbox
                        checked={selectedDealIOs.includes(deal.id)}
                        onChange={() => handleCheckboxChange(deal.id)}
                      />
                      <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="h6">{summarizeText(deal.name)}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          Created: {formatDate(deal.created_at)}
                        </Typography>
                      </Box>
                      <IconButton onClick={() => handleCopyDealIO(deal.id)}>
                        <FileCopy />
                      </IconButton>
                      <IconButton onClick={() => handleShareDealIO(deal.id)}>
                        <Share />
                      </IconButton>
                    </Stack>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </>
      )}

      {/* Dialogs */}
      <CreateCallDialog open={openCastingCreate} onClose={() => setOpenCastingCreate(false)} />
      <CastingCallDialog open={!!selectedCastingCallId} onClose={() => setSelectedCastingCallId(null)} />
      
      <Dialog open={openDealCreate} onClose={() => setOpenDealCreate(false)} maxWidth="md" fullWidth>
        <DialogContent>
          <DealIOCreateForm 
            onClose={() => setOpenDealCreate(false)} 
            onCreateSuccess={fetchDealIOs} 
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default CastingCalls;
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Toolbar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Snackbar,
  Alert,
  CssBaseline,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Divider,
  MenuItem,
  FormControl,
  Select,
  ThemeProvider,
  Tabs,
  Tab
} from '@mui/material';

import { Autocomplete } from '@mui/material'; // <-- IMPORTANT for the company search
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';

import HomeHeader from '../../Components/HomeHeader';
import HomeFooter from '../../Components/HomeFooter';
import CreatorOnboard from '../../CreatorUser/creatorOnboard'; // Existing creator form
import { validateEmail, validatePassword, capitalizeFirstLetter } from '../../Utils';
import { lightTheme } from '../../Utils/baseTheme';

// Your routes file
import routes from '../../Config/routes';

// API Client
import client from '../../API';

// Example: client.companies.list => GET to fetch array of companies
// ...
// companies = {
//   list: () => HttpClient.get(API_ENDPOINTS.COMPANY),
//   demoRegister: (input) => HttpClient.post(API_ENDPOINTS.DEMO_REGISTER, input),
//   // ...
// };

const AllOnboard = () => {
  // Grab query parameters from the URL
  const [searchParams] = useSearchParams();
  const convParam = searchParams.get('conv');   // conversation ID
  const emailParam = searchParams.get('email');   // invitation email
  const tabParam = searchParams.get('tab');       // onboarding tab: "creator" or "brand"

  // Set default tab based on URL query param. Default to "brand" if param not "creator"
  const [tabValue, setTabValue] = useState(tabParam === 'creator' ? 'creator' : 'brand');

  // In case the URL changes after the component mounts, update the tab accordingly
  useEffect(() => {
    if (tabParam === 'creator' || tabParam === 'brand') {
      setTabValue(tabParam);
    }
  }, [tabParam]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <HomeHeader />
      <Toolbar />
      <Box maxWidth="md" sx={{ mx: 'auto', py: 2 }}>
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
            Welcome to Blitz!
          </Typography>
          <Typography variant="body1" paragraph>
            Select your onboarding type and enter your information to get started.
          </Typography>
        </Box>

        <Tabs value={tabValue} onChange={handleTabChange} centered>
          <Tab label="Sign up as a Brand User" value="brand" />
          <Tab label="Sign up as a Creator" value="creator" />
        </Tabs>

        <Box sx={{ mt: 4 }}>
          {tabValue === 'brand' && (
            <BrandRegisterForm
              conversationIdParam={convParam}
              inviteEmailParam={emailParam}
            />
          )}
          {tabValue === 'creator' && (
            <CreatorOnboard
              conversationIdParam={convParam}
              inviteEmailParam={emailParam}
            />
          )}
        </Box>
      </Box>
      <HomeFooter />
    </ThemeProvider>
  );
};

// --------------------------------------------------------------------------
// BRAND REGISTER FORM
// --------------------------------------------------------------------------
const BrandRegisterForm = ({ conversationIdParam, inviteEmailParam }) => {
  const navigate = useNavigate();
  const { ref } = useParams();

  // If there's a numeric ref in the URL (like /onboard/123), treat that as referringUserId
  const referringUserId = ref && !isNaN(Number(ref)) ? Number(ref) : null;
  const referralSource = referringUserId === null ? ref : '';

  const [conversationId] = useState(conversationIdParam || null);

  // Collect brand user form data
  const [userInfo, setUserInfo] = useState({
    firstName: '',
    lastName: '',
    email: inviteEmailParam || '',
    password: '',
    confirmPassword: '',
    // We'll keep 'companyName' but let an Autocomplete fill it
    companyName: '',
    streetAddress: '',
    city: '',
    state: '',
    country: '',
    phoneNumber: '',
    industry: '',
    bio: '',
    businessType: '',
    isAgency: '',
    monthlySpend: '',
    teamAccounts: '',
    paymentMethod: '',
    referralSource: referralSource,
    referringUserId: referringUserId
  });

  // UI states
  const [openDialog, setOpenDialog] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const steps = ['Personal Information', 'Company Information', 'Business Details'];

  // Snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpenSnackbar(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // --------------------------------------------------------------------------
  // FETCH COMPANIES LIST (for Autocomplete)
  // --------------------------------------------------------------------------
  // Using React Query to fetch existing companies from DB
  const {
    data: companiesData = [],
    isLoading: isCompaniesLoading,
    isError: isCompaniesError
  } = useQuery(['companiesList'], client.companies.list);

  // The actual brand user registration mutation
  const { mutate: register, isLoading } = useMutation(client.companies.demoRegister, {
    onSuccess: () => {
      // Example: if you want to handle something with conversationId:
      // if (conversationId) {
      //   // e.g. update that conversation with new user ID
      // }
      navigate(routes.login);
    },
    onError: (error) => {
      console.error('Registration error:', error);
      let errorMessage = 'An error occurred, please try again.';
      if (error.code === 'ERR_NETWORK') {
        errorMessage = 'Network is disconnected!';
      } else if (error.response && error.response.data) {
        errorMessage = error.response.data.error || errorMessage;
      }
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  });

  // Stepper: next, back
  const handleNext = () => {
    // Basic validation for required fields in the current step
    const requiredFields = getRequiredFieldsForStep(activeStep);
    let valid = true;

    requiredFields.forEach((field) => {
      if (valid && (!userInfo[field] || userInfo[field].trim() === '')) {
        setSnackbarMessage(`${capitalizeFirstLetter(field)} field is required!`);
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        valid = false;
      }
    });

    if (!valid) return;

    // Additional validations in step 0
    if (activeStep === 0) {
      if (!validateEmail(userInfo.email)) {
        setSnackbarMessage('Invalid email address!');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        return;
      }
      if (!validatePassword(userInfo.password)) {
        setSnackbarMessage('Password should have 6 or more characters!');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        return;
      }
      if (userInfo.password !== userInfo.confirmPassword) {
        setSnackbarMessage('Passwords should match!');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        return;
      }
    }

    // If last step, register user
    if (activeStep === steps.length - 1) {
      handleRegister();
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleRegister = () => {
    // Final check
    const requiredFields = Object.keys(userInfo).filter(
      (f) => f !== 'referralSource' && f !== 'referringUserId'
    );
    let valid = true;
    requiredFields.forEach((field) => {
      if (valid && (!userInfo[field] || userInfo[field].trim() === '')) {
        setSnackbarMessage(`${capitalizeFirstLetter(field)} field is required!`);
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        valid = false;
      }
    });

    if (!valid) return;

    const params = { ...userInfo };
    register(params);
  };

  // Update userInfo state
  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserInfo((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const getRequiredFieldsForStep = (step) => {
    switch (step) {
      case 0:
        return ['firstName', 'lastName', 'email', 'password', 'confirmPassword', 'phoneNumber'];
      case 1:
        return ['companyName', 'streetAddress', 'city', 'state', 'country', 'industry'];
      case 2:
        return ['businessType', 'isAgency', 'monthlySpend', 'teamAccounts', 'paymentMethod'];
      default:
        return [];
    }
  };

  // Step forms:
  const stepsLabels = ['Personal Info', 'Company Info', 'Business Details'];
  const industryOptions = [
    'Fashion',
    'Tech',
    'Food',
    'Beauty',
    'Sports',
    'Travel',
    'Finance',
    'Health',
    'Education',
    'Entertainment',
    'Automotive',
    'Real Estate',
    'Hospitality',
    'Retail',
    'Manufacturing',
    'Telecommunications',
    'Marketing Agency'
  ];
  const countryOptions = [
    'United States',
    'China',
    'Canada',
    'United Kingdom',
    'Australia',
    'Germany',
    'France',
    'Italy',
    'Spain',
    'Japan',
    'India',
    'Brazil',
    'Mexico',
    'Russia',
    'South Korea',
    'South Africa'
  ];

  // Renders the content for each step
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box>
            <TextField
              disabled={isLoading}
              label="First Name"
              name="firstName"
              variant="outlined"
              value={userInfo.firstName}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <TextField
              disabled={isLoading}
              label="Last Name"
              name="lastName"
              variant="outlined"
              value={userInfo.lastName}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <TextField
              disabled={isLoading}
              label="Email"
              name="email"
              variant="outlined"
              value={userInfo.email}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <TextField
              disabled={isLoading}
              label="Password"
              name="password"
              variant="outlined"
              type="password"
              value={userInfo.password}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <TextField
              disabled={isLoading}
              label="Re-enter Password"
              name="confirmPassword"
              variant="outlined"
              type="password"
              value={userInfo.confirmPassword}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <TextField
              disabled={isLoading}
              label="Phone Number"
              name="phoneNumber"
              value={userInfo.phoneNumber}
              onChange={handleChange}
              margin="dense"
              variant="outlined"
              required
              fullWidth
            />
          </Box>
        );
      case 1:
        return (
          <Box>
            {/* 
               ----------------------------
               Autocomplete for companies
               ----------------------------
            */}
            <Autocomplete
              freeSolo
              loading={isCompaniesLoading}
              options={companiesData.map((company) => company.name)}
              value={userInfo.companyName}
              onChange={(event, newValue) => {
                // The user selected an existing company from the dropdown
                setUserInfo((prev) => ({ ...prev, companyName: newValue || '' }));
              }}
              onInputChange={(event, newInputValue) => {
                // The user typed something (possibly a brand new company)
                setUserInfo((prev) => ({ ...prev, companyName: newInputValue || '' }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Company Name"
                  variant="outlined"
                  margin="dense"
                  required
                  disabled={isLoading}
                  fullWidth
                />
              )}
            />

            <TextField
              disabled={isLoading}
              label="Street Address"
              name="streetAddress"
              variant="outlined"
              value={userInfo.streetAddress}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <TextField
              disabled={isLoading}
              label="City"
              name="city"
              variant="outlined"
              value={userInfo.city}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <TextField
              disabled={isLoading}
              label="State"
              name="state"
              variant="outlined"
              value={userInfo.state}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />

            <FormControl variant="outlined" fullWidth margin="dense" required>
              <Select
                name="country"
                value={userInfo.country}
                onChange={handleChange}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select your country
                </MenuItem>
                {countryOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="outlined" fullWidth margin="dense" required>
              <Select
                name="industry"
                value={userInfo.industry}
                onChange={handleChange}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select your industry
                </MenuItem>
                {industryOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              disabled={isLoading}
              label="Tell us about your company"
              name="bio"
              variant="outlined"
              multiline
              rows={4}
              value={userInfo.bio}
              onChange={handleChange}
              margin="dense"
              fullWidth
            />
          </Box>
        );
      case 2:
        return (
          <Box>
            <FormControl variant="outlined" fullWidth margin="dense" required>
              <Select
                name="businessType"
                value={userInfo.businessType}
                onChange={handleChange}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Is your business all under one company, or is it separate?
                </MenuItem>
                <MenuItem value="One Company">One Company</MenuItem>
                <MenuItem value="Separate">Separate</MenuItem>
              </Select>
            </FormControl>

            <FormControl variant="outlined" fullWidth margin="dense" required>
              <Select
                name="isAgency"
                value={userInfo.isAgency}
                onChange={handleChange}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Are you an agency?
                </MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>

            <TextField
              disabled={isLoading}
              label="How much do you expect to spend monthly?"
              name="monthlySpend"
              variant="outlined"
              value={userInfo.monthlySpend}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <TextField
              disabled={isLoading}
              label="How many accounts do you need (for your team)?"
              name="teamAccounts"
              variant="outlined"
              value={userInfo.teamAccounts}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />

            <FormControl variant="outlined" fullWidth margin="dense" required>
              <Select
                name="paymentMethod"
                value={userInfo.paymentMethod}
                onChange={handleChange}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  What is your main method of payment?
                </MenuItem>
                <MenuItem value="Credit Card">Credit Card</MenuItem>
                <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
                <MenuItem value="PayPal">PayPal</MenuItem>
              </Select>
            </FormControl>

            <TextField
              disabled={isLoading}
              label="How did you hear about us?"
              name="referralSource"
              variant="outlined"
              value={userInfo.referralSource}
              onChange={handleChange}
              margin="dense"
              fullWidth
            />
          </Box>
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <>
      <Box sx={{ width: '100%', maxWidth: 600, mx: 'auto' }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Box sx={{ mt: 2 }}>
          {getStepContent(activeStep)}

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button disabled={activeStep === 0} onClick={handleBack}>
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              endIcon={
                isLoading && activeStep === steps.length - 1 && (
                  <CircularProgress size={20} color="inherit" />
                )
              }
              disabled={isLoading}
            >
              {activeStep === steps.length - 1 ? 'Create Account' : 'Next'}
            </Button>
          </Box>

          {/* If we know we were referred by a creator (userInfo.referringUserId) */}
          {userInfo.referringUserId && (
            <Box mt={2} textAlign="center">
              <Typography variant="body2" sx={{ color: '#000' }}>
                Referred by Creator ID: {userInfo.referringUserId}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      {/* Informational dialog at the beginning */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Thanks for Booking a Creator!</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Thanks for booking a creator through their media kit. To access your collaboration,
            make an account here.
          </Typography>
          <Typography variant="body2" mt={2}>
            For any questions or concerns, please contact{' '}
            <a href="mailto:jc@thecultureclub.us">jc@thecultureclub.us</a>.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for alerts */}
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AllOnboard;

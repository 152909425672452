import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Add this import
import client from '../../API';
const CreateContractDialog = ({ open, onClose, onSuccess }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [content, setContent] = useState('');
  const navigate = useNavigate(); // Add this hook

  const handleSubmit = async () => {
    if (!name.trim() || !content.trim()) {
        alert('Please fill in all required fields (Name and Content)');
        return;
    }

    try {
        const response = await client.files.createContract({
            name: name.trim(),
            description: description.trim(),
            contract_body: content.trim()
        });
        
        navigate(`/contract/${response.contract.id}`);
        handleClose();
        onSuccess();
    } catch (error) {
        console.error('Contract creation failed:', error.response?.data);
        alert(error.response?.data?.error || 'Failed to create contract. Please try again.');
    }
};

  const handleClose = () => {
    setName('');
    setDescription('');
    setContent('');
    onClose();
  };

  return (
    <Dialog 
      open={open} 
      onClose={handleClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle sx={{ 
        backgroundColor: 'primary.main',
        color: 'white',
        padding: '16px 24px'
      }}>
        Create New Contract
      </DialogTitle>
      
      <DialogContent sx={{ pt: 3, pb: 2 }}>
        <TextField
          autoFocus
          margin="dense"
          label="Contract Name"
          fullWidth
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
          sx={{ mb: 2 }}
        />
        
        <TextField
          margin="dense"
          label="Description (Optional)"
          fullWidth
          multiline
          rows={2}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          sx={{ mb: 3 }}
        />
        
        <TextField
          margin="dense"
          label="Contract Content"
          fullWidth
          multiline
          required
          rows={14}
          value={content}
          onChange={(e) => setContent(e.target.value)}
          InputProps={{
            style: {
              fontFamily: '"Courier New", Courier, monospace',
              fontSize: '0.875rem'
            }
          }}
        />
      </DialogContent>

      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button 
          onClick={handleClose}
          variant="outlined"
          sx={{ mr: 2 }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          size="large"
        >
          Create Contract
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateContractDialog;
import React, { useState } from 'react';
import Navbar from './Navbar/NavBar';
import { Box, Toolbar, IconButton, ThemeProvider } from '@mui/material';
import { drawerWidth } from '../Utils/constants';
import useIsDesktop from '../Hooks/useIsDesktop';
import BlitzHeader from './BlitzHeader';
import MenuIcon from '@mui/icons-material/Menu';
import { lightTheme } from '../Utils/baseTheme';

const openedMixin = (theme) => ({
    minWidth: {
        xs: '100%',
        md: `calc(100% - ${drawerWidth})`,
    },
    marginInlineStart: {
        xs: 0,
        md: drawerWidth,
    },
    transition: theme.transitions.create(['min-width', 'margin-inline-start'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create(['min-width', 'margin-inline-start'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    minWidth: {
        xs: '100%',
        md: `calc(100% - ${lightTheme.spacing(8)} + 1px)`,
    },
    marginInlineStart: {
        xs: 0,
        md: `calc(${lightTheme.spacing(8)} - 1px)`,
    },
});

const BlitzLayout = ({ children, containerStyle = {}, onHelp }) => {
    const isDesktop = useIsDesktop();
    const [isOpen, setIsOpen] = useState(isDesktop);

    const isExtended = (!isDesktop || isOpen);

    return (
        <>
            <ThemeProvider theme={lightTheme}>
                <Navbar isDesktop={isDesktop} isOpen={isOpen} onClose={() => setIsOpen(false)} onClickHelp={onHelp} onOpen={() => setIsOpen(true)}></Navbar>
                {!isDesktop && <>
                    <BlitzHeader menuButton={
                        <IconButton edge="start" onClick={() => setIsOpen(true)} sx={{ color: '#000' }}>
                            <MenuIcon />
                        </IconButton>
                    }></BlitzHeader>
                    <Toolbar></Toolbar>
                </>}
            </ThemeProvider>
            <Box
                id='TopPageContainer'
                sx={(theme) => ({
                    ...containerStyle,
                    ...(isExtended ? openedMixin(theme) : closedMixin(theme)),
                })}>
                {children}
            </Box>
        </>
    );
};

export default BlitzLayout;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Typography,
  Box,
  Paper,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Container,
  Toolbar,
  ThemeProvider,
  Grid,
  Stack,
} from '@mui/material';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import HomeHeader from '../../../Components/HomeHeader';
import HomeFooter from '../../../Components/HomeFooter';
import { globalStyles } from '../../../Utils/Styles';
import { StyledDivider as Divider } from '../../../Components/LightDivider';
import API from '../../../API';
import { lightTheme } from '../../../Utils/baseTheme';
import { useQuery } from 'react-query';
import useAlertDialog from '../../../Components/useAlertDialog';
import AlertDialog from '../../../Components/AlertDialog';
import { CheckCircle, Download, Payment } from '@mui/icons-material';

const InvoicePage = () => {
  const { invoiceId } = useParams();
  const { dialogState, openDialog, closeDialog } = useAlertDialog();

  const { data: invoiceData, isLoading: loading, error } = useQuery({
    queryKey: ['invoice', invoiceId],
    queryFn: () => API.invoices.fetch(invoiceId),
    refetchOnWindowFocus: false,
    enabled: !isNaN(invoiceId)
  });

  const invoice = invoiceData?.invoice;
  const user = invoiceData?.user;
  const company = invoiceData?.company;
  const payouts = invoiceData?.payouts;

  useEffect(() => {
    if (error) {
      openDialog('Error', <>Error fetching invoice: <br />{error?.response?.data?.error || error.message}</>, closeDialog, closeDialog, 'Ok', null);
    }
  }, [error]);

  const handleDownloadPDF = () => {
    const input = document.getElementById('invoice-content');
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(`invoice_${invoice.id}.pdf`);
    });
  };

  if (loading) {
    return (
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <AlertDialog alertState={dialogState}></AlertDialog>
        <CircularProgress />
      </Box>
    );
  }

  if (!invoice) {
    return (
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <AlertDialog alertState={dialogState}></AlertDialog>
        <Typography>Invoice not found.</Typography>
      </Box>
    );
  }

  const formattedDate = (date) => new Date(date).toLocaleDateString();
  const getAmountDue = (amount) => amount || 0;

  // Calculate amounts
  const subtotal = payouts.reduce((acc, payout) => acc + getAmountDue(payout.amount), 0);
  const amountDue = getAmountDue(invoice.amount_due);
  const amountPaid = getAmountDue(invoice.amount_paid);
  const platformFee = amountDue - subtotal;
  const total = subtotal + platformFee;
  const amountDueAfterPayment = amountDue - amountPaid;
  return (
    <ThemeProvider theme={lightTheme}>
      <AlertDialog alertState={dialogState}></AlertDialog>
      <Box
        sx={{
          minHeight: '100vh',
          background: 'linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <HomeHeader />
        <Toolbar />
        {/* Main Content */}
        <Container maxWidth="lg" sx={{ py: 6, flex: 1 }}>
          <Box id="invoice-content" sx={{ mb: 4 }}>
            <Paper sx={{ 
              p: 6, 
              borderRadius: 4,
              boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
              background: 'white',
            }}>
              {/* Header Section */}
              <Grid container spacing={4} sx={{ mb: 6 }}>
                <Grid item xs={12} md={6}>
                  {company?.phphref && (
                    <Box sx={{ mb: 4 }}>
                      <img
                        src={company.phphref}
                        alt={`${company.name} Logo`}
                        style={{ maxWidth: '180px', maxHeight: '80px' }}
                      />
                    </Box>
                  )}
                  <Typography variant="h3" sx={{ 
                    fontWeight: 800,
                    background: 'linear-gradient(45deg, #2b2d42 30%, #4a4e69 90%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    mb: 2
                  }}>
                    INVOICE
                  </Typography>
                  <Stack direction="row" spacing={2} sx={{ mb: 1 }}>
                    <Typography variant="body2" color="textSecondary">Date:</Typography>
                    <Typography variant="body2">{formattedDate(invoice.created_at)}</Typography>
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <Typography variant="body2" color="textSecondary">Invoice #:</Typography>
                    <Typography variant="body2">{invoice.po_number || 'N/A'}</Typography>
                  </Stack>
                </Grid>
                
                <Grid item xs={12} md={6} sx={{ textAlign: { xs: 'left', md: 'right' } }}>
                  <Button
                    variant="contained"
                    startIcon={<Download />}
                    onClick={handleDownloadPDF}
                    sx={{
                      mb: 4,
                      background: 'linear-gradient(45deg, #2b2d42 30%, #4a4e69 90%)',
                      '&:hover': { opacity: 0.9 }
                    }}
                  >
                    Download PDF
                  </Button>
                  <Box sx={{ 
                    p: 3,
                    background: '#f8f9fa',
                    borderRadius: 2,
                    display: 'inline-block',
                    textAlign: 'left'
                  }}>
                    <Typography variant="body2" color="textSecondary">Amount Due</Typography>
                    <Typography variant="h4" sx={{ fontWeight: 700 }}>
                      ${amountDueAfterPayment.toFixed(2)}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              {/* Billed To Section */}
              <Grid container spacing={4} sx={{ mb: 6 }}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" sx={{ fontWeight: 600, mb: 2 }}>Billed To</Typography>
                  <Box sx={{ p: 3, background: '#f8f9fa', borderRadius: 2 }}>
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {invoice.client_name || company.name || `${user?.first_name} ${user?.last_name}`}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {invoice.client_email || user?.email}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              {/* Items Table */}
              <TableContainer sx={{ 
                border: '1px solid #e9ecef',
                borderRadius: 2,
                overflow: 'hidden',
                mb: 6
              }}>
                <Table>
                  <TableHead sx={{ 
                    background: 'linear-gradient(45deg, #2b2d42 30%, #4a4e69 90%)'
                  }}>
                    <TableRow>
                      <TableCell sx={{ color: 'white', fontWeight: 600 }}>Item</TableCell>
                      <TableCell sx={{ color: 'white', fontWeight: 600 }}>Description</TableCell>
                      <TableCell sx={{ color: 'white', fontWeight: 600 }}>Rate</TableCell>
                      <TableCell sx={{ color: 'white', fontWeight: 600 }}>Qty</TableCell>
                      <TableCell sx={{ color: 'white', fontWeight: 600 }}>Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {payouts.map((payout) => (
                      <TableRow key={payout.id} sx={{ '&:last-child td': { borderBottom: 0 } }}>
                        <TableCell>{payout.creator_id}</TableCell>
                        <TableCell>{payout.notes || `PO Number ${payout.po_number}`}</TableCell>
                        <TableCell>${getAmountDue(payout.amount).toFixed(2)}</TableCell>
                        <TableCell>1</TableCell>
                        <TableCell sx={{ fontWeight: 500 }}>${getAmountDue(payout.amount).toFixed(2)}</TableCell>
                      </TableRow>
                    ))}
                    {/* ... other table rows ... */}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* Summary Section */}
              <Grid container justifyContent="flex-end" sx={{ mb: 6 }}>
                <Grid item xs={12} md={6}>
                  <Box sx={{ 
                    p: 3,
                    background: '#f8f9fa',
                    borderRadius: 2,
                  }}>
                    <Stack spacing={1}>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2">Subtotal:</Typography>
                        <Typography variant="body2">${subtotal.toFixed(2)}</Typography>
                      </Stack>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2">Platform Fee:</Typography>
                        <Typography variant="body2">${platformFee.toFixed(2)}</Typography>
                      </Stack>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2">Amount Paid:</Typography>
                        <Typography variant="body2">${amountPaid.toFixed(2)}</Typography>
                      </Stack>
                      <Divider sx={{ my: 1 }} />
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body1" sx={{ fontWeight: 600 }}>Total Due:</Typography>
                        <Typography variant="body1" sx={{ fontWeight: 600 }}>
                          ${amountDueAfterPayment.toFixed(2)}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>
                </Grid>
              </Grid>

              {/* Payment Section */}
              <Box sx={{ 
                p: 4,
                background: 'linear-gradient(45deg, #2b2d42 30%, #4a4e69 90%)',
                borderRadius: 2,
                textAlign: 'center',
                color: 'white'
              }}>
                <CheckCircle sx={{ fontSize: 40, mb: 2 }} />
                <Typography variant="h5" sx={{ fontWeight: 600, mb: 2 }}>
                  Secure Payment Options
                </Typography>
                <Typography variant="body1" sx={{ mb: 3 }}>
                  Pay with Credit Card, PayPal, or Bank Transfer
                </Typography>
                {invoice.invoice_link && (
                  <Button
                    variant="contained"
                    color="secondary"
                    href={invoice.invoice_link}
                    startIcon={<Payment />}
                    sx={{
                      px: 6,
                      fontWeight: 600,
                      background: 'white',
                      color: '#2b2d42',
                      '&:hover': { background: '#f8f9fa' }
                    }}
                  >
                    Pay Now
                  </Button>
                )}
              </Box>
            </Paper>
          </Box>
        </Container>
        <HomeFooter />
      </Box>
    </ThemeProvider>
  );
};

export default InvoicePage;
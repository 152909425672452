import React, { useState, useEffect, useMemo, memo } from "react";
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  CircularProgress,
  Pagination,
  TextField,
  IconButton,
  Autocomplete,
  Button,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  OutlinedInput,
  InputAdornment,
  Typography,
} from "@mui/material";

import {
  FilterAltOffRounded,
  Delete as DeleteIcon,
  PersonAddAlt1Outlined as PersonAddAlt1OutlinedIcon,
  Search as SearchIcon,
  GridView,
  ViewList,
} from "@mui/icons-material";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import AccessTimeIcon from "@mui/icons-material/AccessTime"; // For recent
import StarIcon from "@mui/icons-material/Star";            // For popular

import { useMutation, useQuery } from "react-query";
import client from "../../../API";
import CreatorDialog from "./creatorintake";
import CreatorContainers from "./creatorcontainersearch";
import PromoPlatFilter from "./Filters/PromoPlatFilter";
import NichesFilter from "./Filters/NichesFilters";
import CreatorFilter from "./Filters/CreatorFilters";
import DemoFilters from "./Filters/DemoFilters";
import MinMaxFilter from "./Filters/MinMaxFilter";

import {
  headerToKeyMap,
  indexToPromoFilter,
  platformToKey,
  platPromoToKey,
  platPromoToHead,
  headers,
  platforms,
  promotionTypeArray,
  calculateTotalFollowing,
  calculateAverageCreatorRate,
} from "../../../Utils/constants";

import { StyledTableRow } from "../../../Utils/styledcell";

const LeftColumnSearch = memo(function LeftColumnSearch({
  onCreatorSelect,
  selectedItems,
  data,                       // <-- Creators array
  loading,                    // <-- Whether creators are loading
  onInviteToPartnership = null,
  itemsPerPage = 15,
  onChangePromo,
  initialFilters = null,
  initialCollectionId = "",
}) {
  // -----------------------------
  // Local state for filters, etc.
  // -----------------------------
  const [isCreatorDialogOpen, setIsCreatorDialogOpen] = useState(false);
  const [showPretty, setShowPretty] = useState(true);
  const [page, setPage] = useState(0);
  const [promoIndex, setPromoIndex] = useState(1);

  const [nicheSet, setNicheSet] = useState(new Set([]));
  const [creatorGender, setCreatorGender] = useState(new Set([]));
  const [creatorRace, setCreatorRace] = useState(new Set([]));
  const [creatorRegion, setCreatorRegion] = useState(new Set([]));

  const [publicGender, setPublicGender] = useState(new Set([]));
  const [publicAge, setPublicAge] = useState(new Set([]));
  const [publicCountry, setPublicCountry] = useState(new Set([]));

  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);
  const [minFollow, setMinFollow] = useState(null);
  const [maxFollow, setMaxFollow] = useState(null);
  const [minViews, setMinViews] = useState(null);
  const [maxViews, setMaxViews] = useState(null);

  const [filter, setFilter] = useState("");
  const [search, setSearch] = useState("");
  const [aiQuery, setAiQuery] = useState("");

  const [selectedCollection, setSelectedCollection] = useState("");
  const [isAddCollectionDialogOpen, setIsAddCollectionDialogOpen] = useState(false);
  const [newCollectionName, setNewCollectionName] = useState("");

  const [viewMode, setViewMode] = useState("grid"); // "grid" or "list"
  const [showFilters, setShowFilters] = useState(false);

  // -----------------------------------------------
  // 1) Query user’s own Collections (saved filters)
  // -----------------------------------------------
  const { data: collections = [], refetch: fetchCollections } = useQuery(
    "collections",
    () => client.collections.list()
  );

  // ------------------------------------------------------
  // 2) Query ALL popular & recent searches for autocomplete
  // ------------------------------------------------------
  const {
    data: allSearches,            // <-- renamed from 'data' to 'allSearches'
    isLoading: allSearchesLoading, // <-- separate loading for popular/recent
  } = useQuery("allSearches", () => client.collections.popular(), {
    staleTime: 60_000,
  });

  // We build the merged options for the Autocomplete
  const [autoOptions, setAutoOptions] = useState([]);
  useEffect(() => {
    if (!allSearches) return;

    const { popular = [], recent = [] } = allSearches;

    // Convert popular => [ { label, type:'popular', item } ... ]
    const popularOpts = popular.map((p) => ({
      label: p.name, // e.g. "Makeup"
      type: "popular",
      item: p,
    }));

    // Convert recent => [ { label, type:'recent', item } ... ]
    const recentOpts = recent.map((r) => ({
      label: r.name,
      type: "recent",
      item: r,
    }));

    // Merged list
    setAutoOptions([...recentOpts, ...popularOpts]);
  }, [allSearches]);

  // 3) Mutations for creating & deleting Collections
  const { mutate: createCollection } = useMutation(client.collections.create, {
    onSuccess: (newCollection) => {
      fetchCollections();
      setNewCollectionName("");
      setSelectedCollection(newCollection.id);
    },
  });
  const { mutate: deleteCollection } = useMutation(client.collections.delete, {
    onSuccess: (_, id) => {
      fetchCollections();
      if (selectedCollection === id) setSelectedCollection("");
    },
  });

  // 4) AI Search mutation
  const { mutate: aiSearchMutation, isLoading: aiSearching } = useMutation(
    async (queryText) => {
      const res = await client.creators.search({ query: queryText });
      return res;
    },
    {
      onError: (error) => console.error("AI search error:", error),
      onSuccess: async (searchResult) => {
        await fetchCollections();
        setSelectedCollection(searchResult.collection_id);
      },
    }
  );

  // 5) Save a new Collection
  const handleSaveCollection = () => {
    const filters = {
      promoIndex,
      nicheSet: Array.from(nicheSet),
      creatorGender: Array.from(creatorGender),
      creatorRace: Array.from(creatorRace),
      creatorRegion: Array.from(creatorRegion),
      publicGender: Array.from(publicGender),
      publicAge: Array.from(publicAge),
      publicCountry: Array.from(publicCountry),
      minPrice,
      maxPrice,
      minFollow,
      maxFollow,
      minViews,
      maxViews,
    };
    createCollection({ name: newCollectionName, filters });
  };

  // 6) If there is an initialCollectionId, select it
  useEffect(() => {
    if (initialCollectionId) setSelectedCollection(initialCollectionId);
  }, [initialCollectionId]);

  // 7) If there are initial filters, apply them
  useEffect(() => {
    if (initialFilters) {
      const {
        promoIndex: colPromoIndex,
        nicheSet: colNicheSet,
        creatorGender: colCreatorGender,
        creatorRace: colCreatorRace,
        creatorRegion: colCreatorRegion,
        publicGender: colPublicGender,
        publicAge: colPublicAge,
        publicCountry: colPublicCountry,
        minPrice: colMinPrice,
        maxPrice: colMaxPrice,
        minFollow: colMinFollow,
        maxFollow: colMaxFollow,
        minViews: colMinViews,
        maxViews: colMaxViews,
      } = initialFilters;

      setPromoIndex(colPromoIndex || 1);
      if (onChangePromo) onChangePromo(colPromoIndex || 1);

      setNicheSet(new Set(colNicheSet || []));
      setCreatorGender(new Set(colCreatorGender || []));
      setCreatorRace(new Set(colCreatorRace || []));
      setCreatorRegion(new Set(colCreatorRegion || []));
      setPublicGender(new Set(colPublicGender || []));
      setPublicAge(new Set(colPublicAge || []));
      setPublicCountry(new Set(colPublicCountry || []));
      setMinPrice(colMinPrice || null);
      setMaxPrice(colMaxPrice || null);
      setMinFollow(colMinFollow || null);
      setMaxFollow(colMaxFollow || null);
      setMinViews(colMinViews || null);
      setMaxViews(colMaxViews || null);
    }
  }, [initialFilters, onChangePromo]);

  // 8) Helpers for updating filters
  const handleDeleteCollection = (id) => deleteCollection(id);

  const handleMinMaxApply = (value, minSetter, maxSetter) => {
    minSetter(value.min);
    maxSetter(value.max);
  };

  const handleCreatorDemoApply = (value) => {
    setCreatorGender(value.gender);
    setCreatorRace(value.race);
    setCreatorRegion(value.region);
  };

  const handlePublicDemoApply = (value) => {
    setPublicGender(value.gender);
    setPublicAge(value.age);
    setPublicCountry(value.country);
  };

  // 9) Reset
  const handleResetFilters = () => {
    setPromoIndex(1);
    if (onChangePromo) onChangePromo(1);

    setNicheSet(new Set([]));
    setCreatorGender(new Set([]));
    setCreatorRace(new Set([]));
    setCreatorRegion(new Set([]));
    setPublicGender(new Set([]));
    setPublicAge(new Set([]));
    setPublicCountry(new Set([]));

    setMinPrice(null);
    setMaxPrice(null);
    setMinFollow(null);
    setMaxFollow(null);
    setMinViews(null);
    setMaxViews(null);

    setSearch("");
    setFilter("");
  };

  // 10) Debounce the text-based search
  useEffect(() => {
    const delay = setTimeout(() => {
      setFilter(search);
    }, 500);
    return () => clearTimeout(delay);
  }, [search]);

  // 11) Reset page on filter changes
  useEffect(() => {
    setPage(0);
  }, [
    promoIndex,
    filter,
    nicheSet,
    minViews,
    maxViews,
    minPrice,
    maxPrice,
    minFollow,
    maxFollow,
    publicCountry,
    publicAge,
    publicGender,
    creatorRegion,
    creatorRace,
    creatorGender,
  ]);

  // 12) Determine headers
  const genericHeaders = [
    "Creator",
    "Primary Market",
    "Followers",
    "Avg. Views",
    "Price",
    "CPM",
  ];

  let platform = null;
  let promotionType = null;
  let displayHeaders;
  if (promoIndex === -1) {
    displayHeaders = genericHeaders;
    platform = null;
    promotionType = null;
  } else {
    const promoData = indexToPromoFilter(promoIndex);
    platform = promoData.platform;
    promotionType = promoData.promo;
    displayHeaders = [...headers[platform]];
    const promoHeader = platPromoToHead[platform][promotionType];
    if (promoHeader) {
      if (displayHeaders.length >= 2) {
        displayHeaders.splice(-2, 0, promoHeader);
      } else {
        displayHeaders.push(promoHeader);
      }
    }
  }

  // 13) AI Search triggers
  const handleAISearchSubmit = (evt) => {
    if (evt.key === "Enter") {
      aiSearchMutation(aiQuery);
    }
  };
  const handleAISearchClick = () => {
    aiSearchMutation(aiQuery);
  };

  // 14) If user picks an Autocomplete item
  const handleAutoCompleteChange = (event, newVal) => {
    if (!newVal) return;

    if (newVal.type === "recent") {
      // It's a saved FilterCollection
      const coll = newVal.item; // The entire FilterCollection
      setSelectedCollection(coll.id);
    } else {
      // It's a popular search name => run AI search
      aiSearchMutation(newVal.label);
    }
  };

  // 15) "Apply" a collection’s filters
  const applyCollection = (collection) => {
    if (collection && collection.filters) {
      const {
        promoIndex: colPromoIndex,
        nicheSet: colNicheSet,
        creatorGender: colCreatorGender,
        creatorRace: colCreatorRace,
        creatorRegion: colCreatorRegion,
        publicGender: colPublicGender,
        publicAge: colPublicAge,
        publicCountry: colPublicCountry,
        minPrice: colMinPrice,
        maxPrice: colMaxPrice,
        minFollow: colMinFollow,
        maxFollow: colMaxFollow,
        minViews: colMinViews,
        maxViews: colMaxViews,
      } = collection.filters;

      setPromoIndex(colPromoIndex || 1);
      if (onChangePromo) onChangePromo(colPromoIndex || 1);

      setNicheSet(new Set(colNicheSet || []));
      setCreatorGender(new Set(colCreatorGender || []));
      setCreatorRace(new Set(colCreatorRace || []));
      setCreatorRegion(new Set(colCreatorRegion || []));
      setPublicGender(new Set(colPublicGender || []));
      setPublicAge(new Set(colPublicAge || []));
      setPublicCountry(new Set(colPublicCountry || []));
      setMinPrice(colMinPrice || null);
      setMaxPrice(colMaxPrice || null);
      setMinFollow(colMinFollow || null);
      setMaxFollow(colMaxFollow || null);
      setMinViews(colMinViews || null);
      setMaxViews(colMaxViews || null);
    }
  };

  // Whenever selectedCollection changes, re-apply that collection’s filters
  useEffect(() => {
    if (selectedCollection) {
      const coll = collections.find((c) => c.id === selectedCollection);
      applyCollection(coll);
    }
  }, [selectedCollection, collections]);

  // 16) Partnerships logic
  const handleInviteToPartnership = (creator) => {
    const relevantData = {
      ...creator,
      id: creator.creator,
      name: creator.creator,
    };
    onInviteToPartnership(relevantData);
  };

  // 17) Handle selecting a single row
  const handleSelectItem = (creatorId) => {
    const selectedCreator = data.find((c) => c.creator === creatorId);
    if (!selectedCreator) {
      console.warn(`Item with ID ${creatorId} not found.`);
      return;
    }
    if (promoIndex === -1) {
      // "All" is selected => use computed values
      const price = selectedCreator.computedPrice || null;
      const followingCount = selectedCreator.followers || 0;
      const relevantData = {
        id: selectedCreator.creator,
        name: selectedCreator.creator,
        price,
        following: followingCount,
        promotionPlatform: "",
        promotionType: "",
        platformLink: null,
        pfphref: selectedCreator.pfphref,
      };
      if (onCreatorSelect) onCreatorSelect(creatorId, relevantData);
    } else {
      // Single platform/promo
      let priceKey = platPromoToKey[platform]?.[promotionType];
      if (!priceKey) {
        console.warn(
          `Promotion type or platform key is not available for ${platform} - ${promotionType}`
        );
        return;
      }
      const price = selectedCreator[priceKey]
        ? selectedCreator[priceKey] * 1.2
        : null;
      const platformLinkKey = `${platform} Link`;
      const platformLink = selectedCreator[headerToKeyMap[platformLinkKey]];
      const followingCount = selectedCreator[platformToKey[platform]?.[0]];
      const relevantData = {
        id: selectedCreator.creator,
        name: selectedCreator.creator,
        price,
        following: followingCount,
        promotionPlatform: platform,
        promotionType,
        platformLink,
        pfphref: selectedCreator.pfphref,
      };
      if (onCreatorSelect) onCreatorSelect(creatorId, relevantData);
    }
  };

  // 18) Filter logic
  const parseFollowerCount = (followerVar) => {
    if (!followerVar || followerVar === "N/A") return 0;
    if (Number.isFinite(followerVar)) return followerVar;
    return parseInt(followerVar.replace(/,/g, ""), 10) || 0;
  };

  const followerRangeMatch = (fc) => fc >= minFollow && fc <= maxFollow;

  const calculateCPM = (rate, avgViews) => {
    if (!rate || !avgViews || rate === "N/A" || avgViews === "N/A") return null;
    const numericalRate = Number(rate);
    const numericalViews = parseInt((avgViews || "").replace(/[^0-9]/g, ""), 10);
    if (isNaN(numericalRate) || isNaN(numericalViews)) return null;
    return (numericalRate / numericalViews) * 1000;
  };

  const priceRangeMatch = (price) => {
    if (!price) return false;
    return price >= minPrice && price <= maxPrice;
  };

  // For demographics
  const emptyCountry = { code: "N/A", value: Number.NEGATIVE_INFINITY };
  const emptyAge = { age_range: "N/A", value: Number.NEGATIVE_INFINITY };
  const emptyGender = { name: "N/A", value: Number.NEGATIVE_INFINITY };

  const highestValueCountry = (publicData) =>
    publicData.reduce((max, demo) => {
      const demoMax = demo.country_data.reduce((ctrMax, c) => {
        if (c.value > ctrMax.value) ctrMax = c;
        return ctrMax;
      }, emptyCountry);
      return demoMax.value > max.value ? demoMax : max;
    }, emptyCountry);

  const higherValueAge = (publicData) => {
    const publicByAge = publicData.map((demo) =>
      demo.audience_data.reduce((acc, entry) => {
        const { age_range, value } = entry;
        const existing = acc.find((x) => x.age_range === age_range);
        if (existing) existing.value += Math.round(value);
        else acc.push({ age_range, value: Math.round(value) });
        return acc;
      }, [])
    );
    return publicByAge.reduce((max, demo) => {
      const demoMax = demo.reduce(
        (ageMax, group) => (group.value > ageMax.value ? group : ageMax),
        emptyAge
      );
      return demoMax.value > max.value ? demoMax : max;
    }, emptyAge);
  };

  const higherValueGender = (publicData) => {
    const publicByGender = publicData.map((demo) => [
      {
        name: "male",
        value: demo.audience_data.reduce(
          (accum, item) =>
            item.gender.toLowerCase() === "male"
              ? accum + Math.round(item.value)
              : accum,
          0
        ),
      },
      {
        name: "female",
        value: demo.audience_data.reduce(
          (accum, item) =>
            item.gender.toLowerCase() === "female"
              ? accum + Math.round(item.value)
              : accum,
          0
        ),
      },
    ]);
    return publicByGender.reduce((max, demo) => {
      const demoMax = demo.reduce(
        (genderMax, group) =>
          group.value > genderMax.value ? group : genderMax,
        emptyGender
      );
      return demoMax.value > max.value ? demoMax : max;
    }, emptyGender);
  };

  // 19) Filter the creators data
  const filteredData = useMemo(() => {
    if (!Array.isArray(data)) return [];
    const filterValue = indexToPromoFilter(promoIndex);

    if (
      promoIndex !== -1 &&
      (!platformToKey[filterValue.platform] ||
        !platPromoToKey[filterValue.platform])
    ) {
      return [];
    }

    const out = data.filter((creator) => {
      // 1) Check if platform data is present (unless "All")
      if (promoIndex !== -1) {
        const platformKeys = platformToKey[filterValue.platform];
        const isPlatformDataPresent = platformKeys.some(
          (key) => creator[key] && creator[key] !== "N/A"
        );
        if (!isPlatformDataPresent) return false;
      }

      const currPromotionKey =
        promoIndex === -1
          ? null
          : platPromoToKey[filterValue.platform][filterValue.promo];

      const promoTypeMatch =
        promoIndex === -1 ||
        (creator[currPromotionKey] && creator[currPromotionKey] !== null);

      // 2) Primary Market
      const primaryMarketMatch =
        nicheSet.size > 0 ? creator.primary_markets?.some(niche => nicheSet.has(niche)) || false : true;

      // 3) Race
      const raceMatch =
        creatorRace.size > 0
          ? Array.from(creatorRace).some((r) => creator.ethnicity?.includes(r))
          : true;

      // 4) Gender
      const genderMatch =
        creatorGender.size > 0
          ? Array.from(creatorGender).some(
            (g) => (creator.gender || []).includes(g)
          )
          : true;

      // 5) Region
      let regionMatch = true;
      if (creatorRegion.size > 0) {
        regionMatch = creatorRegion.has(creator.region);
      }

      // 6) Views
      const parseViews = (v) => parseInt((v || "").replace(/,/g, ""), 10) || 0;
      let avgViewsMatch = true;
      if (minViews !== null && maxViews !== null) {
        const val = parseViews(creator.avg_views);
        avgViewsMatch = val >= minViews && val <= maxViews;
      }

      // 7) Price
      let checkPrice;
      if (promoIndex === -1) {
        checkPrice = calculateAverageCreatorRate(creator);
      } else {
        checkPrice = creator[currPromotionKey];
      }

      const priceMatch =
        minPrice !== null && maxPrice !== null
          ? checkPrice && priceRangeMatch(checkPrice)
          : true;

      // 8) Followers
      const followerCount =
        promoIndex === -1
          ? parseFollowerCount(calculateTotalFollowing(creator))
          : parseFollowerCount(creator[platformToKey[filterValue.platform][0]]);
      const followersMatch =
        minFollow !== null && maxFollow !== null
          ? followerRangeMatch(followerCount)
          : true;

      // 9) Demographics
      let demoCountryMatch = true;
      if (publicCountry.size > 0 && Array.isArray(creator.public)) {
        const maxCountr = highestValueCountry(creator.public);
        demoCountryMatch = publicCountry.has(maxCountr.code);
      }
      let demoAgeMatch = true;
      if (publicAge.size > 0 && Array.isArray(creator.public)) {
        const maxAge = higherValueAge(creator.public);
        demoAgeMatch = publicAge.has(maxAge.age_range.toLowerCase());
      }
      let demoGenderMatch = true;
      if (publicGender.size > 0 && Array.isArray(creator.public)) {
        const maxGender = higherValueGender(creator.public);
        demoGenderMatch = publicGender.has(maxGender.name);
      }

      // 10) Text search
      const searchMatch =
        !filter ||
        Object.values(creator).some(
          (val) =>
            typeof val === "string" &&
            val.toLowerCase().includes(filter.toLowerCase())
        );

      // Return combined
      return (
        promoTypeMatch &&
        primaryMarketMatch &&
        raceMatch &&
        genderMatch &&
        regionMatch &&
        avgViewsMatch &&
        priceMatch &&
        followersMatch &&
        demoCountryMatch &&
        demoAgeMatch &&
        demoGenderMatch &&
        searchMatch
      );
    });

    // 11) Mark up the price by 20% & compute cpm
    return out.map((creator) => {
      const localFilterValue = indexToPromoFilter(promoIndex);
      const platformKeys =
        promoIndex !== -1 ? platformToKey[localFilterValue.platform] : [];
      const isPlatformDataPresent =
        promoIndex !== -1 &&
        platformKeys.some((k) => creator[k] && creator[k] !== "N/A");

      const rawRate = isPlatformDataPresent
        ? creator[platPromoToKey[localFilterValue.platform][localFilterValue.promo]]
        : promoIndex !== -1
          ? 0
          : calculateAverageCreatorRate(creator);

      const cpm = calculateCPM(rawRate, creator.avg_views);

      const updatedCreator = { ...creator };
      Object.keys(platPromoToKey).forEach((plat) => {
        Object.values(platPromoToKey[plat]).forEach((priceKey) => {
          if (updatedCreator[priceKey] && !isNaN(updatedCreator[priceKey])) {
            updatedCreator[priceKey] = updatedCreator[priceKey] * 1.2;
          }
        });
      });

      return { ...updatedCreator, cpm };
    });
  }, [
    data,
    promoIndex,
    nicheSet,
    minViews,
    maxViews,
    minPrice,
    maxPrice,
    minFollow,
    maxFollow,
    publicCountry,
    publicAge,
    publicGender,
    creatorRegion,
    creatorRace,
    creatorGender,
    filter,
  ]);

  // 20) Creator Dialog submission
  const handleCreatorSubmit = (formData) => {
    console.log(formData);
    setIsCreatorDialogOpen(false);
  };

  // -------------------------------------------------------------------
  // RENDER
  // -------------------------------------------------------------------
  return (
    <>
      {/* Top Bar */}
      <Paper
        sx={{ display: "flex", alignItems: "center", mb: 3, justifyContent: 'space-between' }}
        elevation={2}
        square
      >
        <Box
          sx={{
            flex: 3,
            p: 2,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 2,
              width: "100%",
            }}
          >
            <PromoPlatFilter
              value={promoIndex}
              onChangeValue={(value) => {
                setPromoIndex(value);
                if (onChangePromo) onChangePromo(value);
              }}
            />

            {/* If advanced filters are hidden, show Autocomplete for popular/recent */}
            {!showFilters && (
              <Autocomplete
                sx={{
                  flex: 1,
                }}
                options={autoOptions}
                getOptionLabel={(o) => o.label}
                onChange={handleAutoCompleteChange}
                inputValue={aiQuery}
                onInputChange={(e, val) => setAiQuery(val)}
                renderOption={(props, option) => (
                  <li {...props} key={`${option.type}-${option.label}`}>
                    {option.type === "recent" ? (
                      <AccessTimeIcon sx={{ mr: 1, color: "gray" }} />
                    ) : (
                      <StarIcon sx={{ mr: 1, color: "#F7B519" }} />
                    )}
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder='Try: "female creators beauty 1000-1500 USD on YouTube"'
                    onKeyDown={handleAISearchSubmit}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {params.InputProps.endAdornment}
                          <IconButton
                            onClick={handleAISearchClick}
                            edge="end"
                            color="primary"
                          >
                            <SearchIcon />
                          </IconButton>
                        </>
                      ),
                      sx: { backgroundColor: "background.default" }
                    }}
                  />
                )}
              />
            )}

            {/* Show advanced filters if toggled */}
            {showFilters && (
              <>
                <NichesFilter value={nicheSet} onChangeValue={setNicheSet} />
                <MinMaxFilter
                  minValue={minPrice}
                  maxValue={maxPrice}
                  width="6rem"
                  name="Rate"
                  onChangeValue={(val) => handleMinMaxApply(val, setMinPrice, setMaxPrice)}
                />
                <MinMaxFilter
                  minValue={minFollow}
                  maxValue={maxFollow}
                  name="Followers"
                  width="8rem"
                  onChangeValue={(val) => handleMinMaxApply(val, setMinFollow, setMaxFollow)}
                />
                <MinMaxFilter
                  minValue={minViews}
                  maxValue={maxViews}
                  name="Avg. Views"
                  width="10rem"
                  onChangeValue={(val) => handleMinMaxApply(val, setMinViews, setMaxViews)}
                />
                <CreatorFilter
                  genderValue={creatorGender}
                  raceValue={creatorRace}
                  regionValue={creatorRegion}
                  onChangeValue={handleCreatorDemoApply}
                />
                <DemoFilters
                  genderValue={publicGender}
                  ageValue={publicAge}
                  countryValue={publicCountry}
                  onChangeValue={handlePublicDemoApply}
                />
                <TextField
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  label="Keywords"
                  variant="outlined"
                  size="small"
                  sx={{ width: "12rem" }}
                />
                <TextField
                  value={selectedCollection}
                  onChange={(e) => setSelectedCollection(e.target.value)}
                  label="Searches"
                  variant="outlined"
                  size="small"
                  sx={{ width: "12rem" }}
                  select
                  SelectProps={{
                    renderValue: (selected) => {
                      const col = collections.find((c) => c.id === selected);
                      return col ? col.name : "None";
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {collections.map((collection) => (
                    <MenuItem key={collection.id} value={collection.id}>
                      <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                        <Box sx={{ flexGrow: 1 }}>{collection.name}</Box>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={(evt) => {
                            evt.stopPropagation();
                            handleDeleteCollection(collection.id);
                          }}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </MenuItem>
                  ))}
                  <Divider />
                  <MenuItem onClick={() => setIsAddCollectionDialogOpen(true)}>
                    Add New Filter Set
                  </MenuItem>
                </TextField>
              </>
            )}
          </Box>
        </Box>

        {/* View Mode & Toggle Controls */}
        <Box sx={{ display: "flex", alignItems: "center", flexWrap: 'wrap', maxWidth: { xs: '72px', md: 'none' } }}>
          <IconButton onClick={() => setShowFilters((prev) => !prev)} color="primary" size="small">
            {showFilters ? <SearchIcon /> : <FilterAltIcon />}
          </IconButton>
          <IconButton onClick={handleResetFilters} color="primary" size="small">
            <CleaningServicesIcon />
          </IconButton>
          <IconButton
            onClick={() => setViewMode("grid")}
            color={viewMode === "grid" ? "secondary" : "default"}
            size="small"
          >
            <GridView />
          </IconButton>
          <IconButton
            onClick={() => setViewMode("list")}
            color={viewMode === "list" ? "secondary" : "default"}
            size="small"
          >
            <ViewList />
          </IconButton>
        </Box>
      </Paper>

      {/* Main Content */}
      {loading || aiSearching ? (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center', mt: 4, flexGrow: 1, flexDirection: 'column' }}>
          <CircularProgress />
        </Box>
      ) : filteredData.length === 0 ? (
        <Box sx={{ textAlign: "center", p: 4, flexGrow: 1 }}>
          <Typography variant="h6">
            Sorry, there are no creators for this exact set of filters. Try adjusting your filters!
          </Typography>
        </Box>
      ) : viewMode === "grid" ? (
        <Box sx={{ paddingInline: { xs: 2, md: 1 }, marginBlockEnd: 1, flexGrow: 1 }}>
          <CreatorContainers
            creators={filteredData.slice(
              page * itemsPerPage,
              Math.min(page * itemsPerPage + itemsPerPage, filteredData.length)
            )}
            platform={platform}
            promotion={promotionType}
            selectedItems={selectedItems}
            onAddToCampaign={handleSelectItem}
            onInviteToPartnership={
              onInviteToPartnership ? (creator) => handleInviteToPartnership(creator) : null
            }
          />
        </Box>
      ) : (
        <Box sx={{flexGrow: 1 }}>
          <Paper elevation={3} sx={{ overflowX: "auto", mb: 3}}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">Select</TableCell>
                  {displayHeaders.map((header) => (
                    <TableCell key={header}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData
                  .slice(
                    page * itemsPerPage,
                    Math.min(page * itemsPerPage + itemsPerPage, filteredData.length)
                  )
                  .map((creator) => (
                    <StyledTableRow
                      key={creator.creator}
                      selected={selectedItems.has(creator.creator)}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedItems.has(creator.creator)}
                          onChange={() => handleSelectItem(creator.creator)}
                          color="primary"
                        />
                      </TableCell>
                      <TableCell>{creator.creator}</TableCell>
                      <TableCell>{creator.primary_markets?.join(', ')||'N/A'}</TableCell>
                      <TableCell>{creator.followers}</TableCell>
                      <TableCell>{creator.avg_views}</TableCell>
                      <TableCell>{creator.computedPrice || "N/A"}</TableCell>
                      <TableCell>
                        {creator.computedCPM ? creator.computedCPM.toFixed(2) : "N/A"}
                      </TableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </Paper>
        </Box>
      )}

      {/* Pagination */}
      <Paper elevation={1} sx={{ p: 2, mt: 3, textAlign: "center" }} square>
        <Pagination
          count={Math.ceil(filteredData.length / itemsPerPage)}
          page={page + 1}
          onChange={(e, value) => setPage(value - 1)}
          color="primary"
          sx={{ marginInline: "auto", maxWidth: "fit-content" }}
        />
      </Paper>

      {/* Add Collection Dialog */}
      <Dialog
        open={isAddCollectionDialogOpen}
        onClose={() => setIsAddCollectionDialogOpen(false)}
      >
        <DialogTitle>Add New Collection</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Collection Name"
            fullWidth
            value={newCollectionName}
            onChange={(e) => setNewCollectionName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsAddCollectionDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={() => {
              handleSaveCollection();
              setIsAddCollectionDialogOpen(false);
            }}
            disabled={!newCollectionName}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default LeftColumnSearch;

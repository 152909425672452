import React from 'react';
import { Box, Container, Divider, Typography } from '@mui/material';
import ReleaseScaffolding from './releaseScaffold';

const releaseSections = [
  { id: 'beta-5', title: 'Blitz 5.0 - Blitz Lizard Release (February 5th, 2025)' },
  { id: 'beta-4', title: 'Blitz 4.0 - Platform Foundation Release' },
  { id: 'beta-3', title: 'Blitz 3.0 - MVP Launch Release' },
];

export default function BlitzReleaseNotes() {
  return (
    <ReleaseScaffolding sections={releaseSections}>
      <Container>
        {releaseSections.map((section) => (
          <Box key={section.id} id={section.id} sx={{ marginBottom: 4 }}>
            <Typography variant="h4">{section.title}</Typography>
            <Divider />

            {/* --- Blitz 5.0 Release Notes --- */}
            {section.id === 'beta-5' ? (
              <>
                <Typography variant="h6" sx={{ mt: 2 }}>New Features</Typography>
                <ul>
                  <li>
                    <strong>Functional & Financials:</strong>
                    <ul>
                      <li>
                        **Rewrite Balance Logic** – Allows a single operation even without an existing balance, 
                        improving workflows for campaigns, partnerships, and agency invoicing.
                      </li>
                      <li>
                        **New Financial Entity: Withdrawal** – Represents money going out of Blitz balances 
                        into user/creator accounts. A creator accepting a payout now auto-creates a Withdrawal 
                        for manual approval by Valentina.
                      </li>
                      <li>
                        **Rewrite Payout Logic** – Manual payouts to user accounts; automatic generation 
                        of withdrawal records for streamlined financial oversight.
                      </li>
                      <li>
                        **Agency Invoicing Clients** – Approving a client invoice auto-creates an approved 
                        withdrawal for agency payment.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong>User Functions & Settings Overhaul:</strong>
                    <ul>
                      <li>
                        **Customizable User Preferences** – Colors, emails, feature tabs, and 
                        recommendation algorithms for creators based on search history data points (Alex).
                      </li>
                      <li>
                        **User Settings Table** – Interacts with tokens to store navbar preferences and color themes.
                      </li>
                      <li>
                        **Enhanced Search Algorithm** – Open-ended design for aggregated user collections. 
                        Top searches and user-based recommendations appear in the search bar.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong>Bigger Agency Pages:</strong> Enhanced, customizable roster pages for agencies, 
                    with better layouts and performance.
                  </li>
                  <li>
                    <strong>Conversations in Email Form (Alex, Ricky):</strong>
                    <ul>
                      <li>
                        **Webhook Integration** – A new webhook (partnerships@) reads recipient emails 
                        to form conversation threads automatically.
                      </li>
                      <li>
                        **Helpdesk-like Templates** – Improved email templates for conversation threads and replies.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong>Campaign Param Security Update:</strong> Param structure updated to 
                    <code>[companyName, campaignId]</code> for safer linking.
                  </li>
                  <li>
                    <strong>CRM Updates & Cleaning:</strong> New re-engagement workflows and database 
                    cleaning to boost user activity and reduce clutter.
                  </li>
                  <li>
                    <strong>Agency Creator Connect (Alex):</strong>
                    <ul>
                      <li>Restricted deal visibility (hidden prices/conversations) for limited-access collaboration.</li>
                      <li>Pitch functionality disabled for restricted users.</li>
                    </ul>
                  </li>
                  <li>
                    <strong>Data Exports:</strong> Per-creator detailed data exports, replacing campaign 
                    public reporting. Automated Google Drive integration for partnership documentation.
                  </li>
                  <li>
                    <strong>Campaign Workflow Enhancements:</strong>
                    <ul>
                      <li>Offer Page for brand/creator offers.</li>
                      <li>Negotiation flow (counter, accept, decline) plus live chat with the brand.</li>
                    </ul>
                  </li>
                  <li>
                    <strong>Acquisition Tech & Single Sign-On (SSO):</strong>
                    <ul>
                      <li>Integrated SSO for all Blitz ecosystem apps.</li>
                      <li>Repurposed distribution flow using Creator CRM and Blitz Apps.</li>
                    </ul>
                  </li>
                  <li>
                    <strong>Media Kit Link Enhancements:</strong> Creator links are now bigger, bolder, 
                    underlined, and colored in Blitz-blue (#0073e6).
                  </li>
                  <li>
                    <strong>Sender Email Usage:</strong> Choose from creator/user-owned emails to send 
                    CRM blasts or campaign communications.
                  </li>
                  <li>
                    <strong>Forgot/Reset Password Mechanism:</strong> Secure flow for password recovery.
                  </li>
                  <li>
                    <strong>Contract Management:</strong>
                    <ul>
                      <li>Track contract type, signatures (JSONB), and relevant dates.</li>
                      <li>Facilitate easy contract sharing within conversation threads.</li>
                    </ul>
                  </li>
                  <li>
                    <strong>New Styling & User Conversations Hub:</strong>
                    <ul>
                      <li>
                        **ConversationsHub** – Central place where users see all conversations they’re part of, 
                        with improved naming and participant list display.
                      </li>
                      <li>
                        **File/Contract/Project Sharing** – Share assets directly within conversation threads.
                      </li>
                      <li>
                        **Enhanced Unread/Read Indicators** – Better status tracking for active convos.
                      </li>
                      <li>
                        **Invite People to Join Chats** – Seamless multi-user collaboration. Non-users can 
                        sign up via invite link.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong>Enhanced Onboarding & Profile Updates:</strong>
                    <ul>
                      <li>
                        Search for existing company data to reduce duplication and speed onboarding flow.
                      </li>
                      <li>
                        New user profile photos and detail pages for improved personalization.
                      </li>
                      <li>
                        Streamlined menu and collaboration flow to encourage network effects.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong>Referral Incentives & Blitz Points:</strong> Users can earn points by 
                    inviting creators or signing up for deals, highlighting top users by referral/projects.
                  </li>
                  <li>
                    <strong>Marketplace & Search Redesign:</strong>
                    <ul>
                      <li>Show all creators agnostically at first, then filter by platform or preference.</li>
                      <li>
                        **One-Creator Flow** – Direct partnership creation (“cart-less” approach).
                      </li>
                      <li>
                        **Multi-Creator Flow** – Retained from previous versions for bigger campaign needs.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong>Homepage & Testimonials:</strong> Redesigned homepage with creator 
                    testimonials, top agencies using Blitz, and “prepared by” user profiles for campaigns.
                  </li>
                  <li>
                    <strong>Additional Carryover Features (from initial 5.0 plan):</strong>
                    <ul>
                      <li>**AI-Powered Campaign Creation** – Generate campaigns from email prompts.</li>
                      <li>**User & Creator Banking Integrations** – Manual and future Gusto/Stripe payouts (Ricky).</li>
                      <li>**Advanced Data Exports** – Per-creator detail, automated Google Drive saving.</li>
                    </ul>
                  </li>
                </ul>

                <Typography variant="h6">Improvements</Typography>
                <ul>
                  <li>Streamlined balances for brands, agencies, and creators in new financial tables (Ricky)</li>
                  <li>CRM re-engagement enhancements, including email automation and data cleaning</li>
                  <li>Modernized styling in key areas (creator profile pages, media kits, marketplace cards)</li>
                  <li>Agency Creator Connect – partial view of deals for increased collaboration without disclosing pricing</li>
                  <li>Exported data per creator for simpler reporting inside campaigns</li>
                  <li>“Offer Page” negotiation sequence (counter, accept, decline) for brand-creator alignment</li>
                  <li>Homepage refresh with public-facing campaigns, top agencies, and user testimonials</li>
                  <li>Enhanced onboarding redirects and single sign-on (SSO) for faster multi-app access</li>
                  <li>Incentivized sharing for viral growth (network effects, referral counting, “Blitz Points”)</li>
                  <li>Redesigned marketplace workflow for one-creator or multi-creator cart approach</li>
                  <li>Improved media kit link visibility (underline, Blitz-blue color)</li>
                </ul>

                <Typography variant="h6">Bug Fixes</Typography>
                <ul>
                  <li>Deleted the duplicate “@” symbol on YouTube handle displays</li>
                  <li>Resolved UI inconsistencies in partnerships/deals creator interface</li>
                  <li>Addressed minor styling issues throughout new conversation and onboarding flows</li>
                </ul>
              </>

            ) : section.id === 'beta-4' ? (
              <>
                <Typography variant="h6" sx={{ mt: 2 }}>New Features</Typography>
                <ul>
                  <li>AI Search Alpha: Initial implementation of creator discovery AI with basic filtering capabilities</li>
                  <li>Creator Connect UX: Redesigned collaboration interface with intuitive deal management (Ricky)</li>
                  <li>Financial Modules: Dedicated Money page for transactions and Collabs page for partnership tracking</li>
                  <li>Deal Factoring System: Advanced payment structuring and milestone tracking (Alex)</li>
                  <li>Scheduled Deliverables: Minimum 2-month campaign planning with integrated calendar</li>
                  <li>BlitzPay 2.0: Shared payment schedules, dual-party authorization, and link-based claims (Alex)</li>
                  <li>Marketplace Refresh: Simplified card layout, enhanced filters, and recommendation algorithm (Ricky/Alex)</li>
                  <li>Auth Unification: Consolidated credential system with Stripe-powered MRR tracking (Ricky)</li>
                  <li>Tiered Fee Structure: User status-based payment fees reflected in invoices and UI</li>
                  <li>Onboarding Overhaul: Single-page signup/login flows with reduced friction</li>
                </ul>

                <Typography variant="h6">Improvements</Typography>
                <ul>
                  <li>Platform Cleanup: Removed 18% inactive creators to improve deal success rates (Cody)</li>
                  <li>Educational Resources: Scalable onboarding documentation with agency background materials (Cody)</li>
                  <li>Distribution Network: Expanded partner integration through simplified signup flows</li>
                  <li>User Growth: Achieved 122/150 brand users and 202/225 creator users</li>
                </ul>

                <Typography variant="h6">Bug Fixes</Typography>
                <ul>
                  <li>Resolved payment schedule synchronization issues</li>
                  <li>Fixed marketplace card rendering inconsistencies</li>
                </ul>
              </>

            ) : section.id === 'beta-3' ? (
              <>
                <Typography variant="h6" sx={{ mt: 2 }}>New Features</Typography>
                <ul>
                  <li>Paid Campaign Tiers: Affiliate/gifting access with OpenAI-powered campaign facilitation</li>
                  <li>Talent Roster Import: CSV mapping tool for bulk creator onboarding (Snov.io-like interface)</li>
                  <li>Fan Engagement System: BuyMyBlitz numbers, SMS/WhatsApp integration, and tipping features</li>
                  <li>Hot Creator Algorithm: Weekly rotation of top 5 creators based on engagement metrics</li>
                  <li>Advanced Campaign Filters: Reach/demographic search powered by integrated API analytics</li>
                  <li>Multi-Currency Support: GBP, EUR, CNY, MXN, and CREDX blockchain rewards integration</li>
                  <li>Alito Campaign Manager: Email-driven campaign generation (CSV/Google Sheets/Blitz formats)</li>
                  <li>Creator Pitching System: Apollo API integration with CREDX-powered outreach (Alex)</li>
                  <li>Subscription Tiers: Alpha Free/Silver/Gold/Platinum levels with varying financing terms</li>
                  <li>Media Booking System: Creator inquiry workflow with automated CRM integration</li>
                </ul>

                <Typography variant="h6">Improvements</Typography>
                <ul>
                  <li>Search Page Overhaul: "Hot Creators" algorithm, recommended creators section, and PFPs display</li>
                  <li>Campaign Management: Pagination, collaboration features, and enhanced filtering categories</li>
                  <li>Creator Portal: Dedicated login (blitzpay.pro/creatorconnect) with GSuite integration</li>
                  <li>Payment Infrastructure: Stripe invoice automation and blitzpay.pro routing system</li>
                  <li>CRM Enhancements: Automated email triggers for campaign milestones and team notifications</li>
                  <li>Tax Compliance: Basic withholding calculations for US creators</li>
                  <li>Partner Integrations: 10K Projects, APG, and Concord feedback implementations</li>
                </ul>

                <Typography variant="h6">Bug Fixes</Typography>
                <ul>
                  <li>Resolved campaign duplication issues in CSV imports</li>
                  <li>Fixed currency conversion discrepancies in invoices</li>
                  <li>Patched SMS routing errors in creator conversations</li>
                </ul>
              </>
            ) : (
              <></>
            )}
          </Box>
        ))}
      </Container>
    </ReleaseScaffolding>
  );
}

import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Box,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Autocomplete,
} from "@mui/material";

import client from "../../../API"; // Adjust the path to your API client
import { getPONumber, parseLocalFloat } from "../../../Utils/constants";

/** 
 * STEPS Explanation:
 * 0. Select Creator (autocomplete list)
 * 1. Select Payment Method
 * 2. Enter Client Information
 * 3. Enter Invoice Details
 * 4. Confirm and Submit
 */
const steps = [
  "Select Creator",
  "Select Payment Method",
  "Enter Client Information",
  "Enter Invoice Details",
  "Confirm and Submit",
];

const InvoiceDialog = ({ open, onClose, invoiceInfo = undefined }) => {
  // 1) State for creators, chosen creator
  const [creatorList, setCreatorList] = useState([]);
  const [selectedCreator, setSelectedCreator] = useState(null);

  const [poNumber, setPONumber] = useState(invoiceInfo?.po_number ?? "");
  const [amountDue, setAmountDue] = useState(invoiceInfo?.amount_due ?? "");
  const [email, setEmail] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("");

  // New state for client information
  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [clientNotes, setClientNotes] = useState("");

  const [itemDescription, setItemDescription] = useState("");

  useEffect(() => {
    // If editing an existing invoice
    if (invoiceInfo) {
      setPONumber(invoiceInfo.po_number ?? "");
      setAmountDue(invoiceInfo.amount_due ?? "");
    }
  }, [invoiceInfo]);

  // 2) Fetch the list of creators on mount
  useEffect(() => {
    const fetchCreators = async () => {
      try {
        // Adjust to your actual API call for listing creators
        const data = await client.creators.list();
        // Example: map each item so we can use `label` in the Autocomplete
        const formatted = data.map((c) => ({
          ...c,
          label: c.creator, // or any display name
        }));
        setCreatorList(formatted);
      } catch (error) {
        console.error("Error fetching creators:", error);
      }
    };
    fetchCreators();
  }, []);

  const handleNext = () => {
    // Step-by-step validation
    if (activeStep === 0) {
      if (!selectedCreator) {
        alert("Please select a creator to continue.");
        return;
      }
    }
    else if (activeStep === 1 && !paymentMethod) {
      alert("Please select a payment method.");
      return;
    }
    else if (activeStep === 2 && (!clientName || !clientEmail)) {
      alert("Please enter client name and email.");
      return;
    }
    else if (activeStep === 3 && (!poNumber || !amountDue)) {
      alert("Please enter PO Number and Amount Due.");
      return;
    }
    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleGeneratePO = () => {
    setPONumber(getPONumber("TCC"));
  };

  const handleSubmit = async () => {
    // 3) Submit with the selected creator as mandatory
    const submissionData = {
      creator_id: selectedCreator.creator, // This must match the DB PK for creators
      po_number: poNumber,
      amount_due: parseLocalFloat(amountDue),
      email: email,
      payment_method: paymentMethod,
      client_name: clientName,
      client_email: clientEmail,
      client_notes: clientNotes,
      items: [{
        description: itemDescription,
        amount: parseLocalFloat(amountDue)
      }]
    };

    try {
      const response = await client.invoices.create(submissionData);
      if (response.status === "Success") {
        alert("Invoice created and shared successfully!");
        onClose(true);
      } else {
        throw new Error(response.message || "Error creating invoice");
      }
    } catch (error) {
      console.error("Error submitting invoice:", error);
      alert("Error submitting invoice: " + error.message);
      onClose(true);
    }
  };

  const handlePayPalPayment = async () => {
    try {
      const data = {
        amount: parseFloat(amountDue),
        po_number: poNumber,
        // If your PayPal creation also needs creator_id, add it similarly
        creator_id: selectedCreator.creator
      };
      const response = await client.invoices.createPaypalPayment(data);

      if (response.status === "Success") {
        window.location.href = response.approvalUrl;
      } else {
        alert("PayPal payment initiation failed");
      }
    } catch (error) {
      console.error("Error initiating PayPal payment:", error);
      alert("Error initiating PayPal payment");
    }
  };

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  // 4) Render appropriate fields per step
  const renderStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0: // Select Creator
        return (
          <Box>
            <Autocomplete
              options={creatorList}
              getOptionLabel={(option) => option.label || ""}
              value={selectedCreator}
              onChange={(event, newValue) => setSelectedCreator(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select a Creator"
                  variant="outlined"
                  fullWidth
                  required
                />
              )}
            />
          </Box>
        );
      case 1: // Select Payment Method
        return (
          <Box>
            <TextField
              select
              label="Payment Method"
              value={paymentMethod}
              onChange={handlePaymentMethodChange}
              fullWidth
              SelectProps={{
                native: true,
              }}
              variant="outlined"
              margin="normal"
            >
              <option value=""></option>
              <option value="ach">Bank Wire / ACH Transfer</option>
              <option value="paypal">PayPal</option>
              <option value="stripe">Stripe</option>
            </TextField>
          </Box>
        );
      case 2: // Enter Client Information
        return (
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              label="Client Name"
              value={clientName}
              onChange={(e) => setClientName(e.target.value)}
              fullWidth
              required
            />
            <TextField
              label="Client Email"
              value={clientEmail}
              onChange={(e) => setClientEmail(e.target.value)}
              fullWidth
              required
            />
            <TextField
              label="Client Notes"
              value={clientNotes}
              onChange={(e) => setClientNotes(e.target.value)}
              fullWidth
              multiline
              rows={3}
            />
          </Box>
        );
      case 3: // Enter Invoice Details
        return (
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              margin="dense"
              label="PO #"
              fullWidth
              value={poNumber}
              onChange={(e) => setPONumber(e.target.value)}
              variant="outlined"
              required
            />
            <Button variant="outlined" onClick={handleGeneratePO}>
              Generate PO
            </Button>
            <TextField
              fullWidth
              label='Invoice Item Description'
              name='item-description'
              type='text'
              multiline
              minRows={4}
              value={itemDescription}
              onChange={(e) => setItemDescription(e.target.value)}
            >
            </TextField>
            <TextField
              label="Amount Due"
              type="number"
              fullWidth
              margin="dense"
              value={amountDue}
              onChange={(e) => setAmountDue(e.target.value)}
              required
            />
            <Typography variant='subtitle1'>You will receive ${(amountDue * 0.9).toLocaleString()}</Typography>
            <TextField
              label="Share this Invoice (Email)"
              type="email"
              fullWidth
              margin="dense"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter email to share the invoice"
            />
          </Box>
        );
      case 4: // Confirm and Submit
        return (
          <Box>
            <Typography variant="h6">Review Invoice Details</Typography>
            <Typography variant="body1">
              Creator: {selectedCreator?.creator}
            </Typography>
            <Typography variant="body1">
              Payment Method: {paymentMethod}
            </Typography>
            <Typography variant="body1">
              Client Name: {clientName}
            </Typography>
            <Typography variant="body1">
              Client Email: {clientEmail}
            </Typography>
            <Typography variant="body1">
              Client Notes: {clientNotes}
            </Typography>
            <Typography variant="body1">
              PO Number: {poNumber}
            </Typography>
            <Typography variant="body1">
              Amount Due: ${amountDue}
            </Typography>
            <Typography variant="body1">
              Email to Share: {email}
            </Typography>
          </Box>
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="invoice-dialog-title"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="invoice-dialog-title">Create Invoice</DialogTitle>
      <DialogContent>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box mt={2}>{renderStepContent(activeStep)}</Box>
      </DialogContent>
      <DialogActions>
        {activeStep !== 0 && (
          <Button onClick={handleBack} variant="outlined">
            Back
          </Button>
        )}
        {activeStep === steps.length - 1 ? (
          <>
            {paymentMethod === "stripe" && (
              <Button onClick={handleSubmit} color="primary" variant="contained">
                Submit
              </Button>
            )}
            {paymentMethod === "paypal" && (
              <Button onClick={handlePayPalPayment} color="secondary" variant="contained">
                Pay with PayPal
              </Button>
            )}
            {(paymentMethod === "ach" || paymentMethod === "bank_wire") && (

              <Button onClick={handleSubmit} color="primary">
                Submit
              </Button>
            )}
          </>
        ) : (
          <Button onClick={handleNext} variant="contained">
            Next
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default InvoiceDialog;

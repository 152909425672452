import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Box,
  Snackbar,
  Alert,
  CircularProgress,
  Paper,
  Typography,
  ThemeProvider,
  CssBaseline,
  Container,
  Toolbar,
  Tabs,
  Tab,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { useNavigate, useSearchParams, Link as RouterLink } from "react-router-dom";
import { useMutation } from "react-query";
import client from "../../API";
import useAuth from "../../Hooks/use-auth";
import { validateEmail, validatePassword } from "../../Utils";
import routes from "../../Config/routes";
import { lightTheme } from "../../Utils/baseTheme";
import HomeHeader from "../../Components/HomeHeader";
// Import necessary components for Google Login
import { GoogleLogin } from "@react-oauth/google";
import { useCreatorAuth } from "../../Hooks/creator-use-auth";
import API from "../../API";
import TabPanelHidden from "../../Components/TabPanelHidden";
import useAlertDialog from "../../Components/useAlertDialog";
import AlertDialog from "../../Components/AlertDialog";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const { authorize, setCurrentUser } = useAuth();
  const { login: creatorLogin } = useCreatorAuth(); // For creator authentication
  const [isGoogleLoading, setIsGoogleLoading] = useState(false); // State for Google login loading
  const { dialogState, openDialog, closeDialog } = useAlertDialog();
  const [oauthAsk, setOauthAsk] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  // Check if the 'redirect' query parameter is set to 'edit'
  const redirect = searchParams.get('redirect');
  const service_id = searchParams.get('service_id');
  const state = searchParams.get('state');

  useEffect(() => {
    if(service_id){
      setOauthAsk(true);
    }
  }, [searchParams])

  const GoToCreatorDesiredPage = (loginRedirect) => {
    // Handle path-based redirects first
    if (redirect?.startsWith('/')) {
      navigate(redirect);
      return;
    }
  
    // Handle special cases
    switch (redirect) {
      case 'edit':
        const newQueryParams = new URLSearchParams();
        newQueryParams.set('high', 'platforms');
        navigate(`${routes.creatorConnectEdit}?${newQueryParams.toString()}`);
        break;
      case 'oauth':
        const redirectQuery = new URLSearchParams();
        if (!service_id) {
          setSnackbarMessage("An error occurred while processing this redirect.");
          setSnackbarSeverity("warning");
          setOpenSnackbar(true);
          return;
        }
        redirectQuery.set('service_id', service_id);
        if (state) redirectQuery.set('state', searchParams.get('state'));
        navigate(`${routes.creatorConnectOauth}?${redirectQuery.toString()}`);
        break;
      default:
        navigate(routes.creatorConnectStart);
    }
  };

  // Get current tab value from search parameters; default to "0" if none exists
  const tabValue = searchParams.get('tab') || 'brand';
  const setTabValue = (newValue) => {
    setSearchParams((params) => ({ ...params, tab: newValue }));
  };

  const { mutate: login, isLoading } = useMutation(client.users.login, {
    onSuccess: (data) => {
      authorize(data.token);
      setCurrentUser(data.user);
      navigate(routes.dashboard);
    },
    onError: (error) => {
      console.error("An error occurred during login: ", error);
      let errorMessage = "An error occurred, please try again.";
      if (error.code === "ERR_NETWORK") {
        errorMessage = "Network busy. Try again in a few seconds.";
      } else {
        if (error.response && error.response.data) {
          errorMessage = error?.response?.data?.error || errorMessage;
        }
      }
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    },
  });

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (email.length === 0) {
      setSnackbarMessage("Email field is required!");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    if (!validateEmail(email)) {
      setSnackbarMessage("Invalid email address!");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    if (password.length === 0) {
      setSnackbarMessage("Password field is required!");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    if (!validatePassword(password)) {
      setSnackbarMessage("Password should have 6 or more characters!");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    login({
      email,
      password,
    });
  };

  // Handle Creator Google Login Success
  const handleCreatorGoogleLoginSuccess = async (response) => {
    setIsGoogleLoading(true);
    try {
      const token = response.credential;
      const data = await API.creatorConnect.login({ token });
      const { redirect: loginRedirect, ...loginData } = data;
      creatorLogin(loginData);
      openDialog("Success", "You have logged in successfully",
        () => GoToCreatorDesiredPage(loginRedirect),
        () => GoToCreatorDesiredPage(loginRedirect), "Ok", null);
    } catch (error) {
      setSnackbarMessage(`Login failed: ${error?.response?.data?.error || error.message}`);
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    } finally {
      setIsGoogleLoading(false);
    }
  };

  // Handle Creator Google Login Failure
  const handleCreatorGoogleLoginFailure = (error) => {
    console.error("Failed to login with Google:", error);
    setSnackbarMessage("Google login failed, please try again.");
    setSnackbarSeverity("error");
    setOpenSnackbar(true);
  };

  const handleRegister = () => {
    navigate(routes.requestAccess);
  };

  const onboardLink = routes.creatorConnectOnboardApp + '?service_id=' + service_id + (!!state ? '&state=' + state : '')

  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <AlertDialog alertState={dialogState}></AlertDialog>
      <HomeHeader />
      <Toolbar></Toolbar>
      <Dialog id='alert-creator-oauth' open={oauthAsk}>
        <DialogTitle>Connecting to an App with Blitz</DialogTitle>
        <DialogContent>
          <DialogContentText>To use this App, you'll need a CreatorConnect Account</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" component={RouterLink} to={onboardLink}>I'm new to CreatorConnect</Button>
          <Button variant="text" onClick={()=>setOauthAsk(false)}>I have a CreatorConnect account</Button>
        </DialogActions>
      </Dialog>
      <Container maxWidth="sm">
        <Paper elevation={3} sx={{ mt: 4 }}>
          <Tabs
            value={tabValue}
            onChange={(e, newValue) => setTabValue(newValue)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab value='brand' label='For Brands' />
            <Tab value='creator' label='For Creators' />
          </Tabs>
          <Box sx={{ padding: 4 }}>
            <TabPanelHidden value={tabValue} index='brand'>
              <Box sx={{ textAlign: "center", mb: 3 }}>
                <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                  Welcome back
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Sign in to continue with Blitz
                </Typography>
              </Box>
              <form onSubmit={handleLogin}>
                <TextField
                  disabled={isLoading}
                  autoComplete="username"
                  id="username"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  required
                  sx={{ marginBottom: 2 }}
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  disabled={isLoading}
                  required
                  autoComplete="current-password"
                  id="current-password"
                  label="Password"
                  variant="outlined"
                  type="password"
                  sx={{ marginBottom: 2 }}
                  fullWidth
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />

                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <input type="checkbox" id="remember-me" name="remember-me" />
                  <label htmlFor="remember-me" style={{ marginLeft: 8 }}>
                    Remember me
                  </label>
                </Box>
                <Link component={RouterLink} to={routes.passwordForgot} color='secondary'>Forgot your password?</Link>

                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  sx={{ marginBottom: 2 }}
                  startIcon={
                    isLoading && <CircularProgress size={20} color="inherit" />
                  }
                  disabled={isLoading}
                  type="submit"
                  fullWidth
                >
                  Login
                </Button>
              </form>
            </TabPanelHidden>

            <TabPanelHidden value={tabValue} index='creator'>
              <Box sx={{ textAlign: "center", mb: 3 }}>
                <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                  Welcome back
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Sign in with Google to access CreatorConnect
                </Typography>
              </Box>
              {/* Creator Sign In with Google */}
              <Box sx={{ textAlign: "center", mt: 3, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <GoogleLogin
                  onSuccess={handleCreatorGoogleLoginSuccess}
                  onError={handleCreatorGoogleLoginFailure}
                />
              </Box>
            </TabPanelHidden>
            {/* Contact Us Button */}
            <Box sx={{ textAlign: "center", mt: 3 }}>
              <Typography variant="body2">
                Don't have an account?
              </Typography>
              <Button
                color="primary"
                onClick={handleRegister}
                sx={{ mt: 1 }}
              >
                Contact Us
              </Button>
            </Box>
          </Box>
        </Paper>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Container>
    </ThemeProvider>
  );
};

export default Login;

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Button,
  Skeleton,
  styled,
  alpha
} from '@mui/material';
import { Visibility as ViewIcon, ChatBubbleOutline as ChatIcon } from '@mui/icons-material';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import API from '../../../API';
import ChatModal from '../Users/chatmodal';

// Styled container for each user feed item
const FeedItem = styled(Paper)(({ theme }) => ({
  width: '100%',
  maxWidth: '600px',
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
  borderRadius: '16px',
  transition: 'transform 0.2s, box-shadow 0.2s',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[4],
  },
  background: `linear-gradient(145deg, ${alpha(
    theme.palette.background.paper,
    0.8
  )}, ${alpha(theme.palette.background.default, 0.2)})`,
  backdropFilter: 'blur(10px)',
}));

// Styled Avatar for each user
const FeedAvatar = styled(Avatar)(({ theme }) => ({
  width: 48,
  height: 48,
  border: `2px solid ${theme.palette.primary.main}`,
  boxShadow: theme.shadows[2],
}));

const TopUsersFeed = () => {
  const navigate = useNavigate();
  const [chatOpen, setChatOpen] = useState(false);
  const [targetUserId, setTargetUserId] = useState(null);

  // Fetch the top users from the API
  const {
    data: topUsers,
    isLoading,
    error
  } = useQuery({
    queryKey: ['users', 'topUsers'],
    queryFn: API.users.topUsers,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 30,
    initialData: [],
    initialDataUpdatedAt: 0
  });

  // Only include users with at least one project
  const filteredTopUsers = topUsers.filter(user => user.total_projects > 0);

  /**
   * Compute an arbitrary blitz score.
   * We are applying different weights for each project type:
   * - Campaigns: weight 1
   * - Partnerships: weight 1.5
   * - Payouts: weight 2
   *
   * You can adjust these weights as needed.
   */
  const computeBlitzScore = (user) => {
    const campaignWeight = 1;
    const partnershipWeight = 1.5;
    const payoutWeight = 2;
    return (
      (user.campaign_count * campaignWeight) +
      (user.partnership_count * partnershipWeight) +
      (user.payout_count * payoutWeight)
    );
  };

  // Handle errors from the query
  if (error) {
    return (
      <Box sx={{ mb: 4, p: 2, border: '1px solid red', borderRadius: 2 }}>
        <Typography color="error">
          Error loading Top Users Feed: {error.message}
        </Typography>
      </Box>
    );
  }

  // Display a friendly message if no users have any projects
  if (!isLoading && filteredTopUsers.length === 0) {
    return (
      <Box sx={{ mb: 4, p: 2, textAlign: 'center' }}>
        <Typography variant="h6" color="text.secondary">
          No top users found. Keep up the hustle!
        </Typography>
      </Box>
    );
  }

  // Navigate to the user's profile page
  const handleViewUser = (userId) => {
    navigate(`/user/${userId}`);
  };

  // Open the chat modal with the target user
  const handleChatWithUser = (userId) => {
    setTargetUserId(userId);
    setChatOpen(true);
  };

  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h4" sx={{ mb: 3, fontWeight: 700, color: 'text.primary' }}>
        Top Users
      </Typography>
      <List sx={{ p: 0 }}>
        {isLoading ? (
          Array.from({ length: 3 }).map((_, i) => (
            <FeedItem key={i} elevation={0}>
              <Skeleton variant="rectangular" width="100%" height={80} />
            </FeedItem>
          ))
        ) : (
          filteredTopUsers.map((user) => {
            const blitzScore = computeBlitzScore(user);
            return (
              <FeedItem key={user.user_id} elevation={0}>
                <ListItem alignItems="flex-start" sx={{ p: 0 }}>
                  <ListItemAvatar>
                    <FeedAvatar
                      alt={`${user.first_name} ${user.last_name}`}
                      src={user.pfphref || '/default-avatar.png'}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <>
                        <Typography variant="subtitle2" color="text.primary">
                          {user.first_name} {user.last_name}
                        </Typography>
                        {user.company_name && (
                          <Typography variant="caption" color="text.secondary">
                            {user.company_name}
                          </Typography>
                        )}
                      </>
                    }
                    secondary={
                      <span>
                        <Typography variant="body2" color="text.primary" component='span' sx={{marginInlineEnd:1}}>
                          Total Projects: {user.total_projects}
                        </Typography>
                        <Typography variant="body2" color="text.primary" component='span'>
                          Blitz Score: {blitzScore.toFixed(1)}
                        </Typography>
                      </span>
                    }
                    sx={{ m: 0 }}
                  />
                </ListItem>
                {/* Action buttons for user profile and chat */}
                <Box sx={{ display: 'flex', gap: 1, mt: 1.5 }}>
                  <Button
                    size="small"
                    variant="outlined"
                    startIcon={<ViewIcon />}
                    sx={{ borderRadius: 20 }}
                    onClick={() => handleViewUser(user.user_id)}
                  >
                    View Profile
                  </Button>
                  <Button
                    size="small"
                    variant="outlined"
                    startIcon={<ChatIcon />}
                    sx={{ borderRadius: 20 }}
                    onClick={() => handleChatWithUser(user.user_id)}
                  >
                    Chat
                  </Button>
                </Box>
              </FeedItem>
            );
          })
        )}
      </List>
      <ChatModal
        open={chatOpen}
        onClose={() => setChatOpen(false)}
        recipientId={targetUserId}
      />
    </Box>
  );
};

export default TopUsersFeed;

// ClientInvoiceActionDialog.jsx (just like InvoiceActionDialog, but for client invoices)
import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography } from '@mui/material';

function ClientInvoiceActionDialog({ open, onClose, invoiceId, onSuccess, clientInvoiceActionMutation }) {
  const [loading, setLoading] = useState(false);

  const handleAction = (action) => {
    setLoading(true);
    clientInvoiceActionMutation(
      { invoiceId, action },
      {
        onSuccess: (data) => {
          onSuccess(data.message, data.status);
          setLoading(false);
          onClose();
        },
        onError: (error) => {
          setLoading(false);
        },
      }
    );
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Invoice Action</DialogTitle>
      <DialogContent>
        <Typography>Do you want to approve or decline this client invoice?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleAction("approve")} disabled={loading}>
          Approve
        </Button>
        <Button onClick={() => handleAction("decline")} disabled={loading} color="error">
          Decline
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ClientInvoiceActionDialog;

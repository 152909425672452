import React, { useState } from 'react';
import {
  Box,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Button,
  Skeleton,
  styled,
  alpha
} from '@mui/material';
import {
  Visibility as ViewIcon,
  ChatBubbleOutline as ChatIcon
} from '@mui/icons-material';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import API from '../../../API';
import ChatModal from '../Users/chatmodal';

const FeedItem = styled(Paper)(({ theme }) => ({
  width: '100%',
  maxWidth: '600px',
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
  borderRadius: '16px',
  transition: 'transform 0.2s, box-shadow 0.2s',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[4],
  },
  background: `linear-gradient(145deg, ${alpha(theme.palette.background.paper, 0.8)}, ${alpha(theme.palette.background.default, 0.2)})`,
  backdropFilter: 'blur(10px)',
}));

const FeedAvatar = styled(Avatar)(({ theme }) => ({
  width: 48,
  height: 48,
  border: `2px solid ${theme.palette.primary.main}`,
  boxShadow: theme.shadows[2],
}));

const ZapFeedForUser = () => {
  const navigate = useNavigate();

  const [visibleCount, setVisibleCount] = useState(5);
  const [chatOpen, setChatOpen] = useState(false);
  const [activeConversationId, setActiveConversationId] = useState(null);
  const [targetUserId, setTargetUserId] = useState(null);

  // Fetch the ZapFeed data
  const {
    data: feed,
    isLoading,
    error
  } = useQuery({
    queryKey: ['user', 'zapFeed'],
    queryFn: API.users.fetchZapFeed,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 30,
    initialData: [],
    initialDataUpdatedAt: 0
  });

  // Flatten all user activities
  const getAllActivities = () => {
    if (!feed || !Array.isArray(feed)) return [];
    return feed
      .flatMap(user =>
        (user.activity || []).map(activity => ({
          ...activity,
          userId: user.id, // Assuming the feed includes a user.id
          userName: `${user.first_name} ${user.last_name}`,
          companyName: user.company_name,
          pfphref: user.pfphref || '/default-avatar.png',
        }))
      )
      .sort((a, b) => new Date(b.date) - new Date(a.date));
  };

  const activities = getAllActivities();
  const visibleActivities = activities.slice(0, visibleCount);

  // Error handling
  if (error) {
    return (
      <Box sx={{ mb: 4, p: 2, border: '1px solid red', borderRadius: 2 }}>
        <Typography color="error">
          Error loading ZapFeed: {error.message}
        </Typography>
      </Box>
    );
  }

  // No activities
  if (!isLoading && activities.length === 0) {
    return (
      <Box sx={{ mb: 4, p: 2, textAlign: 'center' }}>
        <Typography variant="h6" color="text.secondary">
          No activities found. Start creating campaigns or partnerships!
        </Typography>
      </Box>
    );
  }

  // Format the "heading" portion
  const formatActivitySummary = (activity) => {
    const date = new Date(activity.date).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
    });

    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Typography variant="subtitle2" color="text.primary">
          {activity.userName}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {activity.companyName}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          •
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {date}
        </Typography>
      </Box>
    );
  };

  // Activity details
  const getActivityContent = (activity) => {
    switch (activity.type) {
      case 'payout':
        return `Sent a BlitzPay of $${activity.details.amount}`;
      case 'campaign':
        return `Launched "${activity.details.name}" campaign`;
      case 'partnership':
        return `Started new partnership with ${activity.details.partner}`;
      default:
        return '';
    }
  };

  // "Load More" handling
  const handleShowMore = () => setVisibleCount(prev => prev + 5);

  // Navigate to user profile
  const handleViewUser = (userId) => {
    navigate(`/user/${userId}`);
  };

  // Check or create conversation & open Chat Modal
  const handleChatWithUser = (userId) => {
    // userId is the "recipient"
    setTargetUserId(userId);
    setChatOpen(true);
  };

  return (
    <Box sx={{ mb: 4 }}>
     

      {/* List of activities */}
      <List sx={{ p: 0 }}>
        {visibleActivities.map((activity, index) => (
          <FeedItem key={index} elevation={0}>
            <ListItem alignItems="flex-start" sx={{ p: 0 }}>
              <ListItemAvatar>
                <FeedAvatar alt={activity.userName} src={activity.pfphref} />
              </ListItemAvatar>
              <ListItemText
                primary={formatActivitySummary(activity)}
                secondary={
                  <Typography
                    variant="body1"
                    sx={{ mt: 0.5, color: 'text.primary', lineHeight: 1.4 }}
                  >
                    {getActivityContent(activity)}
                  </Typography>
                }
                sx={{ m: 0 }}
              />
            </ListItem>

            {/* Buttons: View User + Chat */}
            <Box sx={{ display: 'flex', gap: 1, mt: 1.5 }}>
              <Button
                size="small"
                variant="outlined"
                startIcon={<ViewIcon />}
                sx={{ borderRadius: 20 }}
                onClick={() => handleViewUser(activity.userId)}
              >
                View User
              </Button>
              <Button
                size="small"
                variant="outlined"
                startIcon={<ChatIcon />}
                sx={{ borderRadius: 20 }}
                onClick={() => handleChatWithUser(activity.userId)}
              >
                Chat with User
              </Button>
            </Box>
          </FeedItem>
        ))}
        
        {/* Loading skeletons */}
        {isLoading &&
          Array(3).fill().map((_, i) => (
            <FeedItem key={i} elevation={0}>
              <Skeleton variant="rectangular" width="100%" height={80} />
            </FeedItem>
          ))
        }
      </List>

      {/* "Load More" button */}
      {visibleCount < activities.length && (
        <Button
          onClick={handleShowMore}
          variant="contained"
          sx={{
            mt: 2,
            borderRadius: 20,
            px: 4,
            fontWeight: 600,
            textTransform: 'none',
          }}
        >
          Load More
        </Button>
      )}

      {/* Chat Modal */}
      <ChatModal
        open={chatOpen}
        onClose={() => setChatOpen(false)}
        recipientId={targetUserId}
      />
    </Box>
  );
};

export default ZapFeedForUser;

import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stepper,
  Step,
  StepLabel,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Alert,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Box
} from '@mui/material';
import { useMutation } from 'react-query';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import client from '../../../../../API';
import { validateEmail, validatePassword, capitalizeFirstLetter } from "../../../../../Utils";

const countryOptions = [
  "United States", "Canada", "United Kingdom", "Australia", "Germany",
  "France", "Italy", "Spain", "Japan", "China", "India", "Brazil",
  "Mexico", "Russia", "South Korea", "South Africa"
];

const industryOptions = [
  "Fashion", "Tech", "Food", "Beauty", "Sports", "Travel", "Finance",
  "Health", "Education", "Entertainment", "Automotive", "Real Estate",
  "Hospitality", "Retail", "Manufacturing", "Telecommunications", "Marketing Agency"
];

const RegisterPopup = ({ open, onClose, initialEmail, campaignName }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const steps = ["Personal Information", "Company Information", "Business Details"];

  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    email: initialEmail || "",
    password: "",
    confirmPassword: "",
    companyName: "",
    streetAddress: "",
    city: "",
    state: "",
    country: "",
    phoneNumber: "",
    industry: "",
    bio: "",
    businessType: "",
    isAgency: "",
    monthlySpend: "",
    teamAccounts: "",
    paymentMethod: "",
    referralSource: "",
    referringUserId: null,
  });

  const { mutate: register } = useMutation(client.companies.demoRegister, {
    onSuccess: () => {
      onClose();
    },
    onError: (error) => {
      let errorMessage = "An error occurred, please try again.";
      if (error.code === "ERR_NETWORK") {
        errorMessage = "Network error! Please check your connection.";
      } else if (error.response?.data?.error) {
        errorMessage = error.response.data.error;
      }
      setError(errorMessage);
      setIsLoading(false);
    }
  });

  const handleNext = () => {
    const requiredFields = getRequiredFieldsForStep(activeStep);
    if (!validateStep(requiredFields)) return;
    
    if (activeStep === steps.length - 1) {
      handleSubmit();
    } else {
      setActiveStep(prev => prev + 1);
    }
  };

  const handleBack = () => setActiveStep(prev => prev - 1);

  const validateStep = (requiredFields) => {
    for (const field of requiredFields) {
      if (!userInfo[field]) {
        setError(`${capitalizeFirstLetter(field)} is required`);
        return false;
      }
    }

    if (activeStep === 0) {
      if (!validateEmail(userInfo.email)) {
        setError("Please enter a valid email address");
        return false;
      }
      if (!validatePassword(userInfo.password)) {
        setError("Password must be at least 6 characters");
        return false;
      }
      if (userInfo.password !== userInfo.confirmPassword) {
        setError("Passwords do not match");
        return false;
      }
    }
    
    setError('');
    return true;
  };

  const handleSubmit = () => {
    if (!userInfo.referralSource && !userInfo.referringUserId) {
      setError("Please tell us how you heard about us");
      return;
    }

    setIsLoading(true);
    register(userInfo);
  };

  const handleChange = (e) => {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
  };

  const getRequiredFieldsForStep = (step) => {
    switch (step) {
      case 0: return ['firstName', 'lastName', 'email', 'password', 'confirmPassword', 'phoneNumber'];
      case 1: return ['companyName', 'streetAddress', 'city', 'state', 'country', 'industry'];
      case 2: return ['businessType', 'isAgency', 'monthlySpend', 'teamAccounts', 'paymentMethod'];
      default: return [];
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <TextField fullWidth label="First Name" name="firstName" margin="dense" 
              value={userInfo.firstName} onChange={handleChange} />
            <TextField fullWidth label="Last Name" name="lastName" margin="dense" 
              value={userInfo.lastName} onChange={handleChange} />
            <TextField fullWidth label="Email" name="email" margin="dense" 
              value={userInfo.email} disabled />
            <TextField fullWidth type="password" label="Password" name="password" margin="dense" 
              value={userInfo.password} onChange={handleChange} />
            <TextField fullWidth type="password" label="Confirm Password" name="confirmPassword" margin="dense" 
              value={userInfo.confirmPassword} onChange={handleChange} />
            <TextField fullWidth label="Phone Number" name="phoneNumber" margin="dense" 
              value={userInfo.phoneNumber} onChange={handleChange} />
          </>
        );
      case 1:
        return (
          <>
            <TextField fullWidth label="Company Name" name="companyName" margin="dense" 
              value={userInfo.companyName} onChange={handleChange} />
            <TextField fullWidth label="Street Address" name="streetAddress" margin="dense" 
              value={userInfo.streetAddress} onChange={handleChange} />
            <TextField fullWidth label="City" name="city" margin="dense" 
              value={userInfo.city} onChange={handleChange} />
            <TextField fullWidth label="State/Province" name="state" margin="dense" 
              value={userInfo.state} onChange={handleChange} />
            <FormControl fullWidth margin="dense">
              <InputLabel>Country</InputLabel>
              <Select name="country" value={userInfo.country} onChange={handleChange}>
                {countryOptions.map(option => (
                  <MenuItem key={option} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="dense">
              <InputLabel>Industry</InputLabel>
              <Select name="industry" value={userInfo.industry} onChange={handleChange}>
                {industryOptions.map(option => (
                  <MenuItem key={option} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField fullWidth label="About Your Company" name="bio" margin="dense" 
              value={userInfo.bio} onChange={handleChange} multiline rows={4} 
              placeholder="Briefly describe your company's mission and values" />
          </>
        );
      case 2:
        return (
          <>
            <FormControl fullWidth margin="dense">
              <InputLabel>Business Structure</InputLabel>
              <Select name="businessType" value={userInfo.businessType} onChange={handleChange}>
                <MenuItem value="One Company">Single Legal Entity</MenuItem>
                <MenuItem value="Multiple">Multiple Subsidiaries</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth margin="dense">
              <InputLabel>Are You an Agency?</InputLabel>
              <Select name="isAgency" value={userInfo.isAgency} onChange={handleChange}>
                <MenuItem value="Yes">Yes, we're an agency</MenuItem>
                <MenuItem value="No">No, we're a brand</MenuItem>
              </Select>
            </FormControl>
            <TextField fullWidth label="Monthly Marketing Budget ($)" name="monthlySpend" margin="dense" 
              value={userInfo.monthlySpend} onChange={handleChange} type="number" />
            <TextField fullWidth label="Team Members Needing Access" name="teamAccounts" margin="dense" 
              value={userInfo.teamAccounts} onChange={handleChange} type="number" />
            <FormControl fullWidth margin="dense">
              <InputLabel>Preferred Payment Method</InputLabel>
              <Select name="paymentMethod" value={userInfo.paymentMethod} onChange={handleChange}>
                <MenuItem value="Credit Card">Credit Card</MenuItem>
                <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
                <MenuItem value="PayPal">PayPal</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>
            <TextField fullWidth label="How Did You Hear About Us?" name="referralSource" margin="dense" 
              value={userInfo.referralSource} onChange={handleChange} 
              disabled={!!userInfo.referringUserId} />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ 
        background: 'linear-gradient(45deg, #000000 30%,rgb(241, 6, 6) 90%)',
        color: 'white',
        textAlign: 'center',
        py: 3
      }}>
        <Box component="img" 
          src="https://storage.googleapis.com/blitzccmc.appspot.com/1/New%20Project%282%29.jpg" 
          alt="Blitz Logo"
          sx={{ height: 50, mb: 2 }}
        />
        <Typography variant="h5">
          Access {campaignName || 'Your Campaign'}
        </Typography>
      </DialogTitle>
      
      <DialogContent>
        <Box sx={{ my: 3 }}>
          <Typography variant="h6" gutterBottom sx={{ color: '#ffffff', fontWeight: 'bold' }}>
            Welcome to Blitz Collaboration Platform
          </Typography>
          <Typography variant="body1" paragraph>
            Complete your registration to access {campaignName || 'this campaign'} and unlock powerful features:
          </Typography>

          <List dense sx={{ mb: 3 }}>
            {[
              {
                title: "Creator Management",
                content: "Full lifecycle management from discovery to performance tracking"
              },
              {
                title: "BlitzPay System",
                content: "Automated payments with multiple currency support"
              },
              {
                title: "Marketplace Access",
                content: "Connect with 50,000+ verified creators worldwide"
              },
              {
                title: "Real-time Analytics",
                content: "Campaign performance dashboards with export capabilities"
              }
            ].map((feature, index) => (
              <ListItem key={index} sx={{ alignItems: 'flex-start', px: 0 }}>
                <ListItemIcon sx={{ minWidth: 40, pt: '8px' }}>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={<Typography variant="subtitle1" fontWeight="500">{feature.title}</Typography>}
                  secondary={feature.content}
                />
              </ListItem>
            ))}
          </List>

          <Divider sx={{ my: 2 }} />

          <Stepper activeStep={activeStep} alternativeLabel sx={{ my: 3 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel sx={{ '& .MuiStepLabel-label': { fontWeight: 500 } }}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        {renderStepContent(activeStep)}

        <Divider sx={{ my: 3 }} />

        <Box sx={{ textAlign: 'center', mt: 3 }}>
          <Typography variant="body2" color="text.secondary">
            Need assistance? Contact our support team:
          </Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            ✉️ <a href="mailto:partnerships@useblitz.co" style={{ color: '#003399' }}>
              partnerships@useblitz.co
            </a><br />
            📞 <a href="tel:+19176315694" style={{ color: '#003399' }}>
              +1 (917) 631-5694
            </a>
          </Typography>
          <Typography variant="caption" display="block" sx={{ mt: 2, color: '#666' }}>
            By registering, you agree to our Terms of Service and Privacy Policy
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions sx={{ p: 3 }}>
        <Button 
          onClick={activeStep === 0 ? onClose : handleBack} 
          disabled={isLoading}
          sx={{ minWidth: 100 }}
        >
          {activeStep === 0 ? 'Cancel' : 'Back'}
        </Button>
        <Button 
          onClick={handleNext} 
          color="primary" 
          variant="contained"
          disabled={isLoading}
          sx={{ minWidth: 140, fontWeight: 'bold' }}
        >
          {activeStep === steps.length - 1 ? (
            <>
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Complete Registration'
              )}
            </>
          ) : 'Continue'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RegisterPopup;
import { drawerWidth } from "./constants";

export const globalStyles = {
    root: {
        display: "flex",
        flexDirection: "row",
        height: "100vh",
        overflow: "hidden",
    },
    navbar: {
        width: drawerWidth,
        flexShrink: 0,
        zIndex: 2,
    },
    singleLine: { wordBreak: 'keep-all', whiteSpace: 'nowrap' },
    wholeHeightMinusToolbar: {
        '@media (min-width:0px)': {
            minHeight: { xs: 'calc(100vh - 56px)', md: '100vh' },
            '@media (orientation: landscape)': {
                minHeight: { xs: 'calc(100vh - 48px)', md: '100vh' },
            },
        },
        '@media (min-width:600px)': {
            minHeight: { xs: 'calc(100vh - 64px)', md: '100vh' }
        }
    },
    stickyTopMinusToolbar: {
        '@media (min-width:0px)': {
            top: { xs: '56px', md: '0px' },
            '@media (orientation: landscape)': {
                top: { xs: '48px', md: '0px' },
            },
        },
        '@media (min-width:600px)': {
            top: { xs: '64px', md: '0px' }
        }
    },
    wholeHeightMinusTop: {
        '@media (min-width:0px)': {
            height: 'calc(100vh - 56px)',
            '@media (orientation: landscape)': {
                height: 'calc(100vh - 48px)',
            },
        },
        '@media (min-width:600px)': {
            height: 'calc(100vh - 64px)'
        }
    },
    wholeHeightMinusTopPlusMargin: {
        scrollSnapAlign: 'start',
        '@media (min-width:0px)': {
            height: 'calc(100vh - 56px)',
            scrollMarginTop: '56px',
            '@media (orientation: landscape)': {
                height: 'calc(100vh - 48px)',
                scrollMarginTop: '48px',
            },
        },
        '@media (min-width:600px)': {
            height: 'calc(100vh - 64px)',
            scrollMarginTop: '64px'
        }
    },
    minHeightMinusTop: {
        '@media (min-width:0px)': {
            minHeight: 'calc(100vh - 56px)',
            '@media (orientation: landscape)': {
                minHeight: 'calc(100vh - 48px)',
            },
        },
        '@media (min-width:600px)': {
            minHeight: 'calc(100vh - 64px)'
        }
    }
}
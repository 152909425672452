import { Navigate, useLocation } from "react-router-dom";
import React from 'react'

export default function RedirectWithQuery({ to, newQuery }) {
    const location = useLocation();

    // Parse current query parameters
    const searchParams = new URLSearchParams(location.search);

    // Add the new query parameter
    if (newQuery) {
        Object.entries(newQuery).forEach(([key, value]) => {
            searchParams.set(key, value);
        });
    }

    // Build the final URL
    const finalUrl = searchParams.toString() ? `${to}?${searchParams.toString()}` : to;

    return <Navigate to={finalUrl} replace />;
};

import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  MenuItem,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab,
  Box,
  Select,
} from "@mui/material";
import { useMutation, useQuery } from "react-query";
import client from "../../../API"; // your API client
import { isCompanyBalanceValid, isValidCompanySeats } from "../../../Utils";
import useAlertDialog from "../../../Components/useAlertDialog";

const CompanyDialog = ({ open, onClose, info = undefined }) => {
  // -------------------------------
  // Shared state for "Edit" tab
  // -------------------------------
  const [balance, setBalance] = useState("");
  const [accountStatus, setAccountStatus] = useState("Free");
  const [companyName, setCompanyName] = useState("");
  const [creditLine, setCreditLine] = useState("");
  const [seats, setSeats] = useState("");
  const [phphref, setPhphref] = useState("");
  const [nominalInterestRate, setNominalInterestRate] = useState("10");

  // Vendor fields
  const [vendorSystem, setVendorSystem] = useState(false);
  const [vendorSystemName, setVendorSystemName] = useState("");
  const [vendorPaymentTerm, setVendorPaymentTerm] = useState("30");
  const [blitzVendorId, setBlitzVendorId] = useState("");

  const { dialogState, openDialog } = useAlertDialog();

  // -------------------------------
  // Tab handling
  // -------------------------------
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    if (open && info) {
      // Pre-fill fields when editing an existing company
      setBalance(info?.balance ?? "");
      setAccountStatus(info.account_status || "Free");
      setCompanyName(info?.name || "");
      setCreditLine(info?.credit_line || "");
      setSeats(info?.seats?.join(",") || "");
      setPhphref(info?.phphref || "");
      setNominalInterestRate(info?.nominal_interest_rate ?? "10");
      setVendorSystem(!!info?.vendor_system);
      setVendorSystemName(info?.vendor_system_name || "");
      setVendorPaymentTerm(
        info?.vendor_payment_term != null ? String(info.vendor_payment_term) : "30"
      );
      setBlitzVendorId(info?.blitz_vendor_id || "");
    }
  }, [open, info]);

  // -------------------------------
  // React Query: Mutations
  // -------------------------------
  const { mutate: editCompany, isLoading } = useMutation(client.companies.edit, {
    onSuccess: async () => {
      onClose(true);
    },
    onError: (error) => {
      openDialog("Error", error?.response?.data?.error || error.message);
      console.error("Error editing company:", error);
    },
  });

  const { mutate: createCompany, isLoading: createLoading } = useMutation(
    client.companies.create,
    {
      onSuccess: async () => {
        onClose(true);
      },
      onError: (error) => {
        openDialog("Error", error?.response?.data?.error || error.message);
        console.error("Error creating company:", error);
      },
    }
  );

  // -------------------------------
  // React Query: Invoices fetching
  // -------------------------------
  // Simple local state for invoice filter
  const [invoiceStatusFilter, setInvoiceStatusFilter] = useState("");

  const {
    data: invoiceData = [],
    isLoading: invoicesLoading,
    refetch: refetchInvoices,
  } = useQuery(
    ["companyInvoices", info?.id, invoiceStatusFilter],
    () => client.companies.invoice(info.id, invoiceStatusFilter),
    {
      // Only fetch if we are on the "Invoices" tab and we have a valid company "info"
      enabled: tabIndex === 1 && !!info?.id,
    }
  );

  // -------------------------------
  // Form submission (Edit tab)
  // -------------------------------
  const handleSubmit = () => {
    if ((balance === "" && balance !== 0) || !accountStatus || (seats === "" && seats !== 0)) {
      openDialog("Error", "Please fill in all required fields.");
      return;
    }
    if (!isCompanyBalanceValid(balance)) {
      openDialog("Error", "Please input a valid balance! (Max Length is 8 digits)");
      return;
    }
    if (!isValidCompanySeats(seats)) {
      openDialog("Error", "Please input valid seats! (Format: 1,2,3)");
      return;
    }
    if (accountStatus.length === 0) {
      openDialog("Error", "Please input a valid account status!");
      return;
    }

    const parsedNominalInterest = parseFloat(nominalInterestRate);
    if (
      isNaN(parsedNominalInterest) ||
      parsedNominalInterest < 0 ||
      parsedNominalInterest > 100
    ) {
      openDialog("Error", "Please input a valid nominal interest rate (0-100)!");
      return;
    }

    const submissionData = {
      balance: parseFloat(balance),
      seats: seats.split(","),
      account_status: accountStatus,
      company_name: companyName,
      credit_line: accountStatus !== "Free" ? parseFloat(creditLine) : 0,
      phphref,
      nominal_interest_rate: parsedNominalInterest,
      vendor_system: vendorSystem,
      vendor_system_name: vendorSystemName,
      vendor_payment_term: parseInt(vendorPaymentTerm, 10) || 30,
      blitz_vendor_id: blitzVendorId || null,
    };

    if (info) {
      // Editing existing company
      editCompany({ companyId: info.id, params: submissionData });
    } else {
      // Creating a new company
      createCompany(submissionData);
    }
  };

  // -------------------------------
  // Render
  // -------------------------------
  return (
    <Dialog open={open} onClose={() => onClose(false)} maxWidth="md" fullWidth>
      <DialogTitle>
        {info ? "Edit Company" : "Create Company"}
      </DialogTitle>

      {/* Tabs */}
      <Tabs value={tabIndex} onChange={handleTabChange}>
        <Tab label="Edit" />
        <Tab label="Invoices" />
      </Tabs>

      {/* Tab Panel: EDIT */}
      {tabIndex === 0 && (
        <DialogContent dividers>
          <TextField
            label="Company Name"
            fullWidth
            margin="dense"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            required
          />
          <TextField
            label="Balance"
            type="number"
            fullWidth
            placeholder="12345678.12"
            sx={{ marginTop: "12px" }}
            value={balance}
            onChange={(e) => setBalance(e.target.value)}
            required
          />
          <TextField
            label="Seats"
            sx={{ marginTop: "12px" }}
            fullWidth
            placeholder="1,2,3,4"
            value={seats}
            onChange={(e) => setSeats(e.target.value)}
            required
          />
          <TextField
            select
            label="Account Status"
            fullWidth
            margin="dense"
            value={accountStatus}
            onChange={(e) => setAccountStatus(e.target.value)}
            required
          >
            {["AlphaFree", "Free", "Silver", "Gold", "Platinum", "Agency"].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>

          {accountStatus !== "Free" && (
            <TextField
              label="Credit Line"
              type="number"
              fullWidth
              margin="dense"
              value={creditLine}
              onChange={(e) => setCreditLine(e.target.value)}
              required
            />
          )}

          <TextField
            label="PHP href"
            fullWidth
            margin="dense"
            value={phphref}
            onChange={(e) => setPhphref(e.target.value)}
          />

          <TextField
            label="Nominal Interest Rate (%)"
            type="number"
            fullWidth
            margin="dense"
            value={nominalInterestRate}
            onChange={(e) => setNominalInterestRate(e.target.value)}
            required
          />

          {/* Vendor system */}
          <FormControlLabel
            control={
              <Checkbox
                checked={vendorSystem}
                onChange={(e) => setVendorSystem(e.target.checked)}
              />
            }
            label="Vendor System?"
            sx={{ mt: 1 }}
          />
          {vendorSystem && (
            <TextField
              label="Vendor System Name"
              fullWidth
              margin="dense"
              value={vendorSystemName}
              onChange={(e) => setVendorSystemName(e.target.value)}
            />
          )}

          <TextField
            label="Vendor Payment Term (days)"
            type="number"
            fullWidth
            margin="dense"
            value={vendorPaymentTerm}
            onChange={(e) => setVendorPaymentTerm(e.target.value)}
          />

          {/* Blitz Vendor ID */}
          <TextField
            label="Blitz Vendor ID"
            fullWidth
            margin="dense"
            value={blitzVendorId}
            onChange={(e) => setBlitzVendorId(e.target.value)}
          />
        </DialogContent>
      )}

      {/* Tab Panel: INVOICES */}
      {tabIndex === 1 && (
        <DialogContent dividers>
          {info?.id ? (
            <>
              {/* Filtering */}
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <span style={{ marginRight: 8 }}>Filter by Status:</span>
                <Select
                  size="small"
                  value={invoiceStatusFilter}
                  onChange={(e) => {
                    setInvoiceStatusFilter(e.target.value);
                    // Trigger refetch
                    refetchInvoices();
                  }}
                  displayEmpty
                  sx={{ minWidth: 120 }}
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="approved">Approved</MenuItem>
                  <MenuItem value="paid">Paid</MenuItem>
                </Select>
                {invoicesLoading && <CircularProgress size={24} sx={{ ml: 2 }} />}
              </Box>

              {/* Invoice List */}
              {!invoicesLoading && invoiceData.length === 0 && (
                <p>No invoices found.</p>
              )}
              {invoiceData.map((inv) => (
                <Box
                  key={inv.id}
                  sx={{
                    mb: 2,
                    p: 2,
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                  }}
                >
                  <div>
                    <strong>Invoice ID:</strong> {inv.id}
                  </div>
                  <div>
                    <strong>PO #:</strong> {inv.po_number}
                  </div>
                  <div>
                    <strong>Status:</strong> {inv.status}
                  </div>
                  <div>
                    <strong>Amount Due:</strong> {inv.amount_due}
                  </div>
                  <div>
                    <strong>Days Late:</strong> {inv.days_late}
                  </div>
                  {inv.user_info && (
                    <div>
                      <strong>User:</strong> {inv.user_info.first_name} {inv.user_info.last_name} (
                      {inv.user_info.email})
                    </div>
                  )}
                </Box>
              ))}
            </>
          ) : (
            <p>No company info found. Please create the company first.</p>
          )}
        </DialogContent>
      )}

      {/* Dialog Actions (only show "Submit" on the Edit tab) */}
      <DialogActions>
        <Button onClick={() => onClose(false)} color="primary">
          Cancel
        </Button>
        {tabIndex === 0 && (
          <Button onClick={handleSubmit} color="primary" disabled={isLoading || createLoading}>
            {isLoading || createLoading ? <CircularProgress size="24px" /> : "Submit"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CompanyDialog;

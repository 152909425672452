import React, { useState } from "react";
import {
  Grid,
  Card as MuiCard,
  CardContent,
  Typography,
  Button,
  Box,
  Avatar,
  Chip,
  useTheme,
  alpha,
  Paper,
  List,
  ListItem,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  CircularProgress,
  Backdrop,
  FormControl,
  Link as MuiLink,
} from "@mui/material";
import { TrendingUp, Link as LinkIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import BookingDialog from "../../../Misc/bookingdialog";
import client from "../../../../API";
import { platformRatesListFromCreator } from "../../../../Utils/constants.js";
import profilePhoto from "../../../../Components/globalAssets/ppfLogo.png";
import { FaYoutube, FaInstagram, FaTiktok } from "react-icons/fa";

const PLATFORM_ICONS = {
  youtube: FaYoutube,
  instagram: FaInstagram,
  tiktok: FaTiktok,
};

const CreatorCard = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  marginBottom: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
  padding: theme.spacing(2),
  transition: "transform 0.2s, box-shadow 0.2s",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: theme.shadows[4],
  },
}));

const DetailGrid = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  marginTop: theme.spacing(0.5),
  flexWrap: "wrap",
}));

const StyledCard = styled(MuiCard)(({ theme, accentcolor }) => ({
  borderRadius: "16px",
  transition: "transform 0.3s, box-shadow 0.3s",
  "&:hover": {
    transform: "translateY(-8px)",
    boxShadow: `0 12px 24px ${alpha(theme.palette.primary.main, 0.15)}`,
  },
  position: "relative",
  overflow: "visible",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  "& .MuiAvatar-root": {
    width: 100,
    height: 100,
    margin: "16px auto 8px", // Moved inside the card with proper spacing
    border: `3px solid ${theme.palette.background.paper}`,
    boxShadow: theme.shadows[2],
  },
  "& .MuiCardContent-root": {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const AgencyDetailsPanel = ({ agencyDetails, viewMode, accentColor }) => {
  const theme = useTheme();
  const [bookingDialogOpen, setBookingDialogOpen] = useState(false);
  const [selectedCreator, setSelectedCreator] = useState(null);
  const [promotionData, setPromotionData] = useState([]);
  const [isLoadingCreator, setIsLoadingCreator] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [platformFilter, setPlatformFilter] = useState("all");
  const [sortOption, setSortOption] = useState("reachDesc");

  const creators = agencyDetails.creators || [];

  const handleInviteClick = async (creatorName) => {
    setIsLoadingCreator(true);
    try {
      const creatorData = await client.creators.fetchDetails(creatorName);
      setSelectedCreator(creatorData.creator);
      setPromotionData(platformRatesListFromCreator(creatorData.creator));
      setBookingDialogOpen(true);
    } catch (error) {
      console.error("Failed to fetch creator details:", error);
      alert("Failed to fetch creator details. Please try again.");
    } finally {
      setIsLoadingCreator(false);
    }
  };

  const handleBookingSubmission = async (bookingDetails) => {
    try {
      const result = await client.partnerships.book({
        name: `${bookingDetails.partnershipName} - ${bookingDetails.selectedPromotion}`,
        creator: bookingDetails.creatorId,
        description: bookingDetails.details,
        email: bookingDetails.email,
        proposal_date: bookingDetails.selectedDate,
        total_sum: bookingDetails.rate,
        unique_code: `promo_${bookingDetails.creatorId}_${new Date().getTime()}`,
        notes: bookingDetails.details,
        finish_date: bookingDetails.selectedDate,
      });
      console.log("Booking successful:", result);
      alert("Booking successful! Check your email for details.");
      setBookingDialogOpen(false);
      return true;
    } catch (error) {
      console.error("Error submitting booking:", error);
      alert(
        `Error submitting booking: ${error?.response?.data?.error || error?.message}`
      );
      return false;
    }
  };

  const calculateTotalReach = (creator) => {
    return [
      parseInt(creator.youtube) || 0,
      parseInt(creator.instagram) || 0,
      parseInt(creator.tiktok) || 0,
    ].reduce((acc, val) => acc + val, 0);
  };

  const getFilteredSortedCreators = () => {
    let filtered = [...creators];

    if (searchQuery.trim() !== "") {
      filtered = filtered.filter((c) =>
        c.creator.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    if (platformFilter !== "all") {
      filtered = filtered.filter((c) => {
        switch (platformFilter) {
          case "youtube":
            return parseInt(c.youtube) > 0;
          case "instagram":
            return parseInt(c.instagram) > 0;
          case "tiktok":
            return parseInt(c.tiktok) > 0;
          default:
            return true;
        }
      });
    }

    return filtered.sort((a, b) => {
      const totalA = calculateTotalReach(a);
      const totalB = calculateTotalReach(b);
      switch (sortOption) {
        case "nameAsc":
          return a.creator.localeCompare(b.creator);
        case "nameDesc":
          return b.creator.localeCompare(a.creator);
        case "reachAsc":
          return totalA - totalB;
        case "reachDesc":
          return totalB - totalA;
        default:
          return 0;
      }
    });
  };

  const filteredCreators = getFilteredSortedCreators();

  const renderListMode = () => (
    <List dense sx={{ py: 0 }}>
      {filteredCreators.map((creator, index) => (
        <CreatorCard key={creator.creator || index}>
          <ListItem disablePadding>
            <Avatar
              src={creator.pfphref || profilePhoto}
              sx={{
                width: 56,
                height: 56,
                boxShadow: 3,
                border: "2px solid",
                borderColor: "background.paper",
                mr: 2,
              }}
            />
            <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
              <Typography variant="h6" fontWeight={600}>
                @{creator.creator}
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1, mt: 0.5 }}>
                <TrendingUp fontSize="small" sx={{ color: "text.secondary" }} />
                <Typography variant="body2" color="text.secondary">
                  {calculateTotalReach(creator).toLocaleString()} total reach
                </Typography>
              </Box>
              {creator.youtube_link && (
                <MuiLink
                  href={creator.youtube_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="body2"
                  sx={{ mt: 0.5 }}
                >
                  YouTube Channel
                </MuiLink>
              )}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
              <DetailGrid>
                {creator.youtube && (
                  <Chip
                    component={creator.youtube_link ? "a" : "div"}
                    href={creator.youtube_link}
                    target={creator.youtube_link ? "_blank" : undefined}
                    clickable={!!creator.youtube_link}
                    avatar={
                      <Avatar>
                        <PLATFORM_ICONS.youtube />
                      </Avatar>
                    }
                    label={parseInt(creator.youtube).toLocaleString()}
                    color="error"
                    size="small"
                    variant="outlined"
                  />
                )}
                {creator.instagram && (
                  <Chip
                    component={creator.instagram_link ? "a" : "div"}
                    href={creator.instagram_link}
                    target={creator.instagram_link ? "_blank" : undefined}
                    clickable={!!creator.instagram_link}
                    avatar={
                      <Avatar>
                        <PLATFORM_ICONS.instagram />
                      </Avatar>
                    }
                    label={parseInt(creator.instagram).toLocaleString()}
                    color="secondary"
                    size="small"
                    variant="outlined"
                  />
                )}
                {creator.tiktok && (
                  <Chip
                    component={creator.tiktok_link ? "a" : "div"}
                    href={creator.tiktok_link}
                    target={creator.tiktok_link ? "_blank" : undefined}
                    clickable={!!creator.tiktok_link}
                    avatar={
                      <Avatar>
                        <PLATFORM_ICONS.tiktok />
                      </Avatar>
                    }
                    label={parseInt(creator.tiktok).toLocaleString()}
                    color="info"
                    size="small"
                    variant="outlined"
                  />
                )}
              </DetailGrid>
              <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
                <Button
                  variant="contained"
                  sx={{ bgcolor: accentColor }}
                  href={`https://www.useblitz.co/creators/${creator.creator}`}
                  target="_blank"
                >
                  Media Kit
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => handleInviteClick(creator.creator)}
                >
                  Invite
                </Button>
              </Box>
            </Box>
          </ListItem>
        </CreatorCard>
      ))}
    </List>
  );

  const renderGridMode = () => (
    <Grid container spacing={2}>
      {filteredCreators.map((creator, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
          <StyledCard accentcolor={accentColor}>
            <Avatar
              src={creator.pfphref || profilePhoto}
              alt={creator.creator}
              sx={{ bgcolor: accentColor }}
            />
            <CardContent>
              <Typography variant="h6" gutterBottom>
                @{creator.creator}
              </Typography>
              <Box
                sx={{
                  my: 2,
                  display: "flex",
                  justifyContent: "center",
                  gap: 1,
                  flexWrap: "wrap",
                }}
              >
                {creator.youtube && (
                  <Chip
                    component={creator.youtube_link ? "a" : "div"}
                    href={creator.youtube_link}
                    target={creator.youtube_link ? "_blank" : undefined}
                    clickable={!!creator.youtube_link}
                    avatar={
                      <Avatar>
                        <PLATFORM_ICONS.youtube />
                      </Avatar>
                    }
                    label={parseInt(creator.youtube).toLocaleString()}
                    color="error"
                    size="small"
                    variant="outlined"
                    sx={{ borderRadius: 1 }}
                  />
                )}
                {creator.instagram && (
                  <Chip
                    component={creator.instagram_link ? "a" : "div"}
                    href={creator.instagram_link}
                    target={creator.instagram_link ? "_blank" : undefined}
                    clickable={!!creator.instagram_link}
                    avatar={
                      <Avatar>
                        <PLATFORM_ICONS.instagram />
                      </Avatar>
                    }
                    label={parseInt(creator.instagram).toLocaleString()}
                    color="secondary"
                    size="small"
                    variant="outlined"
                    sx={{ borderRadius: 1 }}
                  />
                )}
                {creator.tiktok && (
                  <Chip
                    component={creator.tiktok_link ? "a" : "div"}
                    href={creator.tiktok_link}
                    target={creator.tiktok_link ? "_blank" : undefined}
                    clickable={!!creator.tiktok_link}
                    avatar={
                      <Avatar>
                        <PLATFORM_ICONS.tiktok />
                      </Avatar>
                    }
                    label={parseInt(creator.tiktok).toLocaleString()}
                    color="info"
                    size="small"
                    variant="outlined"
                    sx={{ borderRadius: 1 }}
                  />
                )}
              </Box>
              <Chip
                icon={<TrendingUp />}
                label={`${calculateTotalReach(creator).toLocaleString()} Total Reach`}
                sx={{
                  mb: 2,
                  bgcolor: alpha(accentColor, 0.1),
                  color: accentColor,
                  width: "100%",
                  borderRadius: 1,
                }}
              />
              <Box sx={{ display: "grid", gap: 1, mt: "auto" }}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => handleInviteClick(creator.creator)}
                  sx={{
                    bgcolor: accentColor,
                    "&:hover": { bgcolor: alpha(accentColor, 0.9) },
                  }}
                >
                  Collaborate
                </Button>
                <Button
                  fullWidth
                  variant="outlined"
                  href={`https://www.useblitz.co/creators/${creator.creator}`}
                  target="_blank"
                  startIcon={<LinkIcon />}
                >
                  Profile
                </Button>
              </Box>
            </CardContent>
          </StyledCard>
        </Grid>
      ))}
    </Grid>
  );

  if (!creators || creators.length === 0) {
    return <Typography>No creators found.</Typography>;
  }

  return (
    <Box sx={{ p: 3 }}>
      <Paper sx={{ p: 2, mb: 3, display: "flex", gap: 2, flexWrap: "wrap" }}>
        <TextField
          label="Search Creators"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ width: 200 }}
        />
        <FormControl size="small" sx={{ minWidth: 120 }}>
          <InputLabel>Platform</InputLabel>
          <Select
            value={platformFilter}
            onChange={(e) => setPlatformFilter(e.target.value)}
          >
            <MenuItem value="all">All Platforms</MenuItem>
            <MenuItem value="youtube">YouTube</MenuItem>
            <MenuItem value="instagram">Instagram</MenuItem>
            <MenuItem value="tiktok">TikTok</MenuItem>
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ minWidth: 130 }}>
          <InputLabel>Sort By</InputLabel>
          <Select
            value={sortOption}
            onChange={(e) => setSortOption(e.target.value)}
          >
            <MenuItem value="nameAsc">Name (A-Z)</MenuItem>
            <MenuItem value="nameDesc">Name (Z-A)</MenuItem>
            <MenuItem value="reachAsc">Reach Ascending</MenuItem>
            <MenuItem value="reachDesc">Reach Descending</MenuItem>
          </Select>
        </FormControl>
      </Paper>

      {filteredCreators.length === 0 ? (
        <Typography variant="h6" textAlign="center" sx={{ p: 4 }}>
          No creators match your current filters.
        </Typography>
      ) : viewMode === "list" ? (
        renderListMode()
      ) : (
        renderGridMode()
      )}

      <BookingDialog
        open={bookingDialogOpen}
        onClose={() => setBookingDialogOpen(false)}
        submitBooking={handleBookingSubmission}
        creator={selectedCreator}
        promotionData={promotionData}
      />

      <Backdrop open={isLoadingCreator} sx={{ zIndex: 1300 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default AgencyDetailsPanel;

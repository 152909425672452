// InternalBookingPage.jsx
import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Button,
  TextField,
  MenuItem,
  Typography,
  Backdrop,
  CircularProgress,
  Avatar,
} from '@mui/material';
import { RateField } from '../../../../Components/RateField';
import {
  getPlatformAndPromotion,
  platformRatesListFromCreator,
  parseLocalFloat,
} from '../../../../Utils/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import API from '../../../../API';
import useAlertDialog from '../../../../Components/useAlertDialog';

const InternalBookingPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { dialogState, openDialog, closeDialog } = useAlertDialog();

  // Retrieve the creator data from router state (or fallback)
  const creatorData = state?.creator || {};

  const [formData, setFormData] = useState({
    selectedPromotion: '',
    rate: 50,
    selectedDate: new Date().toISOString().substring(0, 10),
    details: '',
    partnershipName: '',
    promotionType: '',
    promotionPlatform: '',
  });

  // Get available promotions based on the creator’s available rate keys
  const promotions = useMemo(
    () => platformRatesListFromCreator(creatorData),
    [creatorData]
  );

  const [warning, setWarning] = useState('');

  // Mutation for booking a partnership using the booking endpoint
  const { mutate: postPartnership, isLoading: loadingPartnership } = useMutation(
    API.partnerships.book,
    {
      onError: (error) => {
        openDialog(
          'Error',
          <>
            An error occurred while creating your partnership:
            <br />
            {error?.response?.data?.error || error.message}
          </>,
          closeDialog,
          closeDialog,
          'Ok'
        );
      },
      onSuccess: (data) => {
        openDialog(
          'Partnership Builder',
          <>Your partnership has been created successfully</>,
          () => navigate(`/partnerships/${data.id}`),
          () => navigate(`/partnerships/${data.id}`),
          'Ok'
        );
      },
    }
  );

  // Generic input change handler
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle changes from the RateField (slider/input)
  const handleSliderChange = (event, newValue) => {
    setFormData((prev) => ({ ...prev, rate: newValue }));
  };

  // Handle promotion select change, updating related fields automatically
  const handlePromoChange = (value) => {
    if (value) {
      const platPromo = getPlatformAndPromotion(value);
      setFormData((prev) => ({
        ...prev,
        selectedPromotion: value,
        rate: creatorData[value]?.toFixed(2) || '',
        promotionType: platPromo.promotion,
        promotionPlatform: platPromo.platform,
      }));
    }
  };

  // Calculate the minimum acceptable rate (falling back to 50 if none is set)
  const minRate = formData.selectedPromotion
    ? creatorData[formData.selectedPromotion]
    : 50.0;

  useEffect(() => {
    if (formData.rate < minRate) {
      setWarning(
        `Warning: Offers below the creator's asking range ($${minRate}) are usually rejected.`
      );
    } else {
      setWarning('');
    }
  }, [formData.rate, minRate]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Prepare the data to be sent to the API
    const dataToSubmit = {
      creator: creatorData.creator, // Adjust this if the creator's ID is under a different key (e.g. creatorData.id)
      name: `${formData.partnershipName} - ${formData.promotionPlatform} - ${formData.promotionType}`,
      description: formData.details,
      proposal_date: formData.selectedDate,
      total_sum: parseLocalFloat(formData.rate),
      notes: formData.details,
      finish_date: formData.selectedDate,
      promotionType: formData.promotionType,
      promotionPlatform: formData.promotionPlatform,
      selectedPromotion: formData.selectedPromotion,
    };

    postPartnership(dataToSubmit);
  };

  return (
    <Box sx={{ p: 3, maxWidth: 800, mx: 'auto' }}>
      {loadingPartnership && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {/* Creator info header with avatar */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Avatar
          alt={creatorData.creator}
          src={creatorData.pfphref}
          sx={{ width: 56, height: 56, mr: 2 }}
        />
        <Typography variant="h4">
          Partner with {creatorData.creator} today!
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Partnership Name"
          fullWidth
          name="partnershipName"
          value={formData.partnershipName}
          onChange={handleChange}
          margin="dense"
          required
        />
        <TextField
          select
          label="Promotion Type"
          fullWidth
          name="selectedPromotion"
          value={formData.selectedPromotion}
          onChange={(e) => handlePromoChange(e.target.value)}
          margin="dense"
          required
        >
          {promotions.map((promo) => (
            <MenuItem key={`promoselect-${promo.rateKey}`} value={promo.rateKey}>
              {promo.label}
            </MenuItem>
          ))}
        </TextField>
        <Typography gutterBottom>Your Budget: ${formData.rate}</Typography>
        <RateField
          error={!!warning}
          required
          helperText={warning}
          label="Budget"
          variant="outlined"
          minValue={Math.round(minRate / 2)}
          fullWidth
          value={formData.rate}
          onChange={(value) => handleSliderChange(null, value)}
          sx={{ my: 2 }}
        />
        <TextField
          id="date-picker"
          label="Select Date"
          type="date"
          fullWidth
          variant="outlined"
          name="selectedDate"
          value={formData.selectedDate}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
          margin="dense"
          required
        />
        <TextField
          label="Details"
          fullWidth
          multiline
          rows={4}
          name="details"
          value={formData.details}
          onChange={handleChange}
          margin="dense"
          required
        />
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="contained" onClick={() => navigate(-1)}>
            Cancel
          </Button>
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            disabled={loadingPartnership}
          >
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default InternalBookingPage;

import axios from "axios";
import { getAuthToken, removeAuthToken } from "./token-util";
import { removeAuthToken as removeCreatorToken, getAuthToken as getCreatorToken } from "./creatorTokenUtil";
import { ConfigValue } from "../Config";

// TODO: Due to windows timeout was set to 15000
const Axios = axios.create({
  baseURL: ConfigValue.PUBLIC_REST_API_ENDPOINT,
  timeout: 120000, //150000000,
  headers: {
    "Content-Type": "application/json",
  },
});
// Change request data/error here
Axios.interceptors.request.use(
  (config) => {
    const token = getAuthToken() || getCreatorToken();
    //console.log(token);
    if(token)
      config.headers.Authorization = `Bearer ${token}`;

    // Add X-Testing-Key header if running on localhost and testing key is available
    if (window.location.hostname === "localhost") {
      const testingKey = process.env.REACT_APP_TESTING_KEY;
      if (testingKey) {
        config.headers["X-Testing-Key"] = testingKey;
      }
    }
    
    config.paramsSerializer= {
      indexes: null, // no brackets at all
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { config } = error;
    if (error.response && !config.ignoreAuthError && (error.response.status === 401 || error.response.status === 403 || (error.response.status === 422 && error.response.data?.error === 'Invalid token'))) {
      removeAuthToken();
      removeCreatorToken();
      window.location.reload();
    }
    if (config.accept404 && error.response.status === 404){
      return {data:null};
    }
    return Promise.reject(error);
  }
);

export class HttpClient {
  static async get(url, params = null, config = {}) {
    const response = await Axios.get(url, { params, ...config });
    return response.data;
  }

  static async post(url, data, config = {}) {
    const response = await Axios.post(url, data, { ...config });
    return response.data;
  }

  static async put(url, data, config = {}) {
    const response = await Axios.put(url, data, { ...config });
    return response.data;
  }

  static async delete(url, config = {}) {
    const response = await Axios.delete(url, { ...config });
    return response.data;
  }
}

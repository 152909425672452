import { ConfigValue } from "../Config";
import Cookies from "js-cookie";

export const AUTH_TOKEN_KEY = ConfigValue.AUTH_CREATOR_TOKEN_KEY;
export const AUTH_USER_INFO = ConfigValue.AUTH_CREATOR_USER_INFO;

export const USER_AUTH_TOKEN_KEY = ConfigValue.AUTH_TOKEN_KEY;
export const USER_AUTH_USER_INFO = ConfigValue.AUTH_USER_INFO;

export const getAuthToken = () => {
  if (typeof window === undefined) {
    return null;
  }
  const cookie = Cookies.get(AUTH_TOKEN_KEY);
  if (cookie) {
    return cookie;
  }
  return null;
};

export const getAuthInfo = () => {
  if (typeof window === undefined) {
    return null;
  }
  const cookie = Cookies.get(AUTH_USER_INFO);
  if (cookie) {
    try {
      return JSON.parse(cookie);
    } catch (error) {
      console.log('Error while processing cookies', error)
      Cookies.remove(AUTH_TOKEN_KEY);
      Cookies.remove(AUTH_USER_INFO);
      return null;
    }
  } else
    return null;
};

export function setAuthToken(token) {
  Cookies.set(AUTH_TOKEN_KEY, token.token, { expires: 1 });
  Cookies.set(AUTH_USER_INFO, JSON.stringify(token), { expires: 1 });
  //Cookies.set(USER_AUTH_TOKEN_KEY, token.token, { expires: 1 });
  Cookies.remove(USER_AUTH_TOKEN_KEY);
  Cookies.remove(USER_AUTH_USER_INFO);
  //console.log("Setting cookies!: ", AUTH_TOKEN_KEY, Cookies.get(AUTH_TOKEN_KEY));
}

export function removeAuthToken() {
  Cookies.remove(AUTH_TOKEN_KEY);
  Cookies.remove(AUTH_USER_INFO);
}

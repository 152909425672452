import React, { useState, useCallback, useEffect, useRef } from "react";
import client from "../../../../API";
import {
  Box,
  Typography,
  Button,
  Tabs,
  Tab,
  Paper,
  MenuItem,
  Menu,
  ListItemIcon,
  ListItemText,
  IconButton,
  AppBar,
  Container,
  Stepper,
  Step,
  StepLabel,
  Divider,
  TextField,
  Checkbox,
  Avatar,
  ListItemAvatar,
  List,
  ListItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Backdrop,
  CircularProgress,
  FormControlLabel,
  LinearProgress,
  Grid,
  Card,
  CardContent,
  Collapse,
  Chip,
  InputAdornment,
  Switch,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DownloadIcon from "@mui/icons-material/Download";
import GoogleIcon from "@mui/icons-material/Google";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Share } from "@mui/icons-material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import profilePhoto from "../../../../Components/globalAssets/ppfLogo.png";
import TabPanel from "../../../../Components/TabPanel.js";
import CampaignProjectionsPanel from "../individualcampaigns/campaignTabs/campaignProjections.js";
import useIsDesktop from "../../../../Hooks/useIsDesktop.js";
import useAuth from "../../../../Hooks/use-auth.js";
import SelectAssetDialog from "../../../../Components/tools/selectfile";
import Tools from "./tools.js";
import TimelineTab from "./timeline";
import PageTitle from "../../../../Components/PageTitle";
import {
  CreatorCampaignStatus,
  parseLocalFloat,
  TrackLinks,
  wait,
} from "../../../../Utils/constants.js";
import { TimelineStatus } from "../../../../Utils/constants";
import { RateField } from "../../../../Components/RateField.js";
import BrandNegotiationDialog from "./brandnegotiatedialog.js";
import ShareCampaignDialog from "./share.js";

const RainbowButton = styled(Button)(({ theme }) => ({
  background:
    "linear-gradient(45deg, red, orange, yellow, green, blue, indigo, violet)",
  backgroundSize: "400% 400%",
  animation: "rainbow 5s ease infinite",
  color: "white",
  "@keyframes rainbow": {
    "0%": {
      backgroundPosition: "0% 50%",
    },
    "50%": {
      backgroundPosition: "100% 50%",
    },
    "100%": {
      backgroundPosition: "0% 50%",
    },
  },
}));

const GreenButton = styled(Button)(({ theme }) => ({
  backgroundColor: "green",
  color: "white",
  "&:hover": {
    backgroundColor: "darkgreen",
  },
}));

const ActionCard = ({ title, value, color }) => (
  <Card
    sx={{
      minWidth: 200,
      flex: 1,
      border: `2px solid ${color}`,
      backgroundColor: "transparent",
    }}
  >
    <CardContent>
      <Typography variant="subtitle2" gutterBottom color="text.primary">
        {title}
      </Typography>
      <Typography variant="h6" color="text.primary">
        {value}
      </Typography>
    </CardContent>
  </Card>
);

const socialPlatforms = [
  "TikTok",
  "Instagram",
  "YouTube",
  "Facebook",
  "X/Twitter",
];

const usageDurations = [
  { value: "30", label: "30 days" },
  { value: "60", label: "60 days" },
  { value: "90", label: "90 days" },
  { value: "180", label: "180 days" },
  { value: "360", label: "360 days" },
];

// Update the StructuredBriefEditor component
const StructuredBriefEditor = ({ brief, onBriefChange }) => {
  const [requirements, setRequirements] = useState({
    creativeBrief: "",
    crossposting: [],
    usage: "",
  });

  useEffect(() => {
    if (brief) {
      const parsed = parseFromText(brief);
      setRequirements(parsed);
    }
  }, [brief]);

  const parseFromText = (text) => {
    const newRequirements = {
      creativeBrief: "",
      crossposting: [],
      usage: "",
    };

    let currentSection = "";

    text.split("\n").forEach((line) => {
      if (line.startsWith("# Creative")) {
        currentSection = "creative";
      } else if (line.startsWith("# Additional Requirements")) {
        currentSection = "additional";
      } else if (currentSection === "creative") {
        newRequirements.creativeBrief += line + "\n";
      } else if (currentSection === "additional") {
        if (line.startsWith("Crosspost(s):")) {
          newRequirements.crossposting = line
            .replace("Crosspost(s):", "")
            .trim()
            .split(", ");
        }
        if (line.startsWith("Usage:")) {
          newRequirements.usage = line.replace("Usage:", "").trim().split(" ")[0];
        }
      }
    });

    newRequirements.creativeBrief = newRequirements.creativeBrief.trim();
    return newRequirements;
  };

  const convertToText = () => {
    return `# Creative
${requirements.creativeBrief}

# Additional Requirements
Crosspost(s): ${requirements.crossposting.join(", ")}
Usage: ${requirements.usage} days`;
  };

  const handleCreativeChange = (e) => {
    const updated = {
      ...requirements,
      creativeBrief: e.target.value,
    };
    setRequirements(updated);
    onBriefChange(convertToText(updated));
  };

  const handleCrosspostChange = (platform) => {
    const updated = {
      ...requirements,
      crossposting: requirements.crossposting.includes(platform)
        ? requirements.crossposting.filter((p) => p !== platform)
        : [...requirements.crossposting, platform],
    };
    setRequirements(updated);
    onBriefChange(convertToText(updated));
  };

  const handleUsageChange = (event) => {
    const updated = {
      ...requirements,
      usage: event.target.value,
    };
    setRequirements(updated);
    onBriefChange(convertToText(updated));
  };

  return (
    <Box sx={{ mt: 2 }}>
      <TextField
        fullWidth
        multiline
        minRows={3}
        label="Creative Brief"
        value={requirements.creativeBrief}
        onChange={handleCreativeChange}
        sx={{ mb: 3 }}
      />

      <Typography variant="subtitle1" gutterBottom>
        Usage Duration
      </Typography>
      <TextField
        select
        fullWidth
        value={requirements.usage}
        onChange={handleUsageChange}
        label="Usage Duration"
        sx={{ mb: 3 }}
      >
        {usageDurations.map((duration) => (
          <MenuItem key={duration.value} value={duration.value}>
            {duration.label}
          </MenuItem>
        ))}
      </TextField>

      <Typography variant="subtitle1" gutterBottom>
        Crossposting Platforms
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
        {socialPlatforms.map((platform) => (
          <FormControlLabel
            key={platform}
            control={
              <Checkbox
                checked={requirements.crossposting.includes(platform)}
                onChange={() => handleCrosspostChange(platform)}
                color="primary"
              />
            }
            label={platform}
          />
        ))}
      </Box>
    </Box>
  );
};

const ActionCards = ({
  campaignName,
  brief,
  totalSpend,
  totalViews,
  totalEngagement,
  creatorCount,
  isEditable,
  isEditingBrief,
  editedBrief,
  setEditedBrief,
  handleSaveCampaignDetails,
  setEditingBrief,
}) => {
  const [editMode, setEditMode] = useState("simple");

  const parseFromText = (text) => {
    const newRequirements = {
      crossposting: [],
      usage: "",
    };

    text.split("\n").forEach((line) => {
      if (line.startsWith("Crosspost(s):")) {
        newRequirements.crossposting = line
          .replace("Crosspost(s):", "")
          .trim()
          .split(", ");
      }
      if (line.startsWith("Usage:")) {
        newRequirements.usage = line.replace("Usage:", "").trim().split(" ")[0];
      }
    });

    return newRequirements;
  };

  const convertToText = (requirements) => {
    return `# Additional Requirements
Crosspost(s): ${requirements.crossposting.join(", ")}
Usage: ${requirements.usage} days`;
  };

  return (
    <Grid container spacing={2} sx={{ mb: 3 }}>
      <Grid item xs={12} md={6}>
        <Card sx={{ height: "100%", position: "relative" }}>
          <CardContent>
            <Box display="flex" alignItems="center" gap={1} mb={2}>
              <Typography variant="subtitle2">Campaign Brief</Typography>
              <Box sx={{ ml: "auto", display: "flex", gap: 1 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={editMode === "structured"}
                      onChange={(e) => {
                        const newMode = e.target.checked
                          ? "structured"
                          : "simple";
                        if (newMode === "structured") {
                          // Switch from raw to structured
                          setEditedBrief(
                            convertToText(parseFromText(editedBrief))
                          );
                        } else {
                          // Switch from structured to raw
                          setEditedBrief(editedBrief);
                        }
                        setEditMode(newMode);
                      }}
                      color="primary"
                      sx={{
                        "& .MuiSwitch-thumb": {
                          backgroundColor:
                            editMode === "structured" ? "#1976d2" : "#fff",
                        },
                        "& .Mui-checked": {
                          transform: "translateX(20px)",
                        },
                      }}
                    />
                  }
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="body2"
                        sx={{
                          color:
                            editMode === "structured"
                              ? "primary.main"
                              : "text.secondary",
                        }}
                      >
                        {editMode === "structured"
                          ? "Structured Mode"
                          : "Simple Mode"}
                      </Typography>
                    </Box>
                  }
                  sx={{ mr: 0 }}
                />
                {isEditable && (
                  <IconButton
                    size="small"
                    onClick={() =>
                      isEditingBrief
                        ? handleSaveCampaignDetails()
                        : setEditingBrief(true)
                    }
                  >
                    {isEditingBrief ? <CheckIcon /> : <EditIcon />}
                  </IconButton>
                )}
              </Box>
            </Box>
            {isEditingBrief ? (
              editMode === "structured" ? (
                <StructuredBriefEditor
                  brief={editedBrief}
                  onBriefChange={setEditedBrief}
                />
              ) : (
                <TextField
                  fullWidth
                  multiline
                  minRows={6}
                  value={editedBrief}
                  onChange={(e) => setEditedBrief(e.target.value)}
                  helperText="Edit raw brief content directly"
                  sx={{
                    "& .MuiInputBase-root": {
                      backgroundColor: "#f5f5f5",
                    },
                  }}
                />
              )
            ) : (
              <Typography variant="body2" whiteSpace="pre-wrap">
                {brief || "No brief available"}
              </Typography>
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <ActionCard title="Total Spend" value={`$${totalSpend}`} color="#4CAF50" />
          </Grid>
          <Grid item xs={6}>
            <ActionCard title="Total Views" value={totalViews} color="#FFEB3B" />
          </Grid>
          <Grid item xs={6}>
            <ActionCard
              title="Total Engagement"
              value={totalEngagement}
              color="#2196F3"
            />
          </Grid>
          <Grid item xs={6}>
            <ActionCard title="Creators" value={creatorCount} color="#E91E63" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ActionsButton = ({
  isDraft,
  isLaunched,
  handleAddCreatorsClick,
  handleConfirmCompleteCampaign,
  handleDownloadCSV,
  handleExportToGoogleSheets,
  isTcc,
  isLoading,
  isEditable,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMoreActionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreActionsClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box display="flex" justifyContent="flex-end" gap={2}>
      {isLoading ? (
        <CircularProgress size={24} />
      ) : (
        <>
          <Button onClick={handleMoreActionsClick} startIcon={<MoreVertIcon />}>
            Actions
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMoreActionsClose}
          >
            {isDraft && (
              <MenuItem onClick={handleAddCreatorsClick}>
                <ListItemIcon>
                  <AddCircleIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Add Creators</ListItemText>
              </MenuItem>
            )}
            {isLaunched && isTcc && (
              <MenuItem onClick={handleConfirmCompleteCampaign}>
                <ListItemIcon>
                  <CheckIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Complete Campaign</ListItemText>
              </MenuItem>
            )}
            <MenuItem onClick={handleDownloadCSV}>
              <ListItemIcon>
                <DownloadIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Download as CSV</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleExportToGoogleSheets}>
              <ListItemIcon>
                <GoogleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Export to Google Sheets</ListItemText>
            </MenuItem>
          </Menu>
        </>
      )}
    </Box>
  );
};

const EditCampaignPage = () => {
  const { campaignId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const isDesktop = useIsDesktop();
  const { getCurrrentUser } = useAuth();
  const currentUser = getCurrrentUser();
  const isTcc = currentUser?.company_name === "TCC";

  const [campaignDetails, setCampaignDetails] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [isEditingName, setEditingName] = useState(false);
  const [isEditingBrief, setEditingBrief] = useState(false);
  const [editedName, setEditedName] = useState("");
  const [editedBrief, setEditedBrief] = useState("");
  const [creatorsToRemove, setCreatorsToRemove] = useState([]);
  const [showLaunchConfirmDialog, setShowLaunchConfirmDialog] = useState(false);
  const [isLaunching, setIsLaunching] = useState(false);
  const [isTracking, setIsTracking] = useState(false);
  const [expandedCreator, setExpandedCreator] = useState(null);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [progress, setProgress] = useState(0);
  const [timelineEvents, setTimelineEvents] = useState(undefined);
  const changedEvents = useRef(false);

  // State for assets functionality
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [currentCreatorIndex, setCurrentCreatorIndex] = useState(null);
  const [negotiationDialogOpen, setNegotiationDialogOpen] = useState(false);
  const [selectedCreator, setSelectedCreator] = useState(null);
  const [isResponding, setIsResponding] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [isNegotiating, setIsNegotiating] = useState(false);

  // For "Drop Creator" confirmation
  const [dropCreatorDialogOpen, setDropCreatorDialogOpen] = useState(false);
  const [creatorToDrop, setCreatorToDrop] = useState(null);

  const { isFetching, data, refetch: refetchCampaign } = useQuery(
    ["campaign", campaignId],
    () => client.campaigns.details(campaignId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: files, refetch: refetchFiles } = useQuery("files", () =>
    client.files.fetch("")
  );

  const { data: projectionsData } = useQuery(
    ["campaignProjections", campaignId],
    () => client.campaigns.fetch(campaignId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const InvalidateCampaign = () => {
    queryClient.invalidateQueries(["campaign", campaignId]);
  };

  const handleRefreshStats = async () => {
    if (unsavedChanges) {
      alert("Please save all changes before tracking");
      return;
    }
    const foundLiveLinks = campaignDetails.creators.reduce(
      (found, currentCreator) => found || !!currentCreator.liveLink,
      false
    );
    if (!foundLiveLinks) {
      alert("None of the creators in this campaigns has any Live Links to track");
      return;
    }

    setIsTracking(true);
    const engagement = await TrackLinks(campaignDetails.creators);
    setProgress(100);
    const foundError = Object.values(engagement).reduce(
      (foundAnyError, creatorData) =>
        foundAnyError || (creatorData ? !!creatorData.error : false),
      false
    );
    if (foundError) {
      alert("Failed tracking one or more links. Please try again");
    }
    const payload = {
      campaignId,
      creators: campaignDetails.creators.map((creator) => ({
        id: creator.id,
        comments: engagement[creator.id]?.comments || creator.comments,
        totalViews: engagement[creator.id]?.view_count || creator.totalViews,
        likes: engagement[creator.id]?.like_count || creator.likes,
        postDate: engagement[creator.id]?.upload_date || creator.postDate,
      })),
    };
    try {
      await updateCreatorSpecsAsync(payload);
    } catch (error) {
      alert(error);
    }
    setIsTracking(false);
    await wait(1000);
    setProgress(0);
  };

  useEffect(() => {
    if (data) {
      setCampaignDetails({
        ...data,
        creators: data.creators.map((creator) => ({
          ...creator,
          price: typeof creator?.price === "number" ? creator.price.toFixed(2) : "",
          agencyRate:
            typeof creator?.agencyRate === "number"
              ? creator.agencyRate.toFixed(2)
              : "",
        })),
      });
      setEditedName(data.name || "");
      setEditedBrief(data.brief || "");
      setUnsavedChanges(false);
      setTimelineEvents(
        data.timelineEvents.map((event) => ({
          ...event,
          projected_date: new Date(event.projected_date),
          will_delete: false,
        }))
      );
    }
  }, [data]);

  const AddTimelineEvent = (creator) => {
    changedEvents.current = true;
    setTimelineEvents((prev) => [
      ...prev,
      {
        campaign_id: campaignId,
        id: null,
        last_updated: null,
        manager_completed: false,
        objective: "",
        projected_date: new Date(Date.now()),
        status: TimelineStatus[0],
        notes: "",
        manager_notes: "",
        username: creator,
        will_delete: false,
      },
    ]);
  };

  const OnEditTimelineField = (targetIndex, target, value) => {
    changedEvents.current = true;
    setTimelineEvents(
      timelineEvents.map((event, index) => {
        if (index === targetIndex) {
          return { ...event, [target]: value };
        } else return event;
      })
    );
  };

  const { mutateAsync: updateTimeline, isLoading: isUpdatingTimeline } =
    useMutation(({ id, input }) => client.campaigns.timeline_edit(id, input), {
      onSuccess: () => {
        queryClient.invalidateQueries(["campaign", campaignDetails.id]);
        alert("Timeline updated successfully.");
      },
      onError: (error) => {
        if (error.response && error.response.data && error.response.data.error)
          alert(`Error: ${error.response.data.error}`);
        else alert(`Error: ${error.message}`);
      },
    });

  const estimatedTime = campaignDetails?.creators?.length * 4000 || 1000;
  const intervalDuration = 1000;

  useEffect(() => {
    if (!isTracking) return;

    const interval = setInterval(() => {
      setProgress((prev) => {
        const newValue = prev + 100 / (estimatedTime / intervalDuration);
        if (newValue >= 100) {
          clearInterval(interval);
          return 100;
        }
        return newValue;
      });
    }, intervalDuration);

    return () => clearInterval(interval);
  }, [isTracking, estimatedTime]);

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleFieldChange = (creatorId, field, value) => {
    const updatedCreators = campaignDetails.creators.map((creator) =>
      creator.id === creatorId ? { ...creator, [field]: value } : creator
    );
    setCampaignDetails((prevState) => ({
      ...prevState,
      creators: updatedCreators,
    }));
    setUnsavedChanges(true);
  };

  // If assets are approved, we treat status as 'Approved' in final saving.
  const getStatusForCreator = (creator) => {
    if (creator.assetsApproved) {
      return "Approved";
    }
    return creator.status;
  };

  const saveChanges = () => {
    // If timeline was changed
    if (changedEvents.current === true) {
      updateTimeline({
        id: campaignDetails.id,
        input: timelineEvents
          .filter((event) => event.will_delete === false)
          .map(({ will_delete, ...event }) => ({
            ...event,
            projected_date: event.projected_date.toUTCString(),
          })),
      });
      changedEvents.current = false;
    }

    const payload = {
      campaignId,
      creators: campaignDetails.creators.map((creator) => ({
        id: creator.id,
        status: creatorsToRemove.includes(creator.id)
          ? "Dropped"
          : getStatusForCreator(creator),
        price: parseLocalFloat(creator.price),
        agencyRate: parseLocalFloat(creator.agencyRate),
        assetsSent: creator.assetsSent,
        assetsApproved: creator.assetsApproved,
        likes: creator.likes,
        comments: creator.comments,
        totalViews: creator.totalViews,
        postDate: creator.postDate,
        liveLink: creator.liveLink,
        skipPayout: creator.skipPayout,
        promotionPlatform: creator.promotionPlatform,
        promotionType: creator.promotionType,
        creatorBrief: creator.creatorBrief,
        postingInstructions: creator.postingInstructions,
        boostCode: creator.boostCode,
      })),
    };

    updateCampaignCreatorSpecs(payload);
    setUnsavedChanges(false);
  };

  const saveMetaData = () => {
    const payload = {
      id: campaignId,
      name: editedName,
      brief: editedBrief,
      campaign_sum: totalCampaignSum,
    };
    updateMetaData(payload);
    setUnsavedChanges(false);
  };

  const handleSaveCampaignDetails = () => {
    saveMetaData();
    setEditingName(false);
    setEditingBrief(false);
  };

  const totalCampaignSum = campaignDetails?.creators.reduce((acc, creator) => {
    const price = parseFloat((creator.price || "0").toString().replace(/[^\d.]/g, ""));
    return acc + price;
  }, 0);

  const showSkipPayment = campaignDetails?.creators.some(
    (creator) => creator.skipPayout === true
  );

  const totalCreatorSum = campaignDetails?.creators.reduce((acc, creator) => {
    const cost = parseFloat(
      (creator.agencyRate || "0").toString().replace(/[^\d.]/g, "")
    );
    return acc + cost;
  }, 0);

  const {
    mutate: updateCampaignCreatorSpecs,
    mutateAsync: updateCreatorSpecsAsync,
    isLoading: isLoadingUpateSpecs,
  } = useMutation((payload) => client.campaigns.updateCampaignCreatorSpecs(payload), {
    onSuccess: () => {
      queryClient.invalidateQueries(["campaign", campaignId]);
      refetchCampaign();
      alert("Creator details updated successfully!");
    },
    onError: (error) => {
      alert(error.response?.data?.error || error.message);
    },
  });

  const { mutate: updateMetaData, isLoading: isLoadingUpdateMetadata } = useMutation(
    (payload) => client.campaigns.updateMetaData(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["campaign", campaignId]);
        alert("Campaign metadata updated successfully!");
      },
      onError: (error) => {
        alert(error.response?.data?.error || error.message);
      },
    }
  );

  const { mutate: launchCampaign, isLoading: isLoadingLaunch } = useMutation(
    (payload) => client.campaigns.launch(payload),
    {
      onSuccess: () => {
        alert("Campaign launched successfully!");
        queryClient.invalidateQueries(["campaign", campaignId]);
      },
      onError: (error) => {
        alert(error.response?.data?.error || error.message);
      },
    }
  );

  const handleLaunchCampaign = () => {
    setShowLaunchConfirmDialog(true);
  };

  const handleConfirmLaunch = async () => {
    setIsLaunching(true);
    setShowLaunchConfirmDialog(false);

    const creatorNames = campaignDetails?.creators?.map((c) => c.name) || [];
    const creatorPromotionTypes =
      campaignDetails?.creators?.map((c) => ({
        name: c.name,
        promotionType: c.promotionType,
      })) || [];
    const creatorPrices =
      campaignDetails?.creators?.reduce((acc, c) => {
        acc[c.name] = parseLocalFloat(c.price);
        return acc;
      }, {}) || {};

    const agencyRates =
      campaignDetails?.creators?.reduce((acc, c) => {
        acc[c.name] = parseLocalFloat(c.agencyRate || "0");
        return acc;
      }, {}) || {};

    const payload = {
      campaignId: campaignDetails?.id,
      campaignName: campaignDetails?.name,
      campaignBrief: campaignDetails?.brief,
      creatorNames,
      creatorPromotionTypes,
      creatorPrices,
      agencyRates,
      blitzautocampaign: true,
      idealduedate: campaignDetails?.ideal_duedate,
      userEmail: campaignDetails?.user_email,
      sharedWithEmail: campaignDetails?.shared_with_email,
    };

    try {
      launchCampaign(payload);
    } finally {
      setIsLaunching(false);
    }
  };

  const handleCancelLaunch = () => {
    setShowLaunchConfirmDialog(false);
  };

  const { mutate: completeCampaign, isLoading: isLoadingComplete } = useMutation(
    (input) => client.campaigns.complete(input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["campaign", campaignId]);
        alert("Campaign completed successfully!");
      },
      onError: (error) => {
        alert(error.response?.data?.error || error.message);
      },
    }
  );

  const { mutate: completeCreator, isLoading: isLoadingCompleteCreator } =
    useMutation((input) => client.campaigns.completeCreator(campaignId, input), {
      onSuccess: () => {
        queryClient.invalidateQueries(["campaign", campaignId]);
        alert("Creator completed successfully!");
      },
      onError: (error) => {
        alert(error.response?.data?.error || error.message);
      },
    });

  const handleConfirmCompleteCampaign = async () => {
    completeCampaign({ index: campaignId, params: {} });
  };

  const downloadCSV = () => {
    const csvContent = convertToCSV(campaignDetails);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `campaign_details_${campaignId}.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const exportToSheets = async () => {
    try {
      const response = await client.campaigns.exportSheet({
        campaignId,
        campaignDetails,
      });
      if (response.sheetLink) {
        window.open(response.sheetLink, "_blank");
      } else {
        console.error("Error exporting to Google Sheets:", response.error);
      }
    } catch (error) {
      console.error(
        "Failed to export to Google Sheets:",
        error.response?.data?.error || error.message
      );
    }
  };

  const convertToCSV = (data) => {
    if (!data || !Array.isArray(data.creators)) return "";

    const headers = [
      "Name",
      "Following",
      "Status",
      "Price",
      "Offer Sent",
      "Assets Sent",
      "Assets Approved",
      "Promotion Type",
      "Likes",
      "Comments",
      "Total Views",
      "Post Date",
      "Platform Link",
      "Live Link",
    ];

    const rows = data.creators.map((creator) => [
      creator.name ?? "",
      creator.following ?? "",
      creator.status ?? "",
      creator.price ?? "",
      creator.offerSent ? "Yes" : "No",
      creator.assetsSent ? "Yes" : "No",
      creator.assetsApproved ? "Yes" : "No",
      creator.promotionType ?? "",
      creator.likes ?? 0,
      creator.comments ?? 0,
      creator.totalViews ?? 0,
      creator.postDate ?? "",
      creator.platformLink ?? "",
      creator.liveLink ?? "",
    ]);

    const csvContent = [headers.join(","), ...rows.map((row) => row.join(","))].join("\n");
    return csvContent;
  };

  const handleExpandClick = (creatorId) => {
    setExpandedCreator(expandedCreator === creatorId ? null : creatorId);
  };

  // "Check or create conversation" logic
  const checkConversation = useMutation(client.conversations.check, {
    onSuccess: (data, variables) => {
      if (data.exists) {
        navigateToConversation(variables.creatorName);
      } else {
        createConversation.mutate(variables);
      }
    },
    onError: (error) => {
      console.error("Check response error:", error);
    },
  });

  const createConversation = useMutation(client.conversations.create, {
    onSuccess: (data, variables) => {
      navigateToConversation(variables.creatorName);
    },
    onError: (error) => {
      console.error("Create response error:", error);
    },
  });

  const startConversation = (creatorId, creatorName) => {
    const payload = {
      campaign_id: campaignId,
      creator_id: creatorId,
      creatorName: creatorName,
    };
    checkConversation.mutate(payload);
  };

  const navigateToConversation = (creatorName) => {
    navigate(`/campaigns/convo/${campaignId}/${creatorName}`);
  };

  // Stub for "Resend Offer" - add your actual mutation or logic here
  const { mutate: resendOfferMutation, isLoading: isResendingOffer } = useMutation(
    ({ campaignId, creatorName }) =>
      client.campaigns.resendOffer(campaignId, creatorName),
    {
      onSuccess: data => alert(data.message),
      onError: err => alert(err.response?.data?.error || err.message)
    }
  );
  
  const handleResendOfferClick = (campaignId, creatorName) => {
    resendOfferMutation({ campaignId, creatorName });
  };
  

  const { mutate: handleNegotiationResponse } = useMutation(
    (payload) => client.campaigns.handleNegotiationResponse(campaignId, payload),
    {
      onMutate: () => setIsNegotiating(true),
      onSuccess: () => {
        refetchCampaign();
        setNegotiationDialogOpen(false);
        alert("Response submitted successfully");
      },
      onError: (error) => {
        alert(error.response?.data?.error || error.message);
      },
      onSettled: () => setIsNegotiating(false),
    }
  );

  // Asset insertion logic
  const handleDialogOpen = (index) => {
    setCurrentCreatorIndex(index);
    setDialogOpen(true);
  };

  const handleDialogClose = async (confirmed) => {
    if (confirmed && selectedAsset) {
      const updatedCreators = campaignDetails.creators.map((creator, idx) => {
        if (idx === currentCreatorIndex) {
          return {
            ...creator,
            creatorBrief: `${creator.creatorBrief}\n${selectedAsset.file_url}`,
          };
        }
        return creator;
      });

      const updatedDetails = { ...campaignDetails, creators: updatedCreators };
      setCampaignDetails(updatedDetails);

      const payload = {
        campaignId: updatedDetails.id,
        creators: updatedDetails.creators.map((creator) => ({
          id: creator.id,
          creatorBrief: creator.creatorBrief,
        })),
      };

      updateCampaignCreatorSpecs(payload);
    }
    setDialogOpen(false);
    setSelectedAsset(null);
  };

  const handleAssetClick = (asset) => {
    setSelectedAsset(asset);
  };

  // "Drop Creator" confirm
  const confirmDropCreator = (creatorId) => {
    setCreatorToDrop(creatorId);
    setDropCreatorDialogOpen(true);
  };

  const handleDropCreatorConfirm = () => {
    if (creatorToDrop) {
      // Mark as dropped
      handleFieldChange(creatorToDrop, "status", "Dropped");
    }
    setDropCreatorDialogOpen(false);
    setCreatorToDrop(null);
  };

  const handleDropCreatorCancel = () => {
    setDropCreatorDialogOpen(false);
    setCreatorToDrop(null);
  };

  if (!campaignDetails) {
    return (
      <Typography
        sx={{
          width: "100vw",
          height: "100vh",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Loading campaign details...
      </Typography>
    );
  }

  const totalSpend = campaignDetails.creators
    .reduce((acc, creator) => acc + parseFloat(creator.price || 0), 0)
    .toFixed(2);

  const totalViews = campaignDetails.creators.reduce(
    (acc, creator) => acc + (creator.totalViews || 0),
    0
  );

  const totalEngagement = campaignDetails.creators.reduce(
    (acc, creator) => acc + (creator.likes || 0) + (creator.comments || 0),
    0
  );

  const creatorCount = campaignDetails.creators.length;

  const isDraft = campaignDetails?.campaign_status === "Draft";
  const isLaunched = campaignDetails?.campaign_status === "Launched";
  const isEditable = isTcc || (isDraft && isDesktop);

  return (
    <>
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={
          isFetching ||
          isLaunching ||
          isLoadingComplete ||
          isLoadingCompleteCreator ||
          isLoadingLaunch ||
          isLoadingUpateSpecs ||
          isLoadingUpdateMetadata
        }
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={isTracking}
      >
        <Box sx={{ width: "75%" }}>
          <LinearProgress variant="determinate" color="secondary" value={progress} />
        </Box>
      </Backdrop>
      <AppBar position="static" color="default">
        <Tabs
          value={selectedTab}
          onChange={handleChangeTab}
          textColor="primary"
          indicatorColor="primary"
          centered
          sx={{ width: "100%" }}
        >
          <Tab label="Overview" />
          <Tab label="Deadlines" />
          <Tab label="Projections" />
          <Tab label="Tools" />
        </Tabs>
      </AppBar>

      <Container maxWidth="xl">
        <Box sx={{ margin: 2 }}>
          <Stepper
            activeStep={
              campaignDetails.campaign_status === "Completed"
                ? 2
                : campaignDetails.campaign_status === "Launched"
                ? 1
                : 0
            }
          >
            <Step>
              <StepLabel>Draft</StepLabel>
            </Step>
            <Step>
              <StepLabel>Launched</StepLabel>
            </Step>
            <Step>
              <StepLabel>Completed</StepLabel>
            </Step>
          </Stepper>
        </Box>
        <PageTitle title={`Edit Campaign - ${campaignDetails.name} - Blitz`} />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            mb: 3,
            px: 2,
          }}
        >
          <Typography variant="h4">
            {isEditingName ? (
              <TextField
                value={editedName}
                onChange={(e) => setEditedName(e.target.value)}
                variant="outlined"
                size="small"
              />
            ) : (
              campaignDetails.name
            )}
          </Typography>
          {isEditable && (
            <IconButton
              onClick={() =>
                isEditingName ? handleSaveCampaignDetails() : setEditingName(true)
              }
            >
              {isEditingName ? <CheckIcon /> : <EditIcon />}
            </IconButton>
          )}
        </Box>
        <ActionCards
          campaignName={campaignDetails.name}
          brief={editedBrief}
          totalSpend={totalSpend}
          totalViews={totalViews}
          totalEngagement={totalEngagement}
          creatorCount={creatorCount}
          isEditable={isEditable}
          isEditingBrief={isEditingBrief}
          editedBrief={editedBrief}
          setEditedBrief={setEditedBrief}
          handleSaveCampaignDetails={handleSaveCampaignDetails}
          setEditingBrief={setEditingBrief}
        />
        <Box
          sx={{
            px: 2,
            mb: 4,
            display: "flex",
            gap: 2,
            alignItems: "center",
            flexWrap: "wrap",
            rowGap: 2,
          }}
        >
          {/* Campaign Status Buttons */}
          {isDraft && (
            <RainbowButton variant="contained" onClick={handleLaunchCampaign}>
              Launch Campaign
            </RainbowButton>
          )}

          {/* Action Buttons */}
          <ActionsButton
            isDraft={isDraft}
            isLaunched={campaignDetails.campaign_status === "Launched"}
            handleAddCreatorsClick={() => navigate(`/add-creators/${campaignId}`)}
            handleConfirmCompleteCampaign={handleConfirmCompleteCampaign}
            handleDownloadCSV={downloadCSV}
            handleExportToGoogleSheets={exportToSheets}
            isTcc={isTcc}
            isLoading={isLaunching}
            isEditable={isEditable}
          />

          {/* Save Button */}
          {isEditable && (
            <Button variant="contained" color="primary" onClick={saveChanges}>
              Save Changes
            </Button>
          )}

          {/* Share Buttons */}
          <Button
            variant="contained"
            color="info"
            onClick={() => setShareDialogOpen(true)}
            startIcon={<Share />}
          >
            Share Campaign
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate(`/campaigns/${campaignId}`)}
          >
            View Public Page
          </Button>

          {/* Deliverables Drive Button */}
          {campaignDetails.drive_link ? (
            <GreenButton
              variant="contained"
              href={campaignDetails.drive_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Open Deliverables Drive
            </GreenButton>
          ) : (
            <Typography variant="body2" color="text.secondary">
              Deliverables Drive: Not Launched
            </Typography>
          )}

          {/* Refresh Stats (only if some live link is present) */}
          {campaignDetails?.creators.some((creator) => creator.liveLink) &&
            (campaignDetails.campaign_status === "Launched" ||
              campaignDetails.campaign_status === "Completed") && (
              <Box sx={{ px: 2, mb: 2 }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleRefreshStats}
                >
                  Refresh Campaign Stats
                </Button>
              </Box>
            )}

          {/* Unsaved Changes Warning */}
          {unsavedChanges && (
            <Typography variant="subtitle2" color="error">
              You have unsaved changes
            </Typography>
          )}
        </Box>

        <Divider sx={{ marginBlockStart: 2 }} />
        <Box
          sx={{
            paddingInline: { xs: 1, md: 4 },
            marginBlockStart: { xs: 1, md: 2 },
          }}
        >
          <TabPanel value={selectedTab} index={0}>
            <Typography variant="h6" gutterBottom>
              Creators
            </Typography>
            <List sx={{ width: "100%" }}>
              {campaignDetails.creators.map((creator, index) => (
                <Paper
                  key={creator.id}
                  elevation={1}
                  sx={{
                    mb: 2,
                    borderRadius: 2,
                    border: `2px solid ${
                      creator.status === "Accepted"
                        ? "#4CAF50"
                        : creator.status === "Counter"
                        ? "#FFEB3B"
                        : creator.status === "Approved"
                        ? "#2196F3"
                        : "#e0e0e0"
                    }`,
                    overflow: "hidden",
                    transition: "border-color 0.3s ease",
                  }}
                >
                  <ListItem
                    sx={{
                      bgcolor:
                        expandedCreator === creator.id ? "transparent" : "transparent",
                      transition: "background-color 0.2s",
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar src={creator.pfphref || profilePhoto} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Box display="flex" alignItems="center" gap={2}>
                          <Typography variant="subtitle1">{creator.name}</Typography>
                          <Chip
                            label={
                              creator.status +
                              (creator.payoutComplete ? " + Payment Complete" : "")
                            }
                            size="small"
                            sx={{
                              backgroundColor: (theme) => {
                                switch (creator.status) {
                                  case "Accepted":
                                    return "#4CAF50";
                                  case "Counter":
                                    return "#FFEB3B";
                                  case "Approved":
                                    return "#2196F3";
                                  case "Pitched":
                                    return "#e0e0e0";
                                  default:
                                    return "#e0e0e0";
                                }
                              },
                              color: (theme) => {
                                if (creator.status === "Counter")
                                  return "rgba(0, 0, 0, 0.87)";
                                if (creator.status === "Pitched") return "black";
                                return "white";
                              },
                            }}
                          />
                        </Box>
                      }
                      secondary={`Followers: ${
                        creator.following?.toLocaleString() || "N/A"
                      }`}
                    />
                    <Box display="flex" gap={1}>
                      {/* Show "Negotiate" if status is "Counter" */}
                      {creator.status === "Counter" && (
                        <Button
                          variant="contained"
                          color="warning"
                          onClick={() => {
                            setSelectedCreator(creator);
                            setNegotiationDialogOpen(true);
                          }}
                        >
                          Negotiate
                        </Button>
                      )}

                                            {/* Only show Resend Offer / Chat buttons when the campaign is Launched */}
                      {isLaunched && (
                        <>
                          {creator.status !== "Accepted" ? (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => handleResendOfferClick(campaignId, creator.name)}
                            >
                              Resend Offer
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => startConversation(creator.id, creator.name)}
                            >
                              Chat
                            </Button>
                          )}
                        </>
                      )}


                      {/* Expand/Collapse */}
                      <IconButton onClick={() => handleExpandClick(creator.id)} size="small">
                        {expandedCreator === creator.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton>
                    </Box>
                  </ListItem>

                  <Collapse in={expandedCreator === creator.id}>
                    <Box sx={{ p: 2, bgcolor: "transparent" }}>
                      {creator.submissionLink && (
                        <Card
                          sx={{
                            mb: 2,
                            cursor: "pointer",
                            border: "2px solid",
                            borderColor: "primary.main",
                          }}
                          onClick={() => window.open(creator.submissionLink, "_blank")}
                        >
                          <CardContent>
                            <Typography variant="subtitle1">
                              {creator.name}'s Submission
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Click to view submission
                            </Typography>
                          </CardContent>
                        </Card>
                      )}

                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            multiline
                            minRows={3}
                            label="Creator Brief"
                            value={creator.creatorBrief || campaignDetails?.brief}
                            onChange={(e) =>
                              handleFieldChange(creator.id, "creatorBrief", e.target.value)
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={() => handleDialogOpen(index)} edge="end">
                                    <CloudUploadIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <RateField
                                label="Price"
                                value={creator.price ?? 0}
                                onChange={(value) =>
                                  handleFieldChange(creator.id, "price", value)
                                }
                                fullWidth
                              />
                            </Grid>
                            {isTcc && (
                              <Grid item xs={12} sm={6}>
                                <RateField
                                  label="Agency Rate"
                                  value={creator.agencyRate ?? 0}
                                  onChange={(value) =>
                                    handleFieldChange(creator.id, "agencyRate", value)
                                  }
                                  fullWidth
                                />
                              </Grid>
                            )}
                          </Grid>

                          <Grid container spacing={2} sx={{ mt: 1 }}>
                            <Grid item xs={12} sm={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={creator.assetsSent}
                                    onChange={() =>
                                      handleFieldChange(
                                        creator.id,
                                        "assetsSent",
                                        !creator.assetsSent
                                      )
                                    }
                                  />
                                }
                                label="Deliverables Sent"
                              />
                            </Grid>
                            {creator.assetsSent && (
                              <Grid item xs={12} sm={6}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={creator.assetsApproved}
                                      onChange={() =>
                                        handleFieldChange(
                                          creator.id,
                                          "assetsApproved",
                                          !creator.assetsApproved
                                        )
                                      }
                                    />
                                  }
                                  label="Approved"
                                />
                              </Grid>
                            )}
                          </Grid>

                          {creator.assetsApproved && (
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  fullWidth
                                  label="Live Link"
                                  value={creator.liveLink ?? ""}
                                  onChange={(e) =>
                                    handleFieldChange(creator.id, "liveLink", e.target.value)
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  fullWidth
                                  label="Boost Code"
                                  value={creator.boostCode ?? ""}
                                  onChange={(e) =>
                                    handleFieldChange(creator.id, "boostCode", e.target.value)
                                  }
                                />
                              </Grid>
                            </Grid>
                          )}
                        </Grid>

                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: "flex",
                              gap: 2,
                              flexWrap: "wrap",
                              borderTop: "1px solid #eee",
                              pt: 2,
                              mt: 2,
                            }}
                          >
                            <TextField
                              label="Posting Instructions"
                              value={creator.postingInstructions ?? ""}
                              onChange={(e) =>
                                handleFieldChange(
                                  creator.id,
                                  "postingInstructions",
                                  e.target.value
                                )
                              }
                              fullWidth
                              multiline
                              minRows={2}
                            />

                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  label="Promotion Platform"
                                  value={creator.promotionPlatform ?? ""}
                                  onChange={(e) =>
                                    handleFieldChange(
                                      creator.id,
                                      "promotionPlatform",
                                      e.target.value
                                    )
                                  }
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  label="Promotion Type"
                                  value={creator.promotionType ?? ""}
                                  onChange={(e) =>
                                    handleFieldChange(
                                      creator.id,
                                      "promotionType",
                                      e.target.value
                                    )
                                  }
                                  fullWidth
                                />
                              </Grid>
                            </Grid>

                            {isTcc && creator.liveLink && (
                              <Box sx={{ width: "100%", mt: 2 }}>
                                {showSkipPayment && (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={creator.skipPayout}
                                        disabled={creator.payoutComplete}
                                        onChange={() =>
                                          handleFieldChange(
                                            creator.id,
                                            "skipPayout",
                                            !creator.skipPayout
                                          )
                                        }
                                      />
                                    }
                                    label="Skip Payment"
                                    sx={{ mr: 3 }}
                                  />
                                )}
                                <Button
                                  variant={creator.payoutComplete ? "outlined" : "contained"}
                                  color="secondary"
                                  disabled={
                                    creator.payoutComplete ||
                                    creator.skipPayout ||
                                    !creator.liveLink
                                  }
                                  onClick={() => completeCreator({ creator: creator.id })}
                                  sx={{ minWidth: 160 }}
                                >
                                  {creator.payoutComplete
                                    ? "Payment Completed"
                                    : "Complete Payment"}
                                </Button>
                              </Box>
                            )}
                          </Box>
                        </Grid>
                      </Grid>

                      {/* 2) Drop Creator Button */}
                      <Box sx={{ mt: 2 }}>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => confirmDropCreator(creator.id)}
                          startIcon={<DeleteIcon />}
                        >
                          Drop Creator
                        </Button>
                      </Box>
                    </Box>
                  </Collapse>
                </Paper>
              ))}
            </List>
          </TabPanel>

          <TabPanel value={selectedTab} index={1}>
            <TimelineTab
              campaignDetails={campaignDetails}
              timelineEvents={timelineEvents}
              onEditField={OnEditTimelineField}
              onCreateRow={AddTimelineEvent}
            />
          </TabPanel>
          <TabPanel value={selectedTab} index={2}>
            {projectionsData ? (
              <CampaignProjectionsPanel campaignDetails={projectionsData} />
            ) : (
              <Typography>Loading projections...</Typography>
            )}
          </TabPanel>
          <TabPanel value={selectedTab} index={3}>
            <Tools campaignDetails={campaignDetails} onUpdate={InvalidateCampaign} />
          </TabPanel>
        </Box>
      </Container>

      {/* Launch Confirm Dialog */}
      <Dialog open={showLaunchConfirmDialog} onClose={handleCancelLaunch}>
        <DialogTitle>Confirm Launch</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to launch this campaign? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelLaunch} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmLaunch} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Select Asset Dialog */}
      <SelectAssetDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        files={files}
        selectedAsset={selectedAsset}
        onAssetClick={handleAssetClick}
        refetchFiles={refetchFiles}
      />

      {/* Negotiation Dialog */}
      <BrandNegotiationDialog
        open={negotiationDialogOpen}
        handleClose={() => setNegotiationDialogOpen(false)}
        creator={selectedCreator}
        currentOffer={selectedCreator?.originalCounterOffer || 0}
        onRespond={handleNegotiationResponse}
        isSubmitting={isNegotiating}
      />

      {/* Share Campaign Dialog */}
      <ShareCampaignDialog
        open={shareDialogOpen}
        onClose={() => {
          setShareDialogOpen(false);
          setEmail("");
        }}
        campaignDetails={campaignDetails}
        email={email}
        setEmail={setEmail}
      />

      {/* Drop Creator Confirm Dialog */}
      <Dialog open={dropCreatorDialogOpen} onClose={handleDropCreatorCancel}>
        <DialogTitle>Confirm Drop</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to drop this creator? This cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDropCreatorCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDropCreatorConfirm} color="error">
            Drop
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditCampaignPage;

import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Paper, // Add this line
} from '@mui/material';
import {
  Add as AddIcon,
} from '@mui/icons-material';
import CampaignTimelineEntry from './campaignTimelineEntry';
import CampaignTimelineCard from './campaignTimelineCard';
import useAlertDialog from '../../../../Components/useAlertDialog';
import AlertDialog from '../../../../Components/AlertDialog';

const Timelines = ({
  campaignDetails,
  selectedCreatorsData,
  creatorTimelines,
  setCreatorTimelines,
  onNext,
  onBack,
}) => {
  const [masterTimeline, setMasterTimeline] = useState([]);
  const [expandedCreators, setExpandedCreators] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const { dialogState, openDialog: openAlert, closeDialog: closeAlert } = useAlertDialog();

  const handleAddMasterEvent = () => {
    setMasterTimeline((prev) => [
      ...prev,
      {
        id: Date.now(),
        objective: '',
        status: 'incomplete',
        notes: '',
        manager_notes: '',
        manager_completed: false,
        projected_date: new Date(),
        will_delete: false,
      },
    ].sort((a, b) => new Date(a.projected_date) - new Date(b.projected_date)));
  };

  const handleEditMasterEvent = (index, field, value) => {
    setMasterTimeline((prev) => {
      const updated = [...prev];
      updated[index] = { ...updated[index], [field]: value };
      return updated.sort((a, b) => new Date(a.projected_date) - new Date(b.projected_date));
    });
  };

  const handleDeleteMasterEvent = (index) => {
    setMasterTimeline((prev) => prev.filter((_, i) => i !== index));
  };

  const applyMasterTimelineToAll = () => {
    console.log('Applying master timeline');
    const newCreatorTimelines = {};
    selectedCreatorsData.forEach((creator) => {
      newCreatorTimelines[creator.creator] = masterTimeline.map((event) => ({
        ...event,
        id: Date.now() + Math.random(), // Ensure unique ID
      }));
    });
    setCreatorTimelines(newCreatorTimelines);
    setOpenDialog(false);
  };

  const handleExpandClick = (creatorId) => {
    setExpandedCreators((prev) => ({
      ...prev,
      [creatorId]: !prev[creatorId],
    }));
  };

  const handleAddCreatorEvent = (creatorId) => {
    console.log('Adding new event');
    setCreatorTimelines((prev) => ({
      ...prev,
      [creatorId]: [
        ...(prev[creatorId] || []),
        {
          id: Date.now(),
          objective: '',
          status: 'incomplete',
          notes: '',
          manager_notes: '',
          manager_completed: false,
          projected_date: new Date(),
          will_delete: false,
        },
      ].sort((a, b) => new Date(a.projected_date) - new Date(b.projected_date)),
    }));
  };

  const handleEditCreatorEvent = (creatorId, index, field, value) => {
    setCreatorTimelines((prev) => {
      const updatedEvents = [...(prev[creatorId] || [])];
      updatedEvents[index] = { ...updatedEvents[index], [field]: value };

      return {
        ...prev,
        [creatorId]: updatedEvents.sort((a, b) => new Date(a.projected_date) - new Date(b.projected_date)),
      };
    });
  };

  const handleDeleteCreatorEvent = (creatorId, index) => {
    setCreatorTimelines((prev) => {
      const updatedEvents = [...(prev[creatorId] || [])];
      updatedEvents.splice(index, 1);
      return {
        ...prev,
        [creatorId]: updatedEvents,
      };
    });
  };

  const onTryNext = () => {

    const todayDate = new Date();
    const threeDays = new Date(todayDate.getTime() + (3 * 24 * 60 * 60 * 1000));
    const badDates = selectedCreatorsData.some((creator) => {
      const creatorTimeline = creatorTimelines[creator.creator]
      if (creatorTimeline) {
        return creatorTimeline.some(event => {
          if (event.projected_date <= todayDate) {
            return true;
          }
          return false;
        });
      }
      return false
    });

    if (badDates) {
      openAlert('Incorrect Due Dates', <>Events in a Timeline can't be due before today.<br />Please adjust your Timelines to continue</>, closeAlert, closeAlert, 'Ok', null);
      return;
    }

    const anyBadData = selectedCreatorsData.some((creator) => {
      const creatorTimeline = creatorTimelines[creator.creator]
      return !creatorTimeline || creatorTimeline.length == 0
    });

    if (anyBadData) {
      openAlert('Empty Timelines', <>Some creators in the campaign don't have any events in their timelines. <br />Timelines notify creators of upcoming deadlines, and notify you when they are completed. Continue?</>, handleNext, closeAlert, 'Continue', 'Cancel');
      return;
    }



    handleNext();

  }

  const handleNext = () => {
    onNext();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 2,
      }}
    >
      <Box sx={{ width: '100%', maxWidth: 800 }}>
        <Typography variant="h5" gutterBottom>
          Master Timeline
        </Typography>
        <Paper sx={{ padding: 2, marginBottom: 2 }}>
          {masterTimeline.map((event, index) => (
            <CampaignTimelineEntry
              key={event.id}
              event={event}
              onEditField={(field, value) =>
                handleEditMasterEvent(index, field, value)
              }
              onDelete={() => handleDeleteMasterEvent(index)}
            />
          ))}
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: 1 }}>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={handleAddMasterEvent}
              sx={{ marginRight: 2 }}
            >
              Add Master Event
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenDialog(true)}
            >
              Apply Master Timeline to All Creators
            </Button>
          </Box>
        </Paper>

        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          aria-labelledby="apply-master-timeline-dialog-title"
        >
          <DialogTitle id="apply-master-timeline-dialog-title">
            Apply Master Timeline
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              This will overwrite any existing timelines for all creators. Do you
              want to proceed?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={applyMasterTimelineToAll} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <AlertDialog alertState={dialogState}></AlertDialog>

        <Typography variant="h5" gutterBottom>
          Creators Timelines
        </Typography>
        {selectedCreatorsData.map((creator) => (
          <CampaignTimelineCard
            creator={creator}
            expandedCreators={expandedCreators}
            handleExpandClick={handleExpandClick}
            handleAddCreatorEvent={handleAddCreatorEvent}
            handleDeleteCreatorEvent={handleDeleteCreatorEvent}
            handleEditCreatorEvent={handleEditCreatorEvent}
            setCreatorTimelines={setCreatorTimelines}
            creatorTimelines={creatorTimelines}
            masterTimeline={masterTimeline}
            key={creator.creator}
          ></CampaignTimelineCard>
        ))}
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}
        >
          <Button variant="contained" onClick={onBack}>
            Back
          </Button>
          <Button variant="contained" color="primary" onClick={onTryNext}>
            Next
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Timelines;

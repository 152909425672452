import { Avatar, Backdrop, Box, Button, Card, CardContent, CardHeader, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Grid, MenuItem, Switch, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import useIsDesktop from '../../../../../Hooks/useIsDesktop'
import { dateToDatetimeLocal, withdrawalManualServices, withdrawalStatuses } from '../../../../../Utils/constants';
import { RateField } from '../../../../../Components/RateField';
import AlertDialog from '../../../../../Components/AlertDialog';
import useAlertDialog from '../../../../../Components/useAlertDialog';
import API from '../../../../../API';
import { useQuery } from 'react-query';

const tempData = {
    "amount_no_fee": 100.0,
    "approved_at": new Date("Mon, 03 Feb 2025 13:49:32 GMT"),
    "brief": "Withdrawal Invoice <PO-67890>",
    "company": {
        "account_created": "Mon, 03 Feb 2025 15:00:32 GMT",
        "account_status": "AlphaFree",
        "account_updated": "Mon, 03 Feb 2025 15:00:32 GMT",
        "balance": 1000.0,
        "credit_line": 1000.0,
        "id": 1,
        "name": "TestCompany",
        "nominal_interest_rate": 10.0,
        "phphref": "https://thecultureclub.us/wp-content/uploads/2023/07/New_Project_48__-_Copy-removebg-preview.png",
        "seats": [
            1
        ]
    },
    "company_id": 1,
    "created_at": new Date("Fri, 31 Jan 2025 13:49:32 GMT"),
    "creator": null,
    "creator_id": null,
    "days_to_payment": 1,
    "entity_type": "company",
    "fee_percentage": 10.0,
    "id": "b563c1d9-3f2b-4eb6-b134-d499f32c4786",
    "manual_service": "paypal",
    "manual_subject": null,
    "notes": "",
    "origin": "system",
    "payed_at": new Date("Fri, 31 Jan 2025 13:49:32 GMT"),
    "reference": "WDR-20250203084932-0A0EA",
    "status": "paid",
    "transaction_id": null,
    "user": null,
    "user_id": null,
    "withdrawal_type": "manual"
}

const WithdrawalToSubjectData = (wdw) => {
    switch (wdw.entity_type) {
        case 'company':
            return {
                subject: wdw.company.name,
                subjectPaymentMethod: 'N/A',
                subjectPaymentEmail: 'N/A',
                subjectPicture: wdw.company.phphref
            }
            break;
        case 'creator':
            let subjectPaymentEmail = wdw.creator.payout_email
            let subjectPaymentMethod = 'paypal'
            if (wdw.creator.user) {
                if (wdw.creator.user.payout_preferred == 'PayPal') {
                    subjectPaymentMethod = 'paypal'
                    subjectPaymentEmail = wdw.creator.user.paypal || subjectPaymentEmail
                }
                if (wdw.creator.user.payout_preferred == 'Stripe') {
                    subjectPaymentMethod = 'stripe'
                    subjectPaymentEmail = wdw.creator.user.stripe_id
                }
            }

            return {
                subject: wdw.creator.creator,
                subjectPaymentMethod,
                subjectPaymentEmail,
                subjectPicture: wdw.creator.pfphref
            }
    }
}

const WithdrawalToProjectData = (wdw) => {
    if (wdw.invoice) {
        return {
            projectType: 'Invoice',
            projectId: wdw.invoice.id,
            projectPoNumber: wdw.invoice.po_number
        }
    }
    if (wdw.payout) {
        return {
            projectType: 'Payout',
            projectId: wdw.payout.id,
            projectPoNumber: wdw.payout.po_number
        }
    }
    return null;
}

export default function AdminWithdrawalDialog({ withdrawalId = null, isOpen = true, onSaveForm = (payload) => 0, onCloseAction = () => 0 }) {
    const isDesktop = useIsDesktop();
    const [withdrawalStatus, setWithdrawalStatus] = useState('');
    const [notes, setNotes] = useState('');
    const [recipientMethod, setRecipientMethod] = useState('');
    const [recipientAccount, setRecipientAccount] = useState('');
    const [daysToPay, setDaysToPay] = useState(1);
    const { dialogState, openDialog, closeDialog } = useAlertDialog();

    const { data, error, isLoading } = useQuery({
        queryKey: ['withdrawals', withdrawalId],
        queryFn: () => API.withdrawals.adminGet(withdrawalId),
        refetchOnWindowFocus: false,
        enabled: !!withdrawalId
    });

    useEffect(() => {
        if (data) {
            setWithdrawalStatus(data.status);
            setDaysToPay(data.days_to_payment);
            setRecipientAccount(data.manual_subject || '');
            setRecipientMethod(data.manual_service || '');
            setNotes(data.notes || '');
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            openDialog('Error', <>Error fetching Withdrawal:<br />{error?.response?.data?.error || error.message}</>)
        }
    }, [error]);

    const disableEdit = !data || data.status == 'paid'

    const setNum = (event, setter) => {
        if (!Number.isNaN(event.target.valueAsNumber))
            setter(event.target.valueAsNumber)
    }

    const HandleSelectStatus = (value) => {
        setWithdrawalStatus(value);
    }

    const HandleCopyFromAvailable = () => {
        if (subjectData.subjectPaymentMethod != 'N/A') {
            setRecipientMethod(subjectData.subjectPaymentMethod);
            setRecipientAccount(subjectData.subjectPaymentEmail);
        }
        else
            openDialog('Error', 'No data on file to copy for this recipient. Please fill manually');
    }

    const paidByDate = new Date(data?.created_at.getTime() || Date.now());

    // Add the specified number of days
    paidByDate.setDate(paidByDate.getDate() + (daysToPay || 99));

    const paidByDatePassed = paidByDate.getTime() < Date.now() && withdrawalStatus != 'paid';

    const subjectData = !!data ? WithdrawalToSubjectData(data) : null;
    const projectData = !!data ? WithdrawalToProjectData(data) : null;

    const HandleSubmit = (e) => {
        e.preventDefault();
        if(withdrawalStatus == 'paid' && (!recipientAccount || !recipientMethod)){
            openDialog('Error', 'Please fill the transaction data to mark this withdrawal as paid.')
            return;
        }

        const payload = {
            status: withdrawalStatus || null,
            notes: notes || null,
            manual_service: recipientMethod || null,
            manual_subject: recipientAccount || null,
            days_to_payment: daysToPay || null,
        }

        onSaveForm(payload);
    }

    return (
        <Dialog open={isOpen} fullScreen={!isDesktop} maxWidth='false' onClose={onCloseAction} scroll='body'>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1}} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <AlertDialog alertState={dialogState}></AlertDialog>
            <form onSubmit={HandleSubmit}>
                <DialogTitle>{`Reference # <${data?.reference || '...'}>`}</DialogTitle>
                <DialogContent dividers>

                    <Grid container maxWidth={'60rem'} spacing={2} rowSpacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth label='ID' size='small' disabled name='readonly-id' value={data?.id || '...'}>
                            </TextField>
                        </Grid>
                        {isDesktop && <Grid item xs={12} md={6}>
                            <Box height='24px'></Box>
                        </Grid>}
                        <Grid item xs={12} md={16}>
                            <Divider></Divider>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                required
                                label='Brief'
                                name='withdrawal-brief'
                                value={data?.brief || '...'}
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RateField
                                fullWidth
                                minValue={0}
                                label='Subtotal'
                                name='withdrawal-amount'
                                value={data?.amount_no_fee.toFixed(2) || '...'}
                                disabled
                            >
                            </RateField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label='Fee percentage'
                                name='withdrawal-fee'
                                value={data?.fee_percentage.toFixed() || '...'}
                                disabled
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RateField
                                fullWidth
                                minValue={0}
                                label='Amount to Pay'
                                name='withdrawal-amount'
                                value={data ? (data.amount_no_fee * (1 - (data.fee_percentage / 100))).toFixed(2) : '...'}
                                disabled
                            >
                            </RateField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box height='24px'></Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label='Issued at'
                                name='withdrawal-issued-at'
                                type="datetime-local"
                                value={dateToDatetimeLocal(data?.created_at)}
                                disabled
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                error={paidByDatePassed}
                                fullWidth
                                label={data?.payed_at ? 'Paid at' : 'Pay by'}
                                name='withdrawal-issued-at'
                                type="datetime-local"
                                value={dateToDatetimeLocal(data?.payed_at || paidByDate)}
                                disabled
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                value={daysToPay}
                                onChange={(event) => setNum(event, setDaysToPay)}
                                variant='outlined'
                                label='Days to Payment'
                                type='number'
                                InputProps={{ inputProps: { min: 1 } }}
                                disabled={disableEdit}
                            ></TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label='Notes'
                                name='withdrawal-notes'
                                type='text'
                                multiline
                                minRows={4}
                                value={notes}
                                onChange={(e) => setNotes(e.target.value)}
                                disabled={disableEdit}
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                select
                                fullWidth
                                SelectProps={{ sx: { textTransform: 'capitalize' } }}
                                value={withdrawalStatus}
                                onChange={(e) => HandleSelectStatus(e.target.value)}
                                label='Status'
                                name='withdrawal-status'
                                disabled={disableEdit}
                            >
                                {withdrawalStatuses.map(status => <MenuItem key={status} value={status} sx={{ textTransform: 'capitalize' }}>{status}</MenuItem>)}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box height='24px'></Box>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Card variant='outlined' >
                                <CardHeader subheader='Recipient data on file'></CardHeader>
                                <CardContent sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 1.5
                                }}>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        gap: 1
                                    }}>
                                        <TextField
                                            fullWidth
                                            label={`Recipient (${data?.entity_type})`}
                                            name='recipient-name'
                                            value={subjectData?.subject || '...'}
                                        >
                                        </TextField>
                                        <Avatar src={subjectData?.subjectPicture} alt={subjectData?.subject}></Avatar>
                                    </Box>
                                    <TextField
                                        inputProps={{ sx: { textTransform: 'capitalize' } }}
                                        fullWidth
                                        label='Preferred payment method'
                                        name='recipient-method'
                                        value={subjectData?.subjectPaymentMethod || '...'}
                                    >
                                    </TextField>
                                    <TextField
                                        inputProps={{ sx: { textTransform: 'capitalize' } }}
                                        fullWidth
                                        label='Payment account on file'
                                        name='recipient-account'
                                        value={subjectData?.subjectPaymentEmail || '...'}
                                    >
                                    </TextField>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Card variant='outlined' >
                                <CardHeader subheader='Transaction data'></CardHeader>
                                <CardContent sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 1.5
                                }}>
                                    <Button sx={{ alignSelf: 'flex-end' }} onClick={HandleCopyFromAvailable}>Copy from available</Button>
                                    <TextField
                                        select
                                        fullWidth
                                        SelectProps={{ sx: { textTransform: 'capitalize' } }}
                                        value={recipientMethod}
                                        onChange={(e) => setRecipientMethod(e.target.value)}
                                        disabled={disableEdit}
                                        required={withdrawalStatus == 'paid'}
                                    >
                                        {withdrawalManualServices.map(service => <MenuItem key={service} value={service} sx={{ textTransform: 'capitalize' }}>{service}</MenuItem>)}
                                    </TextField>
                                    <TextField
                                        inputProps={{ sx: { textTransform: 'capitalize' } }}
                                        fullWidth
                                        label='Payment account'
                                        name='recipient-account'
                                        value={recipientAccount}
                                        onChange={(e) => setRecipientAccount(e.target.value)}
                                        disabled={disableEdit}
                                        required={withdrawalStatus == 'paid'}
                                    >
                                    </TextField>
                                    <FormControlLabel label='Update payment data in database?' control={<Switch></Switch>}></FormControlLabel>
                                </CardContent>
                            </Card>
                        </Grid>

                        {projectData && <Grid item xs={12} md={12}>
                            <Card variant='outlined' >
                                <CardHeader subheader={`Related Project data (${projectData?.projectType || '...'})`}></CardHeader>
                                <CardContent sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 1.5
                                }}>
                                    <TextField
                                        inputProps={{ sx: { textTransform: 'capitalize' } }}
                                        fullWidth
                                        label='Project ID'
                                        name='project-id'
                                        value={projectData?.projectId || '...'}
                                    >
                                    </TextField>
                                    <TextField
                                        inputProps={{ sx: { textTransform: 'capitalize' } }}
                                        fullWidth
                                        label='Project PO Number'
                                        name='project-ponumber'
                                        value={projectData?.projectPoNumber || '...'}
                                    >
                                    </TextField>
                                </CardContent>
                            </Card>
                        </Grid>}



                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button variant='text' color='secondary' type='submit' disabled={disableEdit}>Save</Button>
                    <Button variant='text' color='error' onClick={onCloseAction}>Cancel</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

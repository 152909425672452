import React, { useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    Typography,
    Avatar,
    Divider,
    Skeleton,
    IconButton
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import API from '../../API';
import routes from '../../Config/routes';
import CreatorDialog from '../Dashboard/Search/creatorintake'; // Ensure the correct path
import useAuth from "../../Hooks/use-auth";
import RosterSettingsDialog from './agencyRosterSettings';
import { Settings as SettingsIcon } from '@mui/icons-material';
import AgencyZapfeed from './Components/agencyZapfeed';

export default function AgencyStart() {
    const navigate = useNavigate();
    const [openCreatorDialog, setOpenCreatorDialog] = useState(false);
    const { getCurrrentUser } = useAuth();
    const [openRosterSettings, setOpenRosterSettings] = useState(false); // <--- NEW

    const userInfo = getCurrrentUser();
    const agencyName = userInfo?.company_name;

    // Fetch payout data
    const { data: dashboard, isLoading: isLoadingDashboard } = useQuery({
        queryKey: ['agency', 'dashboard'],
        queryFn: API.agency.dashboard,
        refetchOnWindowFocus: false,
    });

    // Handlers for CreatorDialog
    const handleOpenCreatorDialog = () => {
        setOpenCreatorDialog(true);
    };

    const handleCloseCreatorDialog = () => {
        setOpenCreatorDialog(false);
    };

    return (
        <Box sx={{ padding: 2 }}>
            <Typography variant="h4" gutterBottom>
                Agency Dashboard
            </Typography>

            {/* Summary Cards */}
            <Grid container spacing={3} sx={{ marginBottom: 2 }}>
                {/* Total Creators */}
                <Grid item xs={6} sm={3}>
                    <Card elevation={3} sx={{ backgroundColor: '#424242' }}>
                        <CardContent>
                            <Typography variant="h6" color="common.white">
                                Creators
                            </Typography>
                            <Typography variant="h3" color="common.white">
                                {!dashboard ? <Skeleton width={40} /> : dashboard.creators}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={6} sm={3}>
                    <Card elevation={3} sx={{ backgroundColor: '#1E88E5' }}>
                        <CardContent>
                            <Typography variant="h6" color="common.white">
                                Active Campaigns
                            </Typography>
                            <Typography variant="h3" color="common.white">
                                {!dashboard ? <Skeleton width={40} /> : dashboard.active_campaigns}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={6} sm={3}>
                    <Card elevation={3} sx={{ backgroundColor: '#1E88E5' }}>
                        <CardContent>
                            <Typography variant="h6" color="common.white">
                                Active Partnershpis
                            </Typography>
                            <Typography variant="h3" color="common.white">
                                {!dashboard ? <Skeleton width={40} /> : dashboard.active_partnerships}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Payouts (Non-Cancelled) */}
                <Grid item xs={6} sm={3}>
                    <Card elevation={3} sx={{ backgroundColor: '#FB8C00' }}>
                        <CardContent>
                            <Typography variant="h6" color="common.white">
                                Pending Payouts
                            </Typography>
                            <Typography variant="h3" color="common.white">
                                {!dashboard ? <Skeleton width={40} /> : dashboard.active_payouts}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                    <AgencyZapfeed></AgencyZapfeed>
                </Grid>
                <Grid item xs={12} md={7}>
                    {/* Quick Actions (Right-hand side menu) */}
                    <Card elevation={3} sx={{ height: '100%' }}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Quick Actions
                            </Typography>
                            <Divider sx={{ marginBottom: 2 }} />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Button
                                        variant="outlined"
                                        fullWidth
                                        onClick={() => navigate(routes.agencyCreators)}
                                        startIcon={<Avatar sx={{ width: 24, height: 24, bgcolor: 'transparent' }}>👥</Avatar>}
                                    >
                                        Manage Creators
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Button
                                        variant="outlined"
                                        fullWidth
                                        onClick={() => navigate('/dashboard')}
                                        startIcon={<Avatar sx={{ width: 24, height: 24, bgcolor: 'transparent' }}>📊</Avatar>}
                                    >
                                        View Reports
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Button
                                        variant="outlined"
                                        fullWidth
                                        onClick={() => navigate('/user')}
                                        startIcon={<Avatar sx={{ width: 24, height: 24, bgcolor: 'transparent' }}>⚙️</Avatar>}
                                    >
                                        Settings
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Button
                                        variant="outlined"
                                        fullWidth
                                        onClick={handleOpenCreatorDialog}
                                        startIcon={<Avatar sx={{ width: 24, height: 24, bgcolor: 'transparent' }}>➕</Avatar>}
                                    >
                                        Add Creator
                                    </Button>
                                </Grid>

                                {/* NEW: Settings Icon Button */}
                                {/* Settings Icon and Public Roster */}
                                <Grid item xs={12}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            gap: 2,
                                            flexDirection: 'column'
                                        }}
                                    >
                                        <IconButton
                                            color="primary"
                                            onClick={() => setOpenRosterSettings(true)}
                                        >
                                            <SettingsIcon />
                                        </IconButton>
                                        <Typography variant="body2">
                                            Roster Settings
                                        </Typography>

                                        <Button
                                            variant="contained"
                                            size="large"
                                            onClick={() => navigate(`/roster/${agencyName}`)}
                                        >
                                            Preview Public Roster
                                        </Button>
                                    </Box>
                                </Grid>

                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {/* Creator Dialog */}
            <CreatorDialog open={openCreatorDialog} onClose={handleCloseCreatorDialog} />
            {/* NEW: Roster Settings Dialog */}
            <RosterSettingsDialog
                open={openRosterSettings}
                onClose={() => setOpenRosterSettings(false)}
                agencyName={agencyName}
            />
        </Box>
    );
}

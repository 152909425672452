import React, { useEffect, useState } from "react";
import { Box, Typography, Fade, Container } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import profilePhoto from "../../Components/globalAssets/ppfLogo.png";
import testimonial1 from "../../Components/globalAssets/clientspfp/CUSTOM-LOADER-CMG-compressed.jpg";
import testimonial2 from "../../Components/globalAssets/clientspfp/L-2311-1601650775-2816.jpg";
import testimonial3 from "../../Components/globalAssets/clientspfp/ONErpm-logo.webp";
import testimonial4 from "../../Components/globalAssets/clientspfp/SONY_MUSIC__logo.jpg";
import testimonial5 from "../../Components/globalAssets/clientspfp/Temu_logo.svg";
import testimonial6 from "../../Components/globalAssets/clientspfp/aithor_ai_logo.jpg";
import testimonial7 from "../../Components/globalAssets/clientspfp/almo.jpg";
import testimonial8 from "../../Components/globalAssets/clientspfp/artist_publishing_group_logo.jpg";
import testimonial9 from "../../Components/globalAssets/clientspfp/ifnluienmedia.png";

import API from "../../API";

const EndorsedBy = () => {
  const [creators, setCreators] = useState([]);

  useEffect(() => {
    API.creators
      .listPartners()
      .then((data) => setCreators(data.creators))
      .catch((error) => console.error("Error fetching creator partners:", error));
  }, []);

  // Slider settings for creators
  const creatorSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // Slider settings for clients
  const clientSettings = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 5,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const clientLogos = [
    testimonial1,
    testimonial2,
    testimonial3,
    testimonial4,
    testimonial5,
    testimonial6,
    testimonial7,
    testimonial8,
    testimonial9,
  ];

  const clientNames = [
    "CMG",
    "Interscope Records",
    "ONErpm",
    "Sony Music",
    "Temu",
    "Aithor AI",
    "Alamo",
    "Artist Publishing Group",
    "Influence Media",
  ];

  // Light-mode colors
  const textPrimary = "#FFF";
  const cardGradient = "linear-gradient(145deg,rgba(255, 255, 255, 0.18),rgba(255, 255, 255, 0.02))";

  return (
    <Fade in={true} timeout={1000}>
      <Box
        component="section"
        sx={{
          maxWidth: '60rem',
          width: '100%',
          minWidth: '0px',
          marginInline: 'auto',
        }}
      >
        <Container maxWidth="xl">
          {/* Main Title */}
          <Typography
            variant="h3"
            align="center"
            sx={{ fontWeight: "bold", mb: 2, color: textPrimary }}
          >
            Endorsed By
          </Typography>
          {/*<Typography
            variant="h6"
            align="center"
            sx={{
              maxWidth: "700px",
              mx: "auto",
              mb: 6,
              opacity: 0.9,
              color: textPrimary,
            }}
          >
            We’re proud to be trusted by both leading creators and top-tier
            clients. Here’s a glimpse of who we collaborate with:
          </Typography>*/}

          {/* Featured Creators */}
          <Box sx={{ mb: 8 }}>
            <Typography
              variant="h4"
              align="center"
              sx={{ fontWeight: "bold", mb: 4, color: textPrimary, display: { xs: 'none', md: 'inline-block' }}}
            >
              Our Featured Creators
            </Typography>
            <Box
              sx={{
                maxWidth: "80rem",
                mx: "auto",
                position: "relative",
              }}
            >
              <Slider {...creatorSettings}>
                {creators.slice(0, 12).map((creator, index) => (
                  <Box key={index}>
                    <Box
                      sx={{
                        background: cardGradient,
                        borderRadius: 2,
                        p: 3,
                        mx: 1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                        transition: "transform 0.3s, box-shadow 0.3s",
                        cursor: "pointer",
                        "&:hover": {
                          transform: "translateY(-5px)",
                          boxShadow: "0 8px 20px rgba(0,0,0,0.1)",
                        },
                      }}
                    >
                      <img
                        src={creator.pfphref || profilePhoto}
                        alt={creator.creator}
                        style={{
                          width: 100,
                          height: 100,
                          borderRadius: "50%",
                          objectFit: "cover",
                          marginBottom: "10px",
                          border: "3px solid #ddd",
                        }}
                      />
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: 600,
                          mt: 1,
                          fontSize: "1.05rem",
                          color: textPrimary,
                        }}
                      >
                        @{creator.creator}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Slider>
            </Box>
          </Box>

          {/* Valued Clients */}
          <Box sx={{ mb: 4 }}>
            <Typography
              variant="h4"
              align="center"
              sx={{ fontWeight: "bold", mb: 4, color: textPrimary, display: { xs: 'none', md: 'inline-block' } }}
            >
              Our Valued Clients
            </Typography>
            <Box
              sx={{
                maxWidth: "80rem",
                mx: "auto",
                position: "relative",
              }}
            >
              <Slider {...clientSettings}>
                {clientLogos.map((logo, index) => (
                  <Box key={index}>
                    <Box
                      sx={{
                        background: cardGradient,
                        borderRadius: 2,
                        p: 3,
                        mx: 1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                        transition: "transform 0.3s, box-shadow 0.3s",
                        cursor: "pointer",
                        "&:hover": {
                          transform: "translateY(-5px)",
                          boxShadow: "0 8px 20px rgba(0,0,0,0.1)",
                        },
                      }}
                    >
                      <img
                        src={logo}
                        alt={clientNames[index]}
                        style={{
                          width: 100,
                          height: 100,
                          borderRadius: "50%",
                          objectFit: "contain",
                          marginBottom: "10px",
                          border: "3px solid #ddd",
                          backgroundColor: "#fff", // ensures clarity if logos are transparent
                        }}
                      />
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: 600,
                          mt: 1,
                          fontSize: "1.05rem",
                          color: textPrimary,
                        }}
                      >
                        {clientNames[index]}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Slider>
            </Box>
          </Box>
        </Container>
      </Box>
    </Fade>
  );
};

export default EndorsedBy;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
  Container,
  Paper,
  Backdrop,
  CircularProgress,
  Snackbar,
  Card,
  CardContent,
  Divider,
  Stepper,
  Step,
  StepLabel,
  Toolbar,
  Chip,
  keyframes
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import HomeHeader from '../../../../Components/HomeHeader';
import HomeFooter from '../../../../Components/HomeFooter';
import { globalStyles } from '../../../../Utils/Styles';
import { lightTheme } from '../../../../Utils/baseTheme';
import { ThemeProvider } from '@mui/material/styles';
import client from '../../../../API';
import useMediaQuery from '@mui/material/useMediaQuery';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const AnimatedTimeBlock = ({ value, label, isMobile }) => (
  <Box
    sx={{
      textAlign: 'center',
      mx: 1,
      color: 'text.primary' // Reset color inheritance from parent
    }}
  >
    <Box
      sx={{
        bgcolor: 'background.paper',
        borderRadius: 2,
        p: 1,
        minWidth: 60,
        animation: `${pulse} 1s ease-in-out`,
        boxShadow: 2
      }}
    >
      <Typography
        variant="h5"
        fontWeight="bold"
        sx={{ color: 'text.primary' }} // Explicit dark text color
      >
        {value.toString().padStart(2, '0')}
      </Typography>
    </Box>
    <Typography variant="caption" sx={{ color: 'text.secondary' }}>
      {label}
    </Typography>
  </Box>
);

const CountdownTimer = ({ proposalDate }) => {
  const [timeLeft, setTimeLeft] = useState(0);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  useEffect(() => {
    if (!proposalDate) return;

    const calculateTimeLeft = () => {
      const proposalTime = new Date(proposalDate);
      const deadline = new Date(proposalTime.getTime() + 24 * 60 * 60 * 1000);
      const now = new Date();
      const difference = deadline - now;
      setTimeLeft(Math.floor(difference / 1000) * 1000);
    };

    calculateTimeLeft();
    const timer = setInterval(calculateTimeLeft, 1000);
    return () => clearInterval(timer);
  }, [proposalDate]);

  if (!proposalDate) return null;

  const isExpired = timeLeft <= 0;
  const hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((timeLeft / 1000 / 60) % 60);
  const seconds = Math.floor((timeLeft / 1000) % 60);

  return (
    <Box
      sx={{
        backgroundColor: isExpired ? 'warning.dark' : 'error.main',
        p: 2,
        borderRadius: 1,
        mb: 3,
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: { xs: 'flex-start', sm: 'center' },
        gap: 1,
        boxShadow: 2
      }}
    >
      {isExpired ? (
        <Typography variant="body1" sx={{ fontWeight: 600 }}>
          This offer is still available, but could be removed soon, accept now to confirm!
        </Typography>
      ) : (
        <>
          <Typography variant="body1" sx={{ fontWeight: 600, whiteSpace: 'nowrap' }}>
            ⏳ Time Sensitive:
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            <AnimatedTimeBlock 
              value={hours} 
              label={isMobile ? 'hrs' : 'hours'} 
              isMobile={isMobile} 
            />
            <AnimatedTimeBlock 
              value={minutes} 
              label={isMobile ? 'mins' : 'minutes'} 
              isMobile={isMobile} 
            />
            <AnimatedTimeBlock 
              value={seconds} 
              label={isMobile ? 'secs' : 'seconds'} 
              isMobile={isMobile} 
            />
          </Box>
          <Typography variant="body1" sx={{ fontWeight: 600, whiteSpace: 'nowrap' }}>
            left to accept!
          </Typography>
        </>
      )}
    </Box>
  );
};

const BriefRenderer = ({ text, driveLink }) => {
  // Function to detect URLs and convert them to links
  const renderTextWithLinks = (content) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return content.split(urlRegex).map((part, index) => {
      if (part.match(urlRegex)) {
        return (
          <a 
            key={index} 
            href={part} 
            target="_blank" 
            rel="noopener noreferrer"
            style={{ color: lightTheme.palette.primary.main, textDecoration: 'underline' }}
          >
            {part}
          </a>
        );
      }
      return part;
    });
  };

  // Parse the structured brief
  const sections = {
    creative: [],
    crossposting: [],
    usage: '',
    instructions: []
  };
  
  let currentSection = null;
  
  text.split('\n').forEach(line => {
    if (line.startsWith('# Creative')) {
      currentSection = 'creative';
    } else if (line.startsWith('# Posting Instructions')) {
      currentSection = 'instructions';
    } else if (line.startsWith('# Additional Requirements')) {
      currentSection = 'additional';
    } else if (currentSection) {
      if (currentSection === 'additional') {
        if (line.startsWith('Crosspost(s):')) {
          sections.crossposting = line.replace('Crosspost(s):', '').trim().split(', ');
        } else if (line.startsWith('Usage:')) {
          sections.usage = line.replace('Usage:', '').trim();
        }
      } else {
        sections[currentSection].push(line.trim());
      }
    }
  });

  return (
    <Box sx={{ mt: 3 }}>
      {/* Creative Brief Section */}
      {sections.creative.length > 0 && (
        <Card sx={{ mb: 3 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Creative Brief
            </Typography>
            {sections.creative.map((line, index) => (
              <Typography key={`creative-${index}`} paragraph>
                {renderTextWithLinks(line)}
              </Typography>
            ))}
          </CardContent>
        </Card>
      )}

      {/* Posting Instructions Section */}
      {sections.instructions.length > 0 && (
        <Card sx={{ mb: 3 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Posting Requirements
            </Typography>
            {sections.instructions.map((line, index) => (
              <Typography key={`instructions-${index}`} paragraph>
                {renderTextWithLinks(line)}
              </Typography>
            ))}
          </CardContent>
        </Card>
      )}

      {/* Additional Requirements Section */}
      {(sections.crossposting.length > 0 || sections.usage) && (
        <Card sx={{ mb: 3 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Additional Requirements
            </Typography>
            
            {sections.crossposting.length > 0 && (
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle2">Required Platforms:</Typography>
                <Box sx={{ display: 'flex', gap: 1, mt: 1, flexWrap: 'wrap' }}>
                  {sections.crossposting.map((platform, index) => (
                    <Chip 
                      key={`platform-${index}`}
                      label={platform}
                      variant="outlined"
                    />
                  ))}
                </Box>
              </Box>
            )}

            {sections.usage && (
              <Box>
                <Typography variant="subtitle2">Usage Rights:</Typography>
                <Typography variant="body2">{sections.usage}</Typography>
              </Box>
            )}
          </CardContent>
        </Card>
      )}

      {driveLink && (
        <Button 
          variant="contained"
          startIcon={<CloudDownloadIcon />}
          href={driveLink}
          target="_blank"
        >
          Download Campaign Assets
        </Button>
      )}
    </Box>
  );
};

const CampaignOfferPage = () => {
  const { creator, campaignId } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // Main offer details from backend
  const [offerDetails, setOfferDetails] = useState(null);
  // Client info from the same route, if it exists
  const [clientData, setClientData] = useState(null);

  const fetchOfferDetails = async () => {
    try {
      setLoading(true);

      // Call your API client method. This should hit /getCampaignCreatorSpecs
      const response = await client.campaigns.getCreatorSpecs({
        creator: encodeURIComponent(creator),
        campaignId
      });

      if (response.status === 'success') {
        // "data" holds your offer/campaign fields
        setOfferDetails(response.data);

        // "clientData" is attached at the top-level of the response
        if (response.clientData && response.clientData.client_id) {
          setClientData(response.clientData);
        } else {
          setClientData(null);
        }
      } else {
        setError(response.error || 'Failed to load offer details');
      }
    } catch (err) {
      setError(err.response?.data?.error || err.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOfferDetails();
    // eslint-disable-next-line
  }, [creator, campaignId]);

  const handleOfferResponse = async (accepted) => {
    try {
      setLoading(true);
      const response = await client.campaigns.updateCampaignCreatorSpecs({
        campaignId,
        creators: [
          {
            id: creator,
            status: accepted ? 'Accepted' : 'Declined'
          }
        ]
      });
  
      if (response.status === 'success') {
        // Redirect to collabs portal with Offered tab preselected
        navigate('/creatorconnect/collabs?tab=Offered');
      } else {
        setError(response.error || 'Failed to submit response');
      }
    } catch (err) {
      setError(err.response?.data?.error || err.message || 'Submission failed');
    } finally {
      setLoading(false);
    }
  };

  const handleAuthRedirect = (action) => {
    navigate(
      `/login?tab=creator&redirect=${encodeURIComponent(
        '/creatorconnect/collabs?tab=Offered'
      )}&action=${action}`
    );
  };

  if (loading) {
    return (
      <Backdrop open={loading} style={{ color: '#fff', zIndex: 1300 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <ThemeProvider theme={lightTheme}>
      <Box
        sx={{
          width: '100%',
          minHeight: '100vh',
          backgroundImage: 'linear-gradient(to bottom, #ffffff, #e0e0e0)',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <HomeHeader />
        <Toolbar />

        <Container
          sx={{
            ...globalStyles.wholeHeightMinusToolbar,
            py: 2,
            // Allow Container to be smaller on mobile
            maxWidth: { xs: 'sm', md: 'md' },
            width: '100%',
            flex: 1
          }}
        >
          {error ? (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          ) : (
            <Paper
              elevation={3}
              sx={{
                p: { xs: 2, sm: 4 },
                // Slightly reduce padding on mobile
                mt: { xs: 1, sm: 2 }
              }}
            >
              {/* Show countdown if pitched */}
              {offerDetails?.status === 'Pitched' && (
                <CountdownTimer proposalDate={offerDetails.proposal_date} />
              )}

              <Box sx={{ margin: 2 }}>
                <Stepper activeStep={0}>
                  <Step>
                    <StepLabel>Campaign Offer Details</StepLabel>
                  </Step>
                </Stepper>
              </Box>

              <Typography
                variant="h4"
                gutterBottom
                align="center"
                sx={{ fontSize: { xs: '1.8rem', sm: '2.125rem' } }}
              >
                {offerDetails?.campaignName} for {creator}
              </Typography>

              <Card variant="outlined" sx={{ mt: 3, mb: 3 }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Campaign Overview
                  </Typography>
                  {/* Make overview info stack on smaller screens */}
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', sm: 'row' },
                      justifyContent: 'space-between'
                    }}
                  >
                    <Typography>Due Date:</Typography>
                    <Typography fontWeight="bold">
                      {new Date(offerDetails?.idealDueDate).toLocaleDateString(
                        'en-US',
                        {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric'
                        }
                      )}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
              {/* Campaign Brief & Requirements */}
              <>
                  <Divider sx={{ my: 3 }} />
                  <Box sx={{ mb: 4 }}>
                    <Typography variant="h6" gutterBottom>
                      Campaign Brief & Requirements
                    </Typography>
                    <BriefRenderer
                      text={offerDetails?.creatorBrief}
                      driveLink={offerDetails?.drive_link}
                    />
                  </Box>
                </>

              <Divider sx={{ my: 3 }} />

              <Box sx={{ mb: 4 }}>
                <Typography variant="h6" gutterBottom>
                  Financial Terms
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    justifyContent: 'space-between',
                    mb: 2
                  }}
                >
                  <Typography>Offer Value:</Typography>
                  <Typography fontWeight="bold">
                    $
                    {(
                      offerDetails?.agencyRate || offerDetails?.price || 0
                    ).toFixed(2)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    justifyContent: 'space-between'
                  }}
                >
                  <Typography>Payment Terms:</Typography>
                  <Typography fontWeight="bold">
                    30 days standard or instant via BlitzPay
                  </Typography>
                </Box>
              </Box>

              
              {/* About the Client section (only if clientData exists) */}
              {clientData && clientData.client_id && (
                <Box sx={{ mt: 4 }}>
                  <Typography variant="h5" gutterBottom>
                    About The Client
                  </Typography>
                  <Card sx={{ mt: 2 }}>
                    <CardContent>
                      {clientData.profilephoto && (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mb: 2
                          }}
                        >
                          <img
                            src={clientData.profilephoto}
                            alt={`${clientData.full_name}'s Profile`}
                            style={{ width: 120, height: 120, borderRadius: '50%' }}
                          />
                        </Box>
                      )}
                      <Typography variant="h6">
                        {clientData.company || 'N/A'}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
                        {clientData.full_name || ''}
                      </Typography>

                      {clientData.bio && (
                        <Typography variant="body2" sx={{ mt: 2 }}>
                          {clientData.bio}
                        </Typography>
                      )}

                      {clientData.location && (
                        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                          Location: {clientData.location}
                        </Typography>
                      )}
                    </CardContent>
                  </Card>
                </Box>
              )}

              {/* Acceptance / Negotiation / Decline Buttons */}
              {offerDetails?.status === 'Accepted' ? (
                <Typography
                  variant="h5"
                  align="center"
                  sx={{
                    my: 4,
                    color: 'success.dark',
                    fontWeight: 'medium',
                    p: 2,
                    backgroundColor: 'rgba(46, 125, 50, 0.1)',
                    borderRadius: 2
                  }}
                >
                  You have accepted this offer
                </Typography>
              ) : offerDetails?.status === 'Counter' ? (
                <Box
                  sx={{
                    textAlign: 'center',
                    mt: 4,
                    p: 3,
                    backgroundColor: 'action.hover',
                    borderRadius: 2
                  }}
                >
                  <Typography variant="body1" sx={{ mb: 2 }}>
                    This Deal Is Under Negotiation, Login to Blitz to see more details
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('/login')}
                  >
                    Login
                  </Button>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    gap: 2,
                    mt: 4
                  }}
                >
                  <Button
                    variant="contained"
                    color="success"
                    size="large"
                    onClick={() => handleOfferResponse(true)}
                    disabled={loading}
                    sx={{ flex: 1 }}
                  >
                    Accept Offer
                  </Button>
                  <Button
                    variant="contained"
                    color="warning"
                    size="large"
                    onClick={() => handleAuthRedirect('negotiate')}
                    sx={{ flex: 1 }}
                  >
                    Negotiate Offer
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => handleAuthRedirect('chat')}
                    sx={{ flex: 1 }}
                  >
                    Chat with Brand
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    size="large"
                    onClick={() => handleOfferResponse(false)}
                    disabled={loading}
                    sx={{ flex: 1 }}
                  >
                    Decline
                  </Button>
                </Box>
              )}
            </Paper>
          )}

          <Snackbar
            open={!!error}
            autoHideDuration={6000}
            onClose={() => setError('')}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert onClose={() => setError('')} severity="error">
              {error}
            </Alert>
          </Snackbar>
        </Container>

        <HomeFooter />
      </Box>
    </ThemeProvider>
  );
};

export default CampaignOfferPage;

import React from "react";
import { Box, Button, Typography, Fade, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import routes from "../../Config/routes";
import aiManagerImage from "../../Components/globalAssets/blitzBoltBlack.png";

const AIManagerSection = () => {
  const navigate = useNavigate();

  const handleAccess = () => navigate(`${routes.demoRegister}/thealexroa`);

  // Light-mode colors
  const textPrimary = "#000";

  return (
    <Fade in={true} timeout={1000}>
      <Box
        component="section"
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: { xs: 'center', md: 'space-around' },
          alignItems: "center",
          gap: { xs: 4, md: 8 },
          maxWidth: '60rem',
          height: '100%',
          marginInline: 'auto'
        }}
      >
        {/* Left Section with Image */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width:'50%',
            maxWidth: { xs: "150px", md: "250px" },
          }}
        >
          <Box
            sx={{
              borderRadius: "50%",
              overflow: "hidden",
              width: "100%",
              boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",
            }}
          >
            <img
              src={aiManagerImage}
              alt="AI Manager"
              style={{
                width: "100%",
                display: "block",
                objectFit: "cover",
              }}
            />
          </Box>
        </Box>

        {/* Right Section with Text */}
        <Box
          sx={{
            flex: {xs:'none', md:1},
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: { xs: "center", md: "left" },
            alignItems: { xs: "center", md: "flex-start" },
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: 800,
              mb: 2,
              fontSize: { xs: "2rem", md: "2.5rem" },
              color: textPrimary,
            }}
          >
            Blitz AI Agent for Creators
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ mb: 3, color: textPrimary, display: { xs: 'none', md: 'inline-block' } }}
          >
            Transform how you manage your deals with the Blitz AI Agent. This all-in-one
            tool helps creators clean up their inbox, source new deals, respond and
            follow up efficiently, and even book meetings with a single link.
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ mb: 3, color: textPrimary }}
          >
            With the Blitz AI Agent, creators who enable this feature see 2–3x more
            deals closed. Our intelligent system takes care of the repetitive tasks,
            allowing you to focus on creating great content.
          </Typography>
          <Typography
            variant="body1"
            sx={{
              mb: 3,
              fontStyle: "italic",
              color: "rgba(51,51,51,0.7)",
              display: { xs: 'none', md: 'inline-block' } 
            }}
          >
            &quot;Blitz AI Agent has doubled my productivity. I never miss an opportunity, and
            my inbox stays organized!&quot; – @itsyaboyaustin4
          </Typography>
          <Button
            variant="contained"
            onClick={handleAccess}
            sx={{
              px: 4,
              py: 1.5,
              fontSize: "1.1rem",
              fontWeight: 600,
              textTransform: "none",
              borderRadius: 50,
              // Updated to our light-mode gradient
              background: "linear-gradient(45deg, #1976d2 30%, #42a5f5 90%)",
              boxShadow: "0 4px 15px rgba(25,118,210,0.3)",
              color: "#fff",
              "&:hover": {
                transform: "translateY(-2px)",
                boxShadow: "0 6px 20px rgba(25,118,210,0.4)",
              },
              transition: "all 0.3s ease",
            }}
          >
            Enable AI Agent Now
          </Button>
        </Box>
      </Box>
    </Fade>
  );
};

export default AIManagerSection;

import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Grid,
  Typography,
  Button,
  Switch,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Chip,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
  useMediaQuery,
  Checkbox,
  FormGroup,
  FormControlLabel as MuiFormControlLabel,
} from '@mui/material';
import useAuth from '../../../../Hooks/use-auth';
import { useQuery } from 'react-query';
import client from '../../../../API';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const socialPlatforms = [
  'TikTok',
  'Instagram',
  'YouTube',
  'Facebook',
  'X/Twitter',
];

const usageDurations = [
  { value: '30', label: '30 days' },
  { value: '60', label: '60 days' },
  { value: '90', label: '90 days' },
  { value: '180', label: '180 days' },
  { value: '360', label: '360 days' }
];

const CampaignDetails = ({ onNext, onBack, setCampaignData }) => {
  const { getCurrrentUser } = useAuth();
  const currentUser = getCurrrentUser();
  const userCompany = currentUser?.company_name || '';

  const [campaignName, setCampaignName] = useState('');
  const [emailRecipient, setEmailRecipient] = useState('');
  const [managers, setManagers] = useState([]);
  const [currentManager, setCurrentManager] = useState('0');
  const [asset, setAsset] = useState(null);
  const [assetDialogOpen, setAssetDialogOpen] = useState(false);
  const [editMode, setEditMode] = useState('structured');
  const [requirements, setRequirements] = useState({
    creativeBrief: '',
    crossposting: [],
    usage: ''
  });
  const [showUsageCrosspostingAlert, setShowUsageCrosspostingAlert] = useState(false);

  const { data: files } = useQuery('files', () => client.files.fetch(''));
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await client.companies.listUsers();
        setManagers(data);
      } catch (error) {
        setManagers([]);
      }
    };
    fetchData();
  }, []);

  const handleCrosspostChange = (platform) => {
    const isCurrentlyChecked = requirements.crossposting.includes(platform);
    setRequirements(prev => ({
      ...prev,
      crossposting: isCurrentlyChecked
        ? prev.crossposting.filter(p => p !== platform)
        : [...prev.crossposting, platform]
    }));
    if (!isCurrentlyChecked) {
      setShowUsageCrosspostingAlert(true);
    }
  };

  const handleUsageChange = (event) => {
    setRequirements(prev => ({
      ...prev,
      usage: event.target.value
    }));
    setShowUsageCrosspostingAlert(true);
  };

  const handleCreativeChange = (event) => {
    setRequirements(prev => ({
      ...prev,
      creativeBrief: event.target.value
    }));
  };

  const convertToText = () => {
    return `# Creative\n${requirements.creativeBrief}\n\n# Additional Requirements\nCrosspost(s): ${requirements.crossposting.join(', ')}\nUsage: ${requirements.usage} days`;
  };

  const parseFromText = (text) => {
    const newRequirements = {
      creativeBrief: '',
      crossposting: [],
      usage: ''
    };

    let currentSection = '';
    
    text.split('\n').forEach(line => {
      if (line.startsWith('# Creative')) {
        currentSection = 'creative';
      } else if (line.startsWith('# Additional Requirements')) {
        currentSection = 'additional';
      } else if (currentSection === 'creative') {
        newRequirements.creativeBrief += line + '\n';
      } else if (currentSection === 'additional') {
        if (line.startsWith('Crosspost(s):')) {
          newRequirements.crossposting = line.replace('Crosspost(s):', '').trim().split(', ');
        }
        if (line.startsWith('Usage:')) {
          newRequirements.usage = line.replace('Usage:', '').trim().split(' ')[0];
        }
      }
    });

    newRequirements.creativeBrief = newRequirements.creativeBrief.trim();
    return newRequirements;
  };

  const handleNext = () => {
    const managerIndex = Number.parseInt(currentManager);
    const campaignData = {
      campaignName,
      campaignBrief: editMode === 'structured' ? convertToText() : '',
      emailRecipient,
      manager: managers[managerIndex]
        ? {
            name: `${managers[managerIndex].first_name} ${managers[managerIndex].last_name}`,
            email: managers[managerIndex].email,
          }
        : null,
      asset: asset ? asset.file_url : null,
    };
    setCampaignData(campaignData);
    onNext();
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2 }}>
      <Typography variant="h5" gutterBottom>Campaign Details</Typography>
      
      <Box sx={{ width: '100%', maxWidth: 800 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Campaign Name"
              variant="outlined"
              fullWidth
              value={campaignName}
              onChange={(e) => setCampaignName(e.target.value)}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <Card sx={{ mb: 2 }}>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                  <Typography variant="h6">Campaign Brief</Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={editMode === 'structured'}
                        onChange={(e) => setEditMode(e.target.checked ? 'structured' : 'simple')}
                        color="primary"
                      />
                    }
                    label="Structured Editor"
                  />
                </Box>

                {editMode === 'structured' ? (
                  <>
                    <TextField
                      fullWidth
                      multiline
                      minRows={3}
                      label="Creative Brief"
                      value={requirements.creativeBrief}
                      onChange={handleCreativeChange}
                      sx={{ mb: 3 }}
                    />

                    <FormControl fullWidth sx={{ mb: 3 }}>
                      <InputLabel>Usage Duration</InputLabel>
                      <Select
                        value={requirements.usage}
                        onChange={handleUsageChange}
                        label="Usage Duration"
                      >
                        {usageDurations.map((duration) => (
                          <MenuItem key={duration.value} value={duration.value}>
                            {duration.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <Typography variant="subtitle1" gutterBottom>
                      Crossposting Platforms
                    </Typography>
                    <FormGroup row>
                      {socialPlatforms.map((platform) => (
                        <MuiFormControlLabel
                          key={platform}
                          control={
                            <Checkbox
                              checked={requirements.crossposting.includes(platform)}
                              onChange={() => handleCrosspostChange(platform)}
                            />
                          }
                          label={platform}
                        />
                      ))}
                    </FormGroup>
                  </>
                ) : (
                  <TextField
                    label="Campaign Brief"
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={6}
                    value={convertToText()}
                    onChange={() => {}}
                    helperText="Switch to structured editor to make changes"
                    disabled
                  />
                )}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Email Recipient"
              variant="outlined"
              fullWidth
              value={emailRecipient}
              onChange={(e) => setEmailRecipient(e.target.value)}
              required
            />
          </Grid>

          {userCompany === 'TCC' && managers?.length > 0 && (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Campaign Manager</InputLabel>
                <Select
                  value={currentManager}
                  onChange={(e) => setCurrentManager(e.target.value)}
                  label="Campaign Manager"
                >
                  {managers.map((manager, index) => (
                    <MenuItem value={index} key={index}>
                      {manager.first_name} {manager.last_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}

          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button variant="outlined" onClick={onBack}>Back</Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                disabled={!campaignName}
              >
                Next
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Dialog open={assetDialogOpen} onClose={() => setAssetDialogOpen(false)} fullWidth maxWidth="md">
        <DialogTitle>Select Campaign Assets</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {files?.map((file) => (
              <Grid item xs={12} sm={6} md={4} key={file.id}>
                <Card
                  onClick={() => {
                    setAsset(file);
                    setAssetDialogOpen(false);
                  }}
                  sx={{
                    cursor: 'pointer',
                    border: asset?.id === file.id ? 2 : 0,
                    borderColor: 'primary.main'
                  }}
                >
                  <CardContent>
                    <Typography variant="subtitle1">{file.filename}</Typography>
                    <Typography variant="caption">
                      {new Date(file.uploaded_at).toLocaleDateString()}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAssetDialogOpen(false)} color="primary" variant="outlined">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showUsageCrosspostingAlert}
        onClose={() => setShowUsageCrosspostingAlert(false)}
      >
        <DialogTitle>Important Reminder</DialogTitle>
        <DialogContent>
          <Typography>
            Make sure your offer to each creator reflects usage and crossposting. If it doesn't, the creator may try to negotiate or decline your offer.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowUsageCrosspostingAlert(false)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CampaignDetails;
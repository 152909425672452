import React, { useEffect, useState, useRef } from "react";
import { Box, Typography, Button, Container, Toolbar, Link as MuiLink } from "@mui/material";
import { useLocation, useNavigate, Link as RouterLink } from "react-router-dom";

// Import your main API similarly to how CreatorBanner imports it
import API from "../../../API";

import paymentImage from "../../../Components/globalAssets/blackwhite.png";
import marketplaceImage from "../../../Components/globalAssets/logo_placeholder.png";
import routes from "../../../Config/routes";
import { StyledDivider as Divider } from "../../../Components/LightDivider";
import HomeHeader from "../../../Components/HomeHeader";
import HomeFooter from "../../../Components/HomeFooter";
import { globalStyles } from "../../../Utils/Styles";
import frame from "../../../Components/globalAssets/frame.png";
import agencyPreview from "../../../Components/globalAssets/agencyPreview.png";
import laptopFrame from "../../../Components/globalAssets/laptop-02.png";
import marketPreview from "../../../Components/globalAssets/marketplacePreview.png";

const styles = {
  sectionContainer: {
    display: "flex",
    scrollMarginTop: '64px',
    flexDirection: { xs: "column", md: "row" },
    justifyContent: 'space-evenly',
    alignItems: "center",
    px: 2,
    paddingBlock: 8
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    mb: { xs: 4, md: 0 },
    maxWidth: { xs: '150px', md: '300px' }
  },
  laptopContainer: {
    display: "flex",
    justifyContent: "center", 
    alignItems: "center", 
    mb: { xs: 4, md: 0 }
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: '30rem',
    alignItems: { xs: "center", md: "flex-start" },
    textAlign: { xs: "center", md: "left" }
  }
};

const Agencies = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const paymentRef = useRef(null);
  const marketplaceRef = useRef(null);

  // State for holding the filtered companies
  const [filteredCompanies, setFilteredCompanies] = useState([]);

  // Fetch companies on mount, similar to CreatorBanner’s approach
  useEffect(() => {
    API.companies.list()
      .then((data) => {
        // Assuming the API returns something like data.companies
        // If your data structure differs, adjust accordingly
        const companies = data.companies || data.data || data;
        
        // Filter out only AlphaFree or Agency companies
        const filtered = companies.filter(
          (company) =>
            company.account_status === "AlphaFree" ||
            company.account_status === "Agency"
        );
        setFilteredCompanies(filtered);
      })
      .catch((error) => {
        console.error("Error fetching companies:", error);
      });
  }, []);

  return (
    <Box sx={{ backgroundColor: "#f5f5f5", width: "100%", color: "#000" }}>
      <HomeHeader />
      <Toolbar />

      {/* NEW BIG SECTION: Filtered Companies */}
      <Container sx={{ paddingTop: 2, paddingBottom: 4 }}>
        <Typography
          variant="h3"
          sx={{ textAlign: "center", fontWeight: "bold", my: 2 }}
        >
          Our Agency Partners
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: 3,
            mt: 3,
          }}
        >
          {filteredCompanies.map((company) => (
            <Box
              key={company.id}
              sx={{
                width: { xs: "100%", sm: "45%", md: "30%" },
                backgroundColor: "#fff",
                borderRadius: 2,
                boxShadow: 2,
                p: 2,
                textAlign: "center",
              }}
            >
              <Box
                component="img"
                src={company.phphref}
                alt={company.name}
                sx={{
                  maxWidth: "150px",
                  width: "100%",
                  height: "auto",
                  borderRadius: "50%",
                  margin: "0 auto",
                  display: "block",
                  mb: 2,
                }}
              />
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {/* Hyperlink to /roster/{company.name} */}
                <MuiLink
                  component={RouterLink}
                  to={`/roster/${company.name}`}
                  underline="hover"
                  color="inherit"
                >
                  {company.name}
                </MuiLink>
              </Typography>
            </Box>
          ))}
        </Box>
      </Container>

      {/* Main Content */}
      <Container
        sx={{
          ...globalStyles.wholeHeightMinusToolbar,
          paddingTop: 2,
          paddingBottom: 4,
        }}
      >
        {/* Solutions Header */}
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h3"
            sx={{ textAlign: "center", fontWeight: "bold", my: 2 }}
          >
            For agencies
          </Typography>
        </Box>

        <Divider />

        {/* AI Campaign Management Section */}
        <Box sx={styles.sectionContainer}>
          <Box sx={styles.imageContainer}>
            <Box
              sx={{
                position: "relative",
                rotate: "4.96deg",
                boxShadow: "-6px 6px 12px black",
                borderRadius: "48px",
                aspectRatio: "9/18",
                maxWidth: "600px",
                width: "50vw",
                height: "auto",
              }}
            >
              <img
                src={frame}
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 1,
                }}
              />
              <img
                src={agencyPreview}
                alt="Image showing a preview of the Creator Management feature"
                style={{
                  width: "88%",
                  height: "94%",
                  objectFit: "cover",
                  position: "absolute",
                  left: "6%",
                  top: "3%",
                  borderRadius: "4%",
                  zIndex: 0,
                }}
              />
            </Box>
          </Box>
          <Box sx={styles.textContainer}>
            <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
              Creators Management
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
              Manage, modify and check on your agency creators and their
              campaigns on our agency management portal.
            </Typography>
            <Button
              variant="contained"
              sx={{
                fontSize: "1rem",
                textTransform: "none",
                backgroundColor: "#7b2a2a",
                color: "#fff",
              }}
              onClick={() => navigate(`${routes.demoRegister}/thealexroa`)}
            >
              Request Access
            </Button>
          </Box>
        </Box>

        <Divider />

        {/* BlitzPay Section */}
        <Box
          ref={paymentRef}
          sx={{ ...styles.sectionContainer, flexDirection: { xs: "column", md: "row-reverse" } }}
        >
          <Box flex={1} sx={styles.imageContainer}>
            <img
              src={paymentImage}
              alt="Payment"
              style={{
                width: "100%",
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
          </Box>
          <Box sx={styles.textContainer}>
            <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
              BlitzPay - Accounts Payable
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
              Streamline your payment process and ensure creators are paid on
              time, every time, through their preferred methods.
            </Typography>
            <Button
              variant="contained"
              sx={{
                fontSize: "1rem",
                textTransform: "none",
                backgroundColor: "#7b2a2a",
                color: "#fff",
              }}
              onClick={() => navigate(`${routes.demoRegister}/thealexroa`)}
            >
              Request Access
            </Button>
          </Box>
        </Box>

        <Divider />

        {/* Creator Sourcing Section */}
        <Box ref={marketplaceRef} sx={styles.sectionContainer}>
          <Box sx={styles.laptopContainer}>
            <Box
              sx={{
                aspectRatio: "16/9",
                position: "relative",
                maxWidth: "600px",
                width: "50vw",
                height: "auto",
              }}
            >
              <img
                src={laptopFrame}
                alt="Frame"
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 1,
                }}
              />
              <img
                src={marketPreview}
                style={{
                  width: "72%",
                  height: "80%",
                  objectFit: "fill",
                  position: "absolute",
                  left: "14%",
                  top: "7%",
                  zIndex: 2,
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              ...styles.textContainer,
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
              Your Creators, everywhere
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
              Make your creators available on the Blitz Marketplace to be seen
              and enter partnerships with some of the biggest brands.
            </Typography>
            <Button
              variant="contained"
              sx={{
                fontSize: "1rem",
                textTransform: "none",
                backgroundColor: "#7b2a2a",
                color: "#fff",
              }}
              onClick={() => navigate(`${routes.demoRegister}/thealexroa`)}
            >
              Request Access
            </Button>
          </Box>
        </Box>

        <Divider />

        {/* Accelerated Creator Collaborations Section */}
        <Box
          ref={marketplaceRef}
          sx={{ ...styles.sectionContainer, flexDirection: { xs: "column", md: "row-reverse" } }}
        >
          <Box flex={1} sx={styles.imageContainer}>
            <img
              src={marketplaceImage}
              alt="Collaborations"
              style={{ width: "100%" }}
            />
          </Box>
          <Box
            sx={{ ...styles.textContainer, textAlign: { xs: "center", md: "left" } }}
          >
            <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
              Accelerated Creator Collaborations
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
              Fast-track your partnerships with creators through Blitz’s
              efficient collaboration tools.
            </Typography>
            <Button
              variant="contained"
              sx={{
                fontSize: "1rem",
                textTransform: "none",
                backgroundColor: "#7b2a2a",
                color: "#fff",
              }}
              onClick={() => navigate(`${routes.demoRegister}/thealexroa`)}
            >
              Request Access
            </Button>
          </Box>
        </Box>
      </Container>

      <HomeFooter />
    </Box>
  );
};

export default Agencies;

import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Container,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import { useMutation } from "react-query";
import client from "../../../API";
import MuiPhoneInput from "../../../Components/MuiPhoneInput";
import { RateField } from "../../../Components/RateField";
import { parseLocalFloat, parseLocalInt } from "../../../Utils/constants";
import { platformSelection, validatePlatformSelection } from "../../../Components/CreatorData/creatorValidation";
import CreatorPlatforms from "../../../Components/CreatorData/creatorPlatforms";
import CreatorInfo from "../../../Components/CreatorData/creatorInfo";

const CreatorDialog = ({ open, onClose, onSubmit }) => {
  const [contactEmail, setContactEmail] = useState("");
  const [paymentEmail, setPaymentEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailSame, setEmailSame] = useState(false);
  const [creatorName, setCreatorName] = useState("");
  const [creatorRegion, setCreatorRegion] = useState("");
  const [platforms, setPlatforms] = useState(platformSelection);
  const [primaryMarket, setPrimaryMarket] = useState([]);
  const [notesContentStyle, setNotesContentStyle] = useState(
    "tell us about yourself"
  );

  const [manager, setManager] = useState("");
  const [status, setStatus] = useState("");


  const [race, setRace] = useState("");
  const [gender, setGender] = useState("");
  const [location, setLocation] = useState("");
  const [pfphref, setPfphref] = useState(""); // New state for profile photo URL

  // Instagram
  const [instagramLink, setInstagramLink] = useState("");
  const [instagramBrandRate, setInstagramBrandRate] = useState("");
  const [instagramFollowerCount, setInstagramFollowerCount] = useState("");
  const [instagramSongRate, setInstagramSongRate] = useState("");
  const [instagramStoryRate, setInstagramStoryRate] = useState("");
  const [instagramFeedRate, setInstagramFeedRate] = useState("");

  // TikTok
  const [tikTokLink, setTikTokLink] = useState("");
  const [tikTokFollowerCount, setTikTokFollowerCount] = useState("");
  const [tikTokBrandRate, setTikTokBrandRate] = useState("");
  const [tikTokSongRate, setTikTokSongRate] = useState("");

  // YouTube
  const [youtubeLink, setYoutubeLink] = useState("");
  const [youtubeFollowerCount, setYoutubeFollowerCount] = useState("");
  const [youtubeIntegrationRate, setYoutubeIntegrationRate] = useState("");
  const [youtubeShortsRate, setYoutubeShortsRate] = useState("");
  const [youtubeDedicatedRate, setYoutubeDedicatedRate] = useState("");

  const [xLink, setXLink] = useState('');
  const [xFollowerCount, setXFollowerCount] = useState('');
  const [xFeedPost, setXFeedPost] = useState('');
  const [xRepost, setXRepost] = useState('');

  const [podcastLink, setPodcastLink] = useState('');
  const [podcastFollowerCount, setPodcastFollowerCount] = useState('');
  const [podcastPreroll, setPodcastPreroll] = useState('');
  const [podcastMidroll, setPodcastMidroll] = useState('');
  const [podcastPostroll, setPodcastPostroll] = useState('');
  const [podcastFull, setPodcastFull] = useState('');

  const [kickLink, setKickLink] = useState('');
  const [kickFollowerCount, setKickFollowerCount] = useState('');
  const [kickPlacement, setKickPlacement] = useState('');

  const [twitchLink, setTwitchLink] = useState('');
  const [twitchFollowerCount, setTwitchFollowerCount] = useState('');
  const [twitchPlacement, setTwitchPlacement] = useState('');


  const { mutate: addNewCreator, isLoading } = useMutation(
    client.creators.add,
    {
      onSuccess: (data) => {
        alert("Creator added successfully");
        onClose()
      },
      onError: (error) => {
        if (error.response && error.response.data)
          alert(`Error adding creator: ${error.response.data.error}`);
        else
          alert(`Error adding creator: ${error.message}`);

      },
    }
  );

  const handleSaveCreator = async () => {
    if (phoneNumber.length < 11) {
      alert(`This Phone number is not valid`);
      return;
    }

    if (!validatePlatformSelection(
      platforms,
      (message) => alert(message),
      tikTokBrandRate, tikTokSongRate,
      instagramBrandRate, instagramSongRate, instagramFeedRate, instagramStoryRate,
      youtubeIntegrationRate, youtubeShortsRate, youtubeDedicatedRate,
      xFeedPost, xRepost,
      kickPlacement,
      twitchPlacement,
      podcastPreroll,
      podcastMidroll,
      podcastPostroll,
      podcastFull
  )) {
      return;
    }

    const formattedTikTokLink = tikTokLink ? `https://www.tiktok.com/@${tikTokLink}` : null;
    const formattedInstagramLink = instagramLink ? `https://www.instagram.com/${instagramLink}` : null;
    const formattedYouTubeLink = youtubeLink ? `https://www.youtube.com/${youtubeLink}` : null;
    const formattedXLink = xLink ? `https://www.x.com/${xLink}` : null;
    const formattedKickLink = kickLink ? `https://www.kick.com/${kickLink}` : null;
    const formattedTwitchLink = twitchLink ? `https://www.twitch.tv/${twitchLink}` : null;

    let payload = null;

    try {
      payload = {
        creator: creatorName,
        geolocation: location,
        gender: gender,
        ethnicity: race,
        primary_markets: primaryMarket,
        region: creatorRegion,
        notescontent_style: notesContentStyle || null,

        tiktok: parseLocalInt(tikTokFollowerCount) || null,
        tiktok_link: formattedTikTokLink || null,
        instagram: parseLocalInt(instagramFollowerCount) || null,
        instagram_link: formattedInstagramLink || null,
        youtube: parseLocalInt(youtubeFollowerCount) || null,
        youtube_link: formattedYouTubeLink || null,

        twitch: parseLocalInt(twitchFollowerCount) || null,
        twitch_link: formattedTwitchLink || null,

        follow_x: parseLocalInt(xFollowerCount) || null,
        x_link: formattedXLink || null,

        follow_podcast: parseLocalInt(podcastFollowerCount) || null,
        podcast_link: podcastLink || null,

        follow_kick: parseLocalInt(kickFollowerCount) || null,
        kick_link: formattedKickLink || null,

        rate_tt_sound: parseLocalFloat(tikTokSongRate),
        rate_tt_brand: parseLocalFloat(tikTokBrandRate),

        rate_ig_feedpost: parseLocalFloat(instagramStoryRate),
        rate_ig_reelsound: parseLocalFloat(instagramSongRate),
        rate_ig_reelbrand: parseLocalFloat(instagramBrandRate),
        rate_ig_story: parseLocalFloat(instagramStoryRate),

        rate_yt_integ: parseLocalFloat(youtubeIntegrationRate),
        rate_yt_short: parseLocalFloat(youtubeShortsRate),
        rate_yt_dedicated: parseLocalFloat(youtubeDedicatedRate),

        rate_podcast_preroll: parseLocalFloat(podcastPreroll),
        rate_podcast_midroll: parseLocalFloat(podcastMidroll),
        rate_podcast_postroll: parseLocalFloat(podcastPostroll),
        rate_podcast_full: parseLocalFloat(podcastFull),

        rate_twitch_product: parseLocalFloat(twitchPlacement),
        rate_kick_product: parseLocalFloat(kickPlacement),
        rate_x_feedpost: parseLocalFloat(xFeedPost),
        rate_x_repost: parseLocalFloat(xRepost),

        contactEmail: contactEmail,
        paymentEmail: paymentEmail,
        phone_number: phoneNumber,
        pfphref: pfphref || null,
        manager: manager,
        status: status || "Associate",
      };
    } catch (error) {
      alert(`Error parsing this form: ${error}`);
      return;
    }


    addNewCreator(payload);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Container maxWidth="xl">
        <h1>Creator Intake Form</h1>
        <form
          onSubmit={(e) => {
            e.preventDefault(); // Prevent default form submission via HTTP
            handleSaveCreator();
          }}
        >
          <CreatorInfo
            creatorName={creatorName}
            setCreatorName={setCreatorName}
            creatorRegion={creatorRegion}
            setCreatorRegion={setCreatorRegion}
            race={race}
            setRace={setRace}
            gender={gender}
            setGender={setGender}
            location={location}
            setLocation={setLocation}
            primaryMarket={primaryMarket}
            setPrimaryMarket={setPrimaryMarket}
            notesContentStyle={notesContentStyle}
            setNotesContentStyle={setNotesContentStyle}
            contactEmail={contactEmail}
            setContactEmail={setContactEmail}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            validationError={''}
            paymentEmail={paymentEmail}
            setPaymentEmail={setPaymentEmail}
            emailSame={emailSame}
            setEmailSame={setEmailSame}
            pfphref={pfphref}
            setPfphref={setPfphref}
          ></CreatorInfo>
          <Typography variant="h5" sx={{ marginBlockEnd: 1, marginBlockStart: 1 }}>Platform and Rates</Typography>
          <CreatorPlatforms
            platforms={platforms} setPlatforms={setPlatforms}
            instagramLink={instagramLink} setInstagramLink={setInstagramLink}
            instagramFollowerCount={instagramFollowerCount} setInstagramFollowerCount={setInstagramFollowerCount}
            instagramBrandRate={instagramBrandRate} setInstagramBrandRate={setInstagramBrandRate}
            instagramSongRate={instagramSongRate} setInstagramSongRate={setInstagramSongRate}
            instagramFeedRate={instagramFeedRate} setInstagramFeedRate={setInstagramFeedRate}
            instagramStoryRate={instagramStoryRate} setInstagramStoryRate={setInstagramStoryRate}
            tikTokLink={tikTokLink} setTikTokLink={setTikTokLink}
            tikTokFollowerCount={tikTokFollowerCount} setTikTokFollowerCount={setTikTokFollowerCount}
            tikTokBrandRate={tikTokBrandRate} setTikTokBrandRate={setTikTokBrandRate}
            tikTokSongRate={tikTokSongRate} setTikTokSongRate={setTikTokSongRate}
            youtubeLink={youtubeLink} setYoutubeLink={setYoutubeLink}
            youtubeFollowerCount={youtubeFollowerCount} setYoutubeFollowerCount={setYoutubeFollowerCount}
            youtubeIntegrationRate={youtubeIntegrationRate} setYoutubeIntegrationRate={setYoutubeIntegrationRate}
            youtubeShortsRate={youtubeShortsRate} setYoutubeShortsRate={setYoutubeShortsRate}
            youtubeDedicatedRate={youtubeDedicatedRate} setYoutubeDedicatedRate={setYoutubeDedicatedRate}
            xLink={xLink} setXLink={setXLink}
            xFollowerCount={xFollowerCount} setXFollowerCount={setXFollowerCount}
            xFeedPost={xFeedPost} setXFeedPost={setXFeedPost}
            xRepost={xRepost} setXRepost={setXRepost}
            podcastLink={podcastLink} setPodcastLink={setPodcastLink}
            podcastFollowerCount={podcastFollowerCount} setPodcastFollowerCount={setPodcastFollowerCount}
            podcastPreroll={podcastPreroll} setPodcastPreroll={setPodcastPreroll}
            podcastMidroll={podcastMidroll} setPodcastMidroll={setPodcastMidroll}
            podcastPostroll={podcastPostroll} setPodcastPostroll={setPodcastPostroll}
            podcastFull={podcastFull} setPodcastFull={setPodcastFull}
            kickLink={kickLink} setKickLink={setKickLink}
            kickFollowerCount={kickFollowerCount} setKickFollowerCount={setKickFollowerCount}
            kickPlacement={kickPlacement} setKickPlacement={setKickPlacement}
            twitchLink={twitchLink} setTwitchLink={setTwitchLink}
            twitchFollowerCount={twitchFollowerCount} setTwitchFollowerCount={setTwitchFollowerCount}
            twitchPlacement={twitchPlacement} setTwitchPlacement={setTwitchPlacement}
          ></CreatorPlatforms>
          <TextField
            margin="dense"
            label="Manager"
            type="text"
            fullWidth
            value={manager}
            onChange={(e) => setManager(e.target.value)}
            variant="outlined"
          />
          <FormControl variant="outlined" fullWidth margin="dense">
            <InputLabel>Status</InputLabel>
            <Select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              label="Status"
            >
              <MenuItem value="Partner">Partner</MenuItem>
              <MenuItem value="Associate">Associate</MenuItem>
              <MenuItem value="Agency">Agency</MenuItem>
            </Select>
          </FormControl>
          <Button
            type="submit" // Ensures form submission behavior when Enter is pressed
            variant="contained"
            color="primary"
            style={{ marginTop: "5px", marginBottom: "10px" }} // Add some space above the button
          >
            Submit Information
          </Button>
        </form>
      </Container>
    </Dialog>
  );
};

export default CreatorDialog;

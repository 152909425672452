import React, { useEffect, useState, useRef } from "react";
import { useParams, useSearchParams, Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import client from "../../../../API";
import { useMutation, useQuery } from "react-query";
import {
  Typography,
  Box,
  Divider,
  Toolbar,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  Menu,
  MenuItem,
  Link,
  Avatar,
  useMediaQuery
} from "@mui/material";
import {
  Visibility as VisibilityIcon,
  Dashboard as DashboardIcon,
  Assignment as AssignmentIcon,
  TrendingUp as TrendingUpIcon,
  Analytics as AnalyticsIcon,
  Download as DownloadIcon,
  Google as GoogleIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon
} from "@mui/icons-material";
import HomeHeader from "../../../../Components/HomeHeader.js";
import PageTitle from "../../../../Components/PageTitle.js";
import TabPanel from "../../../../Components/TabPanel.js";
import CampaignReportingPanel from "./campaignTabs/campaignReporting.js";
import CampaignDetailsPanel from "./campaignTabs/campaignDetails.js";
import CampaignProjectionsPanel from "./campaignTabs/campaignProjections.js";
import CRMDialog from "../../../Misc/crmComponents/crmPopup.js";
import { parseLocalFloat } from "../../../../Utils/constants.js";
import RegisterPopup from "./campaignTabs/registerpopup.js";

// Styled Components
const GradientHeader = styled(Box)(({ theme }) => ({
  background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
  padding: theme.spacing(3, 2), // Reduced vertical padding for a thinner header
  width: "100%",              // Ensures the header takes the full width of the page
  margin: 0,                  // Removes outer margins
  borderRadius: 0,            // No border radius for a seamless full-width look
  boxShadow: theme.shadows[2], // Optionally, a lighter shadow for a cleaner appearance
}));

const ModernToggleButton = styled(ToggleButton)(({ theme }) => ({
  textTransform: "none",
  fontWeight: 600,
  borderRadius: "8px !important",
  "&.Mui-selected": {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
    boxShadow: theme.shadows[2],
  },
}));

const CampaignDetailsPage = () => {
  const { campaignId } = useParams();
  const [searchParams] = useSearchParams();
  const [showRegisterPopup, setShowRegisterPopup] = useState(false);
  const sharedEmail = searchParams.get("shared_email");

  const [campaignDetails, setCampaignDetails] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [viewMode, setViewMode] = useState("list"); // "list" or "dashboard"
  const [showCRMDialog, setShowCRMDialog] = useState(true);
  const [showInstructions, setShowInstructions] = useState(false);
  const hasShownMessage = useRef(false);

  // Export Menu anchor
  const [exportMenuAnchor, setExportMenuAnchor] = useState(null);

  // Use MUI's breakpoint to set default mode
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (sharedEmail && !isLoggedIn()) {
      setShowRegisterPopup(true);
    }
  }, [sharedEmail]);

  const isLoggedIn = () => {
    // Replace this with your actual auth check
    return false;
  };

  // If user hasn't manually toggled, pick default based on device
  useEffect(() => {
    setViewMode(isDesktop ? "list" : "dashboard");
  }, [isDesktop]);

  const handleChangeView = (event, newValue) => {
    if (newValue !== null) {
      setViewMode(newValue);
    }
  };

  const { data, error, isLoading, refetch } = useQuery(
    ["campaignDetails", campaignId],
    async () => {
      const campaignData = await client.campaigns.fetch(campaignId);
      // campaignData.owner is fetched from back-end if available
      return { ...campaignData, creators: campaignData.creators };
    },
    {
      onSuccess: (data) => {
        try {
          setCampaignDetails(data);
          // Show instructions if the campaign is in Draft and not shown before
          if (data.campaign_status === "Draft" && !hasShownMessage.current) {
            setShowInstructions(true);
            hasShownMessage.current = true;
          }
        } catch (error) {
          console.error("Error processing campaign details:", error);
        }
      },
      onError: (error) => {
        console.error("Failed to fetch campaign details:", error);
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const handleCloseCRMDialog = () => setShowCRMDialog(false);

  const { mutate: updateCreatorStats } = useMutation(
    (payload) => client.campaigns.updateCreatorStats(payload),
    {
      onSuccess: () => refetch(),
      onError: (error) => {
        alert(error?.response?.data?.error || error.message);
        console.error("Failed to update status:", error);
      },
    }
  );

  const handleStatusChange = (creatorId, status) => {
    updateCreatorStats({ campaignId, creators: [{ id: creatorId, status }] });
  };

  // ---------------------
  //   EXPORT FUNCTIONS
  // ---------------------
  const downloadCSV = () => {
    const csvContent = convertToCSV(campaignDetails);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `campaign_details_${campaignId}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const exportToSheets = async () => {
    try {
      const response = await client.campaigns.exportSheet({
        campaignId,
        campaignDetails,
      });
      if (response.sheetLink) {
        window.open(response.sheetLink, "_blank");
      }
    } catch (error) {
      console.error(
        "Export failed:",
        error?.response?.data?.error || error.message
      );
    }
  };

  const convertToCSV = (data) => {
    if (!data?.creators?.length) return "";
    const headers = [
      "Name",
      "Following",
      "Status",
      "Price",
      "Offer Sent",
      "Assets Sent",
      "Assets Approved",
      "Promotion Type",
      "Promotion Platform",
      "Likes",
      "Comments",
      "Total Views",
      "Post Date",
      "Platform Link",
      "Live Link",
    ];

    const rows = data.creators.map((creator) => [
      creator.name ?? "",
      creator.following ?? "",
      creator.status ?? "",
      creator.price ?? "",
      creator.offerSent ? "Yes" : "No",
      creator.assetsSent ? "Yes" : "No",
      creator.assetsApproved ? "Yes" : "No",
      creator.promotionType ?? "",
      creator.promotionPlatform ?? "",
      creator.likes ?? 0,
      creator.comments ?? 0,
      creator.totalViews ?? 0,
      creator.postDate ?? "",
      creator.platformLink ?? "",
      creator.liveLink ?? "",
    ]);

    return [headers, ...rows].map((row) => row.join(",")).join("\n");
  };

  // ---------------------
  //   EXPORT MENU
  // ---------------------
  const handleExportClick = (event) => {
    setExportMenuAnchor(event.currentTarget);
  };
  const handleExportClose = () => {
    setExportMenuAnchor(null);
  };

  if (!campaignDetails) {
    return (
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h6">Loading campaign details...</Typography>
      </Box>
    );
  }

  const isCampaignActive = !["Launched", "Completed"].includes(
    campaignDetails.campaign_status
  );

  return (
    <>
      <HomeHeader />
      <Toolbar />
      <PageTitle title={`${campaignDetails.name} - Blitz`} />

      <GradientHeader>
        {/* 
          Use a two-column layout:
          - Left: Campaign info, toggles, export menu 
          - Right: "Presented By" user info
        */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          {/* Left side */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            {/* Campaign Title + Brief */}
            <Box>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontFamily: "Inter, sans-serif",
                  fontWeight: 800,
                  letterSpacing: "-0.03em",
                  color: "common.white",
                }}
              >
                {campaignDetails.name}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  opacity: 0.9,
                  fontWeight: 400,
                  color: "common.white",
                }}
              >
                {campaignDetails.brief}
              </Typography>
            </Box>

            {/* View Toggle & Export Menu */}
            <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
              <ToggleButtonGroup
                value={viewMode}
                exclusive
                onChange={handleChangeView}
                sx={{ bgcolor: "background.paper", borderRadius: 2 }}
              >
                <ModernToggleButton value="list">
                  <VisibilityIcon sx={{ mr: 1 }} /> List View
                </ModernToggleButton>
                <ModernToggleButton value="dashboard">
                  <DashboardIcon sx={{ mr: 1 }} /> Dashboard
                </ModernToggleButton>
              </ToggleButtonGroup>

              <Button
                variant="contained"
                endIcon={<KeyboardArrowDownIcon />}
                onClick={handleExportClick}
                sx={{
                  borderRadius: 2,
                  px: 3,
                  py: 1,
                  boxShadow: 2,
                  bgcolor: "#FFEB3B", // Bright yellow background
                  color: "#9C27B0",   // Vivid purple text
                  "&:hover": {
                    boxShadow: 3,
                    bgcolor: "#FDD835", // Slightly darker yellow on hover
                  },
                }}
              >
                Export
              </Button>


              <Menu
                anchorEl={exportMenuAnchor}
                open={Boolean(exportMenuAnchor)}
                onClose={handleExportClose}
              >
                <MenuItem
                  onClick={() => {
                    downloadCSV();
                    handleExportClose();
                  }}
                >
                  <DownloadIcon sx={{ mr: 1 }} />
                  CSV Export
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    exportToSheets();
                    handleExportClose();
                  }}
                >
                  <GoogleIcon sx={{ mr: 1 }} />
                  Google Sheets
                </MenuItem>
              </Menu>
            </Box>
          </Box>

          {/* Right side: Presented By */}
          {campaignDetails.owner && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                marginTop: { xs: 2, md: 0 },
              }}
            >
              <Avatar
                src={campaignDetails.owner.phphref || ""}
                alt={`${campaignDetails.owner.first_name} ${campaignDetails.owner.last_name}`}
                sx={{ width: 48, height: 48 }}
              />
              <Box>
                <Typography
                  variant="body2"
                  sx={{ color: "common.white", fontWeight: 600 }}
                >
                  Presented By
                </Typography>
                <Link
                  component={RouterLink}
                  to={`/user/${campaignDetails.owner.id}`}
                  variant="body2"
                  underline="hover"
                  sx={{ color: "common.white", fontWeight: 400 }}
                >
                  {campaignDetails.owner.first_name} {campaignDetails.owner.last_name}
                </Link>
              </Box>
            </Box>
          )}
        </Box>
      </GradientHeader>

      <Box
        sx={{
          maxWidth: 1600,
          margin: "0 auto",
          px: { xs: 2, md: 4 },
          position: "relative",
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleChangeTab}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            "& .MuiTabs-indicator": {
              height: 3,
              borderRadius: 2,
            },
          }}
        >
          <Tab label="Campaign Overview" icon={<AssignmentIcon />} />
          <Tab label="Performance Projections" icon={<TrendingUpIcon />} />
          <Tab label="Creator Analytics" icon={<AnalyticsIcon />} />
        </Tabs>

        <Divider sx={{ my: 3 }} />

        <Box
          sx={{
            padding: { xs: 0, md: 3 },
            borderRadius: 4,
            bgcolor: "background.paper",
          }}
        >
          <TabPanel value={selectedTab} index={0}>
            {/* Pass our selected viewMode to CampaignDetailsPanel */}
            <CampaignDetailsPanel
              campaignDetails={campaignDetails}
              viewMode={viewMode}
              isCampaignActive={isCampaignActive}
              handleStatusChange={handleStatusChange}
            />
          </TabPanel>

          <TabPanel value={selectedTab} index={1}>
            <CampaignProjectionsPanel campaignDetails={campaignDetails} />
          </TabPanel>

          <TabPanel value={selectedTab} index={2}>
            <CampaignReportingPanel
              viewsByDayData={
                campaignDetails.creators
                  ?.reduce((acc, creator) => {
                    const date = creator.postDate || new Date().toISOString();
                    const views = parseInt(creator.totalViews || 0, 10);
                    const existing = acc.find((e) => e.date === date);
                    if (existing) {
                      existing.views += views;
                    } else {
                      acc.push({ date, views });
                    }
                    return acc;
                  }, [])
                  .sort((a, b) => new Date(a.date) - new Date(b.date)) || []
              }
              costPerCreatorData={
                campaignDetails.creators?.map((c) => ({
                  name: c.name,
                  price: parseLocalFloat(
                    String(c.price || 0).replace(/[^0-9.-]/g, "")
                  ),
                })) || []
              }
              reachData={
                campaignDetails.creators?.map((c) => ({
                  name: c.name,
                  value: parseInt(
                    String(c.following || 0).replace(/,/g, ""),
                    10
                  ),
                })) || []
              }
              creatorIds={campaignDetails.creators?.map((c) => c.id) || []}
            />
          </TabPanel>
        </Box>
      </Box>

      <RegisterPopup
        open={showRegisterPopup}
        onClose={() => setShowRegisterPopup(false)}
        initialEmail={sharedEmail}
      />

      {showCRMDialog && (
        <CRMDialog
          isOpen={showCRMDialog}
          handleClose={handleCloseCRMDialog}
          origin={`${campaignDetails.name} - campaign`}
        />
      )}

      <Dialog
        open={showInstructions}
        onClose={() => setShowInstructions(false)}
      >
        <DialogTitle>Welcome to Blitz! 🚀</DialogTitle>
        <DialogContent>
          <Typography>
            This is your campaign proposal sheet featuring hand-picked creators
            for your campaign. Each creator comes with recommended pricing and
            media kits. Manage creator statuses (Approve/Drop/Negotiate),
            export data to CSV/Sheets, and track campaign progress. Once
            launched, this becomes your real-time tracking dashboard with
            creator analytics and post performance data.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowInstructions(false)} color="primary">
            Let&apos;s Get Started!
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CampaignDetailsPage;

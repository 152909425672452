import React from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Card,
  CardContent,
  Divider,
  Skeleton,
  Typography,
  IconButton,
  Badge,
  Button
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

export default function LeftPanel({
  isLoadingConversations,
  conversations,
  selectedConversationId,
  isMobile,
  isLeftPanelCollapsed,
  handleSelectConversation,
  toggleLeftPanel,

  // New prop: call this when user clicks "Create New Chat" button
  onCreateNewChatClicked
}) {
  return (
    <Card
      sx={{
        width: isLeftPanelCollapsed ? 0 : { xs: '100%', md: 400 },
        minWidth: isLeftPanelCollapsed ? 0 : { xs: '100%', md: 400 },
        height: '100%',
        transition: 'all 0.3s ease',
        overflow: 'hidden',
        position: { xs: 'absolute', md: 'relative' },
        zIndex: 1,
        left: isLeftPanelCollapsed ? '-100%' : 0,
        display: { xs: selectedConversationId ? 'none' : 'block', md: 'block' }
      }}
    >
      <CardContent sx={{ p: 0, height: '100%', display: 'flex', flexDirection: 'column' }}>
        {/* Header */}
        <Box
          sx={{
            p: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography variant="h6" sx={{ mr: 2 }}>
            Active Conversations
          </Typography>
          <IconButton onClick={toggleLeftPanel} sx={{ display: { xs: 'none', md: 'flex' } }}>
            <ChevronLeftIcon />
          </IconButton>
        </Box>
        <Divider />

        {/* CREATE NEW CHAT BUTTON */}
        <Box sx={{ p: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={onCreateNewChatClicked}
            fullWidth
          >
            Create New Chat
          </Button>
        </Box>
        <Divider />

        {/* Conversations List */}
        <List sx={{ flex: 1, overflowY: 'auto' }}>
          {isLoadingConversations ? (
            [...Array(5)].map((_, i) => (
              <Skeleton key={i} variant="rectangular" height={60} sx={{ m: 1 }} />
            ))
          ) : (
            conversations.map((convo) => (
              <ListItem
                key={convo.id}
                button
                onClick={() => {
                  handleSelectConversation(convo.id);
                  if (isMobile) toggleLeftPanel();
                }}
                selected={convo.id === selectedConversationId}
              >
                {/* Avatar */}
                <ListItemAvatar>
                  <Avatar src={convo.display_photo || '/default-avatar.png'} />
                </ListItemAvatar>

                {/* Text + Badge */}
                <ListItemText
                  primary={
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                    >
                      {/* Conversation Name */}
                      <Typography variant="subtitle1" noWrap>
                        {convo.display_name || `Conversation #${convo.id}`}
                      </Typography>

                      {/* Subtle Badge for message count */}
                      {convo.message_count ? (
                        <Box sx={{ ml: 2 }}>
                          <Badge
                            badgeContent={convo.message_count || '0'}
                            color="primary"
                            max={999}
                            sx={{
                              '& .MuiBadge-badge': {
                                fontSize: '0.7rem',
                                minWidth: '18px',
                                height: '18px'
                              }
                            }}
                          >
                            <ChatBubbleOutlineIcon fontSize="small" />
                          </Badge>
                        </Box>
                      ) : null}
                    </Box>
                  }
                  secondary={
                    <Typography variant="body2" color="text.secondary" noWrap>
                      {convo.last_message || 'No messages yet'}
                    </Typography>
                  }
                />
              </ListItem>
            ))
          )}
        </List>
      </CardContent>
    </Card>
  );
}

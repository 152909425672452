import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Avatar, Box, Card, CardActions, CardHeader, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Skeleton, Typography, useTheme } from '@mui/material'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useQuery } from 'react-query';
import API from '../../../API';
import { Link } from 'react-router-dom';

const itemListSize = '73.047px'
const itemsPerPage = 6;

function LoadingEntry() {
    return (
        <>
            <ListItemButton
                alignItems='flex-start'
            >
                <ListItemAvatar>
                    <Skeleton variant="circular">
                        <Avatar />
                    </Skeleton>
                </ListItemAvatar>
                <ListItemText
                    primary={<Skeleton animation='wave' />}
                    secondary={<Skeleton animation='wave' variant="rounded" height={40.031} width={'12rem'} />}
                />
            </ListItemButton>
            <Divider variant='inset' component="li"></Divider>
        </>
    );
}

function EmptyEntry() {
    return (
        <ListItem sx={theme => ({ height: `calc(${itemListSize} + ${theme.spacing(1)})` })}>
        </ListItem>
    );
}

function GetTextActivity(activity) {
    const price = activity.rate * (1 - (activity.fee_percentage / 100));
    switch (activity.activity_type) {
        case 'Campaign':
            return {
                primary: `${activity.creator_id} was pitched!`,
                secondary: `"${activity.activity_name}" on ${new Date().toLocaleDateString()}`,
                link: `/agency/creators/${activity.creator_id}/campaigns/${activity.activity_id}`,
                price
            };
        case 'Partnership':
            return {
                primary: `${activity.creator_id} was pitched!`,
                secondary: `"${activity.activity_name}" on ${new Date().toLocaleDateString()}`,
                link: `/agency/creators/${activity.creator_id}/partnerships/${activity.activity_id}`,
                price
            }
        case 'Payout':
            return {
                primary: `${activity.creator_id} received a payout!`,
                secondary: `"${activity.activity_name}" on ${new Date().toLocaleDateString()}`,
                link: `/agency/creators/${activity.creator_id}/payouts`,
                price
            }
        default:
            return {
                primary: '?',
                secondary: '?',
                link: '',
                price: 0
            }
    }
}

function ActivityEntry({ activity }) {
    const text = GetTextActivity(activity);

    return (
        <>
            <ListItemButton
                component={Link}
                to={text.link}
                alignItems='flex-start'
            >
                <ListItemAvatar>
                    <Avatar src={activity.profile_picture} alt={activity.creator_id}>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={text.primary}
                    secondary={<>
                        {text.secondary}<br />
                        <Typography
                            variant='inherit'
                            component="span"
                            color='primary'
                        ><b>${text.price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </b>
                        </Typography>
                    </>}
                    primaryTypographyProps={{ sx: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' } }}
                    secondaryTypographyProps={{ sx: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' } }}
                />
            </ListItemButton>
            <Divider variant='inset' component="li"></Divider>
        </>
    );
}

export default function AgencyZapfeed(props) {
    const theme = useTheme();
    const [feedPage, setFeedPage] = useState(0);
    const [pageDisplay, setPageDisplay] = useState(0);
    const { data, isFetching, error, refetch } = useQuery({
        queryKey: ['agency', 'zapfeed', { page: feedPage }],
        queryFn: () => API.agency.getZapfeed({ rows: itemsPerPage, page: feedPage }),
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 60,
    })
    const listHeight = useMemo(() => `(calc(${itemListSize} + ${theme.spacing(1)}) * ${itemsPerPage})`, [theme]);

    // Create a ref for the list container
    const listRef = useRef(null);

    const totalPages = !!data ? Math.ceil(data.total / itemsPerPage) : 0;

    useEffect(() => {
        if (!!data && listRef.current) {
            listRef.current.scrollTo({ top: listRef.current.clientHeight, behavior: "instant" });
        }
    }, [data]);

    const handleScroll = async (isDown = false) => {
        if (!listRef.current)
            return;
        if (isFetching)
            return;

        if (isDown && pageDisplay == totalPages)
            return;
        if (!isDown && pageDisplay == 0)
            return;

        listRef.current.scrollTo({ top: isDown ? listRef.current.clientHeight * 2 : 0, behavior: "smooth" });

        const newPage = pageDisplay + (isDown ? 1 : -1)
        setPageDisplay(newPage);

        setTimeout(async () => {
            setFeedPage(newPage);
            refetch();
        }, 500); // Adjust to match the duration of the CSS scroll animation
    };


    return (
        <Card elevation={3}>
            <CardHeader title='Recent Activity'></CardHeader>
            <Divider></Divider>
            <Box
                sx={{ height: `calc(${listHeight})`, overflowY: 'hidden' }}
                ref={listRef}
            >
                <List dense sx={{ paddingBlock: 0 }}>
                    {Array(6).fill(1).map((item, index) => <LoadingEntry key={`sim-${index}`}></LoadingEntry>)}
                    {!!data && data.zapfeed.map((activity) => <ActivityEntry key={`${activity.activity_type}-${activity.activity_id}-${activity.creator_id}`} activity={activity}></ActivityEntry>)}
                    {!!data && data.zapfeed.length < 6 && Array(6 - data.zapfeed.length).fill(1).map((item, index) => <EmptyEntry key={`empty-${index + 6}`}></EmptyEntry>)}
                    {!data && Array(6).fill(1).map((item, index) => <LoadingEntry key={`empty-${index + 6}`}></LoadingEntry>)}
                    {Array(6).fill(1).map((item, index) => <LoadingEntry key={`sim-${index + 6}`}></LoadingEntry>)}
                </List>
            </Box>
            <Divider></Divider>
            <CardActions>
                <IconButton disabled={pageDisplay == 0} onClick={() => handleScroll(false)}><KeyboardArrowUpIcon></KeyboardArrowUpIcon></IconButton>
                <IconButton disabled={pageDisplay == totalPages} onClick={() => handleScroll(true)}><KeyboardArrowDownIcon></KeyboardArrowDownIcon></IconButton>
            </CardActions>
        </Card>
    )
}

import React, { useState, useEffect, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Button,
  Typography,
  Box,
  TextField,
  IconButton,
  Avatar,
  CircularProgress,
  Snackbar,
  Alert,
  Paper,
  Chip,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useAuth from "../../../../../Hooks/use-auth";
import client from "../../../../../API";
import DeleteIcon from "@mui/icons-material/Delete";
import useAlertDialog from "../../../../../Components/useAlertDialog";
import AlertDialog from "../../../../../Components/AlertDialog";

//TODO -> CHANGE THE LOGIC OF MESSAGE FETCHING. USE A useQuery TO FETCH MESSAGES IF THEY EXIST, TRIGGER CREATION OF THE CONVERSATION
//        ON SENDING A MESSAGE
const CampaignCreatorSpecific = () => {
  const { campaignId, creatorName } = useParams();
  const [conversationId, setConversationId] = useState(null);
  const [message, setMessage] = useState("");
  const [warningOpen, setWarningOpen] = useState(false);
  const { getCurrrentUser } = useAuth();
  const userInfo = getCurrrentUser();
  const userCompany = userInfo.company_name;
  const isTcc = userCompany === "TCC";
  const queryClient = useQueryClient();
  const {dialogState, openDialog, closeDialog} = useAlertDialog();

  // Fetch campaign data
  const {
    data: campaignData,
    isLoading: campaignLoading,
    error: campaignError,
  } = useQuery(
    ["campaign", campaignId],
    () => client.campaigns.details(campaignId),
    {
      enabled: !!campaignId,
    }
  );

  const creatorCampaignData = useMemo(() => {
    if (!campaignData) {
      return undefined;
    }
    const creators = campaignData.creators;
    return creators.find(
      (creator) => creator.id === creatorName
    );
  }, [campaignData]);

  //console.log(creatorCampaignData);

  // Fetch creator data
  const {
    data: creatorData,
    isLoading: creatorLoading,
    error: creatorError,
  } = useQuery(
    ["creator", creatorName],
    () => client.creators.fetchDetails(creatorName),
    {
      enabled: !!creatorName && !!creatorCampaignData,
    }
  );

  // Mutation to check if conversation exists
  const checkConversation = useMutation(client.conversations.check, {
    onSuccess: (data) => {
      if (data.exists) {
        setConversationId(data.conversation_id);
      } else {
        createConversation.mutate({
          campaign_id: campaignId,
          creator_id: creatorData.creator.creator,
        });
      }
    },
    onError: (error) => {
      console.error("Error checking conversation:", error);
    },
  });

  // Mutation to create a new conversation
  const createConversation = useMutation(client.conversations.create, {
    onSuccess: (data) => {
      setConversationId(data.conversation_id);
    },
    onError: (error) => {
      console.error("Error creating conversation:", error);
    },
  });

  // Check or create conversation when campaign and creator data are available
  useEffect(() => {
    if (campaignData && creatorData) {
      checkConversation.mutate({
        campaign_id: campaignId,
        creator_id: creatorData.creator.creator, // Use numerical ID
      });
    }
  }, [campaignData, creatorData, campaignId]);

  // Fetch messages
  const {
    data: messages,
    isLoading: messagesLoading,
    error: messagesError,
    refetch: messageRefetch
  } = useQuery(
    ["messages", conversationId],
    () => client.conversations.getMessages(conversationId),
    {
      enabled: !!conversationId,
    }
  );

  // Mutation to send a message
  const sendMessageMutation = useMutation(({ id, input }) => client.conversations.sendMessage(id, input), {
    onSuccess: () => {
      queryClient.invalidateQueries(["messages", conversationId]);
      messageRefetch();
      setMessage("");
    },
    onError: (error) => {
      console.error("Error sending message:", error);
    },
  });

  const handleSendMessage = () => {
    if (!conversationId) return;

    // Check for disallowed words
    const disallowedWords = ["whatsapp", "imessage"];
    const containsDisallowedWord = disallowedWords.some((word) =>
      message.toLowerCase().includes(word)
    );

    if (containsDisallowedWord) {
      setWarningOpen(true);
      return;
    }

    if (!message)
      return;

    sendMessageMutation.mutate({
      id: conversationId,
      input: { message },
    });
  };

  // Helper to highlight links in messages
  const formatMessage = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex).map((part, index) => {
      if (urlRegex.test(part)) {
        return (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      }
      return part;
    });
  };

  // Delete message (only TCC can do this)
  const {
    mutate: deleteMessage,
    isLoading: deleteMessageLoading,
  } = useMutation({
    mutationFn: (id) => client.conversations.deleteMessage(id),
    onSuccess: () => fetchMessages.refetch(),
    onError: (error) => {
      openDialog(
        "Error",
        <>
          Error while deleting message: <br />
          {error?.response?.data?.error || error.message}
        </>,
        closeDialog,
        closeDialog,
        "Ok",
        null
      );
    },
  });

  // Basic check for phone numbers or "contact me" attempts
  const isMessageAllowed = (messageText) => {
    const prohibitedKeywords = [
      "text me",
      "contact me",
      "contact",
      "@gmail.com",
      "wa",
      "whatsapp",
      "convenient contact",
    ];
    const phoneRegex = /(?:\d[\s-]?){10,}/; // approximate phone matching
    for (let keyword of prohibitedKeywords) {
      if (messageText.toLowerCase().includes(keyword)) {
        return false;
      }
    }
    if (phoneRegex.test(messageText)) {
      return false;
    }
    return true;
  };

  const onConfirmDeleteMessage = (messageId) => {
    closeDialog();
    deleteMessage(messageId);
  };

  const handleDeleteMessage = (messageId) => {
    if (isTcc) {
      openDialog(
        "Warning",
        "Delete message?",
        () => onConfirmDeleteMessage(messageId),
        closeDialog,
        "Yes",
        "No"
      );
    }
  };

  if (campaignLoading || creatorLoading) {
    return (
      <Typography
        sx={{
          width: "100vw",
          height: "100vh",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Loading...
      </Typography>
    );
  }

  if (campaignError) {
    return (
      <Typography>
        Error loading campaign data: {campaignError.message}
      </Typography>
    );
  }

  if (creatorError) {
    return (
      <Typography>Error loading creator data: {creatorError.message}</Typography>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100vh",
      }}
    >
      <AlertDialog alertState={dialogState}></AlertDialog>
      {creatorData && campaignData && (
        <>
          <Paper
            sx={{ position: "sticky", top: 0, zIndex: 2, padding: 2 }}
            elevation={4}
            square
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{ mb: 2 }}
            >
              <Box display="flex" alignItems="center">
                <Avatar src={creatorData.creator.pfphref} alt={creatorData.creator.creator} />
                <Typography variant="h6" sx={{ marginLeft: 2 }}>
                  Campaign with @{creatorData.creator.creator} - {campaignData.name}
                </Typography>
                <Typography variant="body1" sx={{ marginLeft: 2 }}>
                  Campaign Description: {campaignData.description}<br />
                  Creator Brief: {creatorCampaignData.brief}
                </Typography>
              </Box>
            </Box>
          </Paper>

          {/* Warning banner */}
          <Paper
            elevation={2}
            sx={{ p: 2, mb: 4, backgroundColor: "#fff3cd", margin: 2 }}
          >
            <Typography
              variant="body1"
              gutterBottom
              sx={{ color: "#856404", textAlign: "center" }}
            >
              ⚠️ Deals and payments must happen within the Blitz platform. Going
              outside the Blitz platform could result in banishment from the
              platform and losing deals from other partnerships.
            </Typography>
          </Paper>

          {/* Messages container */}
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              padding: 2,
              minHeight: "400px",
              overflowY: "auto",
              flexGrow: 1,
            }}
          >
            {messagesLoading && <Typography>Loading messages...</Typography>}
            {messagesError && (
              <Typography>Error loading messages</Typography>
            )}
            {messages &&
              messages.map((msg, index) => {
                const isUserMessage = msg.sender_id === userInfo.id;
                const justify = isUserMessage ? "flex-end" : "flex-start";

                // Figure out who is sending the message
                let senderName = "";
                if (msg.sender_first_name && msg.sender_last_name) {
                  senderName = `${msg.sender_first_name} ${msg.sender_last_name}`;
                } else if (msg.creator_id) {
                  senderName = msg.creator_id;
                } else {
                  senderName = "Blitz System";
                }

                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: justify,
                      mb: 2,
                    }}
                  >
                    {/* If there's a deliverable, show the DeliverableCard. Otherwise, show a text message. */}
                    {msg.deliverable ? (
                      <DeliverableCard
                        deliverable={msg.deliverable}
                        onViewOffer={handleViewOfferClick}
                        isUserMessage={isUserMessage}
                      />
                    ) : (
                      <Paper
                        elevation={2}
                        sx={{
                          maxWidth: "70%",
                          p: 2,
                          position: "relative",
                          borderRadius: 2,
                          backgroundColor: isUserMessage ? "#007bff" : "#e4e6eb",
                          color: isUserMessage ? "white" : "black",
                          textAlign: isUserMessage ? "right" : "left",
                          zIndex: 0,
                        }}
                      >
                        {/* Show sender name if not the user's own message */}
                        {!isUserMessage && (
                          <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                            {senderName}
                          </Typography>
                        )}
                        <Typography variant="body1">
                          {formatMessage(msg.message)}
                        </Typography>
                        <Typography variant="caption" sx={{ display: "block", mt: 1 }}>
                          {new Date(msg.created_at).toLocaleTimeString()} -{" "}
                          {msg.read_at
                            ? `Read on ${new Date(msg.read_at).toLocaleString()}`
                            : "Not read yet"}
                        </Typography>
                        {isTcc && (
                          <IconButton
                            disabled={deleteMessageLoading}
                            onClick={() => handleDeleteMessage(msg.id)}
                            sx={(theme) => ({
                              backgroundColor: theme.palette.background.paper,
                              boxShadow: 2,
                              position: "absolute",
                              right: !isUserMessage ? "calc(0% - 30px)" : "auto",
                              left: isUserMessage ? "calc(0% - 30px)" : "auto",
                              top: "calc(50% - 20px)",
                              zIndex: 2,
                            })}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </Paper>
                    )}
                  </Box>
                );
              })
            }
          </Box>

          <Box sx={{ padding: 2 }}>
            <Box display="flex" alignItems="center" gap={1} sx={{ mb: 2 }}>
              <Chip color='primary' label={<>Offer: {creatorCampaignData.price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>}></Chip>
              <Chip color='secondary' label={<>Promotion: {creatorCampaignData.promotionPlatform} - {creatorCampaignData.promotionType}</>}></Chip>
            </Box>
            <Box display="flex" alignItems="center">
              <TextField
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                label="Type a message"
                variant="outlined"
                sx={{ flex: 1 }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleSendMessage();
                  }
                }}
              />
              <IconButton onClick={handleSendMessage} color="primary" sx={{ ml: 2 }}>
                {sendMessageMutation.isLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  <SendIcon />
                )}
              </IconButton>
            </Box>
          </Box>

          <Snackbar
            open={warningOpen}
            autoHideDuration={8000}
            onClose={() => setWarningOpen(false)}
          >
            <Alert
              onClose={() => setWarningOpen(false)}
              severity="warning"
              sx={{ width: "100%" }}
            >
              For security reasons, we ask that you communicate with creators
              only through the Blitz platform. Violating this policy could lead
              to your account being terminated, and you would lose access to all
              your company's work and projects on Blitz. Thank you for
              understanding.
            </Alert>
          </Snackbar>
        </>
      )}
    </Box>
  );
};

export default CampaignCreatorSpecific;

// CreatorSelectDialog.js
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  Chip,
  Avatar,
  CircularProgress,
  useTheme,
  Box,
  Stack 
} from "@mui/material";
import { useQuery } from "react-query";
import client from "../../API";
import profilePhoto from "../../Components/globalAssets/ppfLogo.png";

const SelectCreatorDialog = ({ open, onClose, onCreatorSelect }) => {
  const theme = useTheme();
  const { data: creators, isLoading } = useQuery(
    'creators',
    client.creators.list,
    { refetchOnWindowFocus: false }
  );

  const statusColorMap = {
    Active: 'success',
    Verified: 'success',
    Pending: 'warning',
    Inactive: 'error',
    Draft: 'warning',
    Archived: 'default'
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ bgcolor: theme.palette.primary.main, color: "white" }}>
        Select Creator
        <Chip 
          label={`${creators?.length || 0} items`} 
          size="small"
          sx={{ ml: 2, color: "white", bgcolor: theme.palette.primary.dark }}
        />
      </DialogTitle>
      
      <DialogContent sx={{ p: 3, bgcolor: "#f5f5f5" }}>
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={3}>
            {creators?.map((creator) => (
              <Grid item xs={12} sm={6} md={4} key={creator.creator}>
                <Card
                  onClick={() => onCreatorSelect(creator)}
                  sx={{
                    cursor: "pointer",
                    transition: "transform 0.2s, box-shadow 0.2s",
                    "&:hover": {
                      transform: "translateY(-4px)",
                      boxShadow: theme.shadows[4],
                    },
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
                      <Chip
                        label="Creator"
                        size="small"
                        color="secondary"
                        sx={{ mr: 1 }}
                      />
                      {creator.status && (
                        <Chip
                          label={creator.status}
                          size="small"
                          color={statusColorMap[creator.status] || 'default'}
                        />
                      )}
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                      <Avatar 
                        src={creator.pfphref || profilePhoto}
                        sx={{ width: 56, height: 56 }}
                      />
                      <Typography 
                        variant="subtitle1" 
                        sx={{
                          fontWeight: 600,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical"
                        }}
                      >
                        {creator.creator}
                      </Typography>
                    </Box>

                    <Stack spacing={0.5} sx={{ color: 'text.secondary', fontSize: '0.875rem' }}>
                      {creator.primary_market && (
                        <Typography variant="body2">
                          Market: {creator.primary_market?.join(', ')||'N/A'}
                        </Typography>
                      )}
                      {creator.region && (
                        <Typography variant="body2">
                          Region: {creator.region}
                        </Typography>
                      )}
                      {creator.follow_ig && (
                        <Typography variant="body2">
                          Followers: {creator.follow_ig.toLocaleString()}
                        </Typography>
                      )}
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </DialogContent>

      <DialogActions sx={{ p: 3, gap: 2 }}>
        <Button 
          variant="outlined" 
          onClick={onClose}
          color="secondary"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectCreatorDialog;
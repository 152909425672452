import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import client from "../../../../API";
import { useQuery } from "react-query";
import {
  Typography,
  Box,
  Divider,
  Paper,
  ToggleButtonGroup,
  ToggleButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Backdrop,
  Button,
  styled
} from "@mui/material";

// ICONS
import { GridView, ViewList } from "@mui/icons-material";

// MUI theme helpers
import { alpha, useTheme } from "@mui/material/styles";

// Custom components and hooks
import TabPanel from "../../../../Components/TabPanel.js";
import useIsDesktop from "../../../../Hooks/useIsDesktop.js";
import CRMDialog from "../../../Misc/crmComponents/crmPopup.js";
import HomeHeader from "../../../../Components/HomeHeader.js";
import AgencyDetailsPanel from "./agencydetails.js";

const GradientHeader = styled(Box)(({ theme }) => ({
  background: `linear-gradient(135deg, ${theme.palette.primary.main} 20%, ${theme.palette.secondary.main} 100%)`,
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius * 2,
  // Remove horizontal margins and add a top margin for spacing
  margin: theme.spacing(4, 0, 4),
  position: "relative",
  overflow: "hidden",
  boxShadow: theme.shadows[4],
  // Force full viewport width:
  width: "100vw",
  left: "50%",
  right: "50%",
  marginLeft: "-50vw",
  marginRight: "-50vw",
  "&:after": {
    content: '""',
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: "2px",
    backgroundColor: alpha(theme.palette.common.white, 0.2),
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(3),
  },
}));

const ModernToggleButton = styled(ToggleButton)(({ theme }) => ({
  textTransform: "none",
  fontWeight: 600,
  borderRadius: "8px !important",
  padding: theme.spacing(1.5, 2),
  transition: "all 0.3s ease",
  "&.Mui-selected": {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
    boxShadow: theme.shadows[2],
    "&:hover": {
      backgroundColor: alpha(theme.palette.background.paper, 0.9),
    },
  },
}));

const AgencyDetailsPage = () => {
  const theme = useTheme();
  const { manager } = useParams();
  const [agencyDetails, setAgencyDetails] = useState(null);
  const [rosterSettings, setRosterSettings] = useState(null);
  const [viewMode, setViewMode] = useState("list");
  const [showCRMDialog, setShowCRMDialog] = useState(true);
  const [showInstructions, setShowInstructions] = useState(false);
  const isDesktop = useIsDesktop();
  const hasShownMessage = useRef(false);

  useEffect(() => {
    setViewMode(isDesktop ? "list" : "pretty");
  }, [isDesktop]);

  const { isLoading: isLoadingSettings } = useQuery(
    ["publicRosterSettings", manager],
    () => client.agency.getPublicRosterSettings(manager),
    {
      onSuccess: (res) => setRosterSettings(res),
      onError: (err) => {
        if (err?.response?.status !== 404) {
          console.error("Failed to fetch public roster settings:", err);
        }
      },
      refetchOnWindowFocus: false,
    }
  );

  const { data, isLoading: isLoadingCreators } = useQuery(
    ["agencyDetails", manager],
    () => client.creators.listManager(manager),
    {
      onSuccess: (data) => setAgencyDetails({ ...data }),
      onError: (error) => console.error("Failed to fetch agency details:", error),
      refetchOnWindowFocus: false,
    }
  );

  const handleChangeView = (event, newValue) => {
    setViewMode(newValue || (viewMode === "pretty" ? "list" : "pretty"));
  };

  const handleCloseCRMDialog = () => setShowCRMDialog(false);

  if (isLoadingCreators || !agencyDetails) {
    return (
      <Box sx={{ 
        width: "100vw", 
        height: "100vh", 
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center" 
      }}>
        <CircularProgress />
      </Box>
    );
  }

  const mainColor = rosterSettings?.color_scheme?.main || "#6F72FF";
  const accentColor = rosterSettings?.color_scheme?.accent || "#BA88FF";
  const rosterName = rosterSettings?.name || `Creator Roster by: ${manager}`;
  const rosterDescription = rosterSettings?.description || "A curated list of creators and all their social stats.";
  const rosterLogo = rosterSettings?.logo;

  return (
    <>
      <HomeHeader variant="minimal" />

      <Box sx={{ mx: { xs: 2, md: 4 }, mb: 4 }}>
        <GradientHeader sx={{ background: `linear-gradient(135deg, ${mainColor} 20%, ${accentColor} 100%)` }}>
          <Box sx={{ 
            display: "flex", 
            justifyContent: "space-between", 
            alignItems: "flex-start", 
            flexWrap: "wrap", 
            gap: 3 
          }}>
            <Box sx={{ flex: 1, minWidth: 300 }}>
              {rosterLogo && (
                <Box sx={{ 
                  mb: 3, 
                  display: "flex", 
                  alignItems: "center",
                  transition: "transform 0.3s",
                  "&:hover": { transform: "rotate(-2deg) scale(1.05)" }
                }}>
                  <img
                    src={rosterLogo}
                    alt="Agency Logo"
                    style={{ 
                      height: 80, 
                      borderRadius: 12, 
                      marginRight: 16,
                      filter: "drop-shadow(0 4px 12px rgba(0,0,0,0.15))" 
                    }}
                  />
                </Box>
              )}
              <Typography variant="h2" sx={{ 
                fontWeight: 800, 
                letterSpacing: "-0.03em", 
                color: "common.white", 
                mb: 2 
              }}>
                {rosterName}
              </Typography>
              <Typography variant="body1" sx={{ 
                color: alpha(theme.palette.common.white, 0.9), 
                fontSize: "1.1rem", 
                lineHeight: 1.6, 
                maxWidth: 800 
              }}>
                {rosterDescription}
              </Typography>
            </Box>

            <ToggleButtonGroup
              value={viewMode}
              exclusive
              onChange={handleChangeView}
              sx={{ 
                mt: 1, 
                bgcolor: alpha(theme.palette.common.white, 0.1),
                borderRadius: 2,
                alignSelf: "flex-end"
              }}
            >
              <ModernToggleButton value="list" sx={{ color: "common.white" }}>
                <ViewList sx={{ mr: 1, fontSize: "1.2rem" }} /> List View
              </ModernToggleButton>
              <ModernToggleButton value="pretty" sx={{ color: "common.white" }}>
                <GridView sx={{ mr: 1, fontSize: "1.2rem" }} /> Grid View
              </ModernToggleButton>
            </ToggleButtonGroup>
          </Box>
        </GradientHeader>

        <Paper elevation={2} sx={{ 
          p: { xs: 2, md: 4 }, 
          borderRadius: 4, 
          boxShadow: "0 8px 32px rgba(0,0,0,0.05)",
          position: "relative",
          overflow: "hidden"
        }}>
          <AgencyDetailsPanel
            agencyDetails={agencyDetails}
            viewMode={viewMode}
            accentColor={accentColor}
          />
        </Paper>

        {showCRMDialog && (
          <CRMDialog
            isOpen={showCRMDialog}
            handleClose={handleCloseCRMDialog}
            origin={`${manager} - roster`}
          />
        )}

        <Dialog open={showInstructions} onClose={() => setShowInstructions(false)}>
          <DialogTitle sx={{ fontWeight: 700 }}>Welcome to the Agency Roster 🚀</DialogTitle>
          <DialogContent>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Explore your agency's creator roster with powerful viewing options:
            </Typography>
            <ul style={{ paddingLeft: 24, margin: 0 }}>
              <li><Typography variant="body1">Switch between <strong>List View</strong> for detailed insights</Typography></li>
              <li><Typography variant="body1">Use <strong>Grid View</strong> for visual browsing</Typography></li>
              <li><Typography variant="body1">Hover over creator cards for quick stats</Typography></li>
            </ul>
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={() => setShowInstructions(false)} 
              variant="contained"
              sx={{ borderRadius: 2, px: 3 }}
            >
              Let's Explore!
            </Button>
          </DialogActions>
        </Dialog>
      </Box>

      <Backdrop
        sx={{ 
          color: "#fff", 
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backdropFilter: "blur(4px)"
        }}
        open={isLoadingSettings}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default AgencyDetailsPage;
import SearchIcon from '@mui/icons-material/Search';
import { Alert, Backdrop, Box, CircularProgress, FormControlLabel, InputAdornment, MenuItem, Pagination, Paper, Snackbar, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { StyledTableRow } from '../../../../../Utils/styledcell';
import AdminWithdrawalDialog from './adminWithdrawalDialog';
import { useMutation, useQuery } from 'react-query';
import API from '../../../../../API';
import AlertDialog from '../../../../../Components/AlertDialog';
import useAlertDialog from '../../../../../Components/useAlertDialog';
import { withdrawalStatuses } from '../../../../../Utils/constants';

const WithdrawalToSubjectData = (wdw) => {
    switch (wdw.entity_type) {
        case 'company':
            return {
                subject: wdw.company.name,
                subjectPaymentMethod: 'N/A',
                subjectPaymentEmail: 'N/A'
            }
            break;
        case 'creator':
            let subjectPaymentEmail = wdw.creator.payout_email
            let subjectPaymentMethod = 'paypal'
            if (wdw.creator.user) {
                if (wdw.creator.user.payout_preferred == 'PayPal') {
                    subjectPaymentMethod = 'paypal'
                    subjectPaymentEmail = wdw.creator.user.paypal
                }
                if (wdw.creator.user.payout_preferred == 'Stripe') {
                    subjectPaymentMethod = 'stripe'
                    subjectPaymentEmail = wdw.creator.user.stripe_id
                }
            }

            return {
                subject: wdw.creator.creator,
                subjectPaymentMethod,
                subjectPaymentEmail
            }
    }
}

function WithdrawalRow({ wdw, onClickWithdrawal }) {
    const subjectData = WithdrawalToSubjectData(wdw);

    // Create a new Date object by copying the start date
    const paidByDate = new Date(wdw.created_at.getTime());
    // Add the specified number of days
    paidByDate.setDate(paidByDate.getDate() + wdw.days_to_payment);
    const paidByDatePassed = paidByDate.getTime() < Date.now() && wdw.status!='paid';

    return (
        <StyledTableRow
            onClick={() => onClickWithdrawal(wdw.id)}
            hover
            sx={theme => ({
                backgroundColor: paidByDatePassed ? theme.palette.error.main : 'rgba(0,0,0,0)',
                color: paidByDatePassed ? theme.palette.error.contrastText : theme.palette.text.primary,
                '&.MuiTableRow-hover:hover': {
                    backgroundColor: paidByDatePassed ? theme.palette.error.light : theme.palette.action.hover,
                },
                '&.MuiTableRow-root:nth-of-type(odd)': {
                    backgroundColor: paidByDatePassed ? theme.palette.error.dark : theme.palette.action.focus,
                },
                '&.MuiTableRow-hover:nth-of-type(odd):hover': {
                    backgroundColor: paidByDatePassed ? theme.palette.error.light : theme.palette.action.hover,
                },

            })}>
            <TableCell sx={{textWrap:'nowrap'}}>
                {wdw.reference}
            </TableCell>
            <TableCell sx={{ textTransform: 'capitalize' }}>
                {wdw.status}
            </TableCell>
            <TableCell>
                {wdw.created_at.toLocaleString()}
            </TableCell>
            <TableCell>
                {paidByDate.toLocaleString()}
            </TableCell>
            <TableCell>
                ${wdw.amount_no_fee.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </TableCell>
            <TableCell>
                {wdw.fee_percentage.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
            </TableCell>
            <TableCell>
                ${(wdw.amount_no_fee * (1 - (wdw.fee_percentage / 100))).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </TableCell>
            <TableCell>
                {wdw.entity_type}
            </TableCell>
            <TableCell>
                {subjectData.subject}
            </TableCell>
            <TableCell>
                {subjectData.subjectPaymentMethod}
            </TableCell>
            <TableCell>
                {subjectData.subjectPaymentEmail}
            </TableCell>
            <TableCell>
                {wdw.brief}
            </TableCell>
            <TableCell>
                {wdw.notes}
            </TableCell>
            <TableCell>
                Actions
            </TableCell>
        </StyledTableRow>
    )
}

const rowsPerPage = 20;

export default function Withdrawals({ }) {
    const [usePagination, setUsePagination] = useState(true);
    const [statusQuery, setStatusQuery] = useState('all')
    const [page, setPage] = useState(0);
    const [briefSearchBox, setBriefSearchBox] = useState('');
    const [briefQuery, setBriefQuery] = useState('');
    const [pickedWithdrawal, setPickedWithdrawal] = useState(null);
    const [errorToast, setErrorToast] = useState(false);
    const { dialogState, openDialog, closeDialog } = useAlertDialog();

    const { data, error, isFetching, refetch } = useQuery({
        queryKey: ['withdrawals', 'list', { page, statusQuery, briefQuery }],
        queryFn: () => API.withdrawals.adminList({
            page: usePagination ? page : undefined,
            rows: usePagination ? rowsPerPage : undefined,
            search_brief: !!briefQuery ? briefQuery : undefined,
            status: statusQuery
        }),
        refetchOnWindowFocus: false,
    });

    const { mutate, isLoading: updateLoading } = useMutation({
        mutationFn: ({ id, payload }) => API.withdrawals.adminUpdate(id, payload),
        onSuccess: () => {
            HandleCloseWithdrawal();
            openDialog('Success', 'Withdrawal updated succesfully');
            refetch();
        },
        onError: (updateError) => {
            openDialog('Error', <>Error while updating withdrawal<br />{updateError?.response?.data?.error || updateError?.message || ''}</>)
        }
    });

    const OnUpdateWithdrawal = (payload) => {
        mutate({
            id: pickedWithdrawal,
            payload
        });
    }

    useEffect(() => {
        if (error) {
            console.log(error)
            setErrorToast(true);
        }
    }, [error])

    // Debounce search input
    useEffect(() => {
        const delayInputTimeoutId = setTimeout(() => {
            setBriefQuery(briefSearchBox);
        }, 1000);
        return () => clearTimeout(delayInputTimeoutId);
    }, [briefSearchBox]);

    // Reset page on filter change
    useEffect(() => {
        setPage(0);
    }, [briefQuery, usePagination, statusQuery]);

    const HandleClickWithdrawal = (id) => {
        setPickedWithdrawal(id);
    }

    const HandleCloseWithdrawal = () => {
        setPickedWithdrawal(null);
    }


    return (
        <Box sx={{ padding: 2 }}>
            <AlertDialog alertState={dialogState}></AlertDialog>
            <AdminWithdrawalDialog isOpen={!!pickedWithdrawal} withdrawalId={pickedWithdrawal} onCloseAction={HandleCloseWithdrawal} onSaveForm={OnUpdateWithdrawal}></AdminWithdrawalDialog>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => !!pickedWithdrawal ? theme.zIndex.modal + 1 : theme.zIndex.drawer - 1 }} open={isFetching}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar
                open={errorToast}
                autoHideDuration={6000}
                onClose={() => setErrorToast(false)}
            >
                <Alert severity='error' variant='filled' sx={{ width: '100%' }}>
                    {error?.response?.data?.error || error?.message || ''}
                </Alert>
            </Snackbar>
            {/* Control Bar */}
            <Box sx={{
                display: 'flex',
                gap: 2,
                flexWrap: 'wrap',
                alignItems: 'center',
                mb: 3
            }}>
                <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Search..."
                    value={briefSearchBox}
                    onChange={(e) => setBriefSearchBox(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon fontSize="small" color="action" />
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    select
                    value={statusQuery}
                    onChange={event => setStatusQuery(event.target.value)}
                    label='Status'
                    size="small"
                    sx={{ minWidth: '10rem' }}
                >
                    <MenuItem value="all">All Statuses</MenuItem>
                    {withdrawalStatuses.map((withdrawStatus) => <MenuItem key={withdrawStatus} value={withdrawStatus} sx={{ textTransform: 'capitalize' }}>{withdrawStatus}</MenuItem>)}
                </TextField>
                <FormControlLabel value={usePagination} onChange={(event, checked) => setUsePagination(checked)} label='Use Pagination?' control={<Switch></Switch>}>
                </FormControlLabel>

            </Box>
            <Box sx={{ marginBlockStart: 1, maxWidth: '100em', marginInline: "auto" }}>
                <TableContainer component={Paper} elevation={1}>
                    <Table>
                        <TableHead>
                            <StyledTableRow>
                                <TableCell>
                                    Reference #
                                </TableCell>
                                <TableCell>
                                    Status
                                </TableCell>
                                <TableCell>
                                    Issued at
                                </TableCell>
                                <TableCell>
                                    Pay by
                                </TableCell>
                                <TableCell>
                                    Subtotal
                                </TableCell>
                                <TableCell>
                                    Fees %
                                </TableCell>
                                <TableCell>
                                    Total
                                </TableCell>
                                <TableCell>
                                    Subject Type
                                </TableCell>
                                <TableCell>
                                    Subject
                                </TableCell>
                                <TableCell>
                                    Subject Payment Platform
                                </TableCell>
                                <TableCell>
                                    Subject Payment Email
                                </TableCell>
                                <TableCell>
                                    Brief
                                </TableCell>
                                <TableCell>
                                    Notes
                                </TableCell>
                                <TableCell>
                                    Actions
                                </TableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {data?.withdrawals.map((wdw) => (
                                <WithdrawalRow key={wdw.id} wdw={wdw} onClickWithdrawal={HandleClickWithdrawal}></WithdrawalRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {usePagination && <Box sx={{ paddingBlock: 2 }}>
                    <Pagination
                        sx={{ marginInline: 'auto', maxWidth: 'fit-content' }}
                        count={data ? Math.floor(data.total / rowsPerPage) : 0} page={page + 1}
                        onChange={(event, value) => setPage(value - 1)}>
                    </Pagination>
                </Box>}
            </Box>
        </Box>

    )
}

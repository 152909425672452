import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Box,
  Paper,
  Typography,
  Button,
  Grid,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tabs,
  Tab,
  Link,
} from "@mui/material";
import AssetsTab from "../../../CreatorUser/CreatorPages/Dashboard/creatorcomponents/campaignAssets";
import CreatorTimeline from "../../../CreatorUser/CreatorPages/Dashboard/creatorcomponents/creatorTimeline";
import { useMutation } from "react-query";
import useAlertDialog from "../../../Components/useAlertDialog";
import AlertDialog from "../../../Components/AlertDialog";
import { TimelineStatus } from "../../../Utils/constants";
import useIsDesktop from "../../../Hooks/useIsDesktop";
import TabPanelHidden from "../../../Components/TabPanelHidden";

function formatIdealDueDate(dateString) {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return "Invalid Date";
  } else {
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear().toString().substr(-2);
    return `${month}/${day}/${year}`;
  }
}

const AgencyCampaignDialog = ({
  openDialog,
  handleCloseDialog,
  campaign,
  username
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const editedEvents = useRef(false)
  const isDesktop = useIsDesktop();

  const { dialogState, openDialog: openAlert, closeDialog } = useAlertDialog();

  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };


  const creator = useMemo(() => {
    if (!campaign) {
      return undefined;
    }
    const creators = campaign.creators;
    return creators.find(
      (creator) => creator.id === username
    );
  }, [campaign]);

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="xl"
        fullWidth
        scroll="paper"
        fullScreen={!isDesktop}
        PaperProps={{ elevation: 1, sx: { boxShadow: 24 } }}
      >
        {!!campaign && (
          <>
            <DialogTitle sx={{ padding: 0, overflowY: 'clip' }}>
              <Paper elevation={2} square>
                  <Box padding={2} maxWidth={'100%'} minWidth={'0px'} sx={{marginInlineStart:0, marginBlockEnd:{xs:0, md:'-48px'}}}>
                    <Typography variant="h6">
                      Campaign Name: {campaign.name}
                    </Typography>
                    <Typography variant="h6">
                      Ideal Due Date: {formatIdealDueDate(campaign.ideal_duedate)}
                    </Typography>
                    <Typography variant="h6" sx={{wordBreak:'break-all'}}>
                      Brief: <Typography component={'span'} variant="body1">{campaign.brief}</Typography>
                    </Typography>
                    <Typography variant="h6">
                      Status: {campaign.campaign_status}
                    </Typography>
                  </Box>
                  <Box padding={0} sx={{marginInline:'auto', maxWidth:'18rem'}} >
                    <Paper elevation={4}>
                      <Tabs
                        value={tabIndex}
                        onChange={handleChangeTab}
                        aria-label="campaign details tabs"
                        centered
                      >
                        <Tab label="Overview" />
                        <Tab label="Assets" />
                      </Tabs>
                    </Paper>
                  </Box>
              </Paper>
            </DialogTitle>
            <DialogContent dividers={true}>
              <TabPanelHidden value={tabIndex} index={0}>
                <>
                  <Grid
                    container
                    display="flex"
                    justifyContent="space-between"
                    marginBottom={2}
                    spacing={2}
                  >
                    <Grid item xs={12} md={6}>
                      {creator && (
                        <>
                          <Typography variant="h6">
                            Creator Brief: <Typography variant="body1">{creator.creatorBrief || "N/A"}</Typography>
                          </Typography>
                          <Typography variant="h6">
                            Rate: {creator.agencyRate ? `$${creator.agencyRate}` : creator.price ? `$${creator.price}` : "N/A"}
                          </Typography>

                        </>
                      )}
                      <Typography variant="h6" style={{ marginTop: 20, marginBottom: 10 }}>
                        Campaign Manager:
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                          <Typography variant="body2">
                            <strong>Name:</strong> {campaign.campaign_manager.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Typography variant="body2">
                            <strong>Email:</strong> {campaign.campaign_manager.email}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Typography variant="body2">
                            <strong>Phone:</strong> {campaign.campaign_manager.phone_number}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/*<Typography variant="h6">Timeline</Typography>
                  <CreatorTimeline timelineEvents={timelineEvents} onEditField={OnEditTimelineField} onSaveChanges={onSaveChanges} isSavingChanges={isUpdatingTimeline}></CreatorTimeline>*/}
                </>
              </TabPanelHidden>
              <TabPanelHidden value={tabIndex} index={1}>
                <AssetsTab campaignDetails={campaign} creatorEntry={creator} />
              </TabPanelHidden>
            </DialogContent>
          </>
        )}
        <Paper elevation={2} square>
          <DialogActions>
            <Button onClick={handleCloseDialog} variant="text">Close</Button>
          </DialogActions>
        </Paper>
      </Dialog>
      <AlertDialog alertState={dialogState}></AlertDialog>
    </>
  );
};

export default AgencyCampaignDialog;

import React, { useState, useEffect } from 'react';
import {
    Table, TableHead, TableBody, TableRow, TableCell, Paper, TableContainer, Button, TextField, Box,
    CircularProgress, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Typography
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit'; // Import the edit icon
import client from '../../../../../API';

const ClientDetailsView = () => {
    const [clientData, setClientData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [newClient, setNewClient] = useState({
        full_name: '',
        company: '',
        email: '',
        location: '',
        phone_number: '',
        bio: '', // New field
        profilephoto: '' // New field
    });
    const [selectedClient, setSelectedClient] = useState(null); // State for selected client
    const [editDialogOpen, setEditDialogOpen] = useState(false); // Dialog state

    // Function to fetch client data
    const fetchClients = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const res = await client.users.fetchClient();
            console.log("API response:", res);
            setClientData(res); // Update with the correct data
        } catch (err) {
            console.error("Error fetching clients:", err);
            setError(err);
        } finally {
            setIsLoading(false);
        }
    };

    // Fetch client data on component mount
    useEffect(() => {
        fetchClients();
    }, []);

    // Function to add a new client
    const addClient = async () => {
        setIsLoading(true);
        try {
            await client.users.postClient(newClient);
            setNewClient({
                full_name: '',
                company: '',
                email: '',
                location: '',
                phone_number: '',
                bio: '', // Reset bio
                profilephoto: '' // Reset profilephoto
            });
            await fetchClients(); // Refetch clients after adding
        } catch (err) {
            console.error("Error adding client:", err);
            alert("There was an error adding the client.");
        } finally {
            setIsLoading(false);
        }
    };

    // Handle change in input fields for new client and edit client
    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewClient({
            ...newClient,
            [name]: value
        });
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setSelectedClient({
            ...selectedClient,
            [name]: value
        });
    };

    const handleSubmit = () => {
        if (newClient.full_name && newClient.email) {  // Basic validation
            addClient(); // Use the function directly to add the client
        }
    };

    // Open the edit dialog
    const handleEditClick = (client) => {
        setSelectedClient(client);
        setEditDialogOpen(true);
    };

    // Handle dialog close
    const handleDialogClose = () => {
        setEditDialogOpen(false);
        setSelectedClient(null);
    };

    // Submit edit changes
    const handleEditSubmit = async () => {
        try {
            await client.users.updateClient(selectedClient.id, selectedClient);
            setEditDialogOpen(false);
            fetchClients(); // Refetch client data after updating
        } catch (err) {
            console.error("Error updating client:", err);
            alert("There was an error updating the client.");
        }
    };

    const renderTable = () => (
        <TableContainer component={Paper} sx={{ marginBottom: 2 }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Full Name</TableCell>
                        <TableCell>Company</TableCell>
                        <TableCell>Bio</TableCell>
                        <TableCell>Profile Photo</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>Phone Number</TableCell>
                        <TableCell>Date Created</TableCell>
                        <TableCell>Connected</TableCell> {/* New column for check mark */}
                        <TableCell>Last Project</TableCell> {/* New column for check mark */}
                        <TableCell>Edit</TableCell> {/* New Edit column */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {clientData.map((item) => (
                        <TableRow key={item.id}>
                            <TableCell>{item.full_name || 'N/A'}</TableCell>
                            <TableCell>{item.company || 'N/A'}</TableCell>
                            <TableCell>{item.bio || 'N/A'}</TableCell>
                            <TableCell>{item.profilephoto || 'N/A'}</TableCell>
                            <TableCell>{item.email || 'N/A'}</TableCell>
                            <TableCell>{item.location || 'N/A'}</TableCell> {/* Ensure default value */}
                            <TableCell>{item.phone_number || 'N/A'}</TableCell> {/* Ensure default value */}
                            <TableCell>{new Date(item.date_created).toLocaleDateString()}</TableCell>
                            <TableCell>
                                {item.status ? <CheckIcon style={{ color: 'green', fontSize: '30px' }} /> : null} {/* Show check mark if connected */}
                            </TableCell>
                            <TableCell>{item.last_project || 'N/A'}</TableCell> {/* Ensure default value */}
                            <TableCell>
                                <IconButton onClick={() => handleEditClick(item)}>
                                    <EditIcon />
                                </IconButton>
                            </TableCell> {/* Edit button */}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

    if (isLoading) {
        return <CircularProgress />;
    }

    if (error) {
        return <div>Error loading clients: {error.message}</div>;
    }

    return (
        <Box sx={{ padding: 3 }}>
            <Typography variant='h6'>Clients</Typography>
            {clientData?.length > 0 ? renderTable() : <div>No clients available.</div>}

            <Typography variant='h6'>Add New Client</Typography>
            <Box component="form" noValidate autoComplete="off">
                <TextField
                    name="full_name"
                    label="Full Name"
                    value={newClient.full_name}
                    onChange={handleChange}
                    margin="normal"
                    fullWidth
                />
                <TextField
                    name="company"
                    label="Company"
                    value={newClient.company}
                    onChange={handleChange}
                    margin="normal"
                    fullWidth
                />
                <TextField
                    name="bio"
                    label="Bio"
                    value={newClient.bio || ''}
                    onChange={handleChange}
                    margin="normal"
                    fullWidth
                    multiline
                    rows={4}
                />
                <TextField
                    name="profilephoto"
                    label="Profile Photo URL"
                    value={newClient.profilephoto || ''}
                    onChange={handleChange}
                    margin="normal"
                    fullWidth
                />
                <TextField
                    name="email"
                    label="Email"
                    value={newClient.email}
                    onChange={handleChange}
                    margin="normal"
                    fullWidth
                />
                <TextField
                    name="location"
                    label="Location"
                    value={newClient.location}
                    onChange={handleChange}
                    margin="normal"
                    fullWidth
                />
                <TextField
                    name="phone_number"
                    label="Phone Number"
                    value={newClient.phone_number}
                    onChange={handleChange}
                    margin="normal"
                    fullWidth
                />
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: 2 }}
                >
                    Add Client
                </Button>
            </Box>

            {/* Edit Client Dialog */}
            <Dialog open={editDialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Edit Client</DialogTitle>
                <DialogContent>
                    <TextField
                        name="full_name"
                        label="Full Name"
                        value={selectedClient?.full_name || ''}
                        onChange={handleEditChange}
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        name="company"
                        label="Company"
                        value={selectedClient?.company || ''}
                        onChange={handleEditChange}
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        name="bio"
                        label="Bio"
                        value={selectedClient?.bio || ''}
                        onChange={handleEditChange}
                        margin="normal"
                        fullWidth
                        multiline
                        rows={4}
                    />
                    <TextField
                        name="profilephoto"
                        label="Profile Photo URL"
                        value={selectedClient?.profilephoto || ''}
                        onChange={handleEditChange}
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        name="email"
                        label="Email"
                        value={selectedClient?.email || ''}
                        onChange={handleEditChange}
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        name="location"
                        label="Location"
                        value={selectedClient?.location || ''}
                        onChange={handleEditChange}
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        name="phone_number"
                        label="Phone Number"
                        value={selectedClient?.phone_number || ''}
                        onChange={handleEditChange}
                        margin="normal"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={handleEditSubmit} color="primary" variant="contained">Save</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default ClientDetailsView;
import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import client from "../../../API";
import { useMutation } from "react-query";
import { invoiceStatuses, parseLocalFloat, parseLocalInt } from "../../../Utils/constants";

const InvoiceDialog = ({ open, onClose, invoiceInfo = undefined }) => {
  const [poNumber, setPONumber] = useState("");
  const [amountDue, setAmountDue] = useState("0.00");
  const [status, setStatus] = useState("");
  const [platformFee, setPlatformFee] = useState("");
  const [feePercentage, setFeePercentage] = useState("0");
  const [paymentTerm, setPaymentTerm] = useState(30);

  const calculateAmountReceive = () => {
    try {
      const feePercentageFloat = parseLocalFloat(feePercentage);
      const amountDueFloat = parseLocalFloat(amountDue);
      if (feePercentageFloat!==null && amountDueFloat!==null){
        return (amountDueFloat * (1-(feePercentageFloat/100))).toFixed(2);
      }
      return ''
    }
    catch {
      return ''
    }
  }

  const amountReceive = calculateAmountReceive();

  // NEW: handle date_paid
  // We store it in a format that <input type="datetime-local"> recognizes,
  // typically "YYYY-MM-DDTHH:MM"
  const [datePaid, setDatePaid] = useState("");

  useEffect(() => {
    if (invoiceInfo) {
      setPONumber(invoiceInfo.po_number || "");
      setAmountDue(invoiceInfo.amount_due?.toFixed(2) || "0");
      setStatus(invoiceInfo.status || "");
      setPlatformFee(invoiceInfo.platform_fee || "");
      setFeePercentage(invoiceInfo.fee_percentage?.toFixed(0) || "0");
      setPaymentTerm(invoiceInfo.payment_term?.toFixed(0) || '30');

      // Convert an existing ISO string (2025-03-10T15:30:00) to "YYYY-MM-DDTHH:MM"
      // If date_paid doesn't exist, default to empty
      if (invoiceInfo.date_paid) {
        // substring(0,16) to cut off seconds if you want
        setDatePaid(invoiceInfo.date_paid.substring(0, 16));
      } else {
        setDatePaid("");
      }
    }
  }, [invoiceInfo]);

  const { mutate: editInvoice } = useMutation(client.invoices.editAdmin, {
    onSuccess: (data) => {
      onClose(true);
    },
    onError: (error) => {
      console.error("Error submitting invoice:", error);
      alert(
        error?.response?.data?.error ||
          error?.response?.data?.message ||
          error.message
      );
    },
  });

  const handleSubmit = async () => {
    const submissionData = {
      id: invoiceInfo?.id,
      po_number: poNumber,
      amount_due: parseLocalFloat(amountDue),
      fee_percentage: parseLocalFloat(feePercentage),
      status: status,
      payment_term: parseLocalInt(paymentTerm),
      // If user set date, send as ISO; if empty, send empty string to clear
      date_paid: datePaid ? new Date(datePaid).toISOString() : "",
    };

    editInvoice(submissionData);
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="invoice-dialog-title"
    >
      <DialogTitle id="invoice-dialog-title">{"Edit Invoice"}</DialogTitle>
      <DialogContent>
        <TextField
          label="PO Number"
          fullWidth
          margin="dense"
          value={poNumber}
          onChange={(e) => setPONumber(e.target.value)}
          required
        />
        <TextField
          label="Amount Due"
          type="number"
          fullWidth
          margin="dense"
          value={amountDue}
          onChange={(e) => setAmountDue(e.target.value)}
          required
        />

        {/*<TextField
          label="Platform Fee"
          type="number"
          fullWidth
          margin="dense"
          value={platformFee}
          onChange={(e) => setPlatformFee(e.target.value)}
        />*/}
        <TextField
          label="Fee on Amount"
          type="number"
          fullWidth
          margin="dense"
          value={feePercentage}
          onChange={(e) => setFeePercentage(e.target.value)}
        />
        <TextField
          label={invoiceInfo?.invoice_type == 'client' ? "Amount user will receive":"Amount to collect"}
          type="number"
          fullWidth
          margin="dense"
          disabled
          value={amountReceive}
        />

        <TextField
          label="Status"
          select
          fullWidth
          margin="dense"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          required
        >
          {invoiceStatuses.map((poStatus) => (
            <MenuItem key={poStatus} value={poStatus}>
              {poStatus}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          label="Payment Term"
          type="number"
          fullWidth
          margin="dense"
          value={paymentTerm}
          onChange={(e) => setPaymentTerm(e.target.value)}
          required
        />

        {/* NEW: Date Paid Field */}
        <TextField
          label="Date Paid"
          type="datetime-local"
          fullWidth
          margin="dense"
          value={datePaid}
          onChange={(e) => setDatePaid(e.target.value)}
          InputLabelProps={{
            shrink: true, // keep label above the field even when it has a value
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InvoiceDialog;

import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Box,
  Typography,
  Paper,
  Divider,
  Tabs,
  Tab,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  FormHelperText,
  useMediaQuery
} from "@mui/material";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { SketchPicker } from 'react-color';
import client from "../../API";
import TabPanel from "../../Components/TabPanel";
import DeveloperPanel from "./ApiServices/developerPanel";
import { useSearchParams } from "react-router-dom";
import ppfLogo from "../../Components/globalAssets/ppfLogo.png";
import UploadFileDialog from "./Users/sepcupload";
import { Avatar, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const User = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const tabValue = searchParams.get('tab') || 'user';
  const setTabValue = (newValue) => setSearchParams((params) => ({ ...params, tab: newValue }));
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  // User Information State
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    username: '',
    password: '',
    confirmPassword: '',
    address: '',
    city: '',
    state: '',
    country: '',
    phphref: '',

  });

  // Appearance Settings State
  const [appearanceSettings, setAppearanceSettings] = useState({
    darkMode: null,
    fontPreference: 'default',
    uiDensity: 'normal',
    themeColors: { primary: prefersDarkMode ? '#cb534f' : '#7b2a2a', secondary: '#646aff' }
  });

  // Fetch user data
  const { data: userInfo } = useQuery(['user'], client.users.fetchUser, {
    onSuccess: (data) => {
      if (data) {
        setUserData(prev => ({
          ...prev,
          firstName: data.first_name || '',
          lastName: data.last_name || '',
          email: data.email || '',
          username: data.username || '',
          address: data.address || '',
          phphref: data.phphref || ''

        }));
      }
    }
  });

  // Mutations
  const saveUserMutation = useMutation(
    (userData) => client.users.updateUser(userData),
    { onSuccess: () => queryClient.invalidateQueries('user') }
  );

  const saveSettingsMutation = useMutation(
    (settingsData) => {
      if (userSettings?.id) {
        return client.users.updateUserSettings({
          id: userSettings.id,
          ...settingsData
        });
      }
      return client.users.createUserSettings(settingsData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('userSettings');
        queryClient.invalidateQueries('user');
      }
    }
  );

  // Update the userSettings query to handle proper existence check:
  const { data: userSettings } = useQuery(['userSettings'],
    () => client.users.fetchUserSettings(),
  );

  useEffect(() => {
    if (userSettings?.id) {
      setAppearanceSettings({
        darkMode: userSettings.dark_mode,
        fontPreference: userSettings.font_preference,
        uiDensity: userSettings.ui_density,
        themeColors: userSettings.theme_colors || {
          primary: '#1976d2',
          secondary: '#9c27b0'
        }
      });
    }
  }, [userSettings])

  const handleUserInfoChange = (e) => {
    setUserData(prev => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // Existing save mutation in User component
  const handleSaveUserInfo = async () => {
    try {
      await saveUserMutation.mutateAsync({
        first_name: userData.firstName,
        last_name: userData.lastName,
        email: userData.email,
        username: userData.username,
        address: userData.address,
        phphref: userData.phphref
      }, {
        onSuccess: (data) => {
          // Force immediate refresh of user data
          queryClient.invalidateQueries('user');
          // Update local state with new values from server
          setUserData(prev => ({
            ...prev,
            firstName: data.first_name,
            lastName: data.last_name,
            email: data.email,
            username: data.username,
            address: data.address,
            phphref: data.phphref
          }));
        },
        onError: (error) => {
          console.error('Save failed:', error);
          // Add error notification here
        }
      });
    } catch (error) {
      console.error('Error saving user info:', error);
    }
  };
  const handleSaveAppearance = async () => {
    try {
      await saveSettingsMutation.mutateAsync({
        dark_mode: appearanceSettings.darkMode,
        font_preference: appearanceSettings.fontPreference,
        ui_density: appearanceSettings.uiDensity,
        theme_colors: appearanceSettings.themeColors
      });
    } catch (error) {
      console.error('Error saving settings:', error);
    }
  };

  return (
    <Box>
      <Paper square elevation={4} sx={{ marginBlockEnd: 2, paddingBlockStart: 2, paddingInline: 2 }}>
        <Typography variant='h6'>Edit your information</Typography>
        <Tabs
          value={tabValue}
          onChange={(e, newValue) => setTabValue(newValue)}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Account" value="user" />
          <Tab label="Appearance" value="appearance" />
          <Tab hidden={userInfo?.extras?.includes('services')} label="Developers" value="dev" />
        </Tabs>
      </Paper>

      {/* Account Tab */}
      <TabPanel value={tabValue} index='user'>
        <Box sx={{ p: 3, maxWidth: 800 }}>
          <Typography variant="h5" gutterBottom>User Information</Typography>
          <Divider sx={{ mb: 3 }} />

          <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
            <Box sx={{ width: 120, height: 120, mr: 3, position: 'relative' }}>
              <Avatar
                src={userData.phphref || ppfLogo}
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  cursor: 'pointer'
                }}
              >
                {!userData.phphref && <Typography variant="h6">Upload Photo</Typography>}
              </Avatar>
              <IconButton
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  bgcolor: 'background.paper',
                  '&:hover': { bgcolor: 'action.hover' }
                }}
                onClick={() => setUploadDialogOpen(true)}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Box>
            <Typography variant="subtitle1">@{userInfo?.username}</Typography>
          </Box>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="First Name"
                name="firstName"
                value={userData.firstName}
                onChange={handleUserInfoChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Last Name"
                name="lastName"
                value={userData.lastName}
                onChange={handleUserInfoChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                value={userData.email}
                onChange={handleUserInfoChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Username"
                name="username"
                value={userData.username}
                onChange={handleUserInfoChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Street Address"
                name="address"
                value={userData.address}
                onChange={handleUserInfoChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveUserInfo}
                disabled={saveUserMutation.isLoading}
              >
                {saveUserMutation.isLoading ? 'Saving...' : 'Save Changes'}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </TabPanel>

      {/* Appearance Tab */}
      <TabPanel value={tabValue} index='appearance'>
        <Box sx={{ p: 3, maxWidth: 800 }}>
          <Typography variant="h6" gutterBottom>UI Preferences</Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                select
                fullWidth
                label='Dark Mode'
                value={appearanceSettings.darkMode}
                onChange={(e) => setAppearanceSettings(prev => ({ ...prev, darkMode: e.target.value }))}
              >
                <MenuItem value={null}>System Default</MenuItem>
                <MenuItem value={true}>Dark</MenuItem>
                <MenuItem value={false}>Light</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                fullWidth
                label='Font Style'
                value={appearanceSettings.fontPreference}
                onChange={(e) => setAppearanceSettings(prev => ({ ...prev, fontPreference: e.target.value }))}
              >
                <MenuItem value="default">System Default</MenuItem>
                <MenuItem value="roboto">Roboto</MenuItem>
                <MenuItem value="open-sans">Open Sans</MenuItem>
                <MenuItem value="montserrat">Montserrat</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                fullWidth
                label='UI Density'
                value={appearanceSettings.uiDensity}
                onChange={(e) => setAppearanceSettings(prev => ({ ...prev, uiDensity: e.target.value }))}
              >
                <MenuItem value="compact">Compact</MenuItem>
                <MenuItem value="normal">Normal</MenuItem>
                <MenuItem value="comfortable">Comfortable</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>Theme Colors</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormHelperText>Primary Color</FormHelperText>
                  <SketchPicker
                    color={appearanceSettings.themeColors.primary}
                    onChangeComplete={(color) => setAppearanceSettings(prev => ({
                      ...prev,
                      themeColors: { ...prev.themeColors, primary: color.hex }
                    }))}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormHelperText>Secondary Color</FormHelperText>
                  <SketchPicker
                    color={appearanceSettings.themeColors.secondary}
                    onChangeComplete={(color) => setAppearanceSettings(prev => ({
                      ...prev,
                      themeColors: { ...prev.themeColors, secondary: color.hex }
                    }))}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveAppearance}
                disabled={saveSettingsMutation.isLoading}
              >
                {saveSettingsMutation.isLoading ? 'Saving...' : 'Save Preferences'}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </TabPanel>

      {/* Developers Tab */}
      <TabPanel value={tabValue} index='dev'>
        <DeveloperPanel />
      </TabPanel>
      <UploadFileDialog
        open={uploadDialogOpen}
        onClose={() => setUploadDialogOpen(false)}
        onSuccess={(fileData) => {
          // Update userData with the new profile photo URL
          setUserData(prev => ({ ...prev, phphref: fileData.url }));
        }}
      />

    </Box>

  );
};

export default User;
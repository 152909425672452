import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
} from "@mui/material";
import client from "../../../API"; // Adjust the path to your API client
import { useMutation } from "react-query";

const InvoiceEdit = ({ open, onClose, invoiceInfo = {} }) => {
  // Set state variables for the fields that can be edited.
  const [poNumber, setPONumber] = useState(invoiceInfo.po_number || "");
  const [notes, setNotes] = useState(invoiceInfo.notes || "");
  const [clientName, setClientName] = useState(invoiceInfo.client_name || "");
  const [clientEmail, setClientEmail] = useState(invoiceInfo.client_email || "");
  const [clientNotes, setClientNotes] = useState(invoiceInfo.client_notes || "");

  useEffect(() => {
    if (invoiceInfo) {
      setPONumber(invoiceInfo.po_number || "");
      setNotes(invoiceInfo.notes || "");
      setClientName(invoiceInfo.client_name || "");
      setClientEmail(invoiceInfo.client_email || "");
      setClientNotes(invoiceInfo.client_notes || "");
    }
  }, [invoiceInfo]);

  // Use the new route for editing an invoice.
  const { mutate: editInvoice } = useMutation(client.invoices.editInvoice, {
    onSuccess: (data) => {
      onClose(true);
    },
    onError: (error) => {
      console.error("Error updating invoice:", error);
    },
  });

  const handleSubmit = async () => {
    const submissionData = {
      id: invoiceInfo.id,
      po_number: poNumber,
      notes: notes,
      client_name: clientName,
      client_email: clientEmail,
      client_notes: clientNotes,
    };

    editInvoice(submissionData);
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="invoice-dialog-title"
    >
      <DialogTitle id="invoice-dialog-title">Edit Invoice</DialogTitle>
      <DialogContent>
        <TextField
          label="PO Number"
          fullWidth
          margin="dense"
          value={poNumber}
          onChange={(e) => setPONumber(e.target.value)}
          required
        />
        <TextField
          label="Invoice Notes"
          fullWidth
          margin="dense"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          multiline
          rows={3}
        />
        <TextField
          label="Client Name"
          fullWidth
          margin="dense"
          value={clientName}
          onChange={(e) => setClientName(e.target.value)}
        />
        <TextField
          label="Client Email"
          fullWidth
          margin="dense"
          value={clientEmail}
          onChange={(e) => setClientEmail(e.target.value)}
        />
        <TextField
          label="Client Notes"
          fullWidth
          margin="dense"
          value={clientNotes}
          onChange={(e) => setClientNotes(e.target.value)}
          multiline
          rows={3}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InvoiceEdit;

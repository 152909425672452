// src/Pages/PublicUserProfile/PublicUserProfile.jsx

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  Divider,
  Grid,
  Paper,
  styled,
  ThemeProvider,
  Toolbar,
  Typography,
  Avatar,
  useTheme,
} from '@mui/material';

import PlaceIcon from '@mui/icons-material/Place';
import WorkIcon from '@mui/icons-material/Work';
import CampaignIcon from '@mui/icons-material/Campaign';

import { lightTheme } from '../../../Utils/baseTheme';

// Shared site header and footer
import HomeHeader from '../../../Components/HomeHeader';
import HomeFooter from '../../../Components/HomeFooter';

// Reused global UI or hooks
import useAlertDialog from '../../../Components/useAlertDialog';
import AlertDialog from '../../../Components/AlertDialog';
import PageTitle from '../../../Components/PageTitle';
import ChatModal from './chatmodal'; // Remove if not needed

import API from '../../../API';
import defaultPfp from '../../../Components/globalAssets/ppfLogo.png';

// --------------------------------------------------------------------------------
// Styled Components (mirroring original UserProfile styles)
// --------------------------------------------------------------------------------

const ProfileHeaderBackdrop = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  width: '100%',
  background: 'linear-gradient(45deg, #646AFF, #7B2A2A)',
  zIndex: 0,
  padding: theme.spacing(4),
  marginBottom: theme.spacing(4),
  minHeight: 300,
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
}));

const ProfileContainer = styled(Paper)(({ theme }) => ({
  maxWidth: 1200,
  margin: '-10px auto 2rem',
  padding: theme.spacing(4),
  borderRadius: '16px',
  boxShadow: theme.shadows[10],
  position: 'relative',
  background: theme.palette.background.paper,
}));

const UserAvatar = styled(Avatar)(({ theme }) => ({
  width: 200,
  height: 200,
  border: `4px solid ${theme.palette.primary.main}`,
  boxShadow: theme.shadows[6],
  marginBottom: theme.spacing(2),
}));

const StatsCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: '12px',
  background: `linear-gradient(145deg, ${theme.palette.primary.light}, ${theme.palette.primary.dark})`,
  color: theme.palette.common.white,
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'translateY(-5px)',
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #646AFF, #7B2A2A)',
  color: '#FFF',
  borderRadius: '25px',
  padding: '12px 32px',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  letterSpacing: '1px',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: theme.shadows[4],
  },
}));

/**
 * A small utility component to display an info label/value pair with an icon.
 */
const InfoItem = ({ label, value, icon }) => {
  const theme = useTheme();
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Paper
        sx={{
          p: 2,
          height: '100%',
          borderRadius: '12px',
          transition: 'all 0.3s ease',
          '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: 3,
          },
        }}
      >
        <Box display="flex" alignItems="center" gap={2}>
          <Box
            sx={{
              background: theme.palette.primary.main,
              borderRadius: '50%',
              width: 40,
              height: 40,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'white',
            }}
          >
            {icon}
          </Box>
          <Box>
            <Typography variant="subtitle2" color="textSecondary" gutterBottom>
              {label}
            </Typography>
            <Typography variant="h6" fontWeight="600">
              {value || 'N/A'}
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
};

/**
 * Card to display total projects/campaigns count
 */
const TotalProjectsCard = ({ count }) => (
  <Grid item xs={12} sm={6} md={4}>
    <StatsCard>
      <Box display="flex" alignItems="center" gap={2}>
        <CampaignIcon sx={{ fontSize: 40 }} />
        <Box>
          <Typography variant="subtitle2">Total Projects</Typography>
          <Typography variant="h3">{count}</Typography>
        </Box>
      </Box>
    </StatsCard>
  </Grid>
);

// --------------------------------------------------------------------------------
// Main PublicUserProfile Component
// --------------------------------------------------------------------------------

export default function PublicUserProfile() {
  const { userId } = useParams();
  const { dialogState, openDialog } = useAlertDialog();
  const [chatOpen, setChatOpen] = useState(false);
  const [targetUserId, setTargetUserId] = useState(null);

  // Fetch user data publicly using react-query
  const {
    data: user,
    isLoading,
    error,
  } = useQuery(['userPublicDetails', userId], () => API.users.fetchPublicDetails(userId), {
    enabled: !!userId,
    refetchOnWindowFocus: false,
  });

  // Handle errors
  useEffect(() => {
    if (error) {
      openDialog('Error', `Failed to load user data: ${error.message || 'Unknown error'}`);
    }
  }, [error, openDialog]);

  // Show loading
  if (isLoading) {
    return (
      <Backdrop open sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  // If no user returned
  if (!user) {
    return (
      <ThemeProvider theme={lightTheme}>
        <CssBaseline />
        <HomeHeader />
        <Toolbar />
        <Box sx={{ p: 4 }}>
          <Typography variant="h6">No user data found for &quot;{userId}&quot;.</Typography>
        </Box>
        <HomeFooter />
      </ThemeProvider>
    );
  }

  // Destructure user data
  const { first_name, last_name, company, campaigns = [], created_at, pfphref } = user;

  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      {/* Title that displays in the browser tab */}
      <PageTitle title={`Public Profile - @${userId}`} />
      {/* Global alert dialog hook */}
      <AlertDialog alertState={dialogState} />

      {/* Site Header (Nav) */}
      <HomeHeader />
      <Toolbar />

      {/* Content with gradient backdrop for the "hero" area */}
      <ProfileHeaderBackdrop>
        <ProfileContainer>
          <Box display="flex" flexDirection="column" alignItems="center" mb={4}>
            {/* User Avatar */}
            <UserAvatar src={user.phphref || defaultPfp} alt={`${first_name ?? ''} ${last_name ?? ''}`} />

            {/* Name */}
            <Typography variant="h2" gutterBottom sx={{ fontWeight: 700, letterSpacing: 1 }}>
              {first_name} {last_name}
            </Typography>

            {/* Location / Industry */}
            <Box display="flex" justifyContent="center" gap={2} mb={2}>
              <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                <PlaceIcon fontSize="inherit" sx={{ mr: 1 }} />
                {company?.location || 'Global'}
              </Typography>
              {company?.industry && (
                <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                  <WorkIcon fontSize="inherit" sx={{ mr: 1 }} />
                  {company.industry}
                </Typography>
              )}
            </Box>

            {/* Example: Chat button (remove if you don't need public chat) */}
            <ActionButton
              onClick={() => {
                setTargetUserId(userId);
                setChatOpen(true);
              }}
            >
               Login
            </ActionButton>
          </Box>

          <Divider sx={{ my: 4, borderWidth: 2, borderColor: 'divider' }} />

          {/* Stats / Info row */}
          <Grid container spacing={3} sx={{ mb: 4 }}>
            <TotalProjectsCard count={campaigns.length} />
            <InfoItem label="Company" value={company?.name} icon={<WorkIcon />} />
            <InfoItem
              label="Member Since"
              value={created_at ? new Date(created_at).toLocaleDateString() : 'N/A'}
              icon={<WorkIcon />}
            />
          </Grid>

          <Divider sx={{ my: 4, borderWidth: 2, borderColor: 'divider' }} />

          {/* Active Campaigns */}
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 600, mb: 3 }}>
            Active Campaigns
          </Typography>
          {campaigns.length > 0 ? (
            <Grid container spacing={3}>
              {campaigns.map((campaign) => (
                <Grid item xs={12} sm={6} md={4} key={campaign.id}>
                  <Paper
                    sx={{
                      p: 3,
                      height: '100%',
                      borderRadius: '16px',
                      transition: 'transform 0.3s ease',
                      '&:hover': {
                        transform: 'translateY(-5px)',
                        boxShadow: 4,
                      },
                    }}
                  >
                    <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
                      {campaign.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" paragraph>
                      {campaign.description}
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography variant="body1" color="textSecondary">
              No active campaigns currently running.
            </Typography>
          )}
        </ProfileContainer>
      </ProfileHeaderBackdrop>

      {/* Chat Modal (Remove if not needed) */}
      <ChatModal
        open={chatOpen}
        onClose={() => setChatOpen(false)}
        recipientId={targetUserId}
      />

      {/* Site Footer */}
      <HomeFooter />
    </ThemeProvider>
  );
}

// src/components/Agency/AgencyCreator.jsx

import {
    Backdrop,
    CircularProgress,
    Paper,
    Tab,
    Tabs,
} from '@mui/material';
import React, { useEffect } from 'react';
import useIsDesktop from '../../Hooks/useIsDesktop';
import { Link, Outlet, useMatch, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import API from '../../API';
import AlertDialog from '../../Components/AlertDialog';
import useAlertDialog from '../../Components/useAlertDialog';
import { CreatorProvider } from '../../Hooks/agencySelectedCreatorContext';
import PageTitle from '../../Components/PageTitle';

const tabData = [
    { name: 'Edit', route: '' },
    { name: 'Campaigns', route: 'campaigns' },
    { name: 'Partnerships', route: 'partnerships' },
    { name: 'Conversations', route: 'conversations' },
    { name: 'Payouts', route: 'payouts' }, // New Payouts Tab
];

export default function AgencyCreator({ props }) {
    const match = useMatch('/agency/creators/:creatorId/*');
    const currentTab = match?.params?.['*']?.split('/')[0] || ''; // Default to empty string for the index route

    const isDesktop = useIsDesktop();
    const { creatorId } = useParams();
    const { dialogState, openDialog, closeDialog } = useAlertDialog();

    const {
        data: creatorData,
        isLoading,
        refetch,
        error,
    } = useQuery(
        ['creators', creatorId],
        () => API.agency.getCreator(creatorId),
        {
            refetchOnWindowFocus: false,
            enabled: !!creatorId,
        }
    );

    useEffect(() => {
        if (error) {
            openDialog(
                'Error',
                `Error fetching creator: ${error?.response?.data?.error || error.message
                }\n`,
                closeDialog,
                closeDialog,
                'Ok',
                null
            );
            console.error('Network error:', error);
        }
    }, [error, openDialog, closeDialog]);

    return (
        <>
            <PageTitle title={`${creatorId} - Agency - Blitz`}/>
            {/* Loading Backdrop */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1, position:'absolute' }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* Alert Dialog for Errors */}
            <AlertDialog alertState={dialogState}></AlertDialog>

            {/* Tabs Header */}
            <Paper
                sx={{ paddingInline: { xs: 0, md: 2 }, position: 'sticky' }}
                elevation={2}
                square
            >
                <Tabs
                    value={currentTab}
                    centered={isDesktop}
                    variant={isDesktop?'standard' : 'scrollable'}
                >
                    {tabData.map((entry) => (
                        <Tab
                            key={entry.route}
                            label={entry.name}
                            value={entry.route}
                            to={entry.route}
                            component={Link}
                        ></Tab>
                    ))}
                </Tabs>
            </Paper>

            {/* Tab Panels */}
            <CreatorProvider value={{ creatorData, refetch }}>
                <Outlet />
            </CreatorProvider>
        </>
    );
}

import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Paper,
  Divider,
  IconButton,
  Avatar
} from '@mui/material';
import { Close as CloseIcon, Send as SendIcon } from '@mui/icons-material';
import API from '../../../API';
import useAuth from '../../../Hooks/use-auth'; // Add this import

const ChatModal = ({ open, onClose, recipientId }) => {
  const { getCurrrentUser } = useAuth(); // Get current user
  const currentUser = getCurrrentUser();
  const [conversationId, setConversationId] = useState(null);
  const [isLoadingConversation, setIsLoadingConversation] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');

 // 1) Whenever the modal opens, ensure we have a conversation with recipientId
 useEffect(() => {
  if (open && recipientId) {
    setIsLoadingConversation(true);
    setConversationId(null);  // reset conversationId each time we open for new recipient

    API.conversations
      .checkOrCreateConversation({ recipient_id: recipientId })
      .then((res) => {
        // { message: "...", conversation_id: 123 }
        setConversationId(res.conversation_id);
      })
      .catch((err) => {
        console.error('Error checking/creating conversation:', err);
      })
      .finally(() => {
        setIsLoadingConversation(false);
      });
  }
}, [open, recipientId]);
// ... keep existing useEffect and API calls ...

// 2) Once we know the conversationId, fetch its messages
useEffect(() => {
  if (open && conversationId) {
    API.conversations
      .fetchMessages(conversationId)
      .then((data) => {
        setMessages(data);
      })
      .catch((error) => {
        console.error('Error fetching messages:', error);
      });
  }
}, [open, conversationId]);
  const formatMessage = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex).map((part, index) => {
      if (urlRegex.test(part)) {
        return (
          <a
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'inherit', textDecoration: 'underline' }}
          >
            {part}
          </a>
        );
      }
      return part;
    });
  };

  const handleSendMessage = async () => {
    try {
      if (!newMessage.trim() || !conversationId) return;
      const tempMessage = newMessage;
      setNewMessage('');
      await API.conversations.postMessage(conversationId, tempMessage);
      const updated = await API.conversations.fetchMessages(conversationId);
      setMessages(updated);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 700,
          height: '80vh',
          bgcolor: 'background.paper',
          boxShadow: 24,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 2,
          overflow: 'hidden'
        }}
      >
        {/* Header */}
        <Box sx={{ 
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          p: 2,
          bgcolor: 'background.default'
        }}>
          <Typography variant="h6">
            {conversationId ? `Conversation #${conversationId}` : 'Starting Chat...'}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />

        {/* Messages Area */}
        <Box sx={{ 
          flex: 1,
          overflowY: 'auto',
          p: 2,
          bgcolor: 'background.default'
        }}>
          {isLoadingConversation ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
              <CircularProgress />
            </Box>
          ) : messages.map((msg) => {
            const isCurrentUser = msg.sender_id === currentUser?.id;
            return (
              <Box
                key={msg.id}
                sx={{
                  display: 'flex',
                  justifyContent: isCurrentUser ? 'flex-end' : 'flex-start',
                  mb: 2
                }}
              >
                <Paper
                  elevation={2}
                  sx={{
                    maxWidth: '70%',
                    p: 2,
                    borderRadius: 3,
                    bgcolor: isCurrentUser ? 'primary.main' : 'background.paper',
                    color: isCurrentUser ? 'primary.contrastText' : 'text.primary'
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{ 
                      display: 'block', 
                      mb: 1,
                      color: isCurrentUser ? 'primary.contrastText' : 'text.secondary'
                    }}
                  >
                    {msg.sender?.name || `User #${msg.sender_id}`} •{' '}
                    {new Date(msg.created_at).toLocaleTimeString()}
                  </Typography>
                  <Typography variant="body1">
                    {formatMessage(msg.message)}
                  </Typography>
                </Paper>
              </Box>
            );
          })}
        </Box>

        {/* Input Area */}
        <Box sx={{ p: 2, bgcolor: 'background.default' }}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Type your message..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleSendMessage();
                }
              }}
              disabled={!conversationId}
            />
            <Button
              variant="contained"
              endIcon={<SendIcon />}
              onClick={handleSendMessage}
              disabled={!newMessage.trim() || !conversationId}
            >
              Send
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ChatModal;
import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import React from 'react'
import { ethnicity, genders, niches, region } from '../../Utils/constants'
import MuiPhoneInput from '../MuiPhoneInput'

export default function CreatorInfo({
    creatorName,
    setCreatorName,
    creatorRegion,
    setCreatorRegion,
    race,
    setRace,
    gender,
    setGender,
    location,
    setLocation,
    primaryMarket,
    setPrimaryMarket,
    notesContentStyle,
    setNotesContentStyle,
    contactEmail,
    setContactEmail,
    phoneNumber,
    setPhoneNumber,
    validationError,
    paymentEmail,
    setPaymentEmail,
    emailSame,
    setEmailSame,
    pfphref,
    setPfphref,
    require=true
}) {

    const handleEmailSameChange = (event) => {
        setEmailSame(event.target.checked);
        if (event.target.checked) {
            setPaymentEmail(contactEmail);
        } else {
            setPaymentEmail("");
        }
    };

    return (
        <>
            <Typography variant="h5" sx={{ marginBlockEnd: 2, marginBlockStart: 1 }}>Creator information</Typography>
            <TextField
                margin="dense"
                label="Creator Name"
                type="text"
                fullWidth
                value={creatorName}
                onChange={(e) => setCreatorName(e.target.value)}
                variant="outlined"
                required
                error={!!validationError}
                helperText={validationError}
                disabled={!setCreatorName}
            />
            <FormControl variant="outlined" fullWidth margin="dense" required>
                <InputLabel>Creator Region</InputLabel>
                <Select
                    label="Creator Region"
                    value={creatorRegion}
                    required={require}
                    onChange={(e) => setCreatorRegion(e.target.value)}
                >
                    {region.map((value) => (<MenuItem key={value} value={value}>{value == 'NACAUKAUS' ? 'USA, Canada, UK, Australia' : value}</MenuItem>))}
                </Select>
            </FormControl>
            <FormControl variant="outlined" fullWidth margin="dense">
                <InputLabel id="race-select-label">Race (optional)</InputLabel>
                <Select
                    labelId="race-select-label"
                    value={race}
                    required={require}
                    onChange={(e) => setRace(e.target.value)}
                    label="Race (optional)"
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {ethnicity.map((value) => (<MenuItem key={value} value={value}>{value}</MenuItem>))}
                </Select>
            </FormControl>
            <FormControl variant="outlined" fullWidth margin="dense" required>
                <InputLabel id="gender-select-label">Gender</InputLabel>
                <Select
                    labelId="gender-select-label"
                    value={gender}
                    required={require}
                    onChange={(e) => setGender(e.target.value)}
                    label="Gender"
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {genders.map((value) => (<MenuItem key={value} value={value}>{value}</MenuItem>))}
                </Select>
            </FormControl>
            <TextField
                name='location'
                margin="dense"
                label="Location"
                type="text"
                fullWidth
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                variant="outlined"
                required={require}
            />
            <FormControl variant="outlined" fullWidth margin="dense" required>
                <InputLabel id="primary-market-label">Primary Market</InputLabel>
                <Select
                    name='primary-market'
                    labelId="primary-market-label"
                    value={primaryMarket}
                    onChange={(e) => setPrimaryMarket(e.target.value)}
                    multiple
                    label="Primary Market"
                >
                    {niches.map((market) => (
                        <MenuItem key={market} value={market}>
                            {market}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <TextField
                name='bio'
                margin="dense"
                label="Bio"
                type="text"
                fullWidth
                multiline
                rows={4}
                placeholder="Tell us about yourself"
                variant="outlined"
                value={notesContentStyle}
                required={require}
                onChange={(e) => setNotesContentStyle(e.target.value)}
            />
            <TextField
                name='contact-email'
                margin="dense"
                label="Contact Email"
                type="email"
                fullWidth
                value={contactEmail}
                onChange={(e) => setContactEmail(e.target.value)}
                variant="outlined"
                required={require}
            />
            {paymentEmail !== undefined && <>
                <FormControlLabel
                    control={
                        <Checkbox checked={emailSame} onChange={handleEmailSameChange} />
                    }
                    label="Payment and contact email are the same"
                />
                <TextField
                    margin="dense"
                    label="Paypal Payment Email"
                    type="email"
                    fullWidth
                    disabled={emailSame}
                    value={paymentEmail}
                    onChange={(e) => setPaymentEmail(e.target.value)}
                    variant="outlined"
                    required={!emailSame}
                />
            </>}
            <MuiPhoneInput
                country={'us'}
                preferredCountries={['us']}
                value={phoneNumber}
                onChange={setPhoneNumber}
                isValid={(value) => value.length >= 10}
                required
            />
            {pfphref !== undefined && <TextField
                margin="dense"
                label="Profile Photo URL"
                type="text"
                fullWidth
                value={pfphref}
                onChange={(e) => setPfphref(e.target.value)}
                variant="outlined"
                placeholder="Enter URL for profile photo"
            />}
        </>
    )
}

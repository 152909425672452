import React, { useEffect } from "react";
import {
  Paper,
  Typography,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Backdrop,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useAlertDialog from "../../../Components/useAlertDialog";
import AlertDialog from "../../../Components/AlertDialog";
import useIsDesktop from "../../../Hooks/useIsDesktop";
import API from "../../../API";
import PageTitle from "../../../Components/PageTitle";
import { useNavigate, useParams } from "react-router-dom";
import { useCreator } from "../../../Hooks/agencySelectedCreatorContext";

function formatIdealDueDate(dateString) {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return "Invalid Date";
  } else {
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear().toString().substr(-2);
    return `${month}/${day}/${year}`;
  }
}

const AgencyPartnershipDialog = ({ }) => {
  const isDesktop = useIsDesktop();
  const navigate = useNavigate();
  const { partnershipId } = useParams();
  const { creatorData } = useCreator();
  const { dialogState, openDialog, closeDialog } = useAlertDialog();
  const { data: partnershipData, isLoading, error } = useQuery(
    {
      queryKey: ['partnerships', partnershipId],
      queryFn: () => API.partnerships.fetchPartnership(partnershipId),
      enabled: !!partnershipId && !!creatorData,
      refetchOnWindowFocus: false
    }
  );

  const partnership = partnershipData?.partnership;

  useEffect(() => {
    if (error) {
      openDialog(
        'Error',
        <>Error fetching campaign: <br />{error?.response?.data?.error || error.message}</>,
        handleClose,
        handleClose,
        'Ok',
        null
      );
      console.error('Network error:', error);
    }
  }, [error]);

  const handleCloseDialog = () => {
    navigate('..'); // Navigate back to the campaigns route
  };

  return (
    <>
      <PageTitle title={`Partnership: ${partnership?.name || '...'} - Agency - Blitz`} />
      <AlertDialog alertState={dialogState}></AlertDialog>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
        open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={!!partnership}
        onClose={handleCloseDialog}
        maxWidth={isLoading ? "sm" : "md"}
        fullWidth
        scroll="paper"
        fullScreen={!isDesktop}
        PaperProps={{ elevation: 1, sx: { boxShadow: 24 } }}
      >
        {!!partnership && (
          <>
            <Paper square elevation={2}>
              <DialogTitle>
                Partnership Info
              </DialogTitle>
            </Paper>

            <DialogContent dividers={true}>
              <Typography variant="h6">Partnership: {partnership?.name}</Typography>
              <Typography variant="subtitle1">Ideal due date: {partnership?.finish_date?.toDateString() || 'N/A'}</Typography>
              <Typography variant="subtitle1">Offer: {partnership?.total_sum?.toFixed(2) || 'N/A'}</Typography>
              <Typography variant="subtitle1">Brief:
                <Typography variant="body2" component='span'> {partnership?.description}</Typography></Typography>

            </DialogContent>
          </>
        )}
        <Paper elevation={2} square>
          <DialogActions>
            <Button variant="contained" onClick={handleCloseDialog}>Close</Button>
          </DialogActions>
        </Paper>
      </Dialog>
    </>
  );
};

export default AgencyPartnershipDialog;
